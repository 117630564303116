import type { UseQueryResult } from '@tanstack/react-query'
import type {
  CreateCompanyReportSettingMutation,
  CreateCompanyReportSettingMutationVariables,
  CreateCompanyAndCompanyReportSettingMutation,
  CreateCompanyAndCompanyReportSettingMutationVariables,
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables,
  GetCompanyQuery,
  GetCompanyQueryVariables,
  GetCompanyReportSettingQuery,
  GetCompanyReportSettingQueryVariables,
  ListCompanyByAllowedByAndCreatedAtQuery,
  ListCompanyByAllowedByAndCreatedAtQueryVariables,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  UpdateCompanyReportSettingMutation,
  UpdateCompanyReportSettingMutationVariables
} from 'src/API'
import type { CustomUseMutationResult, TUseQueryOptions } from 'src/common/types'
import {
  deleteCompany,
  updateCompany,
  createCompanyReportSetting,
  updateCompanyReportSetting
} from 'src/graphql/mutations'
import { createCompanyAndCompanyReportSetting } from 'src/graphql/custom/mutations'
import { getCompany, getCompanyReportSetting, listCompanyByAllowedByAndCreatedAt } from 'src/graphql/queries'
import { useCommonQuery, useCommonMutation } from './common'

export const useGetCompany = ({
  variables,
  options,
  key
}: TUseQueryOptions<GetCompanyQueryVariables>): UseQueryResult<GetCompanyQuery> => {
  return useCommonQuery<GetCompanyQuery, GetCompanyQueryVariables>({
    variables,
    query: getCompany,
    queryKey: 'getCompany',
    deps: key,
    options
  })
}

export const useListCompanies = ({
  variables,
  options,
  key
}: TUseQueryOptions<ListCompanyByAllowedByAndCreatedAtQueryVariables>): UseQueryResult<ListCompanyByAllowedByAndCreatedAtQuery> => {
  return useCommonQuery<ListCompanyByAllowedByAndCreatedAtQuery, ListCompanyByAllowedByAndCreatedAtQueryVariables>({
    variables,
    query: listCompanyByAllowedByAndCreatedAt,
    queryKey: 'listCompanyByAllowedByAndCreatedAt',
    deps: key,
    options
  })
}

export const useGetCompanyReportSetting = ({
  variables,
  options,
  key
}: TUseQueryOptions<GetCompanyReportSettingQueryVariables>): UseQueryResult<GetCompanyReportSettingQuery> => {
  return useCommonQuery<GetCompanyReportSettingQuery, GetCompanyReportSettingQueryVariables>({
    variables,
    query: getCompanyReportSetting,
    queryKey: 'getCompanyReportSetting',
    deps: key,
    options
  })
}

export function useCreateCompanyMutation(): CustomUseMutationResult<
  CreateCompanyAndCompanyReportSettingMutation,
  CreateCompanyAndCompanyReportSettingMutationVariables
> {
  return useCommonMutation<
    CreateCompanyAndCompanyReportSettingMutation,
    CreateCompanyAndCompanyReportSettingMutationVariables
  >(createCompanyAndCompanyReportSetting)
}

export function useUpdateCompanyMutation(): CustomUseMutationResult<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
> {
  return useCommonMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(updateCompany)
}

export function useDeleteCompanyMutation(): CustomUseMutationResult<
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables
> {
  return useCommonMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(deleteCompany)
}

export function useCreateCompanyReportSettingMutation(): CustomUseMutationResult<
  CreateCompanyReportSettingMutation,
  CreateCompanyReportSettingMutationVariables
> {
  return useCommonMutation<CreateCompanyReportSettingMutation, CreateCompanyReportSettingMutationVariables>(
    createCompanyReportSetting
  )
}

export function useUpdateCompanyReportSettingMutation(): CustomUseMutationResult<
  UpdateCompanyReportSettingMutation,
  UpdateCompanyReportSettingMutationVariables
> {
  return useCommonMutation<UpdateCompanyReportSettingMutation, UpdateCompanyReportSettingMutationVariables>(
    updateCompanyReportSetting
  )
}
