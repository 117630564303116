import React from 'react'
import { AddonId, type CompanyAddonV2 } from 'src/API'
import { useListCompanyAddonByCompanyId } from '../services'

type Routes = {
  path: string
  component: React.LazyExoticComponent<React.FC>
  breadcrumbs: { name: string; path?: string }[]
}

const DailyDataExportPage = React.lazy(
  () => import(/* webpackChunkName: "DailyDataExportPage" */ '../containers/pages/addons/dailyDataExport')
)

const DailyReportPage = React.lazy(
  () => import(/* webpackChunkName: "DailyReportPage" */ '../containers/pages/addons/dailyReport')
)

/**
 * アドオンIDとアドオンコンポーネントのマッピング
 */
export const ADDON_COMPONENT_MAPPING: Record<keyof typeof AddonId, Pick<Routes, 'component'>> = {
  [AddonId.daily_data_export]: {
    component: DailyDataExportPage
  },
  [AddonId.daily_report]: {
    component: DailyReportPage
  }
}

export const useAddonRoutes = (): { isLoading: boolean; addonsRoutes: Routes[] } => {
  const { data: addonData, isLoading } = useListCompanyAddonByCompanyId({})
  const addons = addonData?.listCompanyAddonV2s
  const addonsRoutes =
    (addons?.items as CompanyAddonV2[] | undefined)?.map(({ addonId, name }) => ({
      path: `/addon/${addonId}`,
      breadcrumbs: [{ name }],
      ...ADDON_COMPONENT_MAPPING[addonId]
    })) || []

  return { isLoading, addonsRoutes }
}
