import { css, type SerializedStyles } from '@emotion/react'
import { theme } from './index'

export const utilityStyles = (): SerializedStyles => css`
  .u-ws-pre {
    word-break: break-all;
    white-space: pre-line;
  }
  .u-lc-2 {
    display: box;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
  }
  .u-d-pc {
    display: none !important;
  }

  .u-d-tb {
    display: none !important;
  }

  .u-d-sp {
    display: block !important;
  }
  @media (min-width: ${theme.base.viewport.desktop}px) {
    .u-d-pc {
      display: block !important;
    }

    .u-d-sp {
      display: none !important;
    }
  }
`
