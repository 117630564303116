import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LinkText } from 'src/components/ui-elements/LinkText'
import { Paper } from 'src/components/ui-elements/Paper'
import { AppLogo } from 'src/components/ui-elements/AppLogo'
import { Flex } from 'src/components/layouts/Flex'
import { Inner } from 'src/components/layouts/Inner'
import { convertMailToLinkText, type ErrMessage } from 'src/common/helper'

const Forbidden: React.FC = () => {
  const { state } = useLocation()

  const navigate = useNavigate()
  React.useEffect(() => {
    if (!state) navigate('/')
  }, [navigate, state])

  const message = (state as { message?: ErrMessage['message'] })?.message

  return (
    <div style={{ marginTop: ' 100px' }}>
      <Flex align="center" direction="column" vAlign="middle">
        <div className="mb-8 text-center">
          <AppLogo width={200} display="inline-block" />
        </div>
        <Paper radius={4} className={'p-6'}>
          <Inner size={2}>
            <h1 className="mb-8 text-center text-xl font-bold md:text-2xl">アクセスが拒否されました</h1>
            <div className="mb-4">
              {typeof message === 'string' ? (
                <>{message}</>
              ) : (
                message?.map((m, i) => (
                  <React.Fragment key={i}>
                    {i !== 0 && <br />}
                    {convertMailToLinkText(m)}
                  </React.Fragment>
                ))
              )}
            </div>
            <div>
              <LinkText to="/login" name="ログイン画面へ" />
            </div>
          </Inner>
        </Paper>
      </Flex>
    </div>
  )
}

export default Forbidden
