import { Amplify, Analytics, Auth } from 'aws-amplify'
// @ts-ignore
import config from 'src/aws-exports'
import { AWSKinesisProviderWrapper } from 'src/lib/amplify/analytics/provider'
import {
  registerSessionTracking,
  registerPageViewTracking,
  registerPageEventTracking
} from 'src/lib/amplify/analytics/helper'

/**
 * Amplify環境名
 * `omt-console-storagehoge-hoge` -> `hoge`
 */
export const amplifyEnvName = config.aws_user_files_s3_bucket.split('-').at(-1) as string

/**
 * Analytics Custom Provider
 */
export const analyticsProvider = new AWSKinesisProviderWrapper(
  {
    // KinesisProviderにすると`aws-exports.js`に登録されないので..
    streamName: `omtConsoleAnalytics-${amplifyEnvName}`
  },
  {
    region: config.aws_project_region,
    bufferSize: 1000,
    flushSize: 100,
    flushInterval: 1000,
    resendLimit: 3
  }
)

export const cofigureAmplify = (): void => {
  Analytics.addPluggable(analyticsProvider)

  // initialize
  Amplify.configure({
    ...config,
    API: {
      graphql_headers: async () => {
        const session = await Auth.currentSession()
        return {
          Authorization: session.getIdToken().getJwtToken()
        }
      }
    }
  })

  // Amplify Analytics Auto Tracking
  registerSessionTracking()
  registerPageViewTracking()
  registerPageEventTracking()
}
