import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClassNamesExport from 'classnames'
import * as React from 'react'
import styled, { type ITheme } from 'src/common/styles'
import type { ComponentStyleProps } from 'src/common/types'

type HeaderProps = {
  title?: string
} & ComponentStyleProps

type OverlayProps = {
  onClick(): void
} & ComponentStyleProps

type Props = {
  modalName: string
  name: string
  title?: string
  onClick(): void
  children: React.ReactNode
} & ComponentStyleProps

type PanelProps = {
  children: React.ReactNode
} & ComponentStyleProps

export const ScModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  overflow-y: scroll;
  transition: all 1s;
  opacity: 0;
  display: none;
  color: #4a4a4a;

  &.fadeIn {
    transition: all 1s;
    display: block;
    opacity: 1;
  }
`

export const ScModalInner = styled.div`
  ${({ theme }: { theme: ITheme }): string => `
    padding: ${theme.base.size * 4}px;
  `};
`

export const ScModalHeader = styled.div<HeaderProps>`
  ${({ theme }: { theme: ITheme }): string => `
    font-weight: bold;
    font-size: 0.875rem;
    padding: ${theme.base.size * 2}px ${theme.base.size * 3}px;
    border-bottom: 1px solid ${theme.base.borderColor};
  `};
`

export const ScModalOverlay = styled.div`
  display: block;
  z-index: -1;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const ScModalPanel = styled.div<PanelProps>`
  ${({ theme }): string => `
    position: relative;
    font-weight: normal;
    background: #fff;
    min-height: 200px;
    padding: 0;
    width: auto;
    border-radius: 8px;
    margin: 100px ${theme.base.size * 2}px;

    @media (min-width: ${theme.base.viewport.desktop}px) {
      width: 70%;
      padding: 0;
      margin: 100px auto 0;
    }
  `};
`

const ScCloseButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 1rem;
  right: 1rem;
`

export const ModalHeader: React.FC<HeaderProps> = (props) => {
  const { className, title } = props
  return <ScModalHeader className={ClassNamesExport(className)}>{title}</ScModalHeader>
}

export const ModalOverlay: React.FC<OverlayProps> = (props) => {
  const { className, onClick } = props
  return <ScModalOverlay className={ClassNamesExport(className)} onClick={(): void => onClick()} />
}

export const ModalPanel: React.FC<PanelProps> = (props) => {
  const { className, children } = props
  return <ScModalPanel className={ClassNamesExport(className)}>{children}</ScModalPanel>
}

export const Modal: React.FC<Props> = (props) => {
  const { modalName, className, name, title, children, onClick } = props
  return (
    <ScModal className={ClassNamesExport({ fadeIn: name === modalName }, className)}>
      <ModalPanel>
        {title && <ModalHeader title={title} />}
        <ScModalInner>{children}</ScModalInner>
        <ScCloseButton onClick={onClick} data-test={`${modalName}-close-btn`}>
          <FontAwesomeIcon icon={faTimes} />
        </ScCloseButton>
      </ModalPanel>
      <ModalOverlay onClick={onClick} />
    </ScModal>
  )
}
