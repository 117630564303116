import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'
import type { ObjectKeys } from 'src/common/types'

const BASE = {
  flexCol: 16,
  flexMaxWidth: 100,
  flexColGutters: 2
}

export type ITheme = {
  base: {
    backgroundColor: string
    borderColor: string
    size: number
    viewport: {
      phone: number
      desktop: number
      largeDesktop: number
    }
    maxWidth: string
    fontFamily: string
    fontSize: string
    lineHeight: number
  }
  font: {
    color: string
    size: {
      extraSmall: string
      small: string
      medium: string
      large: string
    }
  }
  color: {
    default: `#${string}`
    white: `#${string}`
    primary: `#${string}`
    error: `#${string}`
    warning: `#${string}`
    success: `#${string}`
    info: `#${string}`
    secondary: `#${string}`
    action: `#${string}`
    caution: `#${string}`
    ok: `#${string}`
    ng: `#${string}`
    review: `#${string}`
    disable: `#${string}`
  } & ObjectKeys
  status: {
    OK: string
    NG: string
    REVIEW: string
    NONE: string
    VALID: string
    INVALID: string
    DUPLICATED: string
    UNIDENTIFIED: string
    WAITING: string
  } & ObjectKeys
  companyStatus: Record<'CREATED' | 'INSTALL' | 'READY' | 'RUNNING' | 'SUSPENDED' | 'TERMINATED', string> & ObjectKeys
  flex: {
    col: number
    maxWidth: number
    gutters: number
    colWidth: number
    colWidthGutters: number
  }
}

export const theme: ITheme = {
  base: {
    backgroundColor: '#ecf0f5',
    borderColor: '#e6e6e6',
    size: 8,
    viewport: {
      phone: 768,
      desktop: 769,
      largeDesktop: 1020
    },
    maxWidth: '1200px',
    fontFamily: '"Yu Gothic", "Hiragino Kaku Gothic ProN", Meiryo, Arial, sans-serif',
    fontSize: '100%',
    lineHeight: 1.6
  },
  font: {
    color: '#222d32',
    size: {
      extraSmall: '10px',
      small: '12px',
      medium: '14px',
      large: '18px'
    }
  },
  color: {
    default: '#ccc',
    white: '#fff',
    primary: '#3143cf',
    error: '#de4357',
    warning: '#f39c11',
    success: '#00a65a',
    info: '#3143cf',
    secondary: '#2b506f',
    action: '#00a65a',
    caution: '#de4357',
    ok: '#00a65a',
    ng: '#de4357',
    review: '#f39c11',
    disable: '#ccc',
    none: '#ccc'
  },
  status: {
    OK: '#00a65a',
    NG: '#de4357',
    REVIEW: '#f39c11',
    NONE: '#ccc',
    VALID: '#00a65a',
    INVALID: '#de4357',
    DUPLICATED: '#f39c11',
    UNIDENTIFIED: '#f39c11',
    WAITING: '#ccc'
  },
  companyStatus: {
    CREATED: '#f39c11',
    INSTALL: '#f39c11',
    READY: '#f39c11',
    RUNNING: '#3143cf',
    SUSPENDED: '#ccc',
    TERMINATED: '#2b506f'
  },
  flex: {
    col: BASE.flexCol,
    maxWidth: BASE.flexMaxWidth,
    gutters: BASE.flexColGutters,
    colWidth: BASE.flexMaxWidth / BASE.flexCol,
    colWidthGutters: (BASE.flexMaxWidth + BASE.flexColGutters) / BASE.flexCol
  }
}

export default styled

export { css, keyframes }
