import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

import { devtoolsInNonProd } from 'src/store/devtools'

type AuthenticateState = {
  isAuthenticated: boolean
  setAuth: () => void
  reset: () => void
}

/**
 * 認可の状態
 * {@link https://github.com/cacco-product/omoti-console/issues/1272}
 */
export const useAuthenticateStore = create<AuthenticateState>()(
  devtoolsInNonProd(
    persist(
      (set) => ({
        isAuthenticated: false,
        setAuth: () => set({ isAuthenticated: true }, false, 'setAuth'),
        reset: () => set({ isAuthenticated: false })
      }),
      { name: 'omt-authed-store' }
    ),
    { name: 'authenticateStore' }
  )
)

export const useShallowAuthenticateStore = (): Omit<AuthenticateState, 'reset'> =>
  useAuthenticateStore(
    useShallow((state) => ({
      isAuthenticated: state.isAuthenticated,
      setAuth: state.setAuth
    }))
  )
