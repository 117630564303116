import React, { type ReactNode } from 'react'
import { useFetchCognitoUser } from 'src/common/hooks'
import { Alert } from 'src/components/ui-elements/Alert'

type Props = {
  roleTypes: string[]
  alertText?: string
  children: ReactNode
}

export const UserRoleChecker: React.FC<Props> = ({ children, roleTypes, alertText }) => {
  const { user } = useFetchCognitoUser()
  const [role] = user?.getSignInUserSession()?.getIdToken().payload['cognito:groups'] || []
  if (!roleTypes.includes(role) && alertText)
    return (
      <Alert className={'test-alert'} variant="error">
        {alertText}
      </Alert>
    )
  if (!roleTypes.includes(role)) return null
  return <>{children}</>
}
