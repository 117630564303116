import { Auth } from 'aws-amplify'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import type { ErrMessage } from 'src/common/helper'

type ErrorHandler = {
  errMessage: ErrMessage | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSetError: (errors: any) => void
}

export function useErrorHandler(): ErrorHandler {
  const navigate = useNavigate()
  const [errMessage, onSetError] = React.useState<ErrMessage>()

  React.useEffect(() => {
    const handleError = async (): Promise<void> => {
      if (errMessage?.code === 401) {
        await Auth.signOut()
      }
      if (errMessage?.code === 403 && errMessage?.isRedirect) {
        navigate('/403', {
          state: {
            message: errMessage.message
          },
          replace: true
        })
        await Auth.signOut()
      }
    }
    handleError()
  }, [errMessage, navigate])

  return { errMessage, onSetError }
}
