/**
 * PageIdとURLパスとのマッピング
 */
const PageIdAndPathnameMapping = {
  top: '/',
  login: '/login',
  // パスワード忘れ入力
  forgotPassword: '/forgot-password',
  // 個人設定
  profile: '/settings/profile',
  notifications: '/settings/notifications',
  // 審査
  authoriSimpleSearch: '/authori/simple',
  authoriAdvanceSearch: '/authori/advance',
  authoriDetail: /^\/authori\/\d+\w+$/, // authoriIdのパスパラメータ
  authoriExport: '/authori/export',
  // 端末
  userDeviceSearch: '/user_device',
  userDeviceDetail: /^\/user_device\/\w+$/, // esUserDeviceIdのパスパラメータ
  userDeviceUsersCalculation: '/user_device/users_calculation',
  userDeviceDeviceCalculation: '/user_device/device_calculation',
  // IP
  ipAggs: '/ip/aggregation',
  ipAggsExport: '/ip/aggregation/export',
  // 分析
  dashboardTime: '/analytics/dashboard/time',
  dashboardDay: '/analytics/dashboard/day',
  report: '/analytics/report',
  // アドオン
  dailyExport: '/addon/daily_data_export',
  dailyReport: '/addon/daily_report',
  // 設定
  account: '/account',
  auditLog: '/company/audit-log',
  ipReportSetting: '/ip-report-setting',
  forbidden: '/403',
  // 運営は一括でまとめる
  management: /^\/management(:?\/[^/]+)+$/
} as const

type PageId = keyof typeof PageIdAndPathnameMapping

/**
 * 現在のpageIdを返す.
 *
 * 表示中のURLパスから算出
 */
export const getCurrentPageId = (): PageId | 'unknown' => {
  const currentPath = window.location.pathname
  const mappings = Object.entries(PageIdAndPathnameMapping) as [PageId, (typeof PageIdAndPathnameMapping)[PageId]][]
  // pathnameのstringで検証
  const mapping = mappings.find(([, path]) => currentPath === path)
  if (mapping) {
    const [pageId] = mapping
    return pageId
  }
  // pathパラメータ付きで検証
  return (
    mappings.find(([, path]) => path instanceof RegExp && currentPath.match(path))?.[0] ||
    // 不明. 404とか
    'unknown'
  )
}
