import React from 'react'
import PageMeta from 'src/containers/domains/app/elements/PageMeta'
import Forbidden from './elements/Forbidden'

const ForbiddenPage: React.FC = () => {
  return (
    <>
      <PageMeta title="アクセスが拒否されました" />
      <Forbidden />
    </>
  )
}

export default ForbiddenPage
