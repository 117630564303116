import React from 'react'
import { Flex, FlexCol } from 'src/components/layouts/Flex'
import { Paper } from 'src/components/ui-elements/Paper'
import { Inner } from 'src/components/layouts/Inner'
import { AppLogo } from 'src/components/ui-elements/AppLogo'
import ForgotPasswordModal from 'src/containers/pages/login/elements/ForgotPasswordModal'
import PageMeta from 'src/containers/domains/app/elements/PageMeta'
import type { CognitoUser } from 'amazon-cognito-identity-js'
import LoginForm from './elements/LoginForm'
import CompletePasswordForm from './elements/CompletePasswordForm'

const LoginPage: React.FC = () => {
  const [user, setUser] = React.useState<CognitoUser>()
  const [modalName, setModal] = React.useState('')

  return (
    <>
      <PageMeta title="ログイン" />
      <Flex query align="center">
        <FlexCol size={6}>
          <Paper radius={8} style={{ marginTop: ' 100px' }}>
            <Inner size={6}>
              <div className="mb-10 text-center">
                <AppLogo width={267} display="inline-block" />
              </div>
              {!user && (
                <>
                  <LoginForm setUser={setUser} onClickModalOpen={(): void => setModal('ForgotPasswordModal')} />
                  <ForgotPasswordModal
                    modalName={modalName}
                    onClickModalClose={(): void => setModal('')}
                    setUser={setUser}
                  />
                </>
              )}
              {user && <CompletePasswordForm user={user} />}
            </Inner>
          </Paper>
        </FlexCol>
      </Flex>
    </>
  )
}

export default LoginPage
