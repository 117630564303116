import * as React from 'react'
import { Formik, type FormikProps } from 'formik'
import { FormInputField } from 'src/components/ui-groups/FormInputField'
import { Button } from 'src/components/ui-elements/Button'
import { composeValidators, required, passwordLength, passwordPolicy } from 'src/common/utils/Validate'
import { FormFieldWithTitle } from 'src/components/ui-groups/FormFieldWithTitle'
import { useNavigate } from 'react-router'
import type { CognitoUser } from 'amazon-cognito-identity-js'
import { Alert } from 'src/components/ui-elements/Alert'
import { useRegisterPassword } from 'src/containers/pages/login/hooks'

type Values = {
  comfirmPass: string
  password: string
}

type Props = {
  user: CognitoUser
}

const CompletePasswordForm: React.FC<Props> = ({ user }) => {
  const navigate = useNavigate()
  const { registerPassword, isPending, errMessage } = useRegisterPassword({
    onSuccess: () => navigate('/')
  })
  const onSubmit = React.useCallback(
    async ({ password }: Values) => registerPassword({ user, password }),
    [registerPassword, user]
  )
  return (
    <Formik
      initialValues={{
        comfirmPass: '',
        password: ''
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }: FormikProps<Values>): JSX.Element => {
        return (
          <form onSubmit={handleSubmit}>
            {errMessage && (
              <Alert variant="error" className={'mb-4'}>
                <p className="font-bold">{errMessage.title}</p>
                <p>{errMessage.message}</p>
              </Alert>
            )}
            <FormFieldWithTitle title="新しいパスワード">
              <FormInputField
                id={`password`}
                type="password"
                name={`password`}
                className={'mb-6'}
                placeholder="新しいパスワード"
                validate={composeValidators(required, passwordLength, passwordPolicy)}
              />
            </FormFieldWithTitle>
            <FormFieldWithTitle title="確認用">
              <FormInputField
                type="password"
                id={`comfirmPass`}
                name={`comfirmPass`}
                className={'mb-6'}
                placeholder="確認用"
                validate={composeValidators(required, passwordLength, passwordPolicy)}
              />
            </FormFieldWithTitle>
            <Button
              loading={isPending}
              name="送信"
              type="submit"
              color="primary"
              blocked
              size="large"
              className={'mb-4'}
            />
          </form>
        )
      }}
    </Formik>
  )
}

export default CompletePasswordForm
