import React from 'react'
import styled from 'src/common/styles'
import { Link } from 'react-router-dom'
import { CompanyStatusChecker } from 'src/containers/domains/app/elements/CompanyStatusChecker'

type Props = {
  handleEvent: () => void
  handleClose: () => void
}

const ScOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const ScUserMenu = styled.nav`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  padding: 24px;
  min-width: 180px;
  position: absolute;
  top: 80px;
  right: 10px;
  z-index: 1;

  & > div > a,
  & > div > button {
    font-size: 0.875rem;
    font-weight: bold;
  }
`

export const UserMenu: React.FC<Props> = (props) => {
  const { handleEvent, handleClose } = props

  return (
    <>
      <ScOverlay onClick={(): void => handleClose()} />
      <ScUserMenu>
        <div className={'mb-4'}>
          <Link to={'/settings/profile'}>プロフィール</Link>
        </div>
        <CompanyStatusChecker serviceType="ON_SERVICE">
          <div className={'mb-4'}>
            <Link to={'/settings/notifications'}>通知設定</Link>
          </div>
        </CompanyStatusChecker>
        <div>
          <button type="button" onClick={(): void => handleEvent()} data-test="to-logout">
            ログアウト
          </button>
        </div>
      </ScUserMenu>
    </>
  )
}
