import * as React from 'react'
import styled, { type ITheme } from 'src/common/styles'
import type { FormInputProps } from 'src/common/types'
import ClassNamesExport from 'classnames'
import { type FieldProps, useField } from 'formik'
import { FormControl } from './FormControl'

type Props = {
  exText?: string
}

export const ScBoxInput = styled.input<FieldProps['field'] & FormInputProps & Props>`
  ${({ theme, textSize, exText }: FieldProps['field'] & FormInputProps & Props & { theme: ITheme }): string => `
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    background-color: transparent;
    color: #333;
    border: 1px solid #D2D6DE;
    outline: 0;
    width: 100%;
    padding: ${exText ? `${theme.base.size}px 52px ${theme.base.size}px ${theme.base.size}px` : `${theme.base.size}px`};
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: ${textSize || 14}px;
    font-family: inherit;
    line-height: inherit;
    background: #fff;
    position: relative;
    border-radius: 4px;
    &:focus {
      border-color: ${theme.color.primary}
    }
    &.isError {
      border-color: ${theme.color.caution};
      background: #ffe9eb;
    }
    &[disabled] {
      background: #fafafa;
      cursor: not-allowed;
    }
  `};
`

export const ScExText = styled.div`
  ${({ theme }: { theme: ITheme }): string => `
    display: inline-block;
    height: 40px;
    padding: 8px;
    border: solid 1px ${theme.color.disable};
    background: #fafafa;
    position: absolute;
    border-radius: 0 4px 4px 0;
    top: 0;
    right: 0;
  `};
`

const InputField: React.FC<FormInputProps & Props> = (props) => {
  const { type, validate, id, placeholder, ...others } = props
  const [field, meta] = useField(props)

  return (
    <ScBoxInput
      type={type}
      id={id}
      placeholder={placeholder}
      validate={validate}
      className={ClassNamesExport({ isError: meta.error && meta.touched })}
      {...others}
      {...field}
    />
  )
}

export const FormInputField: React.FC<FormInputProps & Props> = (props) => {
  const { style, exText, className, ...others } = props
  return (
    <FormControl className={className} {...others}>
      <InputField exText={exText} {...others} style={style} />
      {exText && <ScExText>以上</ScExText>}
    </FormControl>
  )
}
