import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'
import type { AvailableMapStyle, LocationMapType } from 'src/containers/pages/analytics/dashboard/helper/map'
import type {
  AggregateAuthoriStatus,
  AggregateIllegalStatus,
  AuthoriIpCityLocationResult,
  CfLevel
} from 'src/containers/pages/analytics/dashboard/helper'
import type { ForeignAccessSize } from 'src/components/ui-groups/charts/helper'
import { devtoolsInNonProd } from '../devtools'

type State = {
  heatmapIpCityLocationMapType: LocationMapType
  heatmapIpCityLocationMapStyle: AvailableMapStyle
  heatmapIpCityCfLevel: CfLevel
  heatmapIpCityLocationResult: AuthoriIpCityLocationResult
  authoriStatus: AggregateAuthoriStatus
  illegalStatus: AggregateIllegalStatus
  foreignAccessSize: ForeignAccessSize
}

type Actions = {
  setHeatmapIpCityLocationMapType: (payload: LocationMapType) => void
  setHeatmapIpCityLocationMapStyle: (payload: AvailableMapStyle) => void
  setHeatmapIpCityCfLevel: (payload: CfLevel) => void
  setHeatmapIpCityLocationResult: (payload: AuthoriIpCityLocationResult) => void
  setAuthoriStatus: (payload: AggregateAuthoriStatus) => void
  setIllegalStatus: (payload: AggregateIllegalStatus) => void
  setForeignAccessSize: (payload: ForeignAccessSize) => void
  reset: () => void
}

const initialState: State = {
  heatmapIpCityLocationMapType: 'japan',
  heatmapIpCityLocationMapStyle: 'VectorOpenDataStandardLight',
  heatmapIpCityCfLevel: 1,
  heatmapIpCityLocationResult: undefined,
  authoriStatus: 'authoriResult',
  illegalStatus: 'authoriTorIp',
  foreignAccessSize: 10
}

export const useDashboardStore = create<State & Actions>()(
  persist(
    devtoolsInNonProd((set) => ({
      ...initialState,
      setHeatmapIpCityLocationMapType: (payload): void =>
        set({ heatmapIpCityLocationMapType: payload }, false, 'setHeatmapIpCityLocationMapType'),
      setHeatmapIpCityLocationMapStyle: (payload): void =>
        set({ heatmapIpCityLocationMapStyle: payload }, false, 'setHeatmapIpCityLocationMapStyle'),
      setHeatmapIpCityCfLevel: (payload): void =>
        set({ heatmapIpCityCfLevel: payload }, false, 'setHeatmapIpCityCfLevel'),
      setHeatmapIpCityLocationResult: (payload): void =>
        set({ heatmapIpCityLocationResult: payload }, false, 'setHeatmapIpCityLocationResult'),
      setAuthoriStatus: (payload): void => set({ authoriStatus: payload }, false, 'setAuthoriStatus'),
      setIllegalStatus: (payload): void => set({ illegalStatus: payload }, false, 'setIllegalStatus'),
      setForeignAccessSize: (payload): void => set({ foreignAccessSize: payload }, false, 'setForeignAccessSize'),
      reset: (): void => set(initialState)
    })),
    {
      name: 'dashboard-store'
    }
  )
)

/**
 * useDashboardStore with shallow
 *
 * @link https://github.com/pmndrs/zustand/blob/main/docs/guides/prevent-rerenders-with-use-shallow.md
 */
export const useShallowDashboardStore = (): Omit<State & Actions, 'reset'> => {
  const store = useDashboardStore(
    useShallow((state) => ({
      heatmapIpCityLocationMapType: state.heatmapIpCityLocationMapType,
      setHeatmapIpCityLocationMapType: state.setHeatmapIpCityLocationMapType,
      heatmapIpCityLocationMapStyle: state.heatmapIpCityLocationMapStyle,
      setHeatmapIpCityLocationMapStyle: state.setHeatmapIpCityLocationMapStyle,
      heatmapIpCityCfLevel: state.heatmapIpCityCfLevel,
      setHeatmapIpCityCfLevel: state.setHeatmapIpCityCfLevel,
      heatmapIpCityLocationResult: state.heatmapIpCityLocationResult,
      setHeatmapIpCityLocationResult: state.setHeatmapIpCityLocationResult,
      authoriStatus: state.authoriStatus,
      setAuthoriStatus: state.setAuthoriStatus,
      illegalStatus: state.illegalStatus,
      setIllegalStatus: state.setIllegalStatus,
      foreignAccessSize: state.foreignAccessSize,
      setForeignAccessSize: state.setForeignAccessSize
    }))
  )

  return store
}
