import * as React from 'react'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { isStrictDateValid, toDayjs } from 'src/common/utils/Date'
import { getAuthoriOptionLabel } from 'src/containers/pages/authori/helper'
import type { CommonParamsQuery } from '../types'

const convertValue = (key: string, value: unknown): unknown => {
  const DATE_KEYS = ['beganFrom', 'beganTo', 'createdAtFrom', 'createdAtTo', 'updatedAtFrom', 'updatedAtTo']

  /**
   * {label: "", value: ""}の場合
   * ex. --include
   * */
  if (typeof value === 'string' && value.indexOf('--') !== -1) {
    const v = value.slice(2)
    return { value: v, label: getAuthoriOptionLabel(key, v) }
  }
  /**
   * [{label: "", value: ""}]の場合
   * ex. __NG,REVIEW
   * */
  if (typeof value === 'string' && value.indexOf('__') !== -1) {
    return value
      .slice(2)
      .split(',')
      .map((e) => ({
        value: e,
        label: getAuthoriOptionLabel(key, e)
      }))
  }
  if (DATE_KEYS.includes(key) && typeof value === 'string' && isStrictDateValid(value)) {
    return toDayjs(value).toDate()
  }
  if ((typeof value === 'string' && key === 'page') || (typeof value === 'string' && key === 'size')) {
    return Number(value)
  }
  return value
}

export const generateValues = ({
  search,
  parseNumbers
}: {
  search: string
  parseNumbers?: boolean
}): Record<string, unknown> => {
  const params = queryString.parse(search, { parseNumbers })
  const obj = Object.entries(params).reduce<Record<string, unknown>>(
    (prev, [key, value]) => Object.assign(prev, { [key]: convertValue(key, value) }),
    {}
  )
  return obj
}

/**
 * parseNumbers
 * 審査検索では95.0などの数字を文字列として検索している
 * */
export function useGenerateValues<T = Record<string | number | symbol, unknown>>({
  parseNumbers = true
}: {
  parseNumbers?: boolean
}): [CommonParamsQuery<T>, string] {
  const { search } = useLocation()

  const [values, setValues] = React.useState<CommonParamsQuery<T>>({})

  const set = React.useCallback((o: CommonParamsQuery<T>) => {
    setValues(o)
  }, [])

  React.useEffect(() => {
    const obj = generateValues({ search, parseNumbers })
    set(obj as CommonParamsQuery<T>)
  }, [parseNumbers, search, set])
  return [values, search]
}
