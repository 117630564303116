import hotToast from 'react-hot-toast'
import type { ComponentProps } from 'react'
import { Toast } from './Toast'

type Props = Pick<ComponentProps<typeof Toast>, 'text' | 'subText' | 'variant' | 'code' | 'errors'>

export const customHotToast = (props: Props): string => {
  return hotToast.custom((t) => <Toast isShow={t.visible} {...props} onClose={(): void => hotToast.dismiss(t.id)} />)
}

export const useToast = (): ((props: Props) => string) => customHotToast
