import type { UseQueryResult } from '@tanstack/react-query'
import type {
  CreateAuthoriMutation,
  CreateAuthoriMutationVariables,
  ListAuthoriFeedbackQuery,
  ListAuthoriFeedbackQueryVariables
} from 'src/API'
import type { CustomUseMutationResult, TUseQueryOptions } from 'src/common/types'
import { createAuthori } from 'src/graphql/mutations'
import { listAuthoriFeedback } from 'src/graphql/queries'
import { useBatchMutation, useCommonMutation, useCommonQuery } from './common'

export const useListAuthoriFeedbackQuery = ({
  variables,
  options,
  key
}: TUseQueryOptions<ListAuthoriFeedbackQueryVariables>): UseQueryResult<ListAuthoriFeedbackQuery> => {
  return useCommonQuery<ListAuthoriFeedbackQuery, ListAuthoriFeedbackQueryVariables>({
    variables,
    query: listAuthoriFeedback,
    queryKey: 'listAuthoriFeedback',
    deps: key,
    options
  })
}

export function useCreateAuthoriMutation(): CustomUseMutationResult<
  CreateAuthoriMutation,
  CreateAuthoriMutationVariables
> {
  return useCommonMutation<CreateAuthoriMutation, CreateAuthoriMutationVariables>(createAuthori)
}

export function useBatchCreateAuthoriMutation(): CustomUseMutationResult<CreateAuthoriMutation[], unknown> {
  return useBatchMutation<CreateAuthoriMutation[]>()
}
