import React, { type ReactNode } from 'react'
import styled, { type ITheme } from 'src/common/styles'
import type { ComponentStyleProps } from 'src/common/types'

type Props = {
  size?: number
  children: ReactNode
} & ComponentStyleProps

export const ScInner = styled.div<Props>`
  ${({ theme, size = 1 }: Props & { theme: ITheme }): string => `
    padding: ${theme.base.size * size}px;
  `};
`

export const Inner: React.FC<Props> = (props) => {
  const { children, ...rest } = props
  return <ScInner {...rest}>{children}</ScInner>
}
