import { useErrorHandler } from 'src/common/hooks'
import { Auth } from 'aws-amplify'
import type { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { createErrorMessage, type ErrMessage } from 'src/common/helper'
import { useToast } from 'src/components/ui-elements/Toast'
import { recordAnalyticsEvent } from 'src/lib/amplify/analytics/helper'

type Values = {
  username: string
  password: string
}

export const authSignIn = async (values: Values): Promise<CognitoUser> => {
  const userInfo = await Auth.signIn({
    username: values.username,
    password: values.password
  })

  return userInfo
}

type SignInResult = CognitoUser & {
  signInUserSession?: CognitoUserSession
  /**
   * @see https://docs.amplify.aws/lib/auth/mfa/q/platform/js/#sign-in-with-custom-auth-challenges
   */
  challengeName?: string
  /** MFA認証パラメータ */
  challengeParam?: {
    userAttributes: {
      email_verified: 'true'
      name: string
      'custom:company_id'?: string
      email: string
    }
  }
}

export function useAuthSignIn(): [UseMutationResult<SignInResult, unknown, Values>, ErrMessage | undefined] {
  const toast = useToast()
  const { errMessage, onSetError } = useErrorHandler()
  const result = useMutation({
    mutationFn: async (variables: Values) => {
      try {
        const userInfo = (await authSignIn(variables)) as SignInResult
        return userInfo
      } catch (errors) {
        const errMsg = createErrorMessage(errors)
        onSetError(errMsg)
        toast({
          text: 'ログインに失敗しました',
          code: errMsg.code,
          variant: 'error',
          errors
        })
        throw errors
      }
    },
    // Amplify Analytics ログ計測
    onSettled: (_, e) => {
      recordAnalyticsEvent('login', { result: e ? 'failure' : 'success' })
    }
  }) as UseMutationResult<SignInResult, unknown, Values>
  return [result, errMessage]
}
