import { useCallback, useState } from 'react'
import { Storage } from 'aws-amplify'
import { useToast } from 'src/components/ui-elements/Toast'

type Progress = {
  loaded: number
  total: number
}

export const useDownload = (): {
  downloader: (
    key: string,
    name: string,
    accessLevel?: 'public' | 'protected' | 'private',
    cognitoUserIdentityId?: string
  ) => void
  isDownloading: boolean
  progress: Progress
} => {
  const toast = useToast()
  const [isDownloading, setLoading] = useState(false)
  const [progress, setProgress] = useState<Progress>({ loaded: 0, total: 0 })

  const downloader = useCallback(
    (
      fileKey: string,
      fileName: string,
      accessLevel?: 'public' | 'protected' | 'private',
      cognitoUserIdentityId?: string
    ) => {
      setLoading(true)
      Storage.get(fileKey, {
        download: true,
        expires: 60,
        level: accessLevel,
        identityId: cognitoUserIdentityId,
        progressCallback: (p) => setProgress({ loaded: p.loaded, total: p.total })
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((result: any) => {
          const url = URL.createObjectURL(result.Body)
          const a = document.createElement('a')
          a.href = url
          a.download = fileName
          const clickHandler = (): void => {
            setTimeout(() => {
              URL.revokeObjectURL(url)
              a.removeEventListener('click', clickHandler)
              setLoading(false)
            }, 150)
          }
          a.addEventListener('click', clickHandler, false)
          a.click()
        })
        .catch(() => {
          toast({
            text: 'ダウンロードに失敗しました',
            code: 500,
            variant: 'error'
          })
          setLoading(false)
        })
    },
    [toast]
  )
  return { downloader, isDownloading, progress }
}
