import { Analytics, Auth } from 'aws-amplify'

import { AccountType } from 'src/API'
import type { ICognitoUser } from 'src/common/types/auth'
import { formatTimeStampDate } from 'src/common/utils/Date'
import { analyticsProvider } from 'src/lib/amplify'
import {
  CUSTOM_ATTRIBUTES_KEY,
  type RecordEventKey,
  type RecordEventKeyCustomAttributes,
  RECORD_EVENT_KEY_NAMES
} from 'src/lib/amplify/analytics/eventMapping'
import { pooling } from 'src/common/utils'
import { getCurrentPageId } from 'src/lib/amplify/analytics/helper'

// 共通収集属性
const collectCommonAttributes = async (): Promise<Record<string, string | number | boolean | undefined>> => {
  let user: ICognitoUser | undefined
  let payload: Record<string, unknown>
  let role: string | undefined
  let isAuthed = false
  try {
    user = (await Auth.currentAuthenticatedUser()) as ICognitoUser
    payload = user.getSignInUserSession()?.getIdToken().payload || {}
    ;[role] = (payload['cognito:groups'] || []) as string[]
    isAuthed = true
  } catch (e) {
    // no login
  }

  return {
    email: user?.attributes?.email,
    companyId: user?.attributes?.['custom:company_id'],
    isManagement: user ? `${user?.attributes?.['custom:account_type'] === AccountType.MANAGEMENT}` : undefined,
    useragent: navigator.userAgent,
    role,
    isAuthed: `${isAuthed}`,
    pageId: getCurrentPageId(),
    // ページコンポーネントのレンダリングに対してtitleが非同期で書き換わるため
    pageTitle: await pooling(
      // `IP集計 | O-MOTION` -> `IP集計`
      () => {
        const titles = document.title.split('|')
        if (titles.length !== 2) {
          return undefined
        }
        const [title] = titles
        return title.trim()
      },
      { durationInMilliSec: 300, maxRetry: 10 }
    ),
    url: window.location.origin + window.location.pathname,
    eventedAt: formatTimeStampDate(new Date())
  }
}

/**
 * Amplify Analyticsによるセッショントラッキングを開始します
 *
 * ページのアクティブ、非アクティブを検知してエンドポイントに送信します
 * {@link https://docs.amplify.aws/javascript/prev/build-a-backend/more-features/analytics/auto-track-sessions/#session-tracking}
 */
export const registerSessionTracking = (): void => {
  Analytics.autoTrack('session', {
    enable: true,
    attributes: async () => {
      const attrs = await collectCommonAttributes()
      return {
        eventName: 'セッション',
        ...attrs
      }
    },
    provider: analyticsProvider.getProviderName()
  })
}

/**
 * Amplify Analyticsによるページビュートラッキングを開始します
 * {@link https://docs.amplify.aws/javascript/prev/build-a-backend/more-features/analytics/auto-track-sessions/#page-view-tracking}
 */
export const registerPageViewTracking = (): void => {
  Analytics.autoTrack('pageView', {
    enable: true,
    attributes: async () => {
      const attrs = await collectCommonAttributes()
      return {
        eventName: 'ページビュー',
        ...attrs
        // 長すぎてエラーになるからやめた
        // ...(window.location.search ? { pathParameters: window.location.search.slice(1) } : {})
      }
    },
    type: 'SPA',
    provider: analyticsProvider.getProviderName()
  })
}

/**
 * Amplify Analyticsによるイベントトラッキングを開始します.
 * {@link https://docs.amplify.aws/javascript/prev/build-a-backend/more-features/analytics/auto-track-sessions/#page-event-tracking}
 * 別途、計測したいDOMに対してdata属性を付与する事でオートトラッキングを可能にします
 */
export const registerPageEventTracking = (): void => {
  Analytics.autoTrack('event', {
    enable: true,
    events: ['click'],
    attributes: collectCommonAttributes,
    provider: analyticsProvider.getProviderName()
  })
}

// カスタム属性を持たないイベントキーのみがタグ設置できる
type RecordEventKeyIgnoreCustomAttributes<T extends RecordEventKey> = RecordEventKeyCustomAttributes[T] extends
  | null
  | undefined
  ? T
  : never

/**
 * Amplify Analyticsで計測するイベントdata属性をタグに埋め込みます.
 * 埋め込んだDOMにクリックイベントが発生するとイベントが記録されます.
 *
 * ネストされたカスタムイベント属性はタグによるイベントトラッキングで収集出来ないため、イベントハンドラ`recordAnalyticsEvent`の利用を検討してください.
 */
export const emmbedAnlTags = <T extends RecordEventKey>(
  key: RecordEventKeyIgnoreCustomAttributes<T>
): Record<`data-amplify-analytics-${'on' | 'name' | 'attrs'}`, string> => {
  return {
    'data-amplify-analytics-on': 'click',
    'data-amplify-analytics-name': key,
    'data-amplify-analytics-attrs': `eventName:${RECORD_EVENT_KEY_NAMES[key].replaceAll(':', '：')}`
  }
}

type RecordEventAttributesArgs<T extends RecordEventKey> = RecordEventKeyCustomAttributes[T] extends null | undefined
  ? [T]
  : // `customAttrs:map<string,string>>`というスキーマ定義の制約
    RecordEventKeyCustomAttributes[T] extends Record<string, string>
    ? [T, RecordEventKeyCustomAttributes[T]]
    : never

/**
 * Amplify Analyticsのイベントを記録します.
 *
 * FIXME AmplifyのSDKのバグ?でかawaitが返らない
 */
export const recordAnalyticsEvent = async <T extends RecordEventKey>(
  ...args: RecordEventAttributesArgs<T>
): Promise<void> => {
  const [key, attrs] = args
  const commonAttrs = await collectCommonAttributes()
  // FIXME たぶんresolveされてないので、意図的にawaitしない
  Analytics.record(
    {
      name: key,
      attributes: {
        eventName: RECORD_EVENT_KEY_NAMES[key].replaceAll(':', '：'),
        ...commonAttrs,
        [CUSTOM_ATTRIBUTES_KEY]: attrs as unknown as string
      },
      immediate: true
    },
    analyticsProvider.getProviderName()
  )
}

export const transformToStringHashMap = <T extends Record<string, unknown>>(item?: T): Record<keyof T, string> => {
  if (!item) {
    return {} as Record<keyof T, string>
  }
  return Object.entries(item).reduce(
    (prev, [key, value]) => {
      let transformed
      if (!value) {
        transformed = ''
      } else if (typeof value === 'object') {
        if ('length' in value && value.length === 0) {
          transformed = ''
        }
        // `{"value":"COPY_AND_PASTE","label":"コピー&ペースト"}` -> `COPY_AND_PASTE`,
        else if ('value' in value) {
          transformed = `${value.value}`
        }
        // `[{"value":"false","label":"結果不明"},{"value":"true","label":"成功"}]` -> `false,true`,
        else if (Array.isArray(value) && 'value' in value[0]) {
          transformed = value.map(({ value: val }) => val).join(',')
        } else if (value instanceof Date) {
          transformed = formatTimeStampDate(value)
        } else {
          transformed = `${value}`
        }
      } else {
        transformed = `${value}`
      }
      return { ...prev, [key]: transformed }
    },
    {} as Record<keyof T, string>
  )
}
