import React from 'react'

const Top = React.lazy(() => import(/* webpackChunkName: "Top" */ '../containers/pages/top'))
const Profile = React.lazy(() => import(/* webpackChunkName: "Profile" */ '../containers/pages/settings/profile'))
const NotFound = React.lazy(() => import(/* webpackChunkName: "NotFound" */ '../containers/pages/notfound'))

export const CommonRoutes = [
  { path: '/', component: Top, breadcrumbs: [] },
  {
    path: '/settings/profile',
    component: Profile,
    breadcrumbs: [{ name: '個人設定' }, { name: 'プロフィール' }]
  },
  {
    path: '*',
    component: NotFound,
    breadcrumbs: [{ name: 'ページが見つかりませんでした' }]
  }
]
