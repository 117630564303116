import React, { type ReactNode } from 'react'
import { useFetchCognitoUser } from 'src/common/hooks'

type Props = {
  children: ReactNode
}

/**
 * 加盟店ユーザーかつREACT_APP_BETAが有効になっていると表示しません.
 */
export const BetaChecker: React.FC<Props> = ({ children }) => {
  const { isManagement } = useFetchCognitoUser()

  if (isManagement || !(process.env.REACT_APP_BETA && process.env.REACT_APP_BETA === 'true')) {
    return <>{children}</>
  }
  return null
}
