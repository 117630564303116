import React, { type ReactNode } from 'react'
import { Global } from '@emotion/react'
import { baseStyle } from 'src/common/styles/base'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Toaster } from 'react-hot-toast'
import ScrollToTop from './ScrollToTop'

type Props = {
  children: ReactNode
}

const App: React.FC<Props> = ({ children }) => {
  React.useEffect(() => {
    window.addEventListener('newContentAvailable', () => {
      window.location.reload()
    })
  })

  return (
    <div>
      <ScrollToTop />
      <Global styles={baseStyle} />
      {children}
      <Toaster position="bottom-right" />
      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  )
}

export default App
