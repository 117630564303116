import React, { useCallback, useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { Outlet, useNavigate } from 'react-router'
import Header from 'src/containers/domains/app/elements/Header'
import Sidebar from 'src/containers/domains/app/elements/Sidebar'
import type { HubCapsule } from '@aws-amplify/core'
import { useClearStateAndQueryCaches } from 'src/common/hooks'
import type { ICognitoUser } from 'src/common/types/auth'
import { removeCookies } from 'src/common/helper/cookieManager'
import { useAuthenticateMutation } from 'src/services'
import { useShallowAuthenticateStore } from 'src/store/modules/authenticate'
import Dashboard from './elements/Dashboard'
import Main from './elements/Main'

const Authenticate: React.FC = () => {
  const [authState, setAuthState] = React.useState('signedOut')
  const [isOpen, toggleDrawer] = React.useState(true)
  const { handleClearStateAndQueryCaches } = useClearStateAndQueryCaches()
  const handleToggleDrawer = React.useCallback((bool: boolean) => {
    toggleDrawer(!bool)
  }, [])
  const navigate = useNavigate()
  const { mutate: authMutate } = useAuthenticateMutation()
  const { isAuthenticated, setAuth } = useShallowAuthenticateStore()

  const handleAuthStateChange = useCallback(async () => {
    try {
      const user: ICognitoUser = await Auth.currentAuthenticatedUser()
      // 認可の実施
      if (!isAuthenticated) {
        authMutate(
          {},
          {
            onSuccess: () => {
              setAuthState(user.username ? 'signedIn' : 'signedOut')
              setAuth()
            }
          }
        )
      } else {
        setAuthState(user.username ? 'signedIn' : 'signedOut')
      }
    } catch (e) {
      setAuthState('signedOut')
      removeCookies()
      handleClearStateAndQueryCaches()
      navigate('/login')
    }
  }, [authMutate, handleClearStateAndQueryCaches, isAuthenticated, navigate, setAuth])

  useEffect(() => {
    handleAuthStateChange()
  }, [handleAuthStateChange])

  useEffect(() => {
    const hubCallback = (data: HubCapsule): void => {
      const { channel, payload } = data
      if (channel === 'auth' && payload.event !== 'signIn') {
        handleAuthStateChange()
      }
    }

    Hub.listen('auth', hubCallback)

    return () => {
      Hub.remove('auth', hubCallback)
    }
  }, [handleAuthStateChange, navigate])

  return (
    <>
      {authState === 'signedIn' ? (
        <Dashboard {...{ isOpen }}>
          <Header />
          <Sidebar {...{ isOpen, handleToggleDrawer }} />
          <Main>
            <Outlet />
          </Main>
        </Dashboard>
      ) : null}
    </>
  )
}

export default Authenticate
