import React, { type FC, type LazyExoticComponent } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs } from 'src/components/ui-groups/Breadcrumbs'

type Breadcrumbs = { name: string; path?: string }[]

type Props = {
  component: LazyExoticComponent<FC>
  breadcrumbs: Breadcrumbs
}

export const WithBreadcrumbsHoc: React.FC<Props> = ({ component: Component, breadcrumbs }) => {
  return (
    <React.Suspense fallback={null}>
      <Breadcrumbs>
        <li>
          <Link to={'/'}>TOP</Link>
        </li>
        {breadcrumbs.map((e, index) => (
          <li key={index}>
            {e.path && <Link to={e.path}>{e.name}</Link>}
            {!e.path && <span>{e.name}</span>}
          </li>
        ))}
      </Breadcrumbs>
      <Component />
    </React.Suspense>
  )
}
