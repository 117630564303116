import React, { type ReactNode } from 'react'
import styled from 'src/common/styles'

type Props = {
  children: ReactNode
}

const ScMain = styled.main`
  grid-area: main;
  padding: 24px;
`

const Main: React.FC<Props> = (props) => {
  const { children } = props
  return <ScMain role="main">{children}</ScMain>
}

export default Main
