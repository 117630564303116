import React from 'react'

const Account = React.lazy(() => import(/* webpackChunkName: "Account" */ '../containers/pages/account'))
const AuditLog = React.lazy(
  () => import(/* webpackChunkName: "AuditLog" */ '../containers/pages/company/auditLog/index')
)

export const AdminRoutes = [
  {
    path: '/account',
    component: Account,
    breadcrumbs: [{ name: 'アカウント一覧' }]
  },
  {
    path: '/company/audit-log',
    component: AuditLog,
    breadcrumbs: [{ name: '監査ログ' }]
  }
]
