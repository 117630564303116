import { create } from 'zustand'
import type { CreateAuthoriMutation } from 'src/API'
import { isDateWithinYearsFromNow } from 'src/common/utils/Date'
import { devtoolsInNonProd } from '../devtools'

export type SelectedAuthoriState = CreateAuthoriMutation['createAuthori']

type State = {
  selected: SelectedAuthoriState[]
  isMulti: boolean
}

type Actions = {
  selectAuthori: (payload: { data: SelectedAuthoriState }) => void
  removeAuthori: (payload: { data: SelectedAuthoriState }) => void
  selectAllAuthori: (payload: { data: SelectedAuthoriState[] }) => void
  removeAllAuthori: (payload: { data: SelectedAuthoriState[] }) => void
  toggleMulti: (isMulti: boolean) => void
  initializeSelectedAuthori: () => void
  reset: () => void
}

const initialState: State = {
  selected: [],
  isMulti: false
}

export const useAuthoriStore = create<State & Actions>()(
  devtoolsInNonProd(
    (set) => ({
      ...initialState,
      selectAuthori: (payload): void =>
        set(
          (state) => ({
            ...state,
            selected: [...state.selected, payload.data]
          }),
          false,
          'selectAuthori'
        ),
      removeAuthori: (payload): void =>
        set(
          (state) => ({
            ...state,
            selected: state.selected.filter((e) => e?.authori_id !== payload.data?.authori_id)
          }),
          false,
          'removeAuthori'
        ),
      selectAllAuthori: (payload): void => {
        set(
          (state) => {
            const filteredData = (
              state.selected.length > 0
                ? payload.data.filter((e) => !state.selected.find((s) => s?.authori_id === e?.authori_id))
                : payload.data
            ).filter((v) => isDateWithinYearsFromNow(v?.began_at, 1))
            return {
              ...state,
              selected: [...state.selected, ...filteredData]
            }
          },
          false,
          'selectAllAuthori'
        )
      },
      removeAllAuthori: (payload): void =>
        set(
          (state) => ({
            ...state,
            selected: state.selected.filter((e) => !payload.data.find((s) => s?.authori_id === e?.authori_id))
          }),
          false,
          'removeAllAuthori'
        ),
      toggleMulti: (isMulti): void =>
        set(
          (state) => ({
            ...state,
            isMulti
          }),
          false,
          'toggleMulti'
        ),
      initializeSelectedAuthori: (): void =>
        set(
          (state) => ({
            ...state,
            selected: []
          }),
          false,
          'initializeSelectedAuthori'
        ),
      reset: (): void => set(initialState)
    }),
    { name: 'authoriStore' }
  )
)
