import type { AnalyticsEvent } from '@aws-amplify/analytics/lib/types'
import { AWSKinesisProvider } from 'aws-amplify'

import { log } from 'src/common/utils/Logger'
import { NO_AUDIT_LOGS_EVENTS } from './eventMapping'

type ProviderProps = {
  /** Kinesisストリーム名 */
  streamName: string
}

/**
 * Amplify AnalyticsのKinesis Provider拡張のラッパークラス
 * {@link https://gist.github.com/justro/f72618744d96d9b26acedbb6a711f530}
 * {@link https://docs.amplify.aws/javascript/prev/build-a-backend/more-features/analytics/create-custom-plugin/}
 */
export class AWSKinesisProviderWrapper extends AWSKinesisProvider {
  private readonly streamName: string

  constructor(
    props: ProviderProps,
    config?: ConstructorParameters<typeof AWSKinesisProvider>[number] & {
      region: string
      bufferSize?: number
      flushSize?: number
      flushInterval?: number
      resendLimit?: number
    }
  ) {
    super(config)
    this.streamName = props.streamName
  }

  /**
   * 内部で呼び出されるrecord関数をOverrideしてPayloadに`streamName`を含める
   */
  record(
    params: Record<string, unknown> & {
      event: AnalyticsEvent & { attributes: { customAttrs?: Record<string, unknown> } }
    } & { partitionKey?: string }
  ): Promise<boolean> {
    const { attributes, ...rest } = params.event
    const event = {
      data: {
        ...attributes,
        ...rest,
        // customAttrsの共通プロパティを第一階層に展開 & 初期値として登録
        count: attributes?.customAttrs?.count ?? '0',
        result: attributes?.customAttrs?.result ?? 'success',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        isAudit: !NO_AUDIT_LOGS_EVENTS.includes(params.event.name as any)
      },
      streamName: this.streamName,
      // Tips: パーティションキーなくてもライブラリ側で自動生成してくれるのでOptional
      ...(params.partitionKey ? { partitionKey: params.partitionKey } : {})
    }
    log.debug(`put records to Kinesis Analytics`, { event })
    return super.record({ event })
  }

  /**
   * get provider name of the plugin
   */
  getProviderName(): string {
    return ` ${super.getProviderName()}-${this.streamName}`
  }
}
