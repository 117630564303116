import { useEffect, useState } from 'react'
import type { Env } from 'src/common/types'
import { OMOTI_COMPANY_JS } from 'src/omotion/omoti-company-js/constant'
import { loadCompanyJs, removeCompanyJs } from 'src/omotion/omoti-company-js/domManipulation'

/**
 * 加盟店JSををHTML上にロードする
 */
export const useCompanyJs = (): boolean => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const env = process.env.REACT_APP_STAGE as Env
    loadCompanyJs(env, OMOTI_COMPANY_JS.NAME).then(() => setIsLoaded(true))
    return removeCompanyJs
  }, [])

  return isLoaded
}
