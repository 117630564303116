import React, { useState, useCallback, useMemo } from 'react'
import { ModalConfirm, type Options } from 'src/components/ui-groups/ModalConfirm'
import ConfirmContext from './confirmContext'

const DEFAULT_OPTIONS: Options = {
  message: '本当によろしいですか？',
  labelCancel: 'キャンセル',
  labelConfirm: 'OK',
  confirmButtonColor: 'primary'
}

const buildOptions = (options?: Options): Options => {
  return {
    ...DEFAULT_OPTIONS,
    ...options
  }
}

export const ConfirmProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [options, setOptions] = useState<Options>({ ...DEFAULT_OPTIONS })
  const [resolveReject, setResolveReject] = useState<[() => void, () => void] | []>([])
  const [resolve, reject] = resolveReject

  const confirm = useCallback((opts?: Options): Promise<void> => {
    return new Promise((res, rej) => {
      setOptions(buildOptions(opts))
      setResolveReject([res, rej])
    })
  }, [])

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    if (reject) {
      reject()
    }
    handleClose()
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve()
    }
    handleClose()
  }, [resolve, handleClose])

  const contextValue = useMemo(() => ({ confirm }), [confirm])

  return (
    <>
      {resolveReject.length === 2 && (
        <ModalConfirm
          {...options}
          onClickConfirm={handleConfirm}
          onClickClose={handleClose}
          onClickCancel={handleCancel}
        />
      )}
      <ConfirmContext.Provider value={contextValue}>{children}</ConfirmContext.Provider>
    </>
  )
}
