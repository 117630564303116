import React from 'react'
import type { GraphQLResult } from '@aws-amplify/api'
import { API, graphqlOperation } from 'aws-amplify'
import type { AWSAppSyncRealTimeProvider } from '@aws-amplify/pubsub'
import type { Observable, ZenObservable } from 'zen-observable-ts'
import * as Subscriptions from 'src/graphql/subscriptions'
import { createErrorMessage } from 'src/common/helper'
import { useErrorHandler } from 'src/common/hooks'
import { useToast } from 'src/components/ui-elements/Toast'
import { log } from 'src/common/utils/Logger'

/** Amplify Codegenで生成済みのSubscription */
const generatedSubsctiptions = [...Object.values(Subscriptions)] as const

/**
 * Subscriptionを開始するカスタムフック
 * @param command {SubscriptionCommandType} サブスクリプションのコマンドクエリ.
 */
export const useSubscription = <
  SubscriptionCommandType extends (typeof generatedSubsctiptions)[number],
  SubscriptionInputType extends SubscriptionCommandType['__generatedSubscriptionInput'],
  SubscriptionResultType extends SubscriptionCommandType['__generatedSubscriptionOutput'],
  ObservableValue extends {
    provider: AWSAppSyncRealTimeProvider
    value: GraphQLResult<SubscriptionResultType>
  },
  ObservableType extends Observable<ObservableValue>,
  SubscriptionCallbackType extends Exclude<
    Parameters<ObservableType['subscribe']>[0],
    ZenObservable.Observer<ObservableValue>
  >
>(
  command: SubscriptionCommandType
): Pick<ReturnType<typeof useErrorHandler>, 'errMessage'> & {
  onInitSubscription: (
    input: SubscriptionInputType,
    option: {
      /** データ取得時のコールバック */
      onSubscribe: SubscriptionCallbackType
      onError?: (error: unknown) => void
    }
  ) => void
} => {
  // subscriptionコマンドのoperation文字列を取得 `subscription onHoge() {...}` => `onHoge`
  const [, operation] = React.useMemo(
    () => command.match(/^subscription\s+(\w+)/) as NonNullable<ReturnType<typeof String.prototype.match>>,
    [command]
  )
  const { errMessage, onSetError } = useErrorHandler()
  const toast = useToast()

  const [subscription, setSubscription] = React.useState<ZenObservable.Subscription>()

  // 呼び出し側で行うエラーハンドリングを登録するためカリー化
  const onErrorCurried = React.useCallback(
    (callback?: (_e: unknown) => void) => (errors: unknown) => {
      const errMsg = createErrorMessage(errors)
      callback && callback(errors)
      onSetError(errMsg)
      toast({
        text: errMsg.title,
        code: errMsg.code,
        variant: errMsg.color || 'error',
        errors
      })
    },
    [onSetError, toast]
  )

  const onInitSubscription = React.useCallback(
    (
      input: SubscriptionInputType,
      option: {
        onSubscribe: SubscriptionCallbackType
        onError?: (error: unknown) => void
      }
    ): void => {
      const onError = onErrorCurried(option.onError)
      try {
        // subscriptionの場合Promiseではなく`Observable`型が返る
        const res = API.graphql(graphqlOperation(command, input)) as unknown as ObservableType
        setSubscription(
          res.subscribe({
            next: (val) => {
              log.debug(`${operation}: subscribed.`)
              option.onSubscribe(val)
            },
            error: (e) => {
              log.debug(`${operation}: subscribed fetch err.`, e)
              onError(e)
            }
          })
        )
        log.debug(`${operation}: subscription started.`)
      } catch (e) {
        log.debug(`${operation}: subscription start failed.`)
        onError(e)
      }
    },
    [command, onErrorCurried, operation]
  )

  React.useEffect(
    () => () => {
      if (subscription) {
        subscription.unsubscribe()
        log.debug(`${operation}: unsubscribed.`)
      }
    },
    [operation, subscription]
  )

  return {
    errMessage,
    onInitSubscription
  }
}
