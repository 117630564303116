import axios from 'axios'
import axiosRetry from 'axios-retry'
import type { Env } from 'src/common/types'
import { log } from 'src/common/utils/Logger'

const env = process.env.REACT_APP_STAGE as Env
const client = axios.create({
  baseURL: `https://${env === 'prod' ? '' : `${env}-`}api.o-moti.com`,
  headers: {
    'X-Omotion-Signature': process.env.REACT_APP_OMOTI_SERVER_API_SIGNATURE as string,
    'Content-Type': 'application/json'
  }
})
axiosRetry(client, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000
  },
  retryCondition: (err) => {
    const status = err.response?.status
    return !!status && (status === 400 || status === 404 || status >= 500)
  }
})

export const updateLoginSuccess = ({
  authoriId,
  connectedId,
  userId
}: {
  authoriId: string
  connectedId: string
  userId: string
}): void => {
  client
    .patch(
      `/v1/user/authori/${authoriId}/login_success`,
      {
        value: true,
        connected_id: connectedId
      },
      {
        headers: {
          'X-Omotion-Useridhashed': userId
        }
      }
    )
    .catch((err) => {
      if (err.response.status !== 200) {
        log.error('Login API call failed after 3 retry attempts : ', err)
      }
    })
}
