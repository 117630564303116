import * as React from 'react'
import styled from 'src/common/styles'
import ClassNamesExport from 'classnames'
import type { ComponentStyleProps } from 'src/common/types'
import { Link } from 'react-router-dom'

type Props = {
  name: string
  to: string
  decoration?: 'underline' | 'none'
  id?: string
} & ComponentStyleProps

export const ScLinkText = styled(Link)<{ decoration: Props['decoration'] }>`
  font-size: 0.875rem;
  &:link,
  &:visited {
    color: ${({ theme }): string => theme.color.primary};
    text-decoration: ${({ decoration = 'underline' }): string => decoration};
  }

  &:hover {
    opacity: 0.9;
    text-decoration: ${({ decoration = 'underline' }): string => (decoration === 'underline' ? 'none' : 'underline')};
  }
`

export const LinkText: React.FC<Props> = (props) => {
  const { name, className, to, decoration, ...others } = props
  return (
    <ScLinkText className={ClassNamesExport(className)} to={encodeURI(to)} decoration={decoration} {...others}>
      {name}
    </ScLinkText>
  )
}
