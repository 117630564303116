import React from 'react'
import { AUTHORI_SEARCH_PATHS } from 'src/common/constants'

const Top = React.lazy(() => import(/* webpackChunkName: "Top" */ '../containers/pages/top'))

const DashboardTime = React.lazy(
  () => import(/* webpackChunkName: "DashboardTime" */ '../containers/pages/analytics/dashboard/time')
)
const DashboardDay = React.lazy(
  () => import(/* webpackChunkName: "DashboardDay" */ '../containers/pages/analytics/dashboard/day')
)
const AuthoriSimpleSearch = React.lazy(
  () => import(/* webpackChunkName: "AuthoriSimpleSearch" */ '../containers/pages/authori/simpleSearch')
)
const AuthoriAdvanceSearch = React.lazy(
  () => import(/* webpackChunkName: "AuthoriAdvanceSearch" */ '../containers/pages/authori/advanceSearch')
)
const AuthoriDetail = React.lazy(
  () => import(/* webpackChunkName: "AuthoriDetail" */ '../containers/pages/authori/detail')
)
const AuthoriExport = React.lazy(
  () => import(/* webpackChunkName: "AuthoriExport" */ '../containers/pages/authoriExport/export')
)

const Report = React.lazy(() => import(/* webpackChunkName: "Report" */ '../containers/pages/analytics/report'))

const UserDevice = React.lazy(() => import(/* webpackChunkName: "UserDevice" */ '../containers/pages/userDevice'))
const UserDeviceDetail = React.lazy(
  () => import(/* webpackChunkName: "UserDeviceDetail" */ '../containers/pages/userDevice/detail')
)
const UserNumberCalculation = React.lazy(
  () => import(/* webpackChunkName: "UserNumberCalculation" */ '../containers/pages/userDevice/userNumberCalculation')
)
const DeviceNumberCalculation = React.lazy(
  () =>
    import(/* webpackChunkName: "DeviceNumberCalculation" */ '../containers/pages/userDevice/deviceNumberCalculation')
)

const IpAggregation = React.lazy(
  () => import(/* webpackChunkName: "IpAggregation" */ '../containers/pages/ip/aggregation/aggregation')
)
const IpAggregationExport = React.lazy(
  () => import(/* webpackChunkName: "IpAggregationExport" */ '../containers/pages/ip/aggregationExport/export')
)

const IpReportSetting = React.lazy(
  () => import(/* webpackChunkName: "IpReportSetting" */ '../containers/pages/ip/aggregation/ipReportSetting')
)
const Notifications = React.lazy(
  () => import(/* webpackChunkName: "Notifications" */ '../containers/pages/settings/notifications')
)
const InquiryList = React.lazy(() => import(/* webpackChunkName: "InquiryList" */ '../containers/pages/inquiry/index'))
const InquiryDetail = React.lazy(
  () => import(/* webpackChunkName: "InquiryDetail" */ '../containers/pages/inquiry/detail')
)

export const LoggedInRoutes = [
  { path: '/', component: Top, breadcrumbs: [] },
  {
    path: '/analytics/dashboard/time',
    component: DashboardTime,
    breadcrumbs: [{ name: 'ダッシュボード' }]
  },
  {
    path: '/analytics/dashboard/day',
    component: DashboardDay,
    breadcrumbs: [{ name: 'ダッシュボード' }]
  },
  {
    path: AUTHORI_SEARCH_PATHS.find((e) => e.type === 'simple')?.path,
    component: AuthoriSimpleSearch,
    breadcrumbs: [{ name: '審査検索' }]
  },
  {
    path: AUTHORI_SEARCH_PATHS.find((e) => e.type === 'advanced')?.path,
    component: AuthoriAdvanceSearch,
    breadcrumbs: [{ name: '審査検索' }]
  },
  {
    path: '/authori/export',
    component: AuthoriExport,
    breadcrumbs: [{ name: '審査エクスポート' }]
  },
  {
    path: '/authori/:id',
    component: AuthoriDetail,
    breadcrumbs: [
      {
        name: '審査検索',
        path: AUTHORI_SEARCH_PATHS.find((e) => e.type === 'simple')?.path
      },
      { name: '審査詳細' }
    ]
  },
  {
    path: '/settings/notifications',
    component: Notifications,
    breadcrumbs: [{ name: '個人設定' }, { name: '通知設定' }]
  },
  {
    path: '/user_device',
    component: UserDevice,
    breadcrumbs: [{ name: '端末検索' }]
  },
  {
    path: '/user_device/users_calculation',
    component: UserNumberCalculation,
    breadcrumbs: [{ name: 'ユーザー数算出' }]
  },
  {
    path: '/user_device/device_calculation',
    component: DeviceNumberCalculation,
    breadcrumbs: [{ name: '端末数算出' }]
  },
  {
    path: '/user_device/:id',
    component: UserDeviceDetail,
    breadcrumbs: [{ name: '端末検索', path: '/user_device?from=1&size=20&statusList=__INVALID' }, { name: '端末詳細' }]
  },
  {
    path: '/ip/aggregation',
    component: IpAggregation,
    breadcrumbs: [{ name: 'IP集計' }]
  },
  {
    path: '/ip/aggregation/export',
    component: IpAggregationExport,
    breadcrumbs: [{ name: 'IP集計エクスポート' }]
  },
  {
    path: '/ip-report-setting',
    component: IpReportSetting,
    breadcrumbs: [{ name: 'IP集計レポート条件設定' }]
  },
  {
    path: '/analytics/report',
    component: Report,
    breadcrumbs: [{ name: 'レポート' }]
  },
  {
    path: '/inquiries',
    component: InquiryList,
    breadcrumbs: [{ name: 'お問い合わせ一覧' }]
  },
  {
    path: '/inquiries/:id',
    component: InquiryDetail,
    breadcrumbs: [{ name: 'お問い合わせ一覧', path: '/inquiries' }, { name: 'お問い合わせ詳細' }]
  }
]
