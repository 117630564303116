import React from 'react'
import { Link } from 'react-router-dom'
import { ACCOUNT_TYPE_ARRAY } from 'src/common/constants'
import styled from 'src/common/styles'
import { UserRoleChecker } from 'src/containers/domains/app/elements/RoleChecker'
import { BetaChecker } from 'src/containers/domains/app/elements/BetaChecker'

type Props = {
  handleClose: () => void
}

const ScOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const ScManagementAccountMenu = styled.nav`
  background: ${({ theme }): string => theme.color.primary};
  color: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  padding: 24px;
  min-width: 180px;
  position: absolute;
  top: 80px;
  right: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div > a,
  & > div > button {
    font-size: 0.875rem;
    font-weight: bold;
    color: white;
  }
`

export const ManagementAccountMenu: React.FC<Props> = (props) => {
  const { handleClose } = props

  return (
    <>
      <ScOverlay onClick={(): void => handleClose()} />
      <ScManagementAccountMenu>
        <div>
          <Link to={'/management/switch_company'}>加盟店切り替え</Link>
        </div>
        <div>
          <Link to={'/management/company'}>加盟店一覧</Link>
        </div>
        <div>
          <Link to={'/management/addon'}>アドオン一覧</Link>
        </div>
        <UserRoleChecker roleTypes={ACCOUNT_TYPE_ARRAY.filter((e) => ['ADMIN'].includes(e))}>
          <div>
            <Link to={'/management/account'}>運営アカウント一覧</Link>
          </div>
        </UserRoleChecker>
        <BetaChecker>
          <div>
            <Link to={'/management/inquiries'}>お問い合わせ一覧</Link>
          </div>
        </BetaChecker>
      </ScManagementAccountMenu>
    </>
  )
}
