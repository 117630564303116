import { useErrorHandler } from 'src/common/hooks'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { API, graphqlOperation } from 'aws-amplify'
import type { GraphQLResult } from '@aws-amplify/api'
import { createErrorMessage, type ErrMessage } from 'src/common/helper'
import { useToast } from 'src/components/ui-elements/Toast'

export function useCommonMutation<MutationResultType, MutationVariablesType>(
  mutation: string,
  option?: {
    suppress: 'success' | 'error'
  }
): UseMutationResult<MutationResultType, unknown, MutationVariablesType> & {
  errMessage?: ErrMessage
  refreshError: () => void
} {
  const toast = useToast()
  const { errMessage, onSetError } = useErrorHandler()

  const result = useMutation({
    mutationFn: async (variables: MutationVariablesType) => {
      try {
        onSetError(undefined)
        const res = (await API.graphql(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          graphqlOperation(mutation, variables as any)
        )) as GraphQLResult<MutationResultType>
        if (!option || option?.suppress !== 'success') {
          toast({ text: 'Success', variant: 'success' })
        }
        return res.data
      } catch (errors) {
        const errMsg = createErrorMessage(errors)
        onSetError(errMsg)
        if (!option || option?.suppress !== 'error') {
          toast({
            text: errMsg.title,
            code: errMsg.code,
            variant: errMsg.color || 'error',
            errors
          })
        }
        throw errors
      }
    }
  }) as UseMutationResult<MutationResultType, unknown, MutationVariablesType>
  return { ...result, errMessage, refreshError: () => onSetError(undefined) }
}

export function useBatchMutation<MutationResultType>(): UseMutationResult<MutationResultType> & {
  errMessage?: ErrMessage
  refreshError: () => void
} {
  const toast = useToast()
  const { errMessage, onSetError } = useErrorHandler()

  const result = useMutation({
    mutationFn: async (mutation: MutationResultType) => {
      try {
        onSetError(undefined)
        const res = (await API.graphql(graphqlOperation(mutation))) as GraphQLResult<MutationResultType>
        toast({ text: 'Success', variant: 'success' })
        return res.data
      } catch (errors) {
        const errMsg = createErrorMessage(errors)
        onSetError(errMsg)
        toast({
          text: errMsg.title,
          code: errMsg.code,
          variant: errMsg.color || 'error',
          errors
        })
        throw errors
      }
    }
  }) as UseMutationResult<MutationResultType>

  return { ...result, errMessage, refreshError: () => onSetError(undefined) }
}
