import React, { type ReactNode } from 'react'
import { useFetchCognitoUser } from 'src/common/hooks'

type Props = {
  children: ReactNode
}

export const CompanyAccountChecker: React.FC<Props> = ({ children }) => {
  const { isManagement } = useFetchCognitoUser()
  return isManagement ? null : <>{children} </>
}
