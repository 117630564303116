import { API, type GraphQLResult } from '@aws-amplify/api'
import { graphqlOperation } from 'aws-amplify'
import type { LogInput, LogMutation } from 'src/API'
import { log as mutationLog } from 'src/graphql/mutations'
import { log } from 'src/common/utils/Logger'
import { useAuthenticateStore } from './modules/authenticate'
import { useCognitoStore } from './modules/cognito'
import { useAuthoriStore } from './modules/authori'
import { useDashboardStore } from './modules/dashboard'
import 'setimmediate'

export const logMutation = async (variables: LogInput): Promise<void> => {
  try {
    const res = (await API.graphql(graphqlOperation(mutationLog, { input: { ...variables } }))) as GraphQLResult<{
      data: LogMutation
    }>
    log.info('logMutation success : ', res)
  } catch (e) {
    log.error('logMutation error : ', e)
  }
}

export const resetAllState = (): void => {
  useCognitoStore.getState().reset()
  useAuthoriStore.getState().reset()
  useDashboardStore.getState().reset()
  useAuthenticateStore.getState().reset()
}
