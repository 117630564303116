import { type FC, useEffect } from 'react'
import ClassNamesExport from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import type { ComponentStyleProps } from 'src/common/types'
import { AlertIcon } from 'src/components/ui-elements/Icons'
import { createLogMutationInput, log } from 'src/common/utils/Logger'
import { LogLevel } from 'src/API'
import { logMutation } from 'src/store/helpers'
import type { ToastProps } from './types'
import { ScCloseButton, ScIcon, ScSubText, ScText, ScToastInner, StyledTransition } from './styled'

export type Props = ToastProps & ComponentStyleProps

export const Toast: FC<Props> = (props) => {
  const { className, ...rest } = props
  const { isShow, onClose, text, subText, variant = 'info', code, errors } = rest

  // log mutation
  useEffect(() => {
    if (isShow && errors && typeof code === 'number') {
      const variables = createLogMutationInput({ message: errors, level: LogLevel.ERROR })
      logMutation(variables)
      log.error('errors : ', errors)
    }
  }, [code, errors, isShow])

  return (
    <StyledTransition className={ClassNamesExport(className)} isShow={isShow} id="toast-id">
      <ScToastInner {...rest}>
        <ScIcon {...rest}>
          <AlertIcon variant={variant} />
        </ScIcon>
        <div>
          <ScText>{text}</ScText>
          {subText && <ScSubText>{subText}</ScSubText>}
        </div>
        <ScCloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </ScCloseButton>
      </ScToastInner>
    </StyledTransition>
  )
}
