import { Auth } from 'aws-amplify'
import React, { useCallback, useEffect } from 'react'
import { type AccountRole, AccountType } from 'src/API'
import type { Maybe } from 'src/common/types'
import { useCognitoStore } from 'src/store/modules/cognito'
import { resetAllState } from 'src/store/helpers'
import { useToast } from 'src/components/ui-elements/Toast'
// eslint-disable-next-line no-restricted-syntax
import { useQueryClient } from '@tanstack/react-query'
import { recordAnalyticsEvent } from 'src/lib/amplify/analytics/helper'
import { delay } from 'src/common/utils'
import type { ICognitoUser } from '../types/auth'
import { removeCookies } from '../helper/cookieManager'

type Result = {
  fetchCognitoUser: () => void
  handleSignOut: () => void
  user: Maybe<ICognitoUser>
  /** 運営ユーザーかどうか */
  isManagement: boolean
  companyId: string
  role: keyof typeof AccountRole
  accountName: string
}

type ClearStateAndQuery = {
  handleClearStateAndQueryCaches: () => void
}

export const useClearStateAndQueryCaches = (): ClearStateAndQuery => {
  const queryClient = useQueryClient()
  const handleClearStateAndQueryCaches = useCallback(() => {
    queryClient.clear()
    resetAllState()
  }, [queryClient])

  return { handleClearStateAndQueryCaches }
}

export const useFetchCognitoUser = (): Result => {
  const { user, setUser } = useCognitoStore((state) => state)
  const toast = useToast()

  const fetchCognitoUser = useCallback(async (): Promise<void> => {
    try {
      const cognitoUser: ICognitoUser = await Auth.currentAuthenticatedUser()
      if (cognitoUser && cognitoUser.getSignInUserSession()) {
        setUser({ user: cognitoUser })
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }, [setUser])

  useEffect(() => {
    if (!user) {
      fetchCognitoUser()
    }
  }, [fetchCognitoUser, user])

  const handleSignOut = useCallback(async () => {
    recordAnalyticsEvent('logout')
    // NOTE: `recordAnalyticsEvent`のPromiseがresolveされないのでバッファ処理する
    await delay(500, () => Auth.signOut())
    removeCookies()
    toast({
      text: 'ログアウトしました',
      variant: 'success'
    })
  }, [toast])

  const { companyId, role, isManagement, accountName } = React.useMemo(() => {
    const [group] = user?.getSignInUserSession()?.getIdToken().payload['cognito:groups'] || []
    return {
      companyId: user?.attributes?.['custom:company_id'] || '',
      role: group as keyof typeof AccountRole,
      isManagement: user?.attributes?.['custom:account_type'] === AccountType.MANAGEMENT,
      accountName: user?.attributes?.name || ''
    }
  }, [user])

  return { user, fetchCognitoUser, handleSignOut, companyId, role, isManagement, accountName }
}
