import { createElement } from 'react'

export const nl2br = (
  text: string
): (string | React.DetailedReactHTMLElement<React.HTMLAttributes<HTMLElement>, HTMLElement>)[] => {
  const regex = /(\r\n|\r|\n)/g
  return text.split(regex).map((line, i) => {
    if (line.match(regex)) {
      return createElement('br', { key: i })
    }
    return line
  })
}
