/* eslint-disable @typescript-eslint/no-explicit-any */
import { useErrorHandler } from 'src/common/hooks'
import { Auth } from 'aws-amplify'
import type { CognitoUser } from 'amazon-cognito-identity-js'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { createErrorMessage, type ErrMessage } from 'src/common/helper'
import { useToast } from 'src/components/ui-elements/Toast'

type MutationVariables = {
  user: CognitoUser | any
  password: string
}

export const authCompleteNewPassword = async ({ user, password }: MutationVariables): Promise<CognitoUser | any> => {
  const userInfo = await Auth.completeNewPassword(user, password, {})

  return userInfo
}

export function useCompletePassword(): [
  UseMutationResult<CognitoUser, unknown, MutationVariables>,
  ErrMessage | undefined
] {
  const toast = useToast()
  const { errMessage, onSetError } = useErrorHandler()
  const result = useMutation({
    mutationFn: async (variables: { user: CognitoUser | any; password: string }) => {
      try {
        const userInfo = (await authCompleteNewPassword(variables)) as CognitoUser
        return userInfo
      } catch (errors) {
        const errMsg = createErrorMessage(errors)
        onSetError(errMsg)
        toast({
          text: 'ログインに失敗しました',
          code: errMsg.code,
          variant: 'error',
          errors
        })
        throw errors
      }
    }
  }) as UseMutationResult<CognitoUser, unknown, MutationVariables>
  return [result, errMessage]
}
