import dayjs, { type ManipulateType } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import type { DayjsInput } from 'src/common/types'
import dayjsDuration from 'dayjs/plugin/duration'
import type { ISOUnitType } from './Date-generate'

dayjs.extend(isoWeek)
dayjs.extend(dayjsDuration)

export const toDayjs = (date?: DayjsInput): dayjs.Dayjs => {
  return dayjs(date)
}

export const getEndDateOfMonth = (date: DayjsInput): string => {
  return dayjs(date).endOf('month').format()
}

export const parseDate = (date: DayjsInput): Date => {
  return dayjs(date).toDate()
}

export const getPreviousFirstDate = (): Date => {
  const date = new Date()
  return dayjs(date).add(-1, 'months').startOf('month').startOf('day').toDate()
}

export const getPreviousEndDate = (): Date => {
  const date = new Date()
  return dayjs(date).add(-1, 'months').endOf('month').endOf('day').toDate()
}

export const getStartOfMonthDate = (date: string | Date): Date => {
  return dayjs(date).startOf('month').startOf('day').toDate()
}

export const getEndOfMonthDate = (date: string | Date): Date => {
  return dayjs(date).endOf('month').endOf('day').toDate()
}

export const hourlyData = Array.from({ length: 24 }, (_, i) => i).map((i) => {
  const hour = i < 10 ? `0${i}` : i
  return { value: hour, label: hour }
})

export const minutesData = Array.from({ length: 6 }, (_, i) => i * 10).map((i) => {
  const minute = i < 10 ? `0${i}` : i
  return { value: minute, label: minute }
})

export const secondsData = Array.from({ length: 6 }, (_, i) => i * 10).map((i) => {
  const second = i < 10 ? `0${i}` : i
  return { value: second, label: second }
})

function formatMinutes(hours: number, duration: dayjsDuration.Duration): string {
  if (hours > 0 || duration.minutes()) {
    return `${duration.minutes()}分`
  }
  return ''
}

export const convertMilliseconds = (ms: number): string => {
  const duration = dayjs.duration(ms)
  const hours = duration.hours()
  const minutes = formatMinutes(hours, duration)
  const seconds = duration.seconds()
  const milliseconds = ms % 1000

  const displayStrings = [
    `${hours ? `${hours}時間` : ''}`,
    `${minutes}`,
    `${seconds}.${milliseconds.toString().padStart(3, '0')}秒`
  ]

  return displayStrings.join('').trim()
}

export const endOfDate = (date: Date | string): dayjs.Dayjs => dayjs(date).endOf('days')

export const startOfUnit = (date: Date | string, unit: ISOUnitType): dayjs.Dayjs => {
  return dayjs(date).startOf(unit)
}

export const endOfUnit = (date: Date | string, unit: ISOUnitType): dayjs.Dayjs => {
  return dayjs(date).endOf(unit)
}

export const addToDate = (date: Date | string, amount: number, unit?: ManipulateType | undefined): Date => {
  return dayjs(date).add(amount, unit).toDate()
}

export const addDays =
  (add: number) =>
  (date: DayjsInput): dayjs.Dayjs =>
    dayjs(date).add(add, 'days')
