import type { UseQueryResult } from '@tanstack/react-query'
import type {
  CalcDevicesOfUserV2Query,
  CalcDevicesOfUserV2QueryVariables,
  CalcUsersOfDeviceV2Query,
  CalcUsersOfDeviceV2QueryVariables,
  GetUserDeviceV2Query,
  GetUserDeviceV2QueryVariables,
  SearchUserDeviceV2Query,
  SearchUserDeviceV2QueryVariables
} from 'src/API'
import type { TUseQueryOptions } from 'src/common/types'
import { calcDevicesOfUserV2, calcUsersOfDeviceV2, getUserDeviceV2, searchUserDeviceV2 } from 'src/graphql/queries'
import { useCommonQuery } from './common'

export const useSearchUserDeviceQuery = ({
  key,
  options,
  variables
}: TUseQueryOptions<SearchUserDeviceV2QueryVariables>): UseQueryResult<SearchUserDeviceV2Query> => {
  return useCommonQuery<SearchUserDeviceV2Query, SearchUserDeviceV2QueryVariables>({
    queryKey: 'searchUserDeviceV2',
    deps: key,
    query: searchUserDeviceV2,
    variables,
    options
  })
}

export const useFindUserDeviceQuery = ({
  key,
  options,
  variables
}: TUseQueryOptions<GetUserDeviceV2QueryVariables>): UseQueryResult<GetUserDeviceV2Query> => {
  return useCommonQuery<GetUserDeviceV2Query, GetUserDeviceV2QueryVariables>({
    queryKey: 'getUserDeviceV2',
    deps: key,
    query: getUserDeviceV2,
    variables,
    options
  })
}

export const useCalcDevicesOfUserQuery = ({
  key,
  options,
  variables
}: TUseQueryOptions<CalcDevicesOfUserV2QueryVariables>): UseQueryResult<CalcDevicesOfUserV2Query> => {
  return useCommonQuery<CalcDevicesOfUserV2Query, CalcDevicesOfUserV2QueryVariables>({
    queryKey: 'calcDevicesOfUserV2',
    deps: key,
    query: calcDevicesOfUserV2,
    variables,
    options
  })
}

export const useCalcUsersOfDeviceQuery = ({
  key,
  options,
  variables
}: TUseQueryOptions<CalcUsersOfDeviceV2QueryVariables>): UseQueryResult<CalcUsersOfDeviceV2Query> => {
  return useCommonQuery<CalcUsersOfDeviceV2Query, CalcUsersOfDeviceV2QueryVariables>({
    queryKey: 'calcUsersOfDeviceV2',
    deps: key,
    query: calcUsersOfDeviceV2,
    variables,
    options
  })
}
