import React from 'react'
import { LinkText } from 'src/components/ui-elements/LinkText'

const EMAIL_REGEX = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g

type Context = { text: string; isEmail: boolean }
const extractEmailAndContext = (text: string): Context[] => {
  const matches = Array.from(text.matchAll(EMAIL_REGEX))
  if (!matches.length) {
    return [{ text, isEmail: false }]
  }
  return matches.reduce<Context[]>((prev, current, i) => {
    const { index } = current as RegExpExecArray & { index: number }
    const prevIndex = i > 0 ? (matches[i - 1].index as number) + matches[i - 1][0].length : 0
    if (index !== prevIndex) {
      prev.push({ text: text.slice(prevIndex, index), isEmail: false })
    }
    prev.push({ text: current[0], isEmail: true })
    if (i === matches.length - 1 && index + current[0].length < text.length) {
      prev.push({ text: text.slice(index + current[0].length), isEmail: false })
    }
    return prev
  }, [])
}

export const convertMailToLinkText = (beforeText: string): React.JSX.Element => {
  const contexts = extractEmailAndContext(beforeText)
  return (
    <>
      {contexts.map(({ text, isEmail }, i) =>
        isEmail ? (
          <LinkText key={i} to={`mailto:${text}`} name={text} />
        ) : (
          <React.Fragment key={i}>{text}</React.Fragment>
        )
      )}
    </>
  )
}
