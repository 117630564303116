import React, { type ReactNode } from 'react'
import styled from 'src/common/styles'
import ClassNamesExport from 'classnames'
import type { ComponentStyleProps } from 'src/common/types'

type Props = {
  title: string
  children: ReactNode
  btn?: ReactNode
} & ComponentStyleProps

export const ScFormFieldTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: ${({ theme }): number => theme.base.size}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FormFieldWithTitle: React.FC<Props> = (props) => {
  const { children, title, className, btn } = props
  return (
    <div className={ClassNamesExport(className)}>
      <ScFormFieldTitle>
        <span>{title}</span>
        {btn}
      </ScFormFieldTitle>
      {children}
    </div>
  )
}
