import { ThemeProvider } from '@emotion/react'
import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import { DEFAULT_STALE_TIME } from 'src/common/constants'
import { ConfirmProvider } from 'src/common/context/confirmProvider'
import { theme } from 'src/common/styles'
import { HelmetProvider } from 'react-helmet-async'
import AppRoutes from '../../../routes'
import 'src/common/styles/global.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_STALE_TIME,
      retry: false
    }
  }
})

const Root: React.FC = () => (
  <ThemeProvider theme={theme}>
    <ConfirmProvider>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Router>
            <AppRoutes />
          </Router>
        </HelmetProvider>
      </QueryClientProvider>
    </ConfirmProvider>
  </ThemeProvider>
)

export default Root
