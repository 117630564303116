import React, { type ReactNode } from 'react'
import { useFetchCognitoUser } from 'src/common/hooks'

type Props = {
  children: ReactNode
}

export const ManagementAccountChecker: React.FC<Props> = ({ children }) => {
  const { isManagement } = useFetchCognitoUser()
  return isManagement ? <>{children} </> : null
}
