import * as React from 'react'
import styled, { type ITheme } from 'src/common/styles'
import ClassNamesExport from 'classnames'
import type { FormInputProps } from 'src/common/types'
import { ErrorMessage } from 'formik'

type Props = {
  children: React.ReactNode
} & FormInputProps

export const ScBoxInputWrap = styled.div`
  ${(): string => `
    position: relative;
    & .DateRangePickerInput_arrow {
      position: relative;
    }
  `}
`

export const ScBoxError = styled.span`
  ${({ theme }: { theme: ITheme }): string => `
    color: ${theme.color.caution};
    font-size: 10px;
    display: block;
  `}
`

export const FormControl: React.FC<Props> = (props) => {
  const { children, name, className } = props
  return (
    <ScBoxInputWrap className={ClassNamesExport(className)}>
      {children}
      <ErrorMessage
        name={name}
        render={(errorMessage): JSX.Element | undefined => {
          if (typeof errorMessage !== 'string') return undefined
          return <ScBoxError>{errorMessage}</ScBoxError>
        }}
      />
    </ScBoxInputWrap>
  )
}
