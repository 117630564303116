import { create } from 'zustand'
import type { Maybe } from 'src/common/types'
import type { ICognitoUser } from 'src/common/types/auth'
import { devtoolsInNonProd } from '../devtools'

type State = {
  user: Maybe<ICognitoUser>
}

type Actions = {
  setUser: (payload: State) => void
  reset: () => void
}

const initialState: State = {
  user: undefined
}

export const useCognitoStore = create<State & Actions>()(
  devtoolsInNonProd((set) => ({
    ...initialState,
    setUser: (payload): void =>
      set(
        (state) => ({
          ...state,
          user: payload.user
        }),
        false,
        'setUser'
      ),
    reset: (): void => set(initialState)
  }))
)
