import type { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query'
import {
  type CreateCompanyAccountMutation as CreateAccountMutation,
  type CreateCompanyAccountMutationVariables as CreateAccountMutationVariables,
  type DeleteAccountMutation,
  type DeleteAccountMutationVariables,
  type GetAccountQuery,
  type GetAccountQueryVariables,
  type InviteAccountMutation,
  type InviteAccountMutationVariables,
  type ListAccountByCompanyIdAndCreatedAtQuery,
  type ListAccountByCompanyIdAndCreatedAtQueryVariables,
  ModelSortDirection,
  type UpdateAccountMutation,
  type UpdateAccountMutationVariables
} from 'src/API'
import { useFetchCognitoUser } from 'src/common/hooks'
import type { CustomUseMutationResult, TUseInfiniteQueryOptions, TUseQueryOptions } from 'src/common/types'
import {
  createCompanyAccount as createAccount,
  deleteAccount,
  inviteAccount,
  updateAccount
} from 'src/graphql/mutations'
import { getAccount, listAccountByCompanyIdAndCreatedAt } from 'src/graphql/queries'
import { type CommonInfiniteQueryResult, useCommonInfiniteQuery, useCommonMutation, useCommonQuery } from './common'

/**
 * アカウント一覧
 * 加盟店IDと作成日でソート
 * @param
 */
export const useListAccountsQuery = ({
  variables,
  options,
  key,
  deps
}: TUseInfiniteQueryOptions<
  ListAccountByCompanyIdAndCreatedAtQueryVariables,
  ListAccountByCompanyIdAndCreatedAtQuery
>): UseInfiniteQueryResult<CommonInfiniteQueryResult<ListAccountByCompanyIdAndCreatedAtQuery>> => {
  const { user, companyId } = useFetchCognitoUser()
  const d = Array.isArray(deps) ? [...deps, { companyId }] : [{ companyId }]
  return useCommonInfiniteQuery<
    ListAccountByCompanyIdAndCreatedAtQuery,
    ListAccountByCompanyIdAndCreatedAtQueryVariables
  >({
    variables: {
      limit: 20,
      companyId,
      // 作成日の昇順
      sortDirection: ModelSortDirection.ASC,
      ...variables
    },
    query: listAccountByCompanyIdAndCreatedAt,
    queryKey: key,
    deps: d,
    options: {
      enabled: !!user?.attributes?.email,
      ...options,
      initialPageParam: undefined,
      getNextPageParam: (lastPage) => {
        return lastPage.listAccountByCompanyIdAndCreatedAt?.nextToken
      }
    }
  })
}

export const useGetAccount = ({
  variables,
  options,
  key
}: TUseQueryOptions<GetAccountQueryVariables>): UseQueryResult<GetAccountQuery> => {
  return useCommonQuery<GetAccountQuery, GetAccountQueryVariables>({
    variables,
    query: getAccount,
    queryKey: 'getAccount',
    deps: key,
    options
  })
}

export function useCreateAccountMutation(): CustomUseMutationResult<
  CreateAccountMutation,
  CreateAccountMutationVariables
> {
  return useCommonMutation<CreateAccountMutation, CreateAccountMutationVariables>(createAccount)
}

export function useUpdateAccountMutation(): CustomUseMutationResult<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
> {
  return useCommonMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(updateAccount)
}

export function useDeleteAccountMutation(): CustomUseMutationResult<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
> {
  return useCommonMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(deleteAccount)
}

export function useInviteAccountMutation(): CustomUseMutationResult<
  InviteAccountMutation,
  InviteAccountMutationVariables
> {
  return useCommonMutation<InviteAccountMutation, InviteAccountMutationVariables>(inviteAccount)
}
