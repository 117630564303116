import type { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query'
import {
  type CreateManagementAccountMutation,
  type CreateManagementAccountMutationVariables,
  type DeleteManagementAccountMutation,
  type DeleteManagementAccountMutationVariables,
  type GetManagementAccountQuery,
  type GetManagementAccountQueryVariables,
  type ListManagementAccountByAllowedByAndCreatedAtQuery as ListQuery,
  type ListManagementAccountByAllowedByAndCreatedAtQueryVariables as ListQueryVars,
  type UpdateManagementAccountMutation,
  type UpdateManagementAccountMutationVariables,
  ModelSortDirection,
  AccountType
} from 'src/API'
import { useFetchCognitoUser } from 'src/common/hooks'
import type { CustomUseMutationResult, TUseInfiniteQueryOptions, TUseQueryOptions } from 'src/common/types'
import { createManagementAccount, deleteManagementAccount, updateManagementAccount } from 'src/graphql/mutations'
import { getManagementAccount, listManagementAccountByAllowedByAndCreatedAt } from 'src/graphql/queries'
import { type CommonInfiniteQueryResult, useCommonInfiniteQuery, useCommonMutation, useCommonQuery } from './common'

/**
 * 運営アカウント一覧
 */
export const useListManagementAccountsQuery = ({
  variables,
  options,
  key
}: TUseInfiniteQueryOptions<ListQueryVars, ListQuery>): UseInfiniteQueryResult<
  CommonInfiniteQueryResult<ListQuery>
> => {
  const { user } = useFetchCognitoUser()

  return useCommonInfiniteQuery({
    variables: {
      allowedBy: AccountType.MANAGEMENT,
      limit: 20,
      sortDirection: ModelSortDirection.ASC,
      ...variables
    },
    query: listManagementAccountByAllowedByAndCreatedAt,
    queryKey: key,
    options: {
      enabled: !!user?.attributes?.email,
      ...options,
      initialPageParam: undefined,
      getNextPageParam: (lastPage) => {
        return lastPage.listManagementAccountByAllowedByAndCreatedAt?.nextToken
      }
    }
  })
}

export const useGetManagementAccount = ({
  variables,
  options
}: TUseQueryOptions<GetManagementAccountQueryVariables>): UseQueryResult<GetManagementAccountQuery> => {
  return useCommonQuery<GetManagementAccountQuery, GetManagementAccountQueryVariables>({
    variables,
    query: getManagementAccount,
    queryKey: 'getManagementAccount',
    options
  })
}

export function useCreateManagementAccountMutation(): CustomUseMutationResult<
  CreateManagementAccountMutation,
  CreateManagementAccountMutationVariables
> {
  return useCommonMutation<CreateManagementAccountMutation, CreateManagementAccountMutationVariables>(
    createManagementAccount
  )
}

export function useUpdateManagementAccountMutation(): CustomUseMutationResult<
  UpdateManagementAccountMutation,
  UpdateManagementAccountMutationVariables
> {
  return useCommonMutation<UpdateManagementAccountMutation, UpdateManagementAccountMutationVariables>(
    updateManagementAccount
  )
}

export function useDeleteManagementAccountMutation(): CustomUseMutationResult<
  DeleteManagementAccountMutation,
  DeleteManagementAccountMutationVariables
> {
  return useCommonMutation<DeleteManagementAccountMutation, DeleteManagementAccountMutationVariables>(
    deleteManagementAccount
  )
}
