import type { CustomUseMutationResult, TUseQueryOptions } from 'src/common/types'
import type { UseQueryResult } from '@tanstack/react-query'
import type {
  FindAuthoriV2Query,
  FindAuthoriV2QueryVariables,
  GetAuthoriCountV2Query,
  GetAuthoriCountV2QueryVariables,
  SearchAuthoriByAdvancedParamsV2Query,
  SearchAuthoriByAdvancedParamsV2QueryVariables,
  SearchAuthoriBySimpleParamsV2Query,
  SearchAuthoriBySimpleParamsV2QueryVariables
} from 'src/API'
import {
  findAuthoriV2,
  getAuthoriCountV2,
  searchAuthoriByAdvancedParamsV2,
  searchAuthoriBySimpleParamsV2
} from 'src/graphql/queries'
import { useCommonMutation, useCommonQuery } from './common'

export type SearchAuthoriParamsQuery = SearchAuthoriBySimpleParamsV2Query | SearchAuthoriByAdvancedParamsV2Query

export const useSimpleSearchAuthori = ({
  variables,
  options,
  key
}: TUseQueryOptions<SearchAuthoriBySimpleParamsV2QueryVariables>): UseQueryResult<SearchAuthoriBySimpleParamsV2Query> => {
  return useCommonQuery<SearchAuthoriBySimpleParamsV2Query, SearchAuthoriBySimpleParamsV2QueryVariables>({
    variables,
    query: searchAuthoriBySimpleParamsV2,
    queryKey: 'searchAuthori',
    deps: key,
    options
  })
}

export const useAdvanceSearchAuthori = ({
  variables,
  options,
  key
}: TUseQueryOptions<SearchAuthoriByAdvancedParamsV2QueryVariables>): UseQueryResult<SearchAuthoriByAdvancedParamsV2Query> => {
  return useCommonQuery<SearchAuthoriByAdvancedParamsV2Query, SearchAuthoriByAdvancedParamsV2QueryVariables>({
    variables,
    query: searchAuthoriByAdvancedParamsV2,
    queryKey: 'searchAuthori',
    deps: key,
    options
  })
}

export const useIPAdvanceSearchAuthori = ({
  query,
  options,
  key
}: TUseQueryOptions<SearchAuthoriByAdvancedParamsV2QueryVariables>): UseQueryResult<{
  [key: string]: SearchAuthoriByAdvancedParamsV2Query['searchAuthoriByAdvancedParamsV2']
}> => {
  return useCommonQuery<
    { [key: string]: SearchAuthoriByAdvancedParamsV2Query['searchAuthoriByAdvancedParamsV2'] },
    SearchAuthoriByAdvancedParamsV2Query
  >({
    query: query as string,
    queryKey: 'searchAuthori',
    deps: key,
    options
  })
}

export const useFindAuthoriQuery = ({
  variables,
  options,
  key
}: TUseQueryOptions<FindAuthoriV2QueryVariables>): UseQueryResult<FindAuthoriV2Query> => {
  return useCommonQuery<FindAuthoriV2Query, FindAuthoriV2QueryVariables>({
    variables,
    query: findAuthoriV2,
    queryKey: 'findAuthoriV2',
    deps: key,
    options
  })
}

export const useFindAuthoriByDevice = ({
  variables,
  options,
  key
}: TUseQueryOptions<SearchAuthoriByAdvancedParamsV2QueryVariables>): UseQueryResult<SearchAuthoriByAdvancedParamsV2Query> => {
  return useCommonQuery<SearchAuthoriByAdvancedParamsV2Query, SearchAuthoriByAdvancedParamsV2QueryVariables>({
    variables,
    query: searchAuthoriByAdvancedParamsV2,
    queryKey: 'findAuthoriByDevice',
    deps: key,
    options
  })
}

export const useAuthoriCount = ({
  variables,
  options,
  key
}: TUseQueryOptions<GetAuthoriCountV2QueryVariables>): UseQueryResult<GetAuthoriCountV2Query> => {
  return useCommonQuery<GetAuthoriCountV2Query, GetAuthoriCountV2QueryVariables>({
    variables,
    query: getAuthoriCountV2,
    queryKey: 'getAuthoriCountV2',
    deps: key,
    options
  })
}

export function useDownloadSimpleCsvMutation(): CustomUseMutationResult<
  SearchAuthoriBySimpleParamsV2Query,
  SearchAuthoriBySimpleParamsV2QueryVariables
> {
  return useCommonMutation<SearchAuthoriBySimpleParamsV2Query, SearchAuthoriBySimpleParamsV2QueryVariables>(
    searchAuthoriBySimpleParamsV2
  )
}

export function useDownloadAdvancedCsvMutation(): CustomUseMutationResult<
  SearchAuthoriByAdvancedParamsV2Query,
  SearchAuthoriByAdvancedParamsV2QueryVariables
> {
  return useCommonMutation<SearchAuthoriByAdvancedParamsV2Query, SearchAuthoriByAdvancedParamsV2QueryVariables>(
    searchAuthoriByAdvancedParamsV2
  )
}
