import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
// import { AccountRole } from 'src/API'
import App from 'src/containers/domains/app/App'
import Authenticate from 'src/containers/domains/app/Authenticate'
import { useFetchCognitoUser, useServiceStatus } from 'src/common/hooks'
import { getLastAuthoriSearchedUrlFromCookie } from 'src/common/helper/cookieManager'
import { Loading } from 'src/components/ui-elements/Loading'
import { useParams } from 'react-router'
import Forbidden from 'src/containers/pages/forbidden'
import { WithBreadcrumbsHoc } from '../containers/domains/app/WithBreadcrumbsHoc'
import ForgotPassword from '../containers/pages/forgotPassword'
import Login from '../containers/pages/login'
import { useAddonRoutes } from './addonRoutes'
import { AdminRoutes } from './AdminRoutes'
import { CommonRoutes } from './CommonRoutes'
import { LoggedInRoutes } from './LoggedInRoutes'
import { ManagementAccountAdminRoutes } from './ManagementAccountAdminRoutes'
import { ManagementAccountRoutes } from './ManagementAccountRoutes'

const AppRoutes: React.FC = () => {
  const authoriUrlType = getLastAuthoriSearchedUrlFromCookie()
  const { addonsRoutes } = useAddonRoutes()
  const { role, isManagement } = useFetchCognitoUser()
  const { isLoading, isCompanyInstall, isOnService } = useServiceStatus()

  // const [role]: [keyof typeof AccountRole] = user?.getSignInUserSession()?.getIdToken().payload['cognito:groups'] || []
  // const isManagement = user?.attributes['custom:account_type'] === 'MANAGEMENT'

  const RedirectManagementInquiryDetail: React.FC = () => {
    const { id } = useParams()
    return <Navigate to={`/management/inquiries/${id}`} />
  }

  const routes = [
    // TODO 加盟店導入詳細
    ...((isCompanyInstall && []) || []),
    // 運営
    ...(isManagement ? ManagementAccountRoutes : []),
    // 運営ADMIN
    ...(isManagement && role === 'ADMIN' ? ManagementAccountAdminRoutes : []),
    // サービス開放
    ...(isOnService ? [...(role === 'ADMIN' ? AdminRoutes : []), ...addonsRoutes, ...LoggedInRoutes] : []),
    ...CommonRoutes
  ].map((e) => {
    // 審査詳細のパンくずを動的にするためにオーバーライド
    if (e.path === '/authori/:id') {
      return (
        <Route
          key={e.path}
          path={e.path}
          element={
            <WithBreadcrumbsHoc
              component={e.component}
              breadcrumbs={[
                {
                  name: '審査検索',
                  path: authoriUrlType
                },
                { name: '審査詳細' }
              ]}
            />
          }
        />
      )
    }
    return (
      <Route
        key={e.path}
        path={e.path}
        index={e.path === '/'}
        element={<WithBreadcrumbsHoc component={e.component} breadcrumbs={e.breadcrumbs} />}
      />
    )
  })

  return (
    <App>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/" element={<Authenticate />}>
          <Route path="/settings" element={<Navigate replace to="/settings/profile" />} />
          {isManagement && <Route path="/inquiries" element={<Navigate to="/management/inquiries" />} />}
          {isManagement && <Route path="/inquiries/:id" element={<RedirectManagementInquiryDetail />} />}
          {isLoading ? <Route path="*" element={<Loading />} /> : routes}
        </Route>
        <Route path="/403" element={<Forbidden />} />
      </Routes>
    </App>
  )
}

export default AppRoutes
