import React, { type ReactNode } from 'react'
import styled from 'src/common/styles'
import ClassNamesExport from 'classnames'
import type { ComponentStyleProps } from 'src/common/types'

type Props = {
  children: ReactNode
} & ComponentStyleProps

export const ScBreadcrumbs = styled.ul`
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;
  list-style: none;

  & > li {
    display: inline-block;
    &:after {
      content: '/';
      display: inline-block;
      margin: 0 10px;
    }
    a {
      &:link,
      &:visited {
        color: ${({ theme }): string => theme.color.primary};
        text-decoration: none;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  & > li:last-child {
    span {
      font-weight: 700;
    }
    &:after {
      display: none;
    }
  }
`

export const Breadcrumbs: React.FC<Props> = (props) => {
  const { className, children, ...rest } = props
  return (
    <ScBreadcrumbs className={ClassNamesExport(className)} {...rest}>
      {children}
    </ScBreadcrumbs>
  )
}
