import * as React from 'react'
import { Flex, FlexCol } from 'src/components/layouts/Flex'
import { Paper } from 'src/components/ui-elements/Paper'
import { Inner } from 'src/components/layouts/Inner'
import PageMeta from 'src/containers/domains/app/elements/PageMeta'
import ForgotPasswordForm from './elements/ForgotPasswordForm'

const ForgotPasswordPage: React.FC = () => {
  return (
    <>
      <PageMeta title="パスワードをお忘れの方" />
      <Flex query align="center">
        <FlexCol size={6}>
          <Paper style={{ marginTop: ' 100px' }}>
            <Inner size={6}>
              <ForgotPasswordForm />
            </Inner>
          </Paper>
        </FlexCol>
      </Flex>
    </>
  )
}

export default ForgotPasswordPage
