export const delay = <T>(ms: number, cb?: () => T): Promise<T | void> => {
  return new Promise((resolve) =>
    setTimeout(() => {
      return resolve(cb && cb())
    }, ms)
  )
}

/**
 * Truthyな値が返るまでポーリングします.
 */
export const pooling = async <T>(
  cb: () => T,
  {
    durationInMilliSec,
    maxRetry
  }: {
    durationInMilliSec: number
    maxRetry: number
  }
): Promise<T> => {
  const ret = await new Promise<{
    result: T
    intervalId: NodeJS.Timeout
  }>((resolve) => {
    let count = 0
    const intervalId = setInterval(() => {
      const result = cb()
      if (result || count >= maxRetry) {
        resolve({
          result,
          intervalId
        })
      }
      count += 1
    }, durationInMilliSec)
  })
  clearInterval(ret.intervalId)
  return ret.result
}
