import * as React from 'react'
import { Formik, type FormikProps } from 'formik'
import { FormInputField } from 'src/components/ui-groups/FormInputField'
import { Button } from 'src/components/ui-elements/Button'
import { composeValidators, required, passwordLength, passwordPolicy } from 'src/common/utils/Validate'
import { FormFieldWithTitle } from 'src/components/ui-groups/FormFieldWithTitle'
import { useNavigate, useLocation } from 'react-router'
import { Alert } from 'src/components/ui-elements/Alert'
import { useForgotPasswordSubmit } from 'src/services'

type Values = {
  code: string
  username: string
  password: string
}

const ForgotPasswordForm: React.FC = () => {
  const navigate = useNavigate()
  const state = useLocation().state as { username: string }
  const [{ mutate }, errors] = useForgotPasswordSubmit()

  const onSubmit = React.useCallback(
    async (values: Values) => {
      mutate(values, {
        onSuccess: () => {
          navigate('/login')
        }
      })
    },
    [mutate, navigate]
  )
  return (
    <Formik
      initialValues={{
        code: '',
        username: state?.username,
        password: ''
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }: FormikProps<Values>): JSX.Element => {
        return (
          <form onSubmit={handleSubmit}>
            {errors && (
              <Alert variant="error" className={'mb-4'}>
                <p className="font-bold">{errors?.title}</p>
                <p>{errors?.message}</p>
              </Alert>
            )}
            <FormFieldWithTitle title="メールアドレス">
              <FormInputField
                type="text"
                id={`username`}
                name={`username`}
                className={'mb-6'}
                placeholder="メールアドレスを入力"
                data-test="forgot-username-input"
                validate={composeValidators(required)}
                disabled
              />
            </FormFieldWithTitle>
            <FormFieldWithTitle title="認証コード">
              <FormInputField
                type="text"
                id={`code`}
                name={`code`}
                className={'mb-6'}
                data-test="forgot-code-input"
                placeholder="認証コード"
                validate={composeValidators(required)}
              />
            </FormFieldWithTitle>
            <FormFieldWithTitle title="新しいパスワード">
              <FormInputField
                id={`password`}
                type="password"
                name={`password`}
                className={'mb-6'}
                placeholder="新しいパスワードを入力"
                data-test="forgot-password-input"
                validate={composeValidators(required, passwordLength, passwordPolicy)}
              />
            </FormFieldWithTitle>
            <Button
              name="送信"
              type="submit"
              color="primary"
              data-test="submit-button"
              blocked
              size="large"
              className={'mb-4'}
            />
          </form>
        )
      }}
    </Formik>
  )
}

export default ForgotPasswordForm
