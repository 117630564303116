import styled, { type ITheme, css, keyframes } from 'src/common/styles'
import type { SerializedStyles } from '@emotion/react'
import type { ToastProps } from './types'

export const ScToastInner = styled.div<ToastProps>`
  ${({ theme, variant = 'info' }: ToastProps & { theme: ITheme }): SerializedStyles => css`
    padding: ${theme.base.size * 2}px ${theme.base.size * 6}px;
    display: flex;
    align-items: center;
    background: #fff;
    border-left: 4px solid ${theme.color[variant]};
    min-width: 100px;
    border-radius: 2px 4px 4px 2px;
    box-shadow: 0 12px 24px -12px rgba(0, 0, 0, 0.2);
    position: relative;
    @media (max-width: 798px) {
      width: 100%;
      margin: 0 !important;
    }
  `}
`

export const ScText = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  color: #000;
`

export const ScSubText = styled.div`
  color: #999;
  font-size: 0.75rem;
`

export const ScIcon = styled.div<ToastProps>`
  ${({ theme, variant = 'info' }: ToastProps & { theme: ITheme }): SerializedStyles => css`
    color: ${theme.color[variant]};
    font-size: 0.875rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${theme.base.size * 2}px;
  `}
`

export const ScCloseButton = styled.button`
  position: absolute;
  top: 50%;
  right: 8px;
  line-height: 1;
  margin-top: -15px;
  font-size: 16px;
  padding: 8px;
  cursor: pointer;
  color: #999;
`

const enterAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

const exitAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(100%) scale(0.95);
  }
`

export const StyledTransition = styled.div<Pick<ToastProps, 'isShow'>>`
  ${(props): SerializedStyles =>
    props.isShow
      ? css`
          animation: ${enterAnimation} 0.1s ease-out forwards;
        `
      : css`
          animation: ${exitAnimation} 0.2s ease-in forwards;
        `};
`
