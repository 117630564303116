import type { CustomUseMutationResult, TUseInfiniteQueryOptions, TUseQueryOptions } from 'src/common/types'
import type {
  CreateInquiryAndInquiryMessageMutation,
  CreateInquiryAndInquiryMessageMutationVariables,
  CreateInquiryMessageMutation,
  CreateInquiryMessageMutationVariables,
  DeleteInquiryMutation,
  DeleteInquiryMutationVariables,
  GetInquiryQuery,
  GetInquiryQueryVariables,
  ListInquiryByAllowedByQuery,
  ListInquiryByAllowedByQueryVariables,
  ListInquiryByCategoryQuery,
  ListInquiryByCategoryQueryVariables,
  ListInquiryBycompanyIdOrAccountIdAndCategoryQuery,
  ListInquiryBycompanyIdOrAccountIdAndCategoryQueryVariables,
  ListInquiryBycompanyIdOrAccountIdAndStatusQuery,
  ListInquiryBycompanyIdOrAccountIdAndStatusQueryVariables,
  ListInquiryBycompanyIdOrAccountIdQuery,
  ListInquiryBycompanyIdOrAccountIdQueryVariables,
  ListInquiryByCompanyIdQuery,
  ListInquiryByCompanyIdQueryVariables,
  ListInquiryByStatusQuery,
  ListInquiryByStatusQueryVariables,
  ListInquiryMessageByInquiryIdQuery,
  ListInquiryMessageByInquiryIdQueryVariables,
  UpdateInquiryMutation,
  UpdateInquiryMutationVariables
} from 'src/API'
import {
  getInquiry,
  listInquiryByAllowedBy,
  listInquiryByCategory,
  listInquiryByCompanyId,
  listInquiryBycompanyIdOrAccountId,
  listInquiryBycompanyIdOrAccountIdAndCategory,
  listInquiryBycompanyIdOrAccountIdAndStatus,
  listInquiryByStatus,
  listInquiryMessageByInquiryId
} from 'src/graphql/queries'
import { createInquiryMessage, deleteInquiry, updateInquiry } from 'src/graphql/mutations'
import { createInquiryAndInquiryMessage } from 'src/graphql/custom/mutations'
import type { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query'
import { type CommonInfiniteQueryResult, useCommonInfiniteQuery, useCommonMutation, useCommonQuery } from './common'

export const useInquiryQuery = ({
  variables,
  options,
  key,
  deps
}: TUseInfiniteQueryOptions<ListInquiryByAllowedByQueryVariables, ListInquiryByAllowedByQuery>): UseInfiniteQueryResult<
  CommonInfiniteQueryResult<ListInquiryByAllowedByQuery>
> => {
  return useCommonInfiniteQuery<ListInquiryByAllowedByQuery, ListInquiryByAllowedByQueryVariables>({
    variables,
    query: listInquiryByAllowedBy,
    queryKey: key,
    deps,
    options: {
      ...options,
      initialPageParam: undefined,
      getNextPageParam: (lastPage) => {
        return lastPage.listInquiryByAllowedBy?.nextToken
      }
    }
  })
}

export const useInquiryByStatusQuery = ({
  variables,
  options,
  key,
  deps
}: TUseInfiniteQueryOptions<ListInquiryByStatusQueryVariables, ListInquiryByStatusQuery>): UseInfiniteQueryResult<
  CommonInfiniteQueryResult<ListInquiryByStatusQuery>
> => {
  return useCommonInfiniteQuery<ListInquiryByStatusQuery, ListInquiryByStatusQueryVariables>({
    variables,
    query: listInquiryByStatus,
    queryKey: key,
    deps,
    options: {
      ...options,
      initialPageParam: undefined,
      getNextPageParam: (lastPage) => {
        return lastPage.listInquiryByStatus?.nextToken
      }
    }
  })
}

export const useInquiryByCategoryQuery = ({
  variables,
  options,
  key,
  deps
}: TUseInfiniteQueryOptions<ListInquiryByCategoryQueryVariables, ListInquiryByCategoryQuery>): UseInfiniteQueryResult<
  CommonInfiniteQueryResult<ListInquiryByCategoryQuery>
> => {
  return useCommonInfiniteQuery<ListInquiryByCategoryQuery, ListInquiryByCategoryQueryVariables>({
    variables,
    query: listInquiryByCategory,
    queryKey: key,
    deps,
    options: {
      ...options,
      initialPageParam: undefined,
      getNextPageParam: (lastPage) => {
        return lastPage.listInquiryByCategory?.nextToken
      }
    }
  })
}

export const useInquiryByCompanyIdQuery = ({
  variables,
  options,
  key,
  deps
}: TUseInfiniteQueryOptions<ListInquiryByCompanyIdQueryVariables, ListInquiryByCompanyIdQuery>): UseInfiniteQueryResult<
  CommonInfiniteQueryResult<ListInquiryByCompanyIdQuery>
> => {
  return useCommonInfiniteQuery<ListInquiryByCompanyIdQuery, ListInquiryByCompanyIdQueryVariables>({
    variables,
    query: listInquiryByCompanyId,
    queryKey: key,
    deps,
    options: {
      ...options,
      initialPageParam: undefined,
      getNextPageParam: (lastPage) => {
        return lastPage.listInquiryByCompanyId?.nextToken
      }
    }
  })
}

export const useInquiryByCompanyIdOrAccountIdQuery = ({
  variables,
  options,
  key,
  deps
}: TUseInfiniteQueryOptions<
  ListInquiryBycompanyIdOrAccountIdQueryVariables,
  ListInquiryBycompanyIdOrAccountIdQuery
>): UseInfiniteQueryResult<CommonInfiniteQueryResult<ListInquiryBycompanyIdOrAccountIdQuery>> => {
  return useCommonInfiniteQuery<
    ListInquiryBycompanyIdOrAccountIdQuery,
    ListInquiryBycompanyIdOrAccountIdQueryVariables
  >({
    variables,
    query: listInquiryBycompanyIdOrAccountId,
    queryKey: key,
    deps,
    options: {
      ...options,
      initialPageParam: undefined,
      getNextPageParam: (lastPage) => {
        return lastPage.listInquiryBycompanyIdOrAccountId?.nextToken
      }
    }
  })
}

export const useInquiryByCompanyIdOrAccountIdAndStatusQuery = ({
  variables,
  options,
  key,
  deps
}: TUseInfiniteQueryOptions<
  ListInquiryBycompanyIdOrAccountIdAndStatusQueryVariables,
  ListInquiryBycompanyIdOrAccountIdAndStatusQuery
>): UseInfiniteQueryResult<CommonInfiniteQueryResult<ListInquiryBycompanyIdOrAccountIdAndStatusQuery>> => {
  return useCommonInfiniteQuery<
    ListInquiryBycompanyIdOrAccountIdAndStatusQuery,
    ListInquiryBycompanyIdOrAccountIdAndStatusQueryVariables
  >({
    variables,
    query: listInquiryBycompanyIdOrAccountIdAndStatus,
    queryKey: key,
    deps,
    options: {
      ...options,
      initialPageParam: undefined,
      getNextPageParam: (lastPage) => {
        return lastPage.listInquiryBycompanyIdOrAccountIdAndStatus?.nextToken
      }
    }
  })
}

export const useInquiryByCompanyIdOrAccountIdAndCategoryQuery = ({
  variables,
  options,
  key,
  deps
}: TUseInfiniteQueryOptions<
  ListInquiryBycompanyIdOrAccountIdAndCategoryQueryVariables,
  ListInquiryBycompanyIdOrAccountIdAndCategoryQuery
>): UseInfiniteQueryResult<CommonInfiniteQueryResult<ListInquiryBycompanyIdOrAccountIdAndCategoryQuery>> => {
  return useCommonInfiniteQuery<
    ListInquiryBycompanyIdOrAccountIdAndCategoryQuery,
    ListInquiryBycompanyIdOrAccountIdAndCategoryQueryVariables
  >({
    variables,
    query: listInquiryBycompanyIdOrAccountIdAndCategory,
    queryKey: key,
    deps,
    options: {
      ...options,
      initialPageParam: undefined,
      getNextPageParam: (lastPage) => {
        return lastPage.listInquiryBycompanyIdOrAccountIdAndCategory?.nextToken
      }
    }
  })
}

export function useCreateInquiryAndInquiryMessageMutation(): CustomUseMutationResult<
  CreateInquiryAndInquiryMessageMutation,
  CreateInquiryAndInquiryMessageMutationVariables
> {
  return useCommonMutation<CreateInquiryAndInquiryMessageMutation, CreateInquiryAndInquiryMessageMutationVariables>(
    createInquiryAndInquiryMessage
  )
}

export function useUpdateInquiryMutation(): CustomUseMutationResult<
  UpdateInquiryMutation,
  UpdateInquiryMutationVariables
> {
  return useCommonMutation<UpdateInquiryMutation, UpdateInquiryMutationVariables>(updateInquiry)
}

export function useDeleteInquiryMutation(): CustomUseMutationResult<
  DeleteInquiryMutation,
  DeleteInquiryMutationVariables
> {
  return useCommonMutation<DeleteInquiryMutation, DeleteInquiryMutationVariables>(deleteInquiry)
}

export const useGetInquiry = ({
  variables,
  options,
  key
}: TUseQueryOptions<GetInquiryQueryVariables>): UseQueryResult<GetInquiryQuery> => {
  return useCommonQuery<GetInquiryQuery, GetInquiryQueryVariables>({
    variables,
    query: getInquiry,
    queryKey: 'getInquiry',
    deps: key,
    options
  })
}

export const useInquiryMessageQuery = ({
  variables,
  options,
  key,
  deps
}: TUseInfiniteQueryOptions<
  ListInquiryMessageByInquiryIdQueryVariables,
  ListInquiryMessageByInquiryIdQuery
>): UseInfiniteQueryResult<CommonInfiniteQueryResult<ListInquiryMessageByInquiryIdQuery>> => {
  return useCommonInfiniteQuery<ListInquiryMessageByInquiryIdQuery, ListInquiryMessageByInquiryIdQueryVariables>({
    variables,
    query: listInquiryMessageByInquiryId,
    queryKey: key,
    deps: [deps],
    options: {
      ...options,
      initialPageParam: undefined,
      getNextPageParam: (lastPage) => {
        return lastPage.listInquiryMessageByInquiryId?.nextToken
      }
    }
  })
}

export function useCreateInquiryMessageMutation(): CustomUseMutationResult<
  CreateInquiryMessageMutation,
  CreateInquiryMessageMutationVariables
> {
  return useCommonMutation<CreateInquiryMessageMutation, CreateInquiryMessageMutationVariables>(createInquiryMessage)
}
