import type { CognitoUser } from 'amazon-cognito-identity-js'
import React from 'react'
import type { ErrMessage } from 'src/common/helper'
import { useToast } from 'src/components/ui-elements/Toast'
import { recordAnalyticsEvent } from 'src/lib/amplify/analytics/helper'
import { useCompletePassword, useAuthenticateMutation } from 'src/services'
import { useShallowAuthenticateStore } from 'src/store/modules/authenticate'

type Params = {
  onSuccess: () => void
}

/**
 * 初回パスワード登録
 */
export const useRegisterPassword = ({
  onSuccess
}: Params): {
  registerPassword: (param: { user: CognitoUser; password: string }) => void
  isPending: boolean
  errMessage?: ErrMessage
} => {
  const toast = useToast()
  const [{ mutate: complatePwdMutate, isPending: isPendingCompletePwd }, completePwdErr] = useCompletePassword()
  const { mutate: authMutate, isPending: isPendingAuth, errMessage: authErr } = useAuthenticateMutation()
  const { setAuth } = useShallowAuthenticateStore()

  const registerPassword = React.useCallback(
    (param: { user: CognitoUser; password: string }) => {
      complatePwdMutate(param, {
        onSuccess: () => {
          authMutate(
            {},
            {
              onSuccess: () => {
                toast({ text: 'ログインに成功しました', variant: 'success' })
                onSuccess()
                setAuth()
              }
            }
          )
        },
        onSettled: () => recordAnalyticsEvent('loginFirstPasswordRegistration')
      })
    },
    [authMutate, setAuth, complatePwdMutate, onSuccess, toast]
  )

  return {
    registerPassword,
    isPending: isPendingCompletePwd || isPendingAuth,
    errMessage: completePwdErr || authErr
  }
}
