import * as React from 'react'
import styled, { type ITheme } from 'src/common/styles'
import ClassNamesExport from 'classnames'
import { AlertIcon } from 'src/components/ui-elements/Icons'
import type { ComponentStyleProps, AlertVariants } from 'src/common/types'

type Props = {
  variant: AlertVariants
  hiddenIcon?: boolean
  children: React.ReactNode
} & ComponentStyleProps

export const ScAlert = styled.div<Props>`
  ${({ theme, variant }: Props & { theme: ITheme }): string => `
    display: flex;
    padding: ${theme.base.size * 2}px;
    position: relative;
    font-size: 0.875rem;
    color: ${theme.color[variant]};
    background: ${theme.color[variant]}33 0% 0% no-repeat padding-box;
    border-radius: 4px;
    position: relative;
  `};
`

const ScIcon = styled.div`
  ${({ theme }: { theme: ITheme }): string => `
    margin-right: ${theme.base.size}px;
  `}
`

export const Alert: React.FC<Props> = (props) => {
  const { variant, className, hiddenIcon, children, ...rest } = props
  return (
    <ScAlert className={ClassNamesExport(className)} variant={variant} {...rest}>
      {!hiddenIcon && (
        <ScIcon>
          <AlertIcon variant={variant} />
        </ScIcon>
      )}
      <div>{children}</div>
    </ScAlert>
  )
}
