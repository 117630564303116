import type { UseQueryResult } from '@tanstack/react-query'
import {
  // TODO LSIでAmplify更新時の不具合起きてるのでReact側でソートする
  // ListCompanyAddonByCompanyIdAndDisplayWeightQuery,
  // ListCompanyAddonByCompanyIdAndDisplayWeightQueryVariables,
  ModelSortDirection,
  type GetCompanyAddonV2Query,
  type GetCompanyAddonV2QueryVariables,
  type UpdateCompanyAddonV2Mutation,
  type UpdateCompanyAddonV2MutationVariables,
  type ListCompanyAddonV2sQueryVariables,
  type ListCompanyAddonV2sQuery
} from 'src/API'
import type { CustomUseMutationResult, TUseQueryOptions } from 'src/common/types'
import {
  // listCompanyAddonByCompanyIdAndDisplayWeight,
  getCompanyAddonV2,
  listCompanyAddonV2s
} from 'src/graphql/queries'
import { useFetchCognitoUser } from 'src/common/hooks'
import { updateCompanyAddonV2 } from 'src/graphql/mutations'
import { useCommonMutation, useCommonQuery } from './common'

/**
 * アドオン一覧
 * 全加盟店のアドオンを横断的に取得（運営アカウント用）
 */
export const useListCompanyAddons = ({
  variables,
  options,
  key
}: TUseQueryOptions<ListCompanyAddonV2sQueryVariables>): UseQueryResult<ListCompanyAddonV2sQuery> => {
  return useCommonQuery<ListCompanyAddonV2sQuery, ListCompanyAddonV2sQueryVariables>({
    variables,
    query: listCompanyAddonV2s,
    queryKey: 'listCompanyAddonV2s',
    deps: key,
    options
  })
}

/*
 * 加盟店IDでアドオン一覧を取得
 * enable=trueのみ
 * TODO ソートされてないのでソート必要
 */
export const useListCompanyAddonByCompanyId = ({
  variables,
  options,
  key
}: TUseQueryOptions<ListCompanyAddonV2sQueryVariables>): UseQueryResult<ListCompanyAddonV2sQuery> => {
  const { companyId } = useFetchCognitoUser()

  return useCommonQuery<ListCompanyAddonV2sQuery, ListCompanyAddonV2sQueryVariables>({
    variables: {
      companyId,
      sortDirection: ModelSortDirection.ASC,
      filter: {
        enabled: {
          eq: true
        }
      },
      ...variables
    },
    query: listCompanyAddonV2s,
    queryKey: 'listCompanyAddonV2s',
    deps: key && (typeof key === 'string' ? [{ key }, companyId] : [...key, companyId]),
    options: {
      enabled: !!companyId,
      ...options
    }
  })
}

// /**
//  * アドオン一覧 by companyId and displayWeight
//  * enable=trueのみ
//  *
//  * @param param0
//  * @returns
//  */
// export const useListCompanyAddonByCompanyIdAndDisplayWeight = ({
//   variables,
//   options,
//   key
// }: TUseQueryOptions<ListCompanyAddonByCompanyIdAndDisplayWeightQueryVariables>): UseQueryResult<ListCompanyAddonByCompanyIdAndDisplayWeightQuery> => {
//   const { companyId } = useFetchCognitoUser()

//   return useCommonQuery<
//     ListCompanyAddonByCompanyIdAndDisplayWeightQuery,
//     ListCompanyAddonByCompanyIdAndDisplayWeightQueryVariables
//   >({
//     variables: {
//       companyId,
//       sortDirection: ModelSortDirection.ASC,
//       filter: {
//         enabled: {
//           eq: true
//         }
//       },
//       ...variables
//     },
//     query: listCompanyAddonByCompanyIdAndDisplayWeight,
//     queryKey: 'listCompanyAddonByCompanyIdAndDisplayWeight',
//     deps: key && (typeof key === 'string' ? [{ key }, companyId] : [...key, companyId]),
//     options: {
//       enabled: !!companyId,
//       ...options
//     }
//   })
// }

export const useGetCompanyAddon = ({
  variables,
  options,
  key
}: TUseQueryOptions<GetCompanyAddonV2QueryVariables>): UseQueryResult<GetCompanyAddonV2Query> => {
  return useCommonQuery<GetCompanyAddonV2Query, GetCompanyAddonV2QueryVariables>({
    variables,
    query: getCompanyAddonV2,
    queryKey: 'getCompanyAddonV2',
    deps: key,
    options
  })
}

export function useUpdateCompanyAddonMutation(): CustomUseMutationResult<
  UpdateCompanyAddonV2Mutation,
  UpdateCompanyAddonV2MutationVariables
> {
  return useCommonMutation<UpdateCompanyAddonV2Mutation, UpdateCompanyAddonV2MutationVariables>(updateCompanyAddonV2)
}
