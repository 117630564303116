import React from 'react'
import styled, { theme } from 'src/common/styles'
import ClassNamesExport from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faLaptop,
  faMobileAlt,
  faMobile,
  faRobot,
  faQuestion,
  faCogs,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import type { AlertVariants, ComponentStyleProps, IconVariants } from 'src/common/types'
import IconSuccess from 'src/common/images/icon-success.svg'
import IconWarning from 'src/common/images/icon-warning.svg'
import type { SizeProp } from '@fortawesome/fontawesome-svg-core'

/**
 * Icon
 */
const IconMapping: Record<IconVariants, string> = {
  success: IconSuccess,
  warning: IconWarning
}

type IconProps = {
  variant: IconVariants
  size?: number
}

export const Icon: React.FC<IconProps> = (props) => {
  const { variant, size = 16 } = props
  return <img src={IconMapping[variant]} width={`${size}px`} height={`${size}px`} alt="" />
}

/**
 * Alert Icon
 */
const AlertIconMapping: Record<AlertVariants, JSX.Element> = {
  success: <FontAwesomeIcon size="lg" icon={faCheckCircle} />,
  info: <FontAwesomeIcon size="lg" icon={faExclamationCircle} />,
  warning: <FontAwesomeIcon size="lg" icon={faExclamationTriangle} />,
  error: <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
}

type AlertIconProps = {
  variant?: AlertVariants
}

export const AlertIcon: React.FC<AlertIconProps> = (props) => {
  const { variant = 'info' } = props
  return AlertIconMapping[variant]
}

/**
 * Device Icon
 * TODO: misc, appliance
 */
export type DeviceCategoryName = 'pc' | 'smartphone' | 'mobilephone' | 'appliance' | 'crawler' | 'misc' | 'UNKNOWN'

type DeviceIconProps = {
  deviceCategoryName: DeviceCategoryName
} & ComponentStyleProps

export const DeviceIconMapping: Record<DeviceCategoryName, JSX.Element> = {
  pc: <FontAwesomeIcon size="lg" icon={faLaptop} />,
  smartphone: <FontAwesomeIcon size="lg" icon={faMobileAlt} />,
  mobilephone: <FontAwesomeIcon size="lg" icon={faMobile} />,
  appliance: <FontAwesomeIcon size="lg" icon={faCogs} />,
  crawler: <FontAwesomeIcon size="lg" icon={faRobot} />,
  misc: <FontAwesomeIcon size="lg" icon={faExclamationTriangle} />,
  UNKNOWN: <FontAwesomeIcon size="lg" icon={faQuestion} />
}

const ScDeviceIcon = styled.span`
  display: inline-block;
`

export const DeviceIcon: React.FC<DeviceIconProps> = (props) => {
  const { deviceCategoryName, className } = props
  return <ScDeviceIcon className={ClassNamesExport(className)}>{DeviceIconMapping[deviceCategoryName]}</ScDeviceIcon>
}

type HelpIconProps = {
  color?: string
  size?: SizeProp
}

export const HelpIcon: React.FC<HelpIconProps> = ({ color = theme.color.primary, size = 'lg' }) => {
  return <FontAwesomeIcon size={size} icon={faQuestionCircle} color={color} />
}
