import {
  AuthoriReason,
  AuthoriResult,
  Charset,
  type CompanyStatus,
  FeedbackType,
  UserDeviceStatus,
  TimeUnit,
  type InquiryStatus,
  type InquiryCategory,
  type AccessLevel
} from 'src/API'
import type { ObjectKeys } from 'src/common/types'
import { COUNTRY_NAMES } from './country'

export const DEFAULT_STALE_TIME = 300000 // 5分

export const ACCOUNT_TYPE: {
  ADMIN: string
  OPE: string
  VIEW: string
} & ObjectKeys = {
  ADMIN: '管理者',
  OPE: '運用者',
  VIEW: '閲覧者'
}

export const ACCOUNT_TYPE_ARRAY = Object.keys(ACCOUNT_TYPE).map((k) => k)

export const ACTIVATION_OPTION_VALUE = [
  { value: 'true', label: '利用する' },
  { value: 'false', label: '利用しない' }
] as const

// https://cacco.notepm.jp/page/b3549a3fdc#%E3%82%B9%E3%83%86%E3%83%BC%E3%82%BF%E3%82%B9%E3%81%A8%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E6%A8%A9
export const COMPANY_STATUS: Record<CompanyStatus, string> = {
  CREATED: '作成',
  INSTALL: '導入',
  READY: '実行可能',
  RUNNING: '実行中',
  SUSPENDED: '一時停止',
  TERMINATED: '終了'
} as const

export const loginSuccess = (status: boolean): string => {
  return status ? '成功' : '結果不明'
}

export const STATUS_LIST_VALUE = [
  { value: UserDeviceStatus.VALID, label: '有効' },
  { value: UserDeviceStatus.INVALID, label: '不正' },
  { value: UserDeviceStatus.DUPLICATED, label: '重複' },
  { value: UserDeviceStatus.UNIDENTIFIED, label: '不明' },
  { value: UserDeviceStatus.WAITING, label: '待機中' }
]

export const DEVICE_CATEGORY_VALUE = [
  { value: 'pc', label: 'PC' },
  { value: 'smartphone', label: 'SP' },
  { value: 'その他', label: 'その他' }
]

export const OS_NAME_LIST_VALUE = [
  { value: 'Windows 10', label: 'Windows 10' },
  { value: 'Windows 8.1', label: 'Windows 8.1' },
  { value: 'Windows 8', label: 'Windows 8' },
  { value: 'Windows 7', label: 'Windows 7' },
  { value: 'Windows Vista', label: 'Windows Vista' },
  { value: 'Windows XP', label: 'Windows XP' },
  { value: 'Windows Phone OS', label: 'Windows Phone OS' },
  { value: 'Mac OSX', label: 'Mac OSX' },
  { value: 'ChromeOS', label: 'ChromeOS' },
  { value: 'Linux', label: 'Linux' },
  { value: 'iPad', label: 'iPad' },
  { value: 'iPhone', label: 'iPhone' },
  { value: 'Android', label: 'Android' },
  { value: 'その他', label: 'その他' }
]

export const BROWSER_NAME_LIST_VALUE = [
  { value: 'Internet Explorer', label: 'Internet Explorer' },
  { value: 'Edge', label: 'Edge' },
  { value: 'Chrome', label: 'Chrome' },
  { value: 'Firefox', label: 'Firefox' },
  { value: 'Safari', label: 'Safari' },
  { value: 'Webview', label: 'Webview' },
  { value: 'その他', label: 'その他' }
]

export const AUTHORI_RESULT_VALUE = [
  { value: AuthoriResult.OK, label: 'OK' },
  { value: AuthoriResult.NG, label: 'NG' },
  { value: AuthoriResult.REVIEW, label: 'REVIEW' }
]

export const AUTHORI_FEEDBACK_VALUE = [
  { value: AuthoriResult.OK, label: 'OK' },
  { value: AuthoriResult.NG, label: 'NG' },
  { value: AuthoriResult.NONE, label: 'NONE' }
]

export const AUTHORI_REASON_VALUE = [
  { value: AuthoriReason.USER_DEVICE_COOKIE, label: 'ユーザーIDの端末と一致' },
  { value: AuthoriReason.USER_DEVICE_ETAG, label: 'ユーザーIDの端末と一致' },
  { value: AuthoriReason.USER_DEVICE_LOCAL_STORAGE, label: 'ユーザーIDの端末と一致' },
  { value: AuthoriReason.USER_DEVICE_DID_SHORT, label: 'ユーザーIDの端末と一致' },
  { value: AuthoriReason.USER_DEVICE_DID_MIDDLE_AND_IP, label: 'ユーザーIDの端末と一致' },
  { value: AuthoriReason.USER_DEVICE, label: 'ユーザーIDの端末と一致' },
  { value: AuthoriReason.NG_DEVICE, label: '異なるユーザーIDの不正端末と一致' },
  { value: AuthoriReason.SAME_DEVICE, label: '異なるユーザーIDの端末と重複' },
  { value: AuthoriReason.EXCLUDED_IP, label: '審査対象外IPリストと一致' },
  { value: AuthoriReason.NEGATIVE_IP, label: 'ネガティブIPリストと一致' },
  { value: AuthoriReason.TOR_IP_MATCH, label: 'Torからのアクセス' },
  { value: AuthoriReason.FOREIGN_IP_AND_LANGUAGE, label: '外国語設定で海外からのアクセス' },
  { value: AuthoriReason.FOREIGN_IP, label: '海外からのアクセス' },
  { value: AuthoriReason.BOT, label: 'BOT特徴と一致' },
  { value: AuthoriReason.FIRST_USER, label: '利用履歴のないユーザーID' },
  { value: AuthoriReason.FIRST_USER_DEVICE, label: '利用履歴のない端末' },
  { value: AuthoriReason.FIRST_USER_DEVICE_COUNT_OVER, label: 'ユーザーIDの端末数が閾値を超過' }
]

export const AUTHORI_REASON_EXCEPT_REGEXP = /^USER_DEVICE_/

export const LOGIN_SUCCESS_VALUE: { value: 'true' | 'false'; label: string }[] = [
  { value: 'true', label: '成功' },
  { value: 'false', label: '結果不明' }
]

export const WT_DEVICE_CATEGORY_NAME_VALUE = [
  { value: 'pc', label: 'PC' },
  { value: 'smartphone', label: 'SP' },
  { value: 'mobilephone', label: 'フィーチャーフォン' },
  { value: 'appliance', label: 'アプライアンス' },
  { value: 'MISC', label: 'その他' },
  { value: 'UNKNOWN', label: 'UNKNOWN' }
]

export const INPUT_TYPE_VALUE = [
  { value: 'SOFTWARE_KEYBOARD', label: 'ソフトウェアキーボード' },
  { value: 'AUTO_COMPLETE', label: 'オートコンプリート' },
  { value: 'COPY_AND_PASTE', label: 'コピー&ペースト' },
  { value: 'DRAG_AND_DROP', label: 'ドラッグ&ドロップ' },
  { value: 'KEY_TYPED', label: 'キータイプ' },
  { value: 'UNKNOWN', label: '不明' },
  { value: 'NONE', label: 'なし' }
]

export const IP_FLAG_OPTIONS_VALUE = [
  { value: 'foreign_ip_flag', label: '海外IP' },
  { value: 'ip_proxy_flag', label: 'プロキシIP' },
  { value: 'tor_ip_flag', label: 'TorIP' }
]

export const TABLE_STYLE = {
  large: { width: '240px', maxWidth: '240px', minWidth: '240px' },
  middle: { width: '120px', maxWidth: '120px', minWidth: '120px' },
  small: { width: '80px', maxWidth: '80px', minWidth: '80px' }
}

export const FEEDBACK_TYPES = [
  { value: FeedbackType.API, label: 'API' },
  { value: FeedbackType.CONSOLE, label: '管理画面' },
  { value: FeedbackType.NONE, label: '不明' }
]

export const CHARSET_VALUE = [
  { value: Charset.Shift_JIS, label: 'Shift_JIS' },
  { value: Charset.UTF_8, label: 'UTF_8' },
  { value: Charset.UTF_16, label: 'UTF_16' }
]

export const AUTHORI_EXPORT_FILTER_STATUSES = [
  { value: 'REQUEST', label: '受付' },
  { value: 'COMPLETED', label: '完了' },
  { value: 'ERROR', label: '失敗' }
]

export const AUTHORI_EXPORT_LIMIT: Record<Charset, { limit: number; limitInMsg: string }> = {
  UTF_8: {
    limit: 100 * 10000,
    limitInMsg: '100万'
  },
  UTF_16: { limit: 10 * 10000, limitInMsg: '10万' },
  Shift_JIS: { limit: 10 * 10000, limitInMsg: '10万' }
}

export const REPORT_AMOUNT = {
  from: {
    hour: 12,
    month: 6,
    week: 6,
    day: 6
  },
  to: 0
}

export const SWITCH_COMPANY_WAITING_TIME = 10000 // 10秒

export const AUTHORI_EXPORT_LIMIT_OF_SYSTEM = 500
export const DEFAULT_AUTHORI_EXPORT_LIMIT = 100
export const COMPANY_ID_MAX_LENGTH = 16

export const IP_LINE_J_NAMES = [
  { name: 'フレッツ光ネクスト', class: 'FTTH' },
  { name: 'フレッツ光プレミアム', class: 'FTTH' },
  { name: 'フレッツ光（いずれか）', class: 'FTTH' },
  { name: 'フレッツ系（ftth/xDSL混在）', class: '不明' },
  { name: 'Bフレッツ', class: 'FTTH' },
  { name: 'auひかり', class: 'FTTH' },
  { name: 'コミュファ光', class: 'FTTH' },
  { name: 'eo光', class: 'FTTH' },
  { name: 'Pikara光', class: 'FTTH' },
  { name: 'BBIQ光', class: 'FTTH' },
  { name: 'その他FTTH', class: 'FTTH' },
  { name: 'CATV', class: 'CATV' },
  { name: 'IPoE', class: 'IPoE' },
  { name: '判定不明', class: '不明' },
  { name: 'フレッツADSL', class: 'ADSL' },
  { name: 'Yahoo! BB ADSL', class: 'ADSL' },
  { name: 'その他ADSL', class: 'ADSL' },
  { name: 'ISDN', class: 'ISDN' },
  { name: 'WiMAX', class: '無線' },
  { name: 'その他無線', class: '無線' },
  { name: 'docomo', class: '携帯' },
  { name: 'au', class: '携帯' },
  { name: 'softbank', class: '携帯' },
  { name: 'Y!mobile', class: '携帯' },
  { name: 'その他携帯（格安SIM）', class: '携帯' },
  { name: '楽天モバイル（MNO）', class: '携帯' },
  { name: '楽天モバイル（MVNO）', class: '携帯' },
  { name: 'mineo', class: '携帯' },
  { name: 'LIBMO', class: '携帯' },
  { name: 'b-mobile', class: '携帯' },
  { name: 'ANSIM', class: '携帯' },
  { name: 'BIGLOBEモバイル', class: '携帯' },
  { name: 'UQ mobile', class: '携帯' },
  { name: 'OCNモバイルONE', class: '携帯' },
  { name: 'ダイヤルアップ', class: 'ダイヤルアップ' },
  { name: 'PIAFS・PHS', class: 'PIAFS・PHS' },
  { name: '他ブロードバンド', class: '不明' },
  { name: '他ナローバンド', class: '不明' }
]

export const LINE_J_NAMES_VALUES = IP_LINE_J_NAMES.map((e) => ({ value: e.name, label: e.name }))

export const DATE_UNIT_VALUE = [
  { value: 'minutes', label: '分' },
  { value: 'hours', label: '時間' },
  { value: 'days', label: '日' },
  { value: 'weeks', label: '週' },
  { value: 'months', label: 'ヶ月' }
] as const

export const DATE_UNIT_VALUE_WITHOUT_WEEK = [
  { value: TimeUnit.minutes, label: '分' },
  { value: TimeUnit.hours, label: '時間' },
  { value: TimeUnit.days, label: '日' },
  { value: TimeUnit.months, label: '月' }
]

export const DATE_UNIT_LABEL = {
  minutes: '分',
  hours: '時間',
  days: '日',
  weeks: '週',
  months: '月'
}

export const INCLUDE_OR_EXCLUDE: { value: 'include' | 'exclude'; label: string }[] = [
  { value: 'include', label: '含める' },
  { value: 'exclude', label: '含めない' }
]

export const COUNTRY_NAMES_VALUES = COUNTRY_NAMES.map((e) => ({ value: e.countryCode, label: e.countryJName }))

export const AUTHORI_SEARCH_PATHS = [
  { path: '/authori/simple', type: 'simple', label: 'かんたん' },
  { path: '/authori/advance', type: 'advanced', label: '高度' }
] as const

export const INQUIRY_STATUS: Record<InquiryStatus, string> = {
  MANAGEMENT_PENDING: '運営未回答',
  COMPANY_PENDING: '加盟店未回答',
  DONE: '完了'
} as const

export const INQUIRY_CATEGORY: Record<InquiryCategory, string> = {
  GENERAL_QUESTION: 'ご質問(全般)',
  ONBOARDING_QUESTION: 'ご質問(オンボーディング)',
  FEATURE_REQUEST: '機能追加のご要望',
  REVIEW_RULE_CHANGE_REQUEST: '審査ルール変更のご要望',
  BUG_REPORT: '不具合のご報告',
  OTHERS: 'その他'
} as const

export const INQUIRY_ACCESS_LEVEL: Record<AccessLevel, string> = {
  PUBLIC: '公開',
  PRIVATE: '非公開'
} as const

export const MANAGEMENT_EMAIL = 'omoti_support@cacco.co.jp'
