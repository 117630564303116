/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const dummy = /* GraphQL */ `mutation Dummy {
  dummy {
    status
    errorCode
    message
    name
    stack
    cause
  }
}
` as GeneratedMutation<APITypes.DummyMutationVariables, APITypes.DummyMutation>;
export const createAccountByIam = /* GraphQL */ `mutation CreateAccountByIam(
  $input: CreateAccountInput!
  $condition: ModelAccountConditionInput
) {
  createAccountByIam(input: $input, condition: $condition) {
    email
    companyId
    name
    role
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountByIamMutationVariables,
  APITypes.CreateAccountByIamMutation
>;
export const updateAccount = /* GraphQL */ `mutation UpdateAccount(
  $input: UpdateAccountInput!
  $condition: ModelAccountConditionInput
) {
  updateAccount(input: $input, condition: $condition) {
    email
    companyId
    name
    role
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountMutationVariables,
  APITypes.UpdateAccountMutation
>;
export const deleteAccount = /* GraphQL */ `mutation DeleteAccount(
  $input: DeleteAccountInput!
  $condition: ModelAccountConditionInput
) {
  deleteAccount(input: $input, condition: $condition) {
    email
    companyId
    name
    role
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountMutationVariables,
  APITypes.DeleteAccountMutation
>;
export const createManagementAccountByIam = /* GraphQL */ `mutation CreateManagementAccountByIam(
  $input: CreateManagementAccountInput!
  $condition: ModelManagementAccountConditionInput
) {
  createManagementAccountByIam(input: $input, condition: $condition) {
    email
    name
    role
    allowedBy
    assumedCompanyId
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateManagementAccountByIamMutationVariables,
  APITypes.CreateManagementAccountByIamMutation
>;
export const updateManagementAccount = /* GraphQL */ `mutation UpdateManagementAccount(
  $input: UpdateManagementAccountInput!
  $condition: ModelManagementAccountConditionInput
) {
  updateManagementAccount(input: $input, condition: $condition) {
    email
    name
    role
    allowedBy
    assumedCompanyId
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateManagementAccountMutationVariables,
  APITypes.UpdateManagementAccountMutation
>;
export const deleteManagementAccount = /* GraphQL */ `mutation DeleteManagementAccount(
  $input: DeleteManagementAccountInput!
  $condition: ModelManagementAccountConditionInput
) {
  deleteManagementAccount(input: $input, condition: $condition) {
    email
    name
    role
    allowedBy
    assumedCompanyId
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteManagementAccountMutationVariables,
  APITypes.DeleteManagementAccountMutation
>;
export const createAuthori = /* GraphQL */ `mutation CreateAuthori(
  $input: CreateAuthoriInput!
  $condition: ModelAuthoriConditionInput
) {
  createAuthori(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    expirationUnixTime
    authori_id
    omotion_user_id
    company_id
    send_token
    event_id
    event_name
    user_id_hashed
    user_id_encrypted
    result
    final_result
    reason
    feedback
    feedback_comment
    feedbacked_at
    feedback_type
    feedback_account_id
    feedback_account_name
    user_device_id
    event_url
    login_success
    cookie
    etag
    local_storage
    input_type
    bot_flag
    bot_type
    did_short
    did_middle
    source_ip
    tor_ip_flag
    foreign_ip_flag
    useragent
    wt_device_category_name
    wt_os_name
    wt_os_version
    wt_browser_name
    wt_browser_version
    browser_language
    referer
    timezone_offset
    access_at
    send_at
    request_at
    began_at
    ended_at
    login_time
    residence_time
    authori_time
    es_index_date
    es_company_index
    ip_version
    ip_country_code
    ip_country_a_name
    ip_country_j_name
    ip_pref_code
    ip_pref_a_name
    ip_pref_j_name
    ip_city_code
    ip_city_a_name
    ip_city_j_name
    ip_org_name
    ip_domain_name
    ip_line_j_name
    ip_proxy_flag
    ip_pref_cf_level
    ip_city_cf_level
    ip_line_cf_level
    ip_pref_location
    ip_city_location
    ip_read_time
    ip_created
    click_count
    keystroke_count
    mouse_count
    swipe_count
    remarks
    created_at
    updated_at
    labels
    memo
    connected_id
  }
}
` as GeneratedMutation<
  APITypes.CreateAuthoriMutationVariables,
  APITypes.CreateAuthoriMutation
>;
export const updateAuthori = /* GraphQL */ `mutation UpdateAuthori(
  $input: UpdateAuthoriInput!
  $condition: ModelAuthoriConditionInput
) {
  updateAuthori(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    expirationUnixTime
    authori_id
    omotion_user_id
    company_id
    send_token
    event_id
    event_name
    user_id_hashed
    user_id_encrypted
    result
    final_result
    reason
    feedback
    feedback_comment
    feedbacked_at
    feedback_type
    feedback_account_id
    feedback_account_name
    user_device_id
    event_url
    login_success
    cookie
    etag
    local_storage
    input_type
    bot_flag
    bot_type
    did_short
    did_middle
    source_ip
    tor_ip_flag
    foreign_ip_flag
    useragent
    wt_device_category_name
    wt_os_name
    wt_os_version
    wt_browser_name
    wt_browser_version
    browser_language
    referer
    timezone_offset
    access_at
    send_at
    request_at
    began_at
    ended_at
    login_time
    residence_time
    authori_time
    es_index_date
    es_company_index
    ip_version
    ip_country_code
    ip_country_a_name
    ip_country_j_name
    ip_pref_code
    ip_pref_a_name
    ip_pref_j_name
    ip_city_code
    ip_city_a_name
    ip_city_j_name
    ip_org_name
    ip_domain_name
    ip_line_j_name
    ip_proxy_flag
    ip_pref_cf_level
    ip_city_cf_level
    ip_line_cf_level
    ip_pref_location
    ip_city_location
    ip_read_time
    ip_created
    click_count
    keystroke_count
    mouse_count
    swipe_count
    remarks
    created_at
    updated_at
    labels
    memo
    connected_id
  }
}
` as GeneratedMutation<
  APITypes.UpdateAuthoriMutationVariables,
  APITypes.UpdateAuthoriMutation
>;
export const deleteAuthori = /* GraphQL */ `mutation DeleteAuthori(
  $input: DeleteAuthoriInput!
  $condition: ModelAuthoriConditionInput
) {
  deleteAuthori(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    expirationUnixTime
    authori_id
    omotion_user_id
    company_id
    send_token
    event_id
    event_name
    user_id_hashed
    user_id_encrypted
    result
    final_result
    reason
    feedback
    feedback_comment
    feedbacked_at
    feedback_type
    feedback_account_id
    feedback_account_name
    user_device_id
    event_url
    login_success
    cookie
    etag
    local_storage
    input_type
    bot_flag
    bot_type
    did_short
    did_middle
    source_ip
    tor_ip_flag
    foreign_ip_flag
    useragent
    wt_device_category_name
    wt_os_name
    wt_os_version
    wt_browser_name
    wt_browser_version
    browser_language
    referer
    timezone_offset
    access_at
    send_at
    request_at
    began_at
    ended_at
    login_time
    residence_time
    authori_time
    es_index_date
    es_company_index
    ip_version
    ip_country_code
    ip_country_a_name
    ip_country_j_name
    ip_pref_code
    ip_pref_a_name
    ip_pref_j_name
    ip_city_code
    ip_city_a_name
    ip_city_j_name
    ip_org_name
    ip_domain_name
    ip_line_j_name
    ip_proxy_flag
    ip_pref_cf_level
    ip_city_cf_level
    ip_line_cf_level
    ip_pref_location
    ip_city_location
    ip_read_time
    ip_created
    click_count
    keystroke_count
    mouse_count
    swipe_count
    remarks
    created_at
    updated_at
    labels
    memo
    connected_id
  }
}
` as GeneratedMutation<
  APITypes.DeleteAuthoriMutationVariables,
  APITypes.DeleteAuthoriMutation
>;
export const createAuthoriSummaryMonthly = /* GraphQL */ `mutation CreateAuthoriSummaryMonthly(
  $input: CreateAuthoriSummaryMonthlyInput!
  $condition: ModelAuthoriSummaryMonthlyConditionInput
) {
  createAuthoriSummaryMonthly(input: $input, condition: $condition) {
    companyId
    yearMonth
    ok
    ng
    review
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateAuthoriSummaryMonthlyMutationVariables,
  APITypes.CreateAuthoriSummaryMonthlyMutation
>;
export const updateAuthoriSummaryMonthly = /* GraphQL */ `mutation UpdateAuthoriSummaryMonthly(
  $input: UpdateAuthoriSummaryMonthlyInput!
  $condition: ModelAuthoriSummaryMonthlyConditionInput
) {
  updateAuthoriSummaryMonthly(input: $input, condition: $condition) {
    companyId
    yearMonth
    ok
    ng
    review
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateAuthoriSummaryMonthlyMutationVariables,
  APITypes.UpdateAuthoriSummaryMonthlyMutation
>;
export const deleteAuthoriSummaryMonthly = /* GraphQL */ `mutation DeleteAuthoriSummaryMonthly(
  $input: DeleteAuthoriSummaryMonthlyInput!
  $condition: ModelAuthoriSummaryMonthlyConditionInput
) {
  deleteAuthoriSummaryMonthly(input: $input, condition: $condition) {
    companyId
    yearMonth
    ok
    ng
    review
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteAuthoriSummaryMonthlyMutationVariables,
  APITypes.DeleteAuthoriSummaryMonthlyMutation
>;
export const createCompany = /* GraphQL */ `mutation CreateCompany(
  $input: CreateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  createCompany(input: $input, condition: $condition) {
    companyId
    companyName
    companyCode
    formalName
    hashSalt
    authoriExportLimit
    allowedBy
    status
    ipAccessControl {
      enable
      ipEntry {
        ip
        description
      }
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyMutationVariables,
  APITypes.CreateCompanyMutation
>;
export const updateCompany = /* GraphQL */ `mutation UpdateCompany(
  $input: UpdateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  updateCompany(input: $input, condition: $condition) {
    companyId
    companyName
    companyCode
    formalName
    hashSalt
    authoriExportLimit
    allowedBy
    status
    ipAccessControl {
      enable
      ipEntry {
        ip
        description
      }
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyMutationVariables,
  APITypes.UpdateCompanyMutation
>;
export const deleteCompany = /* GraphQL */ `mutation DeleteCompany(
  $input: DeleteCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  deleteCompany(input: $input, condition: $condition) {
    companyId
    companyName
    companyCode
    formalName
    hashSalt
    authoriExportLimit
    allowedBy
    status
    ipAccessControl {
      enable
      ipEntry {
        ip
        description
      }
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyMutationVariables,
  APITypes.DeleteCompanyMutation
>;
export const createCompanyAddonV2 = /* GraphQL */ `mutation CreateCompanyAddonV2(
  $input: CreateCompanyAddonV2Input!
  $condition: ModelCompanyAddonV2ConditionInput
) {
  createCompanyAddonV2(input: $input, condition: $condition) {
    companyId
    addonId
    name
    displayWeight
    companyEmail
    enabled
    allowedBy
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyAddonV2MutationVariables,
  APITypes.CreateCompanyAddonV2Mutation
>;
export const updateCompanyAddonV2 = /* GraphQL */ `mutation UpdateCompanyAddonV2(
  $input: UpdateCompanyAddonV2Input!
  $condition: ModelCompanyAddonV2ConditionInput
) {
  updateCompanyAddonV2(input: $input, condition: $condition) {
    companyId
    addonId
    name
    displayWeight
    companyEmail
    enabled
    allowedBy
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyAddonV2MutationVariables,
  APITypes.UpdateCompanyAddonV2Mutation
>;
export const deleteCompanyAddonV2 = /* GraphQL */ `mutation DeleteCompanyAddonV2(
  $input: DeleteCompanyAddonV2Input!
  $condition: ModelCompanyAddonV2ConditionInput
) {
  deleteCompanyAddonV2(input: $input, condition: $condition) {
    companyId
    addonId
    name
    displayWeight
    companyEmail
    enabled
    allowedBy
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyAddonV2MutationVariables,
  APITypes.DeleteCompanyAddonV2Mutation
>;
export const createCompanyReportSetting = /* GraphQL */ `mutation CreateCompanyReportSetting(
  $input: CreateCompanyReportSettingInput!
  $condition: ModelCompanyReportSettingConditionInput
) {
  createCompanyReportSetting(input: $input, condition: $condition) {
    companyId
    ipReportSetting {
      foreignIp
      proxyIp
      torIp
      country {
        items
        isInclude
      }
      domain {
        items
        isInclude
      }
      line {
        items
        isInclude
      }
      hourly {
        userThreshold
        authoriThreshold
      }
      daily {
        userThreshold
        authoriThreshold
      }
      weekly {
        userThreshold
        authoriThreshold
      }
    }
    allowedBy
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyReportSettingMutationVariables,
  APITypes.CreateCompanyReportSettingMutation
>;
export const updateCompanyReportSetting = /* GraphQL */ `mutation UpdateCompanyReportSetting(
  $input: UpdateCompanyReportSettingInput!
  $condition: ModelCompanyReportSettingConditionInput
) {
  updateCompanyReportSetting(input: $input, condition: $condition) {
    companyId
    ipReportSetting {
      foreignIp
      proxyIp
      torIp
      country {
        items
        isInclude
      }
      domain {
        items
        isInclude
      }
      line {
        items
        isInclude
      }
      hourly {
        userThreshold
        authoriThreshold
      }
      daily {
        userThreshold
        authoriThreshold
      }
      weekly {
        userThreshold
        authoriThreshold
      }
    }
    allowedBy
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyReportSettingMutationVariables,
  APITypes.UpdateCompanyReportSettingMutation
>;
export const deleteCompanyReportSetting = /* GraphQL */ `mutation DeleteCompanyReportSetting(
  $input: DeleteCompanyReportSettingInput!
  $condition: ModelCompanyReportSettingConditionInput
) {
  deleteCompanyReportSetting(input: $input, condition: $condition) {
    companyId
    ipReportSetting {
      foreignIp
      proxyIp
      torIp
      country {
        items
        isInclude
      }
      domain {
        items
        isInclude
      }
      line {
        items
        isInclude
      }
      hourly {
        userThreshold
        authoriThreshold
      }
      daily {
        userThreshold
        authoriThreshold
      }
      weekly {
        userThreshold
        authoriThreshold
      }
    }
    allowedBy
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyReportSettingMutationVariables,
  APITypes.DeleteCompanyReportSettingMutation
>;
export const createExportRequest = /* GraphQL */ `mutation CreateExportRequest(
  $input: CreateExportRequestInput!
  $condition: ModelExportRequestConditionInput
) {
  createExportRequest(input: $input, condition: $condition) {
    id
    companyId
    event
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    createdAt
    ttl
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateExportRequestMutationVariables,
  APITypes.CreateExportRequestMutation
>;
export const updateExportRequest = /* GraphQL */ `mutation UpdateExportRequest(
  $input: UpdateExportRequestInput!
  $condition: ModelExportRequestConditionInput
) {
  updateExportRequest(input: $input, condition: $condition) {
    id
    companyId
    event
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    createdAt
    ttl
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateExportRequestMutationVariables,
  APITypes.UpdateExportRequestMutation
>;
export const deleteExportRequest = /* GraphQL */ `mutation DeleteExportRequest(
  $input: DeleteExportRequestInput!
  $condition: ModelExportRequestConditionInput
) {
  deleteExportRequest(input: $input, condition: $condition) {
    id
    companyId
    event
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    createdAt
    ttl
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteExportRequestMutationVariables,
  APITypes.DeleteExportRequestMutation
>;
export const createExportResult = /* GraphQL */ `mutation CreateExportResult(
  $input: CreateExportResultInput!
  $condition: ModelExportResultConditionInput
) {
  createExportResult(input: $input, condition: $condition) {
    companyId
    exportRequestId
    createdAt
    status
    error {
      errorType
      errorCode
    }
    s3Object {
      bucket
      key
      region
    }
    event
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    requestAt
    exportedAt
    expiredAt
    count
    ttl
    companyIdAndEvent
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateExportResultMutationVariables,
  APITypes.CreateExportResultMutation
>;
export const updateExportResult = /* GraphQL */ `mutation UpdateExportResult(
  $input: UpdateExportResultInput!
  $condition: ModelExportResultConditionInput
) {
  updateExportResult(input: $input, condition: $condition) {
    companyId
    exportRequestId
    createdAt
    status
    error {
      errorType
      errorCode
    }
    s3Object {
      bucket
      key
      region
    }
    event
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    requestAt
    exportedAt
    expiredAt
    count
    ttl
    companyIdAndEvent
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateExportResultMutationVariables,
  APITypes.UpdateExportResultMutation
>;
export const deleteExportResult = /* GraphQL */ `mutation DeleteExportResult(
  $input: DeleteExportResultInput!
  $condition: ModelExportResultConditionInput
) {
  deleteExportResult(input: $input, condition: $condition) {
    companyId
    exportRequestId
    createdAt
    status
    error {
      errorType
      errorCode
    }
    s3Object {
      bucket
      key
      region
    }
    event
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    requestAt
    exportedAt
    expiredAt
    count
    ttl
    companyIdAndEvent
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteExportResultMutationVariables,
  APITypes.DeleteExportResultMutation
>;
export const createInquiry = /* GraphQL */ `mutation CreateInquiry(
  $input: CreateInquiryInput!
  $condition: ModelInquiryConditionInput
) {
  createInquiry(input: $input, condition: $condition) {
    inquiryId
    companyId
    companyName
    accountId
    accountName
    accountEmail
    title
    status
    category
    accessLevel
    referrerUrl
    allowedBy
    companyIdOrAccountId
    companyIdOrAccountIdAndStatus
    companyIdOrAccountIdAndCategory
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateInquiryMutationVariables,
  APITypes.CreateInquiryMutation
>;
export const updateInquiry = /* GraphQL */ `mutation UpdateInquiry(
  $input: UpdateInquiryInput!
  $condition: ModelInquiryConditionInput
) {
  updateInquiry(input: $input, condition: $condition) {
    inquiryId
    companyId
    companyName
    accountId
    accountName
    accountEmail
    title
    status
    category
    accessLevel
    referrerUrl
    allowedBy
    companyIdOrAccountId
    companyIdOrAccountIdAndStatus
    companyIdOrAccountIdAndCategory
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateInquiryMutationVariables,
  APITypes.UpdateInquiryMutation
>;
export const deleteInquiry = /* GraphQL */ `mutation DeleteInquiry(
  $input: DeleteInquiryInput!
  $condition: ModelInquiryConditionInput
) {
  deleteInquiry(input: $input, condition: $condition) {
    inquiryId
    companyId
    companyName
    accountId
    accountName
    accountEmail
    title
    status
    category
    accessLevel
    referrerUrl
    allowedBy
    companyIdOrAccountId
    companyIdOrAccountIdAndStatus
    companyIdOrAccountIdAndCategory
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteInquiryMutationVariables,
  APITypes.DeleteInquiryMutation
>;
export const createInquiryMessage = /* GraphQL */ `mutation CreateInquiryMessage(
  $input: CreateInquiryMessageInput!
  $condition: ModelInquiryMessageConditionInput
) {
  createInquiryMessage(input: $input, condition: $condition) {
    inquiryMessageId
    inquiryId
    companyId
    senderAccountType
    senderName
    body
    fileKeys
    cognitoUserIdentityId
    allowedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateInquiryMessageMutationVariables,
  APITypes.CreateInquiryMessageMutation
>;
export const updateInquiryMessage = /* GraphQL */ `mutation UpdateInquiryMessage(
  $input: UpdateInquiryMessageInput!
  $condition: ModelInquiryMessageConditionInput
) {
  updateInquiryMessage(input: $input, condition: $condition) {
    inquiryMessageId
    inquiryId
    companyId
    senderAccountType
    senderName
    body
    fileKeys
    cognitoUserIdentityId
    allowedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateInquiryMessageMutationVariables,
  APITypes.UpdateInquiryMessageMutation
>;
export const deleteInquiryMessage = /* GraphQL */ `mutation DeleteInquiryMessage(
  $input: DeleteInquiryMessageInput!
  $condition: ModelInquiryMessageConditionInput
) {
  deleteInquiryMessage(input: $input, condition: $condition) {
    inquiryMessageId
    inquiryId
    companyId
    senderAccountType
    senderName
    body
    fileKeys
    cognitoUserIdentityId
    allowedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteInquiryMessageMutationVariables,
  APITypes.DeleteInquiryMessageMutation
>;
export const createReportScheduleAccount = /* GraphQL */ `mutation CreateReportScheduleAccount(
  $input: CreateReportScheduleAccountInput!
  $condition: ModelReportScheduleAccountConditionInput
) {
  createReportScheduleAccount(input: $input, condition: $condition) {
    email
    companyId
    schedule {
      hourly
      daily
      weekly
    }
    ipReportSchedule {
      hourly
      daily
      weekly
    }
    ipReportForced
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateReportScheduleAccountMutationVariables,
  APITypes.CreateReportScheduleAccountMutation
>;
export const updateReportScheduleAccount = /* GraphQL */ `mutation UpdateReportScheduleAccount(
  $input: UpdateReportScheduleAccountInput!
  $condition: ModelReportScheduleAccountConditionInput
) {
  updateReportScheduleAccount(input: $input, condition: $condition) {
    email
    companyId
    schedule {
      hourly
      daily
      weekly
    }
    ipReportSchedule {
      hourly
      daily
      weekly
    }
    ipReportForced
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportScheduleAccountMutationVariables,
  APITypes.UpdateReportScheduleAccountMutation
>;
export const deleteReportScheduleAccount = /* GraphQL */ `mutation DeleteReportScheduleAccount(
  $input: DeleteReportScheduleAccountInput!
  $condition: ModelReportScheduleAccountConditionInput
) {
  deleteReportScheduleAccount(input: $input, condition: $condition) {
    email
    companyId
    schedule {
      hourly
      daily
      weekly
    }
    ipReportSchedule {
      hourly
      daily
      weekly
    }
    ipReportForced
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportScheduleAccountMutationVariables,
  APITypes.DeleteReportScheduleAccountMutation
>;
export const createSavedSearch = /* GraphQL */ `mutation CreateSavedSearch(
  $input: CreateSavedSearchInput!
  $condition: ModelSavedSearchConditionInput
) {
  createSavedSearch(input: $input, condition: $condition) {
    id
    userId
    pagePathSearchId
    pagePath
    searchId
    queryString
    savedAt
    name
    createdAt
    updatedAt
    companyId
  }
}
` as GeneratedMutation<
  APITypes.CreateSavedSearchMutationVariables,
  APITypes.CreateSavedSearchMutation
>;
export const updateSavedSearch = /* GraphQL */ `mutation UpdateSavedSearch(
  $input: UpdateSavedSearchInput!
  $condition: ModelSavedSearchConditionInput
) {
  updateSavedSearch(input: $input, condition: $condition) {
    id
    userId
    pagePathSearchId
    pagePath
    searchId
    queryString
    savedAt
    name
    createdAt
    updatedAt
    companyId
  }
}
` as GeneratedMutation<
  APITypes.UpdateSavedSearchMutationVariables,
  APITypes.UpdateSavedSearchMutation
>;
export const deleteSavedSearch = /* GraphQL */ `mutation DeleteSavedSearch(
  $input: DeleteSavedSearchInput!
  $condition: ModelSavedSearchConditionInput
) {
  deleteSavedSearch(input: $input, condition: $condition) {
    id
    userId
    pagePathSearchId
    pagePath
    searchId
    queryString
    savedAt
    name
    createdAt
    updatedAt
    companyId
  }
}
` as GeneratedMutation<
  APITypes.DeleteSavedSearchMutationVariables,
  APITypes.DeleteSavedSearchMutation
>;
export const inviteAccount = /* GraphQL */ `mutation InviteAccount($email: AWSEmail!) {
  inviteAccount(email: $email) {
    success
  }
}
` as GeneratedMutation<
  APITypes.InviteAccountMutationVariables,
  APITypes.InviteAccountMutation
>;
export const createCompanyAccount = /* GraphQL */ `mutation CreateCompanyAccount($input: CreateAccountInput!) {
  createCompanyAccount(input: $input) {
    email
    companyId
    name
    role
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyAccountMutationVariables,
  APITypes.CreateCompanyAccountMutation
>;
export const createManagementAccount = /* GraphQL */ `mutation CreateManagementAccount($input: CreateManagementAccountInput!) {
  createManagementAccount(input: $input) {
    email
    name
    role
    allowedBy
    assumedCompanyId
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateManagementAccountMutationVariables,
  APITypes.CreateManagementAccountMutation
>;
export const log = /* GraphQL */ `mutation Log($input: LogInput) {
  log(input: $input) {
    success
  }
}
` as GeneratedMutation<APITypes.LogMutationVariables, APITypes.LogMutation>;
export const authenticate = /* GraphQL */ `mutation Authenticate {
  authenticate {
    status
  }
}
` as GeneratedMutation<
  APITypes.AuthenticateMutationVariables,
  APITypes.AuthenticateMutation
>;
export const deleteCompanyInfra = /* GraphQL */ `mutation DeleteCompanyInfra($companyId: String!) {
  deleteCompanyInfra(companyId: $companyId) {
    success
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyInfraMutationVariables,
  APITypes.DeleteCompanyInfraMutation
>;
