import React, { type FC } from 'react'
import styled from 'src/common/styles'
import { nl2br } from 'src/common/utils/String'
import { Icon } from 'src/components/ui-elements/Icons'
import { Flex } from '../layouts/Flex'
import { Button } from '../ui-elements/Button'
import { ScModalOverlay } from '../ui-elements/ModalOverlay'
import { Paper } from '../ui-elements/Paper'

export const ScModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }): string => theme.font.color};
`

export const ScModalPanel = styled.div`
  position: relative;
  font-weight: normal;
  background: #fff;
  padding: ${({ theme }): number => theme.base.size * 2}px;
  max-width: 90%;
  width: max-content;
  border-radius: 8px;
  z-index: 1;
  @media (min-width: ${({ theme }): number => theme.base.viewport.desktop}px) {
    min-width: 320px;
    max-width: 480px;
  }
`

export type Options = {
  message?: string
  labelCancel?: string
  labelConfirm?: string
  confirmButtonColor?: 'primary' | 'error'
  /** 注意事項 */
  cautionList?: string[]
}

export type Props = Options & {
  onClickCancel?: () => void
  onClickConfirm?: () => void
  onClickClose?: () => void
}

export const ModalConfirm: FC<Props> = ({
  message = '本当によろしいですか？',
  labelCancel = 'キャンセル',
  labelConfirm = 'OK',
  confirmButtonColor = 'primary',
  onClickCancel,
  onClickConfirm,
  onClickClose,
  cautionList
}) => {
  const [checked, toggle] = React.useReducer((state) => !state, false)
  return (
    <ScModal>
      <ScModalPanel>
        <div className="mb-6" data-test="confirm-modal-message">
          {nl2br(message)}
        </div>
        {/* 注意事項のチェック */}
        {cautionList && cautionList.length > 0 && (
          <>
            <Paper radius={4} color="#f6f6f6" className="mb-2 p-2">
              <p className="mb-2 font-bold">
                <Icon variant="warning" size={20} />
                {' 注意事項'}
              </p>
              {cautionList.map((caution, key) => (
                <p key={key} className="mb-2">
                  ・{caution}
                </p>
              ))}
            </Paper>
            <Paper radius={4} color="#f6f6f6" className="mb-6 p-2">
              <>
                <input id={'cautionCheck'} type="checkbox" defaultChecked={checked} onClick={() => toggle()} />
                <label htmlFor={'cautionCheck'}>注意事項を確認しました。</label>
              </>
            </Paper>
          </>
        )}
        <Flex align="right">
          {/* cancel */}
          <Button
            type="button"
            variant="text"
            color="secondary"
            onClick={(): void => onClickCancel && onClickCancel()}
            data-test="confirm-modal-cancel"
          >
            {labelCancel}
          </Button>
          {/* ok */}
          <Button
            type="button"
            variant="contained"
            className={'ml-2'}
            disabled={cautionList && cautionList.length > 0 && !checked}
            color={confirmButtonColor}
            onClick={(): void => onClickConfirm && onClickConfirm()}
            data-test="confirm-modal-confirm"
          >
            {labelConfirm}
          </Button>
        </Flex>
      </ScModalPanel>
      <ScModalOverlay onClick={(): void => onClickClose && onClickClose()} />
    </ScModal>
  )
}
