import type { SerializedStyles } from '@emotion/react'
import React, { type ReactNode } from 'react'
import styled, { css } from 'src/common/styles'

type Props = {
  isOpen: boolean
  children: ReactNode
}

const ScDashboard = styled.div<Props>`
  ${({ isOpen }): SerializedStyles => css`
    display: flex; /* fallback IE11 */
    flex-wrap: wrap; /* fallback IE11 */
    display: grid;
    height: 100vh;
    grid-template-rows: 72px 1fr 1fr 1fr;
    grid-template-columns: ${isOpen ? '240px' : '44px'} 1fr 1fr 1fr;
    grid-template-areas: 'header header header header' 'nav main main main';
  `};
`

const Dashboard: React.FC<Props> = (props) => {
  const { children, isOpen } = props
  return <ScDashboard isOpen={isOpen}>{children}</ScDashboard>
}

export default Dashboard
