import { theme } from 'src/common/styles'

/**
 * Convert hex to rgb
 */
export const hex2Rgb = (hex: `#${string}`): number[] => {
  let hexColor = hex.slice(1)
  if (hexColor.length === 3) {
    hexColor =
      hexColor.slice(0, 1) +
      hexColor.slice(0, 1) +
      hexColor.slice(1, 2) +
      hexColor.slice(1, 2) +
      hexColor.slice(2, 3) +
      hexColor.slice(2, 3)
  }

  const r = parseInt(`0x${hexColor.slice(0, 2)}`, 16)
  const g = parseInt(`0x${hexColor.slice(2, 4)}`, 16)
  const b = parseInt(`0x${hexColor.slice(4, 6)}`, 16)

  return [r, g, b]
}

/**
 * background-color(RGB) から color 値を出力
 * https://www.w3.org/TR/AERT/#color-contrast
 */
export const fontColorContrast = (rgb: number[]): string => {
  const [r, g, b] = rgb

  return (r * 299 + g * 587 + b * 114) / 1000 < 128 ? '#fff' : theme.font.color
}
