import { useEffect, useState } from 'react'
import type { CommonParamsQuery, FieldSelectOption } from 'src/common/types'
import type { ZodTypeAny } from 'zod'
import { INCLUDE_OR_EXCLUDE } from '../constants'

export const checkIpDomainNameInclude = (value: FieldSelectOption): boolean => {
  return INCLUDE_OR_EXCLUDE.map((e) => e.value as string).includes(value.value)
}

export const useValidateQueryStringWithZod = <T>(
  query: CommonParamsQuery<T>,
  schema: ZodTypeAny,
  pageLimit?: number
): {
  isValidQueryString: boolean
  isChecked: boolean
} => {
  const [isValid, setIsValid] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  useEffect(() => {
    setIsChecked(false)
    if (Object.keys(query).length) {
      try {
        schema.parse(query)
        setIsValid(true)
        setIsChecked(true)
      } catch (e) {
        setIsValid(false)
        setIsChecked(true)
      }
    }
    if (!!query.page && !!pageLimit && query.page > pageLimit) {
      setIsValid(false)
      setIsChecked(true)
    }
  }, [pageLimit, query, schema])
  return { isValidQueryString: isValid, isChecked }
}
