import type { SerializedStyles } from '@emotion/react'
import React from 'react'
import styled, { css, keyframes } from 'src/common/styles'
import type { ComponentStyleProps, ThemeColors } from 'src/common/types'

type Props = {
  isCenter?: boolean
  color?: ThemeColors
} & ComponentStyleProps

const ScWrapper = styled.div<Props>`
  position: relative;
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3px solid transparent;
  ${({ isCenter = true }): string | false =>
    isCenter &&
    `
      margin-right: auto;
      margin-left: auto;
    `}
`

type ScEffectProps = {
  delay: number
  color?: ThemeColors
}

const rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
`

const rotateOpacity = keyframes`
  0% {
    opacity: 0.8;
    transform: rotate(0);
  }
  to {
    opacity: 1;
    transform: rotate(1turn);
  }
`

const ScEffect = styled.div<ScEffectProps>`
  border: 3px solid transparent;
  border-radius: 50%;
  height: 100%;
  position: absolute;
  width: 100%;
  transition: all 0.3s ease;
  ${({ delay }): SerializedStyles | false =>
    delay === 0 &&
    css`
      animation: ${rotate} 1s ease infinite;
    `}
  ${({ delay }): SerializedStyles | false =>
    delay !== undefined &&
    css`
      animation: ${rotateOpacity} 1s ease infinite ${delay}s;
    `}
  ${({ theme, color = 'primary' }): string => `
    border-left: 3px solid ${theme.color[color]}; 
  `}
`

export const Loading: React.FC<Props> = ({ color, ...other }) => {
  return (
    <ScWrapper {...other}>
      <ScEffect delay={0} color={color} />
      <ScEffect delay={0.1} color={color} />
      <ScEffect delay={0.2} color={color} />
    </ScWrapper>
  )
}

const ScCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const LoadingCenter: React.FC<Props> = ({ style, ...rest }) => {
  return (
    <ScCenterWrapper style={style}>
      <Loading {...rest} />
    </ScCenterWrapper>
  )
}
