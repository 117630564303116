import * as React from 'react'
import styled from 'src/common/styles'
import ClassNamesExport from 'classnames'
import type { ComponentStyleProps } from 'src/common/types'

type OverlayProps = {
  onClick(name: string): void
} & ComponentStyleProps

export const ScModalOverlay = styled.div`
  ${(): string => `
    display: block;
    z-index: -1;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `};
`

export const ModalOverlay: React.FC<OverlayProps> = (props) => {
  const { className, onClick } = props
  return <ScModalOverlay className={ClassNamesExport(className)} onClick={(): void => onClick('')} />
}
