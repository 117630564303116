import * as React from 'react'
import { Formik, type FormikProps } from 'formik'
import { FormInputField } from 'src/components/ui-groups/FormInputField'
import { Button } from 'src/components/ui-elements/Button'
import { composeValidators, required } from 'src/common/utils/Validate'
import { FormFieldWithTitle } from 'src/components/ui-groups/FormFieldWithTitle'
import type { CognitoUser } from 'amazon-cognito-identity-js'
import { useNavigate } from 'react-router'
import { Modal } from 'src/components/ui-groups/Modal'
import { useForgotPassword } from 'src/services'

type Values = {
  username: string
}

type Props = {
  setUser: (user: CognitoUser) => void
  modalName: string
  onClickModalClose: () => void
}

const ForgotPasswordModal: React.FC<Props> = ({ onClickModalClose, modalName }) => {
  const navigate = useNavigate()
  const [{ mutate }] = useForgotPassword()
  const onSubmit = React.useCallback(
    async (values: Values) => {
      mutate(values.username, {
        onSettled: () => {
          navigate('/forgot-password', { state: { username: values.username } })
        }
      })
    },
    [mutate, navigate]
  )
  return (
    <Modal modalName={modalName} name={'ForgotPasswordModal'} title={'パスワード忘れ'} onClick={onClickModalClose}>
      <Formik
        initialValues={{
          username: ''
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }: FormikProps<Values>): JSX.Element => {
          return (
            <form onSubmit={handleSubmit}>
              <FormFieldWithTitle title="メールアドレス">
                <FormInputField
                  type="text"
                  id={`passwordreset-username`}
                  name={`username`}
                  className={'mb-6'}
                  placeholder="メールアドレスを入力"
                  data-test="passwordreset-username-input"
                  validate={composeValidators(required)}
                />
              </FormFieldWithTitle>
              <Button
                name="送信"
                type="submit"
                color="primary"
                data-test="send-mail-button"
                blocked
                size="large"
                className={'mb-4'}
              />
            </form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ForgotPasswordModal
