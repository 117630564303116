import { CompanyStatus } from 'src/API'
import { useGetCompany } from 'src/services'
import type { UseQueryResult } from '@tanstack/react-query'
import React from 'react'
import { useCognitoStore } from 'src/store/modules/cognito'

/**
 * サービスの提供状況
 * Company#statusで判断する
 * @see {@link https://cacco.notepm.jp/page/b3549a3fdc#%E7%8A%B6%E6%85%8B%E9%81%B7%E7%A7%BB%E5%9B%B3}
 */
export const useServiceStatus = (): {
  /** 加盟店導入 */
  isCompanyInstall: boolean
  /** オンサービス */
  isOnService: boolean
  /** 実行中
   * トップページの件数の表示用に使用
   * https://github.com/cacco-product/omoti-console/issues/1288
   */
  isRunning: boolean
} & Partial<UseQueryResult> => {
  const user = useCognitoStore((state) => state.user)
  const companyId = user?.attributes?.['custom:company_id']

  const { data, ...result } = useGetCompany({
    variables: { companyId: companyId as string },
    options: { enabled: !!companyId },
    key: [{ companyId }]
  })

  const isCompanyInstall = React.useMemo(
    () => data?.getCompany?.status === CompanyStatus.INSTALL,
    [data?.getCompany?.status]
  )
  // サービス開放 or 運営
  const isOnService = React.useMemo(
    () =>
      !!data?.getCompany &&
      [CompanyStatus.RUNNING, CompanyStatus.SUSPENDED, CompanyStatus.READY].includes(data.getCompany.status),
    [data?.getCompany]
  )

  const isRunning = React.useMemo(
    () => !!data?.getCompany && data.getCompany.status === CompanyStatus.RUNNING,
    [data?.getCompany]
  )

  return { isCompanyInstall, isOnService, isRunning, ...result }
}
