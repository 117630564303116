import React from 'react'
import { Helmet } from 'react-helmet-async'

type Props = Partial<{
  title: string
}>

const PageMeta: React.FC<Props> = (props) => {
  const { title } = props

  const meta = {
    title: title ? `${title} | O-MOTION` : 'O-MOTION'
  }

  return (
    <Helmet>
      <title>{meta.title}</title>
    </Helmet>
  )
}

export default PageMeta
