import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { parseDate } from './Date-manipulate'

dayjs.extend(isBetween)

export const isDateValid = (date: Date | string | number | null | undefined): boolean => {
  if (!date) return false
  if (date instanceof Date) {
    return !Number.isNaN(date.getTime())
  }

  const dateDDD = new Date(date)
  return !Number.isNaN(dateDDD.getTime())
}

export const isStrictDateValid = (date: Date | string | number | null | undefined): boolean => {
  const iso8601Format = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d{3})?Z?$/
  if (!date) return false
  const toDayjsDate = dayjs(date)
  if (!toDayjsDate.isValid()) return false
  const formatDate = toDayjsDate.toISOString()
  if (!isDateValid(date)) return false

  return iso8601Format.test(formatDate) && dayjs(date).isValid()
}

/**
 * isDateWithinYearsFromNow
 *
 * 指定された日付が現在日時から指定した年数以内にあるかどうか
 */
export const isDateWithinYearsFromNow = (date: Date | number | string | undefined | null, years: number): boolean => {
  if (!date) return false
  const dayjsDate = dayjs(new Date())
  const yearsAgo = dayjsDate.subtract(years, 'year')

  return dayjs(date).isAfter(yearsAgo) || dayjs(date).isSame(dayjsDate)
}

export const isThisMonth = (from: Date | string, to: Date | string): boolean => {
  const fromDate = new Date(from)
  const toDate = new Date(to)
  return fromDate.getFullYear() === toDate.getFullYear() && fromDate.getMonth() === toDate.getMonth()
}

export const isValidISODate = (value: string): boolean => {
  return `${parseDate(value)}` !== 'Invalid Date'
}
