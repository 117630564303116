import type { GraphQLResult } from '@aws-amplify/api'
import type { GraphQLError } from 'graphql/error'
import type { LambdaResolverError } from 'src/API'
import type { OmitAppSyncMetaField } from 'src/common/types'

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * AppSync GraphQL リゾルバーエラー
 */
export const isAppSyncGraphQLResolverErrorResult = (error: any): error is AppSyncGraphQLResolverResult<string> => {
  if (typeof error === 'object' && error !== null && 'data' in error) {
    const errorObj = error?.errors?.[0]
    return 'locations' in errorObj && 'errorInfo' in errorObj && 'errorType' in errorObj && 'data' in errorObj
  }
  return false
}

/**
 * AppSync LambdaリゾルバーGraphQLエラー
 */
export const isAppSyncLambdaResolverErrorResult = (
  error: any
): error is AppSyncGraphQLResolverResult<'Lambda:Unhandled'> => {
  const isAppSyncLambdaResolver =
    typeof error === 'object' && error != null && error?.errors?.[0]?.errorType === 'Lambda:Unhandled'
  // `message`がstringifyされてるかどうかで判断
  if (isAppSyncLambdaResolver) {
    try {
      const message = error?.errors?.[0]?.message
      JSON.parse(message)
      return true
    } catch (_) {
      /* empty */
    }
  }
  return false
}

/**
 * 旧AppSyncLambdaリゾルバーGraphQLエラー
 * TODO 新リゾルバーに移行する前の旧Lambdaのリゾルバー {@link https://github.com/cacco-product/omoti-console/issues/1197}
 */
export const isLegacyAppSyncLambdaResolverErrorResult = (
  error: any
): error is AppSyncGraphQLResolverResult<'Lambda:Unhandled'> =>
  typeof error === 'object' && error != null && error?.errors?.[0]?.errorType === 'Lambda:Unhandled'

/**
 * searchAuthoriパイプラインリゾルバーかつCSVダウンロードは入力チェックの際にVTLで`BadRequest`を返している
 * {@link https://github.com/cacco-product/omoti-console/pull/147}
 */
export const isCsvDownloadResolverErrorResult = (error: any): error is AppSyncGraphQLResolverResult<'BadRequest'> =>
  typeof error === 'object' && error != null && error?.errors?.[0]?.errorType === 'BadRequest'

/**
 * DynamoDB のリゾルバーエラー
 * errorType=`DynamoDB:{エラー内容}`
 */
export const isDynamoDBResolverError = (error: any): error is AppSyncGraphQLResolverResult<string> =>
  isAppSyncGraphQLResolverErrorResult(error) && !!error?.errors?.[0]?.errorType?.includes('DynamoDB')

/**
 * なんらかのErrorインスタンス
 */
export const isError = (error: any): error is Error =>
  typeof error === 'object' && error != null && error instanceof Error

/**
 * Cognitoエラー
 */
export const isCognitoError = (error: any): error is { code: string; message: string } =>
  typeof error === 'object' && 'message' in error && 'code' in error

/**
 * 文字列をthrowしているエラー
 */
export const isStringError = (error: any): error is string => typeof error === 'string'

/* eslint-enable @typescript-eslint/no-explicit-any */

export interface AppSyncGraphQLResolverResult<ErrorType extends string, MessageType = string>
  extends Omit<GraphQLResult, 'errors'> {
  errors?: AppSyncGraphQLResolverError<ErrorType, MessageType>[]
}

interface AppSyncGraphQLResolverError<ErrorType extends string, MessageType = string>
  extends Omit<GraphQLError, 'message'> {
  // AppSyncのデータソースが返すエラー種別
  errorType: ErrorType
  errorInfo: unknown
  message: MessageType
}

/**
 * `omtConsoleAppSyncResolver`が返すエラーの型に変換
 */
export const transformToAppSyncResolverError = (
  e: AppSyncGraphQLResolverResult<'Lambda:Unhandled'>
): AppSyncGraphQLResolverResult<'Lambda:Unhandled', OmitAppSyncMetaField<LambdaResolverError>> => {
  return {
    ...e,
    errors: e.errors?.map((error) => {
      return {
        ...error,
        message: JSON.parse(error.message) as LambdaResolverError
      }
    })
  }
}
