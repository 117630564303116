import React, { memo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useFetchCognitoUser } from 'src/common/hooks'
import ArrowDown from 'src/common/images/arrow-down.svg'
import OfflineIcon from 'src/common/images/offline.svg'
import OnlineIcon from 'src/common/images/online.svg'
import styled from 'src/common/styles'
import { Flex, FlexCol } from 'src/components/layouts/Flex'
import { AppLogo } from 'src/components/ui-elements/AppLogo'
import { ManagementAccountMenu } from 'src/components/ui-groups/ManagementAccountMenu'
import { UserMenu } from 'src/components/ui-groups/UserMenu'
import { useGetCompany } from 'src/services/company'
import { ManagementAccountChecker } from './ManagementAccountChecker'

const ScHeader = styled.header`
  display: flex; /* fallback IE11 */
  flex-basis: 100%;
  grid-area: header;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 0 0 0 24px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3000;
  height: 72px;
`
const ScWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const ScImg = styled.img`
  width: 16px;
  height: 16px;
`

const ScUserName = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  height: 72px;
  padding: 0 16px;

  img {
    display: block;
    margin-left: 8px;
  }
`

const ScSystemUser = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  background: ${({ theme }): string => theme.color.primary};
  color: white;
  height: 72px;
  padding: 0 16px;
  img {
    display: block;
    margin-left: 8px;

    filter: invert(100%) sepia(2%) saturate(7%) hue-rotate(358deg) brightness(104%) contrast(104%);
  }
`

const Header: React.FC = memo(() => {
  const [isMenuOpen, setMenuOpen] = React.useState('')
  const [isOnline, setOnline] = React.useState(false)
  const { handleSignOut, user } = useFetchCognitoUser()

  const companyId = user?.attributes?.['custom:company_id']
  const { data } = useGetCompany({
    variables: { companyId: companyId as string },
    options: { enabled: !!companyId },
    key: [{ companyId }]
  })
  const { pathname } = useLocation()

  const onlineListener = React.useCallback(() => {
    setOnline(true)
  }, [])

  const offlineListener = React.useCallback(() => {
    setOnline(false)
  }, [])

  React.useEffect(() => {
    if (navigator.onLine) {
      setOnline(true)
    } else {
      setOnline(false)
    }
  }, [user])

  React.useEffect(() => {
    window.addEventListener('online', onlineListener)
    window.addEventListener('offline', offlineListener)

    return () => {
      window.removeEventListener('online', onlineListener)
      window.removeEventListener('offline', offlineListener)
    }
  }, [onlineListener, offlineListener])

  React.useEffect(() => {
    let cleanedUp = false
    if (!cleanedUp) {
      setMenuOpen('')
    }
    return () => {
      cleanedUp = true
    }
  }, [pathname])

  return (
    <ScHeader role="banner">
      <Flex vAlign="middle" align="between" flex={1}>
        <FlexCol>
          <Link to="/">
            <AppLogo width={160} />
          </Link>
        </FlexCol>
        <FlexCol>
          <ScWrapper>
            <ScImg src={isOnline ? OnlineIcon : OfflineIcon} alt={isOnline ? 'online' : 'offline'} className={'mr-4'} />
            <ManagementAccountChecker>
              <div style={{ position: 'relative' }}>
                <ScSystemUser onClick={(): void => setMenuOpen('System')}>
                  運営メニュー
                  <img src={ArrowDown} alt="" />
                </ScSystemUser>
                {isMenuOpen === 'System' && <ManagementAccountMenu handleClose={(): void => setMenuOpen('')} />}
              </div>
            </ManagementAccountChecker>
            <div style={{ position: 'relative' }}>
              <ScUserName onClick={(): void => setMenuOpen('User')} data-test="open-user-menu">
                {data && data?.getCompany && (
                  <span className="mr-2">
                    {data.getCompany.formalName}｜{data.getCompany.companyName}
                  </span>
                )}
                {user && <span>{user?.attributes?.name}</span>}
                <img src={ArrowDown} alt="" />
              </ScUserName>
              {isMenuOpen === 'User' && (
                <UserMenu handleEvent={(): void => handleSignOut()} handleClose={(): void => setMenuOpen('')} />
              )}
            </div>
          </ScWrapper>
        </FlexCol>
      </Flex>
    </ScHeader>
  )
})

export default Header
