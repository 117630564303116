import { useErrorHandler } from 'src/common/hooks'
import { Auth } from 'aws-amplify'
import type { CognitoUser } from 'amazon-cognito-identity-js'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { createErrorMessage, type ErrMessage } from 'src/common/helper'
import { useToast } from 'src/components/ui-elements/Toast'
import { recordAnalyticsEvent } from 'src/lib/amplify/analytics/helper'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const authForgotPassword = async (user: string): Promise<any> => {
  const userInfo = await Auth.forgotPassword(user)

  return userInfo
}

export function useForgotPassword(): [UseMutationResult<CognitoUser>, ErrMessage | undefined] {
  const toast = useToast()
  const { errMessage, onSetError } = useErrorHandler()
  const result = useMutation({
    mutationFn: async (variables: string) => {
      try {
        recordAnalyticsEvent('loginForgotPassword', { email: variables })
        const userInfo = (await authForgotPassword(variables)) as CognitoUser
        toast({ text: '成功しました', variant: 'success' })
        return userInfo
      } catch (errors) {
        const errMsg = createErrorMessage(errors)
        onSetError(errMsg)
        throw errors
      }
    }
  }) as UseMutationResult<CognitoUser>
  return [result, errMessage]
}
