import React from 'react'
import styled from 'src/common/styles'
import LogoImage from 'src/common/images/logo-o-motion.svg'

type Props = {
  width?: number
  display?: 'block' | 'inline-block'
}

const getHeight = (width: number): number => {
  const ratio = 0.121212
  return width * ratio
}

const ScLogo = styled.img<Props>`
  width: 100%;
  ${({ display, width = 198 }): string => `
    display: ${display};
    max-width: ${width}px;
    max-height: ${getHeight(width)}px;
  `}
`

export const AppLogo: React.FC<Props> = (props) => {
  const { display = 'block', width = 198 } = props
  return <ScLogo src={LogoImage} display={display} width={width} alt="O-MOTION" />
}
