import { format, transports, createLogger } from 'winston'
import type { LogLevel } from 'src/API'
import { formatTimeStampDate } from './Date'

// JSON.stringify のオブジェクトの翻訳関数
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const replacer = (key: any, value: any): any => {
  // 関数なら "= function" と返す
  if (value instanceof Function) {
    return '= function'

    // undefined なら "= undefined" と返す
  }
  if (value === undefined) {
    return '= undefined'
  }

  // 他のオブジェクト・配列はそのまま次へ
  return value
}

// ログのカスタムフォーマッタ
const formatter = format.printf(
  // ログ情報をフォーマットした文字列に展開する関数
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (info: any): string => {
    // 引数を展開する
    const {
      level, // デフォルトで level と message が渡る
      message,
      timestamp, // format.combine() で format.timestamp() 指定されている
      ...etc // その他の内容は JSON で表示する
    } = info

    // フォーマットした文字列を返す
    return (
      `${formatTimeStampDate(timestamp)} [${level}] - ${message}` +
      `${etc && Object.keys(etc).length ? `\n${JSON.stringify(etc, replacer, 2)}` : ''}`
    )
  }
)

export const createLogMutationInput = ({
  message,
  level
}: {
  message: unknown
  level: LogLevel
}): {
  level: LogLevel
  message: string
  path: string
  timestamp: string
} => {
  return {
    level,
    message: `${JSON.stringify(message)}`,
    path: window.location.href,
    timestamp: formatTimeStampDate(new Date())
  }
}

export const log = createLogger(
  // ロガーオプション
  {
    level: process.env.REACT_APP_STAGE === 'dev' ? 'debug' : 'info',
    format: format.combine(
      format.colorize(), // logLevelに色つける
      format.timestamp(), // ログ情報に timestamp を付加する
      format.simple(), // テキスト行としてログを出す
      formatter // テキスト化するフォーマッタ
    ),
    transports: [
      new transports.Console() // コンソールへ出力する
    ]
  }
)
