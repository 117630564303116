import { isIP, isIPv4 } from 'is-ip'
import icCidr from 'is-cidr'
import type { FieldSelectOption } from 'src/common/types'
import dayjs from 'dayjs'
import { AUTHORI_EXPORT_LIMIT_OF_SYSTEM, COMPANY_ID_MAX_LENGTH } from '../constants'
import { defaultFormatDate } from './Date'

export const ErrorMessages = {
  required: '必須項目です。入力してください。',
  email: '正しいメールアドレスの形式でご入力ください。',
  num: '半角数字(小数不可)で入力して下さい。',
  passwordLength: '8文字以上で入力してください。',
  passwordPolicy: '半角英大文字、英小文字、数字を全て含む必要があります',
  time: '時刻の形式で入力してください。',
  url: 'URLの形式が間違っています。 例：https://example.com',
  space: '半角・全角スペースは入力できません',
  halfWidthAlphanumeric: '半角英数字で入力してください',
  ip: 'IPの形式が間違っています。',
  ipv4: 'IPv4の形式で入力してください。',
  ipv4AllowCidr: 'IPv4かIPv4のCIDR形式で入力してください。'
} as const

// prettier-ignore
export const Regex = {
  email:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  num: /^[0-9]+$/,
  positiveNum: /^[1-9]\d*$/,
  passwordLength: /^(?=.*?[a-zA-Z])(?=.*?\d)[!-~]{8,16}$/,
  passwordPolicy: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).+$/,
  url: /^(https?)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/,
  time: /^\d{2}:\d{2}$/,
  pageSize: /^(20|50|100)$/,
  halfWidthAlphanumeric: /^[A-Za-z0-9]*$/,
}

const checkRegex = (value: string, prop: keyof typeof Regex): string => {
  if (!value || Regex[prop].test(value)) return ''
  return prop
}
const NAME_COLUMN_SPRIT_VALUE = ' '
// 全角スペース
const NAME_COLUMN_SPRIT_VALUE_W = '　'
const checkSpace = (value: { value: string; label: string }[] | string, prop: string): string => {
  const d =
    typeof value !== 'string' &&
    value.find((e) => e.value.indexOf(NAME_COLUMN_SPRIT_VALUE) > 0 || e.value.indexOf(NAME_COLUMN_SPRIT_VALUE_W) > 0)
  if (!value || !d) return ''
  return prop
}

const checkIp = (
  isIpFn: (val: string) => boolean,
  errorType: Partial<keyof typeof ErrorMessages>,
  value: string | FieldSelectOption[]
): keyof typeof ErrorMessages | '' => {
  if (typeof value === 'string') {
    if (!value || isIpFn(value)) return ''
  }
  if (Array.isArray(value)) {
    if (value.every(({ value: val }) => isIpFn(val))) return ''
  }
  return errorType
}
const checkIpV4V6 = (value: string | FieldSelectOption[]): string => checkIp(isIP, 'ip', value)
const checkIpV4 = (value: string | FieldSelectOption[]): string => checkIp(isIPv4, 'ipv4', value)
const checkIpV4AllowCidr = (value: string | FieldSelectOption[]): string =>
  checkIp((v: string) => isIPv4(v) || icCidr.v4(v), 'ipv4AllowCidr', value)

const checkRequired = (value: string | number): string => {
  if (Array.isArray(value)) {
    if (value.length > 0) return ''
    return 'required'
  }
  if (value || typeof value === 'number') return ''
  return 'required'
}

const checkMinNumber = (value: string, num: number, prop: string): string => {
  if (!value || value.length >= num) return ''
  return prop
}

const checkMaxNumber = (value: string, num: number): string => {
  if (!value || Number(value) <= num) return ''
  return `${num}以下で入力してください。`
}
const checkMaxLength = (value: string, num: number): string => {
  if (!value || Number(value.length) <= num) return ''
  return `${num}文字以下で入力してください。`
}

const pushMessage = (prop: string): string | undefined =>
  prop.length > 0 ? ErrorMessages[prop as keyof typeof ErrorMessages] : undefined

export const required = (value: string): string | undefined => pushMessage(checkRequired(value))

export const email = (value: string): string | undefined => pushMessage(checkRegex(value, 'email'))

export const passwordLength = (value: string): string | undefined =>
  pushMessage(checkMinNumber(value, 8, 'passwordLength'))

export const passwordPolicy = (value: string): string | undefined => pushMessage(checkRegex(value, 'passwordPolicy'))

export const num = (value: string): string | undefined => pushMessage(checkRegex(value, 'num'))
export const checkExportLimitOfSystem = (value: string): string | undefined =>
  checkMaxNumber(value, AUTHORI_EXPORT_LIMIT_OF_SYSTEM)
export const checkCompanyIdLength = (value: string): string | undefined => checkMaxLength(value, COMPANY_ID_MAX_LENGTH)

export const url = (value: string): string | undefined => pushMessage(checkRegex(value, 'url'))

export const time = (value: string): string | undefined => pushMessage(checkRegex(value, 'time'))

export const ip = (value: string | FieldSelectOption[]): string | undefined => pushMessage(checkIpV4V6(value))
export const ipv4 = (value: string | FieldSelectOption[]): string | undefined => pushMessage(checkIpV4(value))
export const ipv4allowcidr = (value: string | FieldSelectOption[]): string | undefined =>
  pushMessage(checkIpV4AllowCidr(value))

export const rangeDate = (value: string, toDate: string): string | undefined => {
  const from = dayjs(value)
  const to = dayjs(toDate)
  if (!value) return ErrorMessages.required
  if (from.isBefore(to)) {
    return `${defaultFormatDate(toDate)}以降の日付を選択してください`
  }
  return ''
}

export const withInMonth = (value: string, compareDate: string): string => {
  const valueMonth = dayjs(value).format('YYYYMM')
  const compareMonth = dayjs(compareDate).format('YYYYMM')
  if (!value) return ErrorMessages.required
  if (valueMonth !== compareMonth) {
    return `月を跨いだ選択はできません`
  }
  return ''
}

export const space = (value: { value: string; label: string }[] | string): string | undefined =>
  pushMessage(checkSpace(value, 'space'))

export const halfWidthAlphanumeric = (value: string): string | undefined =>
  pushMessage(checkRegex(value, 'halfWidthAlphanumeric'))

type arg = (value: string, compareValue?: string) => string | undefined

export const composeValidators =
  (...args: arg[]) =>
  (value: string, compareValue?: string): string | undefined => {
    let error
    args.forEach((validator) => {
      const err = validator(value, compareValue)
      if (err) {
        error = err
      }
    })
    return error
  }
