import * as React from 'react'
import { useParams } from 'react-router'

type TabMenu = {
  name: string
  path: string
  isCurrent: boolean
  func: () => void
}

export function useTabMenu({ list, dir }: { list: { name: string; path: string }[]; dir: string }): {
  tabMenu: TabMenu[]
  router: Readonly<
    Partial<{
      id: string
      view: string
    }>
  >
  tab: string
} {
  const router = useParams<{ id: string; view: string }>()
  const [tabMenu, setTabMenu] = React.useState<TabMenu[]>([])
  const [tab, changeTab] = React.useState(list[0].path)

  React.useEffect(() => {
    setTabMenu(
      list.map((e: { name: string; path: string }) => {
        return {
          name: e.name,
          path: e.path,
          isCurrent: e.path === tab,
          func: () => changeTab(e.path)
        }
      })
    )
  }, [changeTab, tab, list, dir])

  return { tabMenu, router, tab }
}
