import * as React from 'react'
import styled from 'src/common/styles'
import ClassNamesExport from 'classnames'
import type { ComponentStyleProps, ThemeColors, ThemeSize } from 'src/common/types'
import { fontColorContrast, hex2Rgb } from 'src/common/utils/Color'
import { Loading } from './Loading'

type Props = Partial<{
  name: string
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled: boolean
  blocked: boolean
  color: ThemeColors
  size: ThemeSize
  type: 'button' | 'submit' | 'reset'
  loading: boolean
  variant: 'text' | 'contained' | 'outlined'
  children: React.ReactNode
}> &
  ComponentStyleProps

export const ScButton = styled.button<Props>`
  position: relative;
  display: ${({ blocked }): string => (blocked ? 'block' : 'inline-block')};
  width: ${({ blocked }): string => (blocked ? '100%' : ' auto')};
  padding: calc(0.64em - 1px) calc(1.14em - 1px);
  line-height: 1;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  font-size: ${({ size = 'medium', theme }): string => theme.font.size[size]};
  white-space: nowrap;
  outline: none;
  touch-action: manipulation;
  cursor: pointer;
  font-weight: bold;

  &:link,
  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    background: #c0c0c0;
    border: 1px solid #c0c0c0;
    color: #fff;
    &:hover {
      opacity: 1;
      background: #c0c0c0;
    }
  }

  // contained
  ${({ theme, variant, color = 'default' }): string | false =>
    variant === 'contained' &&
    `
    background: ${theme.color[color]};
    border: 1px solid ${theme.color[color]};
    color: ${fontColorContrast(hex2Rgb(theme.color[color]))};
    &:hover {
      opacity: 0.85;
    }
  `}

  // outlined
  ${({ theme, variant, color = 'default' }): string | false =>
    variant === 'outlined' &&
    `
    background: #fff;
    border: 1px solid ${theme.color[color]};
    color: ${color === 'default' ? theme.font.color : theme.color[color]};
    &:hover {
      background: rgba(${hex2Rgb(theme.color[color])}, 0.08);
    }
  `}

  // text
  ${({ theme, variant, color = 'default' }): string | false =>
    variant === 'text' &&
    `
    background: transparent;
    border: 1px solid transparent;
    color: ${theme.color[color]};
    &:hover {
      background: rgba(${hex2Rgb(theme.color[color])}, 0.08);
    }
    &.disabled,
    &[disabled] {
      color: #c0c0c0;
      background: transparent;
      border: 1px solid transparent;
      &:hover {
        opacity: 1;
        background: #f7f7f7;
      }
    }
  `}
`

export const Button: React.FC<Props> = (props) => {
  const {
    name,
    onClick,
    className,
    children,
    color = 'default',
    type = 'button',
    variant = 'contained',
    loading,
    disabled,
    size = 'medium',
    ...others
  } = props
  const loaderSize = size === 'large' ? '25px' : '14px'

  return (
    <ScButton
      className={ClassNamesExport(className)}
      type={type}
      color={color}
      variant={variant}
      {...others}
      onClick={onClick}
      disabled={disabled || loading}
      size={size}
    >
      {loading && <Loading color={'white'} style={{ width: loaderSize, height: loaderSize }} />}
      {!loading && name}
      {!loading && children}
    </ScButton>
  )
}

type IScButton = {
  current: boolean
}

export const ScSwitchButton = styled(Button)<IScButton>`
  ${({ theme, current }): string => `
    background-color: ${current ? theme.color.primary : '#fff'};
    color: ${current ? ' #fff' : theme.font.color};
  `}
  padding: 0.64em;
  min-width: 88px;
`
