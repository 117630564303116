import { useErrorHandler } from 'src/common/hooks'
import { Auth } from 'aws-amplify'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { createErrorMessage, type ErrMessage } from 'src/common/helper'
import { useToast } from 'src/components/ui-elements/Toast'
import { recordAnalyticsEvent } from 'src/lib/amplify/analytics/helper'

type Values = {
  code: string
  username: string
  password: string
}

export const forgotPassword = async (username: string, code: string, pass: string): Promise<string> => {
  const userInfo = await Auth.forgotPasswordSubmit(username, code, pass)

  return userInfo
}

export function useForgotPasswordSubmit(): [UseMutationResult<{ isSuccess: boolean }>, ErrMessage | undefined] {
  const toast = useToast()
  const { errMessage, onSetError } = useErrorHandler()
  const result = useMutation({
    mutationFn: async (variables: Values) => {
      try {
        await Auth.forgotPasswordSubmit(variables.username, variables.code, variables.password)
        toast({ text: 'パスワードを変更しました', variant: 'success' })
        return { isSuccess: true }
      } catch (errors) {
        const errMsg = createErrorMessage(errors)
        onSetError(errMsg)
        toast({
          text: '失敗しました',
          code: errMsg.code,
          variant: 'error',
          errors
        })
        throw errors
      }
    },
    onSettled: (_, e) => recordAnalyticsEvent('loginForgotPasswordComplete', { result: e ? 'failure' : 'success' })
  }) as UseMutationResult<{ isSuccess: boolean }>
  return [result, errMessage]
}
