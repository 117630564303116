import type { UseQueryResult } from '@tanstack/react-query'
import type {
  AggregateAuthoriResultV2Query,
  AggregateAuthoriReasonNgV2Query,
  AggregateAuthoriReasonNgV2QueryVariables,
  AggregateAuthoriReasonReviewV2Query,
  AggregateAuthoriReasonReviewV2QueryVariables,
  AggregateAuthoriResultV2QueryVariables,
  AggregateAuthoriTorIpV2QueryVariables,
  AggregateAuthoriForeignIpV2Query,
  AggregateAuthoriForeignIpV2QueryVariables,
  AggregateAuthoriForeignLanguageV2QueryVariables,
  AggregateAuthoriForeignLanguageV2Query,
  AggregateAuthoriBotV2QueryVariables,
  AggregateAuthoriBotV2Query,
  AggregateDeviceStatusV2QueryVariables,
  AggregateDeviceStatusV2Query,
  AggregateAuthoriInputTypeV2QueryVariables,
  AggregateAuthoriInputTypeV2Query,
  AggregateAuthoriForeignAccessV2QueryVariables,
  AggregateAuthoriForeignAccessV2Query,
  AggregateByIpV2Query,
  AggregateByIpV2QueryVariables,
  AggregateAuthoriIpCityLocationV2QueryVariables,
  AggregateAuthoriIpCityLocationV2Query,
  AggregateAuthoriTorIpV2Query
} from 'src/API'
import type { TUseQueryOptions } from 'src/common/types'
import {
  aggregateAuthoriResultV2,
  aggregateAuthoriReasonReviewV2,
  aggregateAuthoriReasonNgV2,
  aggregateAuthoriTorIpV2,
  aggregateAuthoriForeignIpV2,
  aggregateAuthoriForeignLanguageV2,
  aggregateAuthoriBotV2,
  aggregateDeviceStatusV2,
  aggregateAuthoriForeignAccessV2,
  aggregateByIpV2,
  aggregateAuthoriIpCityLocationV2,
  aggregateAuthoriInputTypeV2
} from 'src/graphql/queries'
import { useCommonQuery } from './common'

/**
 * 審査結果
 * interval: https://www.elastic.co/guide/en/elasticsearch/reference/6.3/search-aggregations-bucket-datehistogram-aggregation.html
 */
export const useAggregateAuthoriResultQuery = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateAuthoriResultV2QueryVariables>): UseQueryResult<AggregateAuthoriResultV2Query> => {
  return useCommonQuery<AggregateAuthoriResultV2Query, AggregateAuthoriResultV2QueryVariables>({
    variables,
    query: aggregateAuthoriResultV2,
    queryKey: 'aggregateAuthoriResultV2',
    deps: key,
    options
  })
}

// 審査結果: 審査結果REVIEW
export const useAggregateAuthoriReasonReviewQuery = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateAuthoriReasonReviewV2QueryVariables>): UseQueryResult<AggregateAuthoriReasonReviewV2Query> => {
  return useCommonQuery<AggregateAuthoriReasonReviewV2Query, AggregateAuthoriReasonReviewV2QueryVariables>({
    variables,
    query: aggregateAuthoriReasonReviewV2,
    queryKey: 'aggregateAuthoriReasonReviewV2',
    deps: key,
    options
  })
}

// 審査結果: 審査結果NG
export const useAggregateAuthoriReasonNgQuery = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateAuthoriReasonNgV2QueryVariables>): UseQueryResult<AggregateAuthoriReasonNgV2Query> => {
  return useCommonQuery<AggregateAuthoriReasonNgV2Query, AggregateAuthoriReasonNgV2QueryVariables>({
    variables,
    query: aggregateAuthoriReasonNgV2,
    queryKey: 'aggregateAuthoriReasonNgV2',
    deps: key,
    options
  })
}

// 不正傾向アクセス: TorIP
export const useAggregateAuthoriTorIp = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateAuthoriTorIpV2QueryVariables>): UseQueryResult<AggregateAuthoriTorIpV2Query> => {
  return useCommonQuery<AggregateAuthoriTorIpV2Query, AggregateAuthoriTorIpV2QueryVariables>({
    variables,
    query: aggregateAuthoriTorIpV2,
    queryKey: 'aggregateAuthoriTorIpV2',
    deps: key,
    options
  })
}

// 不正傾向アクセス: 海外IP
export const useAggregateAuthoriForeignIp = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateAuthoriForeignIpV2QueryVariables>): UseQueryResult<AggregateAuthoriForeignIpV2Query> => {
  return useCommonQuery<AggregateAuthoriForeignIpV2Query, AggregateAuthoriForeignIpV2QueryVariables>({
    variables,
    query: aggregateAuthoriForeignIpV2,
    queryKey: 'aggregateAuthoriForeignIpV2',
    deps: key,
    options
  })
}

// 不正傾向アクセス: 外国語設定
export const useAggregateAuthoriForeignLanguage = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateAuthoriForeignLanguageV2QueryVariables>): UseQueryResult<AggregateAuthoriForeignLanguageV2Query> => {
  return useCommonQuery<AggregateAuthoriForeignLanguageV2Query, AggregateAuthoriForeignLanguageV2QueryVariables>({
    variables,
    query: aggregateAuthoriForeignLanguageV2,
    queryKey: 'aggregateAuthoriForeignLanguageV2',
    deps: key,
    options
  })
}

// 海外からのアクセス国別割合
export const useAggregateAuthoriForeignAccess = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateAuthoriForeignAccessV2QueryVariables>): UseQueryResult<AggregateAuthoriForeignAccessV2Query> => {
  return useCommonQuery<AggregateAuthoriForeignAccessV2Query, AggregateAuthoriForeignAccessV2QueryVariables>({
    variables,
    query: aggregateAuthoriForeignAccessV2,
    queryKey: 'aggregateAuthoriForeignAccessV2',
    deps: key,
    options
  })
}

// BOTアクセス
export const useAggregateAuthoriBot = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateAuthoriBotV2QueryVariables>): UseQueryResult<AggregateAuthoriBotV2Query> => {
  return useCommonQuery<AggregateAuthoriBotV2Query, AggregateAuthoriBotV2QueryVariables>({
    variables,
    query: aggregateAuthoriBotV2,
    queryKey: 'aggregateAuthoriBotV2',
    deps: key,
    options
  })
}

// 端末状態
export const useAggregateDeviceStatus = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateDeviceStatusV2QueryVariables>): UseQueryResult<AggregateDeviceStatusV2Query> => {
  return useCommonQuery<AggregateDeviceStatusV2Query, AggregateDeviceStatusV2QueryVariables>({
    variables,
    query: aggregateDeviceStatusV2,
    queryKey: 'aggregateDeviceStatusV2',
    deps: key,
    options
  })
}

// 入力種別割合
export const useAggregateAuthoriInputType = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateAuthoriInputTypeV2QueryVariables>): UseQueryResult<AggregateAuthoriInputTypeV2Query> => {
  return useCommonQuery<AggregateAuthoriInputTypeV2Query, AggregateAuthoriInputTypeV2QueryVariables>({
    variables,
    query: aggregateAuthoriInputTypeV2,
    queryKey: 'aggregateAuthoriInputTypeV2',
    deps: key,
    options
  })
}

// IPで集計
export const useAggregateByIp = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateByIpV2QueryVariables>): UseQueryResult<AggregateByIpV2Query> => {
  return useCommonQuery<AggregateByIpV2Query, AggregateByIpV2QueryVariables>({
    variables,
    query: aggregateByIpV2,
    queryKey: 'aggregateByIpV2',
    deps: key,
    options
  })
}

// 位置情報の集計値
export const useAggregateAuthoriIpCityLocation = ({
  key,
  options,
  variables
}: TUseQueryOptions<AggregateAuthoriIpCityLocationV2QueryVariables>): UseQueryResult<AggregateAuthoriIpCityLocationV2Query> => {
  return useCommonQuery<AggregateAuthoriIpCityLocationV2Query, AggregateAuthoriIpCityLocationV2QueryVariables>({
    variables,
    query: aggregateAuthoriIpCityLocationV2,
    queryKey: 'aggregateAuthoriIpCityLocationV2',
    deps: key,
    options
  })
}
