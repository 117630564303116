import type { Env } from 'src/common/types'
import { OMOTI_COMPANY_JS } from 'src/omotion/omoti-company-js/constant'

/**
 * HTML上の加盟店JSを取り除く
 */
export const removeCompanyJs = (): void => {
  const exists = document.getElementById(OMOTI_COMPANY_JS.ID)
  if (exists) {
    exists.parentNode?.removeChild(exists)
  }
}

/**
 * HTML上に加盟店JSを挿入する
 */
export const loadCompanyJs = async (env: Env, name: string): Promise<void> => {
  removeCompanyJs()
  const body = document.getElementsByTagName('body')[0]
  const scriptElement = document.createElement('script')
  scriptElement.id = OMOTI_COMPANY_JS.ID
  scriptElement.type = 'text/javascript'
  scriptElement.src = `https://cacco.${env === 'prod' ? '' : `${env}.`}web.o-moti.com/assets/js/${name}`
  body.appendChild(scriptElement)
}
