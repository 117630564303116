export const COUNTRY_CODES = [
  'AD',
  'AE',
  'AF',
  'AG',
  'AI',
  'AL',
  'AM',
  'AO',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AW',
  'AX',
  'AZ',
  'BA',
  'BB',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BL',
  'BM',
  'BN',
  'BO',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BV',
  'BW',
  'BY',
  'BZ',
  'CA',
  'CC',
  'CD',
  'CF',
  'CG',
  'CH',
  'CI',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CR',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
  'DE',
  'DJ',
  'DK',
  'DM',
  'DO',
  'DZ',
  'EC',
  'EE',
  'EG',
  'EH',
  'ER',
  'ES',
  'ET',
  'FI',
  'FJ',
  'FK',
  'FM',
  'FO',
  'FR',
  'GA',
  'GB',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GL',
  'GM',
  'GN',
  'GP',
  'GQ',
  'GR',
  'GS',
  'GT',
  'GU',
  'GW',
  'GY',
  'HK',
  'HM',
  'HN',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IM',
  'IN',
  'IO',
  'IQ',
  'IR',
  'IS',
  'IT',
  'JE',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KI',
  'KM',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
  'LB',
  'LC',
  'LI',
  'LK',
  'LR',
  'LS',
  'LT',
  'LU',
  'LV',
  'LY',
  'MA',
  'MC',
  'MD',
  'ME',
  'MF',
  'MG',
  'MH',
  'MK',
  'ML',
  'MM',
  'MN',
  'MO',
  'MP',
  'MQ',
  'MR',
  'MS',
  'MT',
  'MU',
  'MV',
  'MW',
  'MX',
  'MY',
  'MZ',
  'NA',
  'NC',
  'NE',
  'NF',
  'NG',
  'NI',
  'NL',
  'NO',
  'NP',
  'NR',
  'NU',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PF',
  'PG',
  'PH',
  'PK',
  'PL',
  'PM',
  'PN',
  'PR',
  'PS',
  'PT',
  'PW',
  'PY',
  'QA',
  'RE',
  'RO',
  'RS',
  'RU',
  'RW',
  'SA',
  'SB',
  'SC',
  'SD',
  'SE',
  'SG',
  'SH',
  'SI',
  'SJ',
  'SK',
  'SL',
  'SM',
  'SN',
  'SO',
  'SR',
  'SS',
  'ST',
  'SV',
  'SX',
  'SY',
  'SZ',
  'TC',
  'TD',
  'TF',
  'TG',
  'TH',
  'TJ',
  'TK',
  'TL',
  'TM',
  'TN',
  'TO',
  'TR',
  'TT',
  'TV',
  'TW',
  'TZ',
  'UA',
  'UG',
  'UM',
  'US',
  'UY',
  'UZ',
  'VA',
  'VC',
  'VE',
  'VG',
  'VI',
  'VN',
  'VU',
  'WF',
  'WS',
  'YE',
  'YT',
  'ZA',
  'ZM',
  'ZW',
  '00'
] as const
export type COUNTRY_CODES = (typeof COUNTRY_CODES)[number]

export const COUNTRY_NAMES: { countryCode: COUNTRY_CODES; countryJName: string; countryAName: string }[] = [
  { countryCode: 'AD', countryJName: 'アンドラ', countryAName: 'Andorra' },
  { countryCode: 'AE', countryJName: 'アラブ首長国連邦', countryAName: 'United Arab Emirates' },
  { countryCode: 'AF', countryJName: 'アフガニスタン', countryAName: 'Afghanistan' },
  { countryCode: 'AG', countryJName: 'アンティグア・バーブーダ', countryAName: 'Antigua and Barbuda' },
  { countryCode: 'AI', countryJName: 'アンギラ', countryAName: 'Anguilla' },
  { countryCode: 'AL', countryJName: 'アルバニア', countryAName: 'Albania' },
  { countryCode: 'AM', countryJName: 'アルメニア', countryAName: 'Armenia' },
  { countryCode: 'AO', countryJName: 'アンゴラ', countryAName: 'Angola' },
  { countryCode: 'AQ', countryJName: '南極', countryAName: 'Antarctica' },
  { countryCode: 'AR', countryJName: 'アルゼンチン', countryAName: 'Argentina' },
  { countryCode: 'AS', countryJName: 'アメリカ領サモア', countryAName: 'American Samoa' },
  { countryCode: 'AT', countryJName: 'オーストリア', countryAName: 'Austria' },
  { countryCode: 'AU', countryJName: 'オーストラリア', countryAName: 'Australia' },
  { countryCode: 'AW', countryJName: 'アルバ', countryAName: 'Aruba' },
  { countryCode: 'AX', countryJName: 'オーランド諸島', countryAName: 'Aland Islands' },
  { countryCode: 'AZ', countryJName: 'アゼルバイジャン', countryAName: 'Azerbaijan' },
  { countryCode: 'BA', countryJName: 'ボスニア・ヘルツェゴビナ', countryAName: 'Bosnia and Herzegovina' },
  { countryCode: 'BB', countryJName: 'バルバドス', countryAName: 'Barbados' },
  { countryCode: 'BD', countryJName: 'バングラデシュ', countryAName: 'Bangladesh' },
  { countryCode: 'BE', countryJName: 'ベルギー', countryAName: 'Belgium' },
  { countryCode: 'BF', countryJName: 'ブルキナファソ', countryAName: 'Burkina Faso' },
  { countryCode: 'BG', countryJName: 'ブルガリア', countryAName: 'Bulgaria' },
  { countryCode: 'BH', countryJName: 'バーレーン', countryAName: 'Bahrain' },
  { countryCode: 'BI', countryJName: 'ブルンジ', countryAName: 'Burundi' },
  { countryCode: 'BJ', countryJName: 'ベナン', countryAName: 'Benin' },
  { countryCode: 'BL', countryJName: 'サン・バルテルミー島', countryAName: 'Saint Barthelemy' },
  { countryCode: 'BM', countryJName: 'バミューダ', countryAName: 'Bermuda' },
  { countryCode: 'BN', countryJName: 'ブルネイ・ダルサラーム', countryAName: 'Brunei Darussalam' },
  { countryCode: 'BO', countryJName: 'ボリビア多民族国', countryAName: 'Bolivia' },
  {
    countryCode: 'BQ',
    countryJName: 'ボネール、シント・ユースタティウスおよびサバ',
    countryAName: 'Bonaire, Sint Eustatius and Saba'
  },
  { countryCode: 'BR', countryJName: 'ブラジル', countryAName: 'Brazil' },
  { countryCode: 'BS', countryJName: 'バハマ', countryAName: 'Bahamas' },
  { countryCode: 'BT', countryJName: 'ブータン', countryAName: 'Bhutan' },
  { countryCode: 'BV', countryJName: 'ブーベ島', countryAName: 'Bouvet Island' },
  { countryCode: 'BW', countryJName: 'ボツワナ', countryAName: 'Botswana' },
  { countryCode: 'BY', countryJName: 'ベラルーシ', countryAName: 'Belarus' },
  { countryCode: 'BZ', countryJName: 'ベリーズ', countryAName: 'Belize' },
  { countryCode: 'CA', countryJName: 'カナダ', countryAName: 'Canada' },
  { countryCode: 'CC', countryJName: 'ココス（キーリング）諸島', countryAName: 'Cocos' },
  { countryCode: 'CD', countryJName: 'コンゴ民主共和国', countryAName: 'Congo' },
  { countryCode: 'CF', countryJName: '中央アフリカ共和国', countryAName: 'Central African Republic' },
  { countryCode: 'CG', countryJName: 'コンゴ共和国', countryAName: 'Congo' },
  { countryCode: 'CH', countryJName: 'スイス', countryAName: 'Switzerland' },
  { countryCode: 'CI', countryJName: 'コートジボワール', countryAName: "Cote d'Ivoire" },
  { countryCode: 'CK', countryJName: 'クック諸島', countryAName: 'Cook Islands' },
  { countryCode: 'CL', countryJName: 'チリ', countryAName: 'Chile' },
  { countryCode: 'CM', countryJName: 'カメルーン', countryAName: 'Cameroon' },
  { countryCode: 'CN', countryJName: '中華人民共和国', countryAName: 'China' },
  { countryCode: 'CO', countryJName: 'コロンビア', countryAName: 'Colombia' },
  { countryCode: 'CR', countryJName: 'コスタリカ', countryAName: 'Costa Rica' },
  { countryCode: 'CU', countryJName: 'キューバ', countryAName: 'Cuba' },
  { countryCode: 'CV', countryJName: 'カーボベルデ', countryAName: 'Cabo Verde' },
  { countryCode: 'CW', countryJName: 'キュラソー', countryAName: 'Curacao' },
  { countryCode: 'CX', countryJName: 'クリスマス島', countryAName: 'Christmas Island' },
  { countryCode: 'CY', countryJName: 'キプロス', countryAName: 'Cyprus' },
  { countryCode: 'CZ', countryJName: 'チェコ', countryAName: 'Czechia' },
  { countryCode: 'DE', countryJName: 'ドイツ', countryAName: 'Germany' },
  { countryCode: 'DJ', countryJName: 'ジブチ', countryAName: 'Djibouti' },
  { countryCode: 'DK', countryJName: 'デンマーク', countryAName: 'Denmark' },
  { countryCode: 'DM', countryJName: 'ドミニカ国', countryAName: 'Dominica' },
  { countryCode: 'DO', countryJName: 'ドミニカ共和国', countryAName: 'Dominican Republic' },
  { countryCode: 'DZ', countryJName: 'アルジェリア', countryAName: 'Algeria' },
  { countryCode: 'EC', countryJName: 'エクアドル', countryAName: 'Ecuador' },
  { countryCode: 'EE', countryJName: 'エストニア', countryAName: 'Estonia' },
  { countryCode: 'EG', countryJName: 'エジプト', countryAName: 'Egypt' },
  { countryCode: 'EH', countryJName: '西サハラ', countryAName: 'Western Sahara*' },
  { countryCode: 'ER', countryJName: 'エリトリア', countryAName: 'Eritrea' },
  { countryCode: 'ES', countryJName: 'スペイン', countryAName: 'Spain' },
  { countryCode: 'ET', countryJName: 'エチオピア', countryAName: 'Ethiopia' },
  { countryCode: 'FI', countryJName: 'フィンランド', countryAName: 'Finland' },
  { countryCode: 'FJ', countryJName: 'フィジー', countryAName: 'Fiji' },
  { countryCode: 'FK', countryJName: 'フォークランド（マルビナス）諸島', countryAName: 'Falkland Islands' },
  { countryCode: 'FM', countryJName: 'ミクロネシア連邦', countryAName: 'Micronesia' },
  { countryCode: 'FO', countryJName: 'フェロー諸島', countryAName: 'Faroe Islands' },
  { countryCode: 'FR', countryJName: 'フランス', countryAName: 'France' },
  { countryCode: 'GA', countryJName: 'ガボン', countryAName: 'Gabon' },
  { countryCode: 'GB', countryJName: 'イギリス', countryAName: 'United Kingdom of Great Britain and Northern Ireland' },
  { countryCode: 'GD', countryJName: 'グレナダ', countryAName: 'Grenada' },
  { countryCode: 'GE', countryJName: 'グルジア', countryAName: 'Georgia' },
  { countryCode: 'GF', countryJName: 'フランス領ギアナ', countryAName: 'French Guiana' },
  { countryCode: 'GG', countryJName: 'ガーンジー', countryAName: 'Guernsey' },
  { countryCode: 'GH', countryJName: 'ガーナ', countryAName: 'Ghana' },
  { countryCode: 'GI', countryJName: 'ジブラルタル', countryAName: 'Gibraltar' },
  { countryCode: 'GL', countryJName: 'グリーンランド', countryAName: 'Greenland' },
  { countryCode: 'GM', countryJName: 'ガンビア', countryAName: 'Gambia' },
  { countryCode: 'GN', countryJName: 'ギニア', countryAName: 'Guinea' },
  { countryCode: 'GP', countryJName: 'グアドループ', countryAName: 'Guadeloupe' },
  { countryCode: 'GQ', countryJName: '赤道ギニア', countryAName: 'Equatorial Guinea' },
  { countryCode: 'GR', countryJName: 'ギリシャ', countryAName: 'Greece' },
  {
    countryCode: 'GS',
    countryJName: 'サウスジョージア・サウスサンドウィッチ諸島',
    countryAName: 'South Georgia and the South Sandwich Islands'
  },
  { countryCode: 'GT', countryJName: 'グアテマラ', countryAName: 'Guatemala' },
  { countryCode: 'GU', countryJName: 'グアム', countryAName: 'Guam' },
  { countryCode: 'GW', countryJName: 'ギニアビサウ', countryAName: 'Guinea-Bissau' },
  { countryCode: 'GY', countryJName: 'ガイアナ', countryAName: 'Guyana' },
  { countryCode: 'HK', countryJName: '香港', countryAName: 'Hong Kong' },
  { countryCode: 'HM', countryJName: 'ハード島とマクドナルド諸島', countryAName: 'Heard Island and McDonald Islands' },
  { countryCode: 'HN', countryJName: 'ホンジュラス', countryAName: 'Honduras' },
  { countryCode: 'HR', countryJName: 'クロアチア', countryAName: 'Croatia' },
  { countryCode: 'HT', countryJName: 'ハイチ', countryAName: 'Haiti' },
  { countryCode: 'HU', countryJName: 'ハンガリー', countryAName: 'Hungary' },
  { countryCode: 'ID', countryJName: 'インドネシア', countryAName: 'Indonesia' },
  { countryCode: 'IE', countryJName: 'アイルランド', countryAName: 'Ireland' },
  { countryCode: 'IL', countryJName: 'イスラエル', countryAName: 'Israel' },
  { countryCode: 'IM', countryJName: 'マン島', countryAName: 'Isle of Man' },
  { countryCode: 'IN', countryJName: 'インド', countryAName: 'India' },
  { countryCode: 'IO', countryJName: 'イギリス領インド洋地域', countryAName: 'British Indian Ocean Territory' },
  { countryCode: 'IQ', countryJName: 'イラク', countryAName: 'Iraq' },
  { countryCode: 'IR', countryJName: 'イラン・イスラム共和国', countryAName: 'Iran' },
  { countryCode: 'IS', countryJName: 'アイスランド', countryAName: 'Iceland' },
  { countryCode: 'IT', countryJName: 'イタリア', countryAName: 'Italy' },
  { countryCode: 'JE', countryJName: 'ジャージー', countryAName: 'Jersey' },
  { countryCode: 'JM', countryJName: 'ジャマイカ', countryAName: 'Jamaica' },
  { countryCode: 'JO', countryJName: 'ヨルダン', countryAName: 'Jordan' },
  { countryCode: 'JP', countryJName: '日本', countryAName: 'Japan' },
  { countryCode: 'KE', countryJName: 'ケニア', countryAName: 'Kenya' },
  { countryCode: 'KG', countryJName: 'キルギス', countryAName: 'Kyrgyzstan' },
  { countryCode: 'KH', countryJName: 'カンボジア', countryAName: 'Cambodia' },
  { countryCode: 'KI', countryJName: 'キリバス', countryAName: 'Kiribati' },
  { countryCode: 'KM', countryJName: 'コモロ', countryAName: 'Comoros' },
  { countryCode: 'KN', countryJName: 'セントクリストファー・ネイビス', countryAName: 'Saint Kitts and Nevis' },
  {
    countryCode: 'KP',
    countryJName: '朝鮮民主主義人民共和国',
    countryAName: "the Democratic People's Republic of Korea"
  },
  { countryCode: 'KR', countryJName: '大韓民国', countryAName: 'the Republic of Korea' },
  { countryCode: 'KW', countryJName: 'クウェート', countryAName: 'Kuwait' },
  { countryCode: 'KY', countryJName: 'ケイマン諸島', countryAName: 'Cayman Islands' },
  { countryCode: 'KZ', countryJName: 'カザフスタン', countryAName: 'Kazakhstan' },
  { countryCode: 'LA', countryJName: 'ラオス人民民主共和国', countryAName: "Lao People's Democratic Republic" },
  { countryCode: 'LB', countryJName: 'レバノン', countryAName: 'Lebanon' },
  { countryCode: 'LC', countryJName: 'セントルシア', countryAName: 'Saint Lucia' },
  { countryCode: 'LI', countryJName: 'リヒテンシュタイン', countryAName: 'Liechtenstein' },
  { countryCode: 'LK', countryJName: 'スリランカ', countryAName: 'Sri Lanka' },
  { countryCode: 'LR', countryJName: 'リベリア', countryAName: 'Liberia' },
  { countryCode: 'LS', countryJName: 'レソト', countryAName: 'Lesotho' },
  { countryCode: 'LT', countryJName: 'リトアニア', countryAName: 'Lithuania' },
  { countryCode: 'LU', countryJName: 'ルクセンブルク', countryAName: 'Luxembourg' },
  { countryCode: 'LV', countryJName: 'ラトビア', countryAName: 'Latvia' },
  { countryCode: 'LY', countryJName: 'リビア', countryAName: 'Libya' },
  { countryCode: 'MA', countryJName: 'モロッコ', countryAName: 'Morocco' },
  { countryCode: 'MC', countryJName: 'モナコ', countryAName: 'Monaco' },
  { countryCode: 'MD', countryJName: 'モルドバ共和国', countryAName: 'Moldova' },
  { countryCode: 'ME', countryJName: 'モンテネグロ', countryAName: 'Montenegro' },
  { countryCode: 'MF', countryJName: 'サン・マルタン（フランス領）', countryAName: 'Saint Martin' },
  { countryCode: 'MG', countryJName: 'マダガスカル', countryAName: 'Madagascar' },
  { countryCode: 'MH', countryJName: 'マーシャル諸島', countryAName: 'Marshall Islands' },
  { countryCode: 'MK', countryJName: 'マケドニア旧ユーゴスラビア共和国', countryAName: 'Macedonia' },
  { countryCode: 'ML', countryJName: 'マリ', countryAName: 'Mali' },
  { countryCode: 'MM', countryJName: 'ミャンマー', countryAName: 'Myanmar' },
  { countryCode: 'MN', countryJName: 'モンゴル', countryAName: 'Mongolia' },
  { countryCode: 'MO', countryJName: 'マカオ', countryAName: 'Macao' },
  { countryCode: 'MP', countryJName: '北マリアナ諸島', countryAName: 'Northern Mariana Islands' },
  { countryCode: 'MQ', countryJName: 'マルティニーク', countryAName: 'Martinique' },
  { countryCode: 'MR', countryJName: 'モーリタニア', countryAName: 'Mauritania' },
  { countryCode: 'MS', countryJName: 'モントセラト', countryAName: 'Montserrat' },
  { countryCode: 'MT', countryJName: 'マルタ', countryAName: 'Malta' },
  { countryCode: 'MU', countryJName: 'モーリシャス', countryAName: 'Mauritius' },
  { countryCode: 'MV', countryJName: 'モルディブ', countryAName: 'Maldives' },
  { countryCode: 'MW', countryJName: 'マラウイ', countryAName: 'Malawi' },
  { countryCode: 'MX', countryJName: 'メキシコ', countryAName: 'Mexico' },
  { countryCode: 'MY', countryJName: 'マレーシア', countryAName: 'Malaysia' },
  { countryCode: 'MZ', countryJName: 'モザンビーク', countryAName: 'Mozambique' },
  { countryCode: 'NA', countryJName: 'ナミビア', countryAName: 'Namibia' },
  { countryCode: 'NC', countryJName: 'ニューカレドニア', countryAName: 'New Caledonia' },
  { countryCode: 'NE', countryJName: 'ニジェール', countryAName: 'Niger' },
  { countryCode: 'NF', countryJName: 'ノーフォーク島', countryAName: 'Norfolk Island' },
  { countryCode: 'NG', countryJName: 'ナイジェリア', countryAName: 'Nigeria' },
  { countryCode: 'NI', countryJName: 'ニカラグア', countryAName: 'Nicaragua' },
  { countryCode: 'NL', countryJName: 'オランダ', countryAName: 'Netherlands' },
  { countryCode: 'NO', countryJName: 'ノルウェー', countryAName: 'Norway' },
  { countryCode: 'NP', countryJName: 'ネパール', countryAName: 'Nepal' },
  { countryCode: 'NR', countryJName: 'ナウル', countryAName: 'Nauru' },
  { countryCode: 'NU', countryJName: 'ニウエ', countryAName: 'Niue' },
  { countryCode: 'NZ', countryJName: 'ニュージーランド', countryAName: 'New Zealand' },
  { countryCode: 'OM', countryJName: 'オマーン', countryAName: 'Oman' },
  { countryCode: 'PA', countryJName: 'パナマ', countryAName: 'Panama' },
  { countryCode: 'PE', countryJName: 'ペルー', countryAName: 'Peru' },
  { countryCode: 'PF', countryJName: 'フランス領ポリネシア', countryAName: 'French Polynesia' },
  { countryCode: 'PG', countryJName: 'パプアニューギニア', countryAName: 'Papua New Guinea' },
  { countryCode: 'PH', countryJName: 'フィリピン', countryAName: 'Philippines' },
  { countryCode: 'PK', countryJName: 'パキスタン', countryAName: 'Pakistan' },
  { countryCode: 'PL', countryJName: 'ポーランド', countryAName: 'Poland' },
  { countryCode: 'PM', countryJName: 'サンピエール島・ミクロン島', countryAName: 'Saint Pierre and Miquelon' },
  { countryCode: 'PN', countryJName: 'ピトケアン', countryAName: 'Pitcairn' },
  { countryCode: 'PR', countryJName: 'プエルトリコ', countryAName: 'Puerto Rico' },
  { countryCode: 'PS', countryJName: 'パレスチナ', countryAName: 'Palestine, State of' },
  { countryCode: 'PT', countryJName: 'ポルトガル', countryAName: 'Portugal' },
  { countryCode: 'PW', countryJName: 'パラオ', countryAName: 'Palau' },
  { countryCode: 'PY', countryJName: 'パラグアイ', countryAName: 'Paraguay' },
  { countryCode: 'QA', countryJName: 'カタール', countryAName: 'Qatar' },
  { countryCode: 'RE', countryJName: 'レユニオン', countryAName: 'Reunion' },
  { countryCode: 'RO', countryJName: 'ルーマニア', countryAName: 'Romania' },
  { countryCode: 'RS', countryJName: 'セルビア', countryAName: 'Serbia' },
  { countryCode: 'RU', countryJName: 'ロシア連邦', countryAName: 'Russian Federation' },
  { countryCode: 'RW', countryJName: 'ルワンダ', countryAName: 'Rwanda' },
  { countryCode: 'SA', countryJName: 'サウジアラビア', countryAName: 'Saudi Arabia' },
  { countryCode: 'SB', countryJName: 'ソロモン諸島', countryAName: 'Solomon Islands' },
  { countryCode: 'SC', countryJName: 'セーシェル', countryAName: 'Seychelles' },
  { countryCode: 'SD', countryJName: 'スーダン', countryAName: 'Sudan' },
  { countryCode: 'SE', countryJName: 'スウェーデン', countryAName: 'Sweden' },
  { countryCode: 'SG', countryJName: 'シンガポール', countryAName: 'Singapore' },
  {
    countryCode: 'SH',
    countryJName: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
    countryAName: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  { countryCode: 'SI', countryJName: 'スロベニア', countryAName: 'Slovenia' },
  { countryCode: 'SJ', countryJName: 'スヴァールバル諸島およびヤンマイエン島', countryAName: 'Svalbard and Jan Mayen' },
  { countryCode: 'SK', countryJName: 'スロバキア', countryAName: 'Slovakia' },
  { countryCode: 'SL', countryJName: 'シエラレオネ', countryAName: 'Sierra Leone' },
  { countryCode: 'SM', countryJName: 'サンマリノ', countryAName: 'San Marino' },
  { countryCode: 'SN', countryJName: 'セネガル', countryAName: 'Senegal' },
  { countryCode: 'SO', countryJName: 'ソマリア', countryAName: 'Somalia' },
  { countryCode: 'SR', countryJName: 'スリナム', countryAName: 'Suriname' },
  { countryCode: 'SS', countryJName: '南スーダン共和国', countryAName: 'South Sudan' },
  { countryCode: 'ST', countryJName: 'サントメ・プリンシペ', countryAName: 'Sao Tome and Principe' },
  { countryCode: 'SV', countryJName: 'エルサルバドル', countryAName: 'El Salvador' },
  { countryCode: 'SX', countryJName: 'シント・マールテン', countryAName: 'Sint Maarten' },
  { countryCode: 'SY', countryJName: 'シリア・アラブ共和国', countryAName: 'Syrian Arab Republic' },
  { countryCode: 'SZ', countryJName: 'スワジランド', countryAName: 'Swaziland' },
  { countryCode: 'TC', countryJName: 'タークス・カイコス諸島', countryAName: 'Turks and Caicos Islands' },
  { countryCode: 'TD', countryJName: 'チャド', countryAName: 'Chad' },
  { countryCode: 'TF', countryJName: 'フランス領南方・南極地域', countryAName: 'French Southern Territories' },
  { countryCode: 'TG', countryJName: 'トーゴ', countryAName: 'Togo' },
  { countryCode: 'TH', countryJName: 'タイ', countryAName: 'Thailand' },
  { countryCode: 'TJ', countryJName: 'タジキスタン', countryAName: 'Tajikistan' },
  { countryCode: 'TK', countryJName: 'トケラウ', countryAName: 'Tokelau' },
  { countryCode: 'TL', countryJName: '東ティモール', countryAName: 'Timor-Leste' },
  { countryCode: 'TM', countryJName: 'トルクメニスタン', countryAName: 'Turkmenistan' },
  { countryCode: 'TN', countryJName: 'チュニジア', countryAName: 'Tunisia' },
  { countryCode: 'TO', countryJName: 'トンガ', countryAName: 'Tonga' },
  { countryCode: 'TR', countryJName: 'トルコ', countryAName: 'Turkey' },
  { countryCode: 'TT', countryJName: 'トリニダード・トバゴ', countryAName: 'Trinidad and Tobago' },
  { countryCode: 'TV', countryJName: 'ツバル', countryAName: 'Tuvalu' },
  { countryCode: 'TW', countryJName: '台湾（台湾省/中華民国）', countryAName: 'Taiwan' },
  { countryCode: 'TZ', countryJName: 'タンザニア', countryAName: 'Tanzania, United Republic of' },
  { countryCode: 'UA', countryJName: 'ウクライナ', countryAName: 'Ukraine' },
  { countryCode: 'UG', countryJName: 'ウガンダ', countryAName: 'Uganda' },
  { countryCode: 'UM', countryJName: '合衆国領有小離島', countryAName: 'United States Minor Outlying Islands' },
  { countryCode: 'US', countryJName: 'アメリカ合衆国', countryAName: 'United States of America' },
  { countryCode: 'UY', countryJName: 'ウルグアイ', countryAName: 'Uruguay' },
  { countryCode: 'UZ', countryJName: 'ウズベキスタン', countryAName: 'Uzbekistan' },
  { countryCode: 'VA', countryJName: 'バチカン市国', countryAName: 'Holy See' },
  {
    countryCode: 'VC',
    countryJName: 'セントビンセントおよびグレナディーン諸島',
    countryAName: 'Saint Vincent and the Grenadines'
  },
  { countryCode: 'VE', countryJName: 'ベネズエラ・ボリバル共和国', countryAName: 'Venezuela' },
  { countryCode: 'VG', countryJName: 'イギリス領ヴァージン諸島', countryAName: 'British Virgin Islands' },
  {
    countryCode: 'VI',
    countryJName: 'アメリカ領ヴァージン諸島',
    countryAName: 'the Virgin Islands of the United States'
  },
  { countryCode: 'VN', countryJName: 'ベトナム', countryAName: 'Viet Nam' },
  { countryCode: 'VU', countryJName: 'バヌアツ', countryAName: 'Vanuatu' },
  { countryCode: 'WF', countryJName: 'ウォリス・フツナ', countryAName: 'Wallis and Futuna' },
  { countryCode: 'WS', countryJName: 'サモア', countryAName: 'Samoa' },
  { countryCode: 'YE', countryJName: 'イエメン', countryAName: 'Yemen' },
  { countryCode: 'YT', countryJName: 'マヨット', countryAName: 'Mayotte' },
  { countryCode: 'ZA', countryJName: '南アフリカ', countryAName: 'South Africa' },
  { countryCode: 'ZM', countryJName: 'ザンビア', countryAName: 'Zambia' },
  { countryCode: 'ZW', countryJName: 'ジンバブエ', countryAName: 'Zimbabwe' },
  { countryCode: '00', countryJName: '判定不明', countryAName: 'unknown' }
]
