type TypeFetchLimit = {
  type: string
  count: number
  limit: number
}

export const OPERATION_WARNING_MESSAGE = {
  notFound: '該当するデータが見つかりませんでした。',
  searchNotFound: '該当するデータが見つかりませんでした。条件を変更して再度お試しください。',
  invalidUrlParameter: 'URLパラメータで指定されている条件が正しくありません。',
  termLimit: (limit: string): string => `対象期間は${limit}以内で設定してください。`,
  fetchLimit: ({ type, count, limit }: TypeFetchLimit): string =>
    `${type}件数(${count.toLocaleString()}件)が上限(${limit.toLocaleString()}件)を超えました。条件を変更して再度お試しください。`
}
