import type { AuthenticateMutation, AuthenticateMutationVariables } from 'src/API'
import type { CustomUseMutationResult } from 'src/common/types'
import { authenticate } from 'src/graphql/mutations'
import { useCommonMutation } from './common'

export function useAuthenticateMutation(): CustomUseMutationResult<
  AuthenticateMutation,
  AuthenticateMutationVariables
> {
  return useCommonMutation<AuthenticateMutation, AuthenticateMutationVariables>(authenticate, { suppress: 'success' })
}
