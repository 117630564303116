/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const searchAuthoriBySimpleParamsV2 = /* GraphQL */ `query SearchAuthoriBySimpleParamsV2(
  $input: SearchAuthoriBySimpleParamsInput!
  $fileDownload: Boolean
) {
  searchAuthoriBySimpleParamsV2(input: $input, fileDownload: $fileDownload) {
    total
    authori_list {
      id
      createdAt
      updatedAt
      expirationUnixTime
      authori_id
      omotion_user_id
      company_id
      send_token
      event_id
      event_name
      user_id_hashed
      user_id_encrypted
      result
      final_result
      reason
      feedback
      feedback_comment
      feedbacked_at
      feedback_type
      feedback_account_id
      feedback_account_name
      user_device_id
      event_url
      login_success
      cookie
      etag
      local_storage
      input_type
      bot_flag
      bot_type
      did_short
      did_middle
      source_ip
      tor_ip_flag
      foreign_ip_flag
      useragent
      wt_device_category_name
      wt_os_name
      wt_os_version
      wt_browser_name
      wt_browser_version
      browser_language
      referer
      timezone_offset
      access_at
      send_at
      request_at
      began_at
      ended_at
      login_time
      residence_time
      authori_time
      es_index_date
      es_company_index
      ip_version
      ip_country_code
      ip_country_a_name
      ip_country_j_name
      ip_pref_code
      ip_pref_a_name
      ip_pref_j_name
      ip_city_code
      ip_city_a_name
      ip_city_j_name
      ip_org_name
      ip_domain_name
      ip_line_j_name
      ip_proxy_flag
      ip_pref_cf_level
      ip_city_cf_level
      ip_line_cf_level
      ip_pref_location
      ip_city_location
      ip_read_time
      ip_created
      click_count
      keystroke_count
      mouse_count
      swipe_count
      remarks
      created_at
      updated_at
      labels
      memo
      connected_id
    }
    authori_file {
      bucket
      key
      region
    }
  }
}
` as GeneratedQuery<
  APITypes.SearchAuthoriBySimpleParamsV2QueryVariables,
  APITypes.SearchAuthoriBySimpleParamsV2Query
>;
export const searchAuthoriByAdvancedParamsV2 = /* GraphQL */ `query SearchAuthoriByAdvancedParamsV2(
  $input: SearchAuthoriByAdvancedParamsInput!
  $fileDownload: Boolean
) {
  searchAuthoriByAdvancedParamsV2(input: $input, fileDownload: $fileDownload) {
    total
    authori_list {
      id
      createdAt
      updatedAt
      expirationUnixTime
      authori_id
      omotion_user_id
      company_id
      send_token
      event_id
      event_name
      user_id_hashed
      user_id_encrypted
      result
      final_result
      reason
      feedback
      feedback_comment
      feedbacked_at
      feedback_type
      feedback_account_id
      feedback_account_name
      user_device_id
      event_url
      login_success
      cookie
      etag
      local_storage
      input_type
      bot_flag
      bot_type
      did_short
      did_middle
      source_ip
      tor_ip_flag
      foreign_ip_flag
      useragent
      wt_device_category_name
      wt_os_name
      wt_os_version
      wt_browser_name
      wt_browser_version
      browser_language
      referer
      timezone_offset
      access_at
      send_at
      request_at
      began_at
      ended_at
      login_time
      residence_time
      authori_time
      es_index_date
      es_company_index
      ip_version
      ip_country_code
      ip_country_a_name
      ip_country_j_name
      ip_pref_code
      ip_pref_a_name
      ip_pref_j_name
      ip_city_code
      ip_city_a_name
      ip_city_j_name
      ip_org_name
      ip_domain_name
      ip_line_j_name
      ip_proxy_flag
      ip_pref_cf_level
      ip_city_cf_level
      ip_line_cf_level
      ip_pref_location
      ip_city_location
      ip_read_time
      ip_created
      click_count
      keystroke_count
      mouse_count
      swipe_count
      remarks
      created_at
      updated_at
      labels
      memo
      connected_id
    }
    authori_file {
      bucket
      key
      region
    }
  }
}
` as GeneratedQuery<
  APITypes.SearchAuthoriByAdvancedParamsV2QueryVariables,
  APITypes.SearchAuthoriByAdvancedParamsV2Query
>;
export const findAuthoriV2 = /* GraphQL */ `query FindAuthoriV2($authoriId: String!) {
  findAuthoriV2(authoriId: $authoriId) {
    id
    createdAt
    updatedAt
    expirationUnixTime
    authori_id
    omotion_user_id
    company_id
    send_token
    event_id
    event_name
    user_id_hashed
    user_id_encrypted
    result
    final_result
    reason
    feedback
    feedback_comment
    feedbacked_at
    feedback_type
    feedback_account_id
    feedback_account_name
    user_device_id
    event_url
    login_success
    cookie
    etag
    local_storage
    input_type
    bot_flag
    bot_type
    did_short
    did_middle
    source_ip
    tor_ip_flag
    foreign_ip_flag
    useragent
    wt_device_category_name
    wt_os_name
    wt_os_version
    wt_browser_name
    wt_browser_version
    browser_language
    referer
    timezone_offset
    access_at
    send_at
    request_at
    began_at
    ended_at
    login_time
    residence_time
    authori_time
    es_index_date
    es_company_index
    ip_version
    ip_country_code
    ip_country_a_name
    ip_country_j_name
    ip_pref_code
    ip_pref_a_name
    ip_pref_j_name
    ip_city_code
    ip_city_a_name
    ip_city_j_name
    ip_org_name
    ip_domain_name
    ip_line_j_name
    ip_proxy_flag
    ip_pref_cf_level
    ip_city_cf_level
    ip_line_cf_level
    ip_pref_location
    ip_city_location
    ip_read_time
    ip_created
    click_count
    keystroke_count
    mouse_count
    swipe_count
    remarks
    created_at
    updated_at
    labels
    memo
    connected_id
  }
}
` as GeneratedQuery<
  APITypes.FindAuthoriV2QueryVariables,
  APITypes.FindAuthoriV2Query
>;
export const listAuthoriFeedback = /* GraphQL */ `query ListAuthoriFeedback($authoriId: String!) {
  listAuthoriFeedback(authoriId: $authoriId) {
    authoriId
    feedbackedAt
    companyId
    omotionUserId
    feedback
    type
    comment
    accountId
    accountName
    createdAt
  }
}
` as GeneratedQuery<
  APITypes.ListAuthoriFeedbackQueryVariables,
  APITypes.ListAuthoriFeedbackQuery
>;
export const searchUserDeviceV2 = /* GraphQL */ `query SearchUserDeviceV2($input: SearchUserDeviceInput!) {
  searchUserDeviceV2(input: $input) {
    total
    user_device_list {
      user_device_id
      es_user_device_id
      es_company_index
      omotion_user_id
      user_id_hashed
      company_id
      status
      cookie
      etag
      local_storage
      did_short
      did_middle
      wt_device_category_name
      wt_os_name
      wt_browser_name
      created_at
      updated_at
      current_ip
    }
  }
}
` as GeneratedQuery<
  APITypes.SearchUserDeviceV2QueryVariables,
  APITypes.SearchUserDeviceV2Query
>;
export const calcUsersOfDeviceV2 = /* GraphQL */ `query CalcUsersOfDeviceV2($input: CalcUsersOfDeviceInput!) {
  calcUsersOfDeviceV2(input: $input) {
    total
    list {
      user_device_id
      count
    }
  }
}
` as GeneratedQuery<
  APITypes.CalcUsersOfDeviceV2QueryVariables,
  APITypes.CalcUsersOfDeviceV2Query
>;
export const calcDevicesOfUserV2 = /* GraphQL */ `query CalcDevicesOfUserV2($input: CalcDevicesOfUserInput!) {
  calcDevicesOfUserV2(input: $input) {
    total
    list {
      user_id_hashed
      count
    }
  }
}
` as GeneratedQuery<
  APITypes.CalcDevicesOfUserV2QueryVariables,
  APITypes.CalcDevicesOfUserV2Query
>;
export const getUserDeviceV2 = /* GraphQL */ `query GetUserDeviceV2($esUserDeviceId: String!) {
  getUserDeviceV2(esUserDeviceId: $esUserDeviceId) {
    user_device_id
    es_user_device_id
    es_company_index
    omotion_user_id
    user_id_hashed
    company_id
    status
    cookie
    etag
    local_storage
    did_short
    did_middle
    wt_device_category_name
    wt_os_name
    wt_browser_name
    created_at
    updated_at
    current_ip
  }
}
` as GeneratedQuery<
  APITypes.GetUserDeviceV2QueryVariables,
  APITypes.GetUserDeviceV2Query
>;
export const aggregateAuthoriResultV2 = /* GraphQL */ `query AggregateAuthoriResultV2($input: AggregateInput!) {
  aggregateAuthoriResultV2(input: $input) {
    total
    list {
      date
      count
      data {
        ok
        ng
        review
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateAuthoriResultV2QueryVariables,
  APITypes.AggregateAuthoriResultV2Query
>;
export const aggregateAuthoriReasonReviewV2 = /* GraphQL */ `query AggregateAuthoriReasonReviewV2($input: AggregateInput!) {
  aggregateAuthoriReasonReviewV2(input: $input) {
    total
    list {
      date
      count
      data {
        foreign_ip
        tor_ip
        user_device
        same_device
        ng_device
        bot_review
        foreign_ip_and_language_review
        first_user_device_count_over
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateAuthoriReasonReviewV2QueryVariables,
  APITypes.AggregateAuthoriReasonReviewV2Query
>;
export const aggregateAuthoriReasonNgV2 = /* GraphQL */ `query AggregateAuthoriReasonNgV2($input: AggregateInput!) {
  aggregateAuthoriReasonNgV2(input: $input) {
    total
    list {
      date
      count
      data {
        bot_ng
        user_device
        ng_device
        foreign_ip_ng
        tor_ip_ng
        foreign_ip_and_language_ng
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateAuthoriReasonNgV2QueryVariables,
  APITypes.AggregateAuthoriReasonNgV2Query
>;
export const aggregateAuthoriTorIpV2 = /* GraphQL */ `query AggregateAuthoriTorIpV2($input: AggregateInput!) {
  aggregateAuthoriTorIpV2(input: $input) {
    total
    list {
      date
      count
      data {
        tor_ip
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateAuthoriTorIpV2QueryVariables,
  APITypes.AggregateAuthoriTorIpV2Query
>;
export const aggregateAuthoriBotV2 = /* GraphQL */ `query AggregateAuthoriBotV2($input: AggregateInput!) {
  aggregateAuthoriBotV2(input: $input) {
    total
    list {
      date
      count
      data {
        bot_flag
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateAuthoriBotV2QueryVariables,
  APITypes.AggregateAuthoriBotV2Query
>;
export const aggregateAuthoriForeignAccessV2 = /* GraphQL */ `query AggregateAuthoriForeignAccessV2($input: AggregateInput!) {
  aggregateAuthoriForeignAccessV2(input: $input) {
    total
    list {
      count
      country
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateAuthoriForeignAccessV2QueryVariables,
  APITypes.AggregateAuthoriForeignAccessV2Query
>;
export const aggregateAuthoriForeignIpV2 = /* GraphQL */ `query AggregateAuthoriForeignIpV2($input: AggregateInput!) {
  aggregateAuthoriForeignIpV2(input: $input) {
    total
    list {
      date
      count
      data {
        foreign_ip
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateAuthoriForeignIpV2QueryVariables,
  APITypes.AggregateAuthoriForeignIpV2Query
>;
export const aggregateAuthoriForeignLanguageV2 = /* GraphQL */ `query AggregateAuthoriForeignLanguageV2($input: AggregateInput!) {
  aggregateAuthoriForeignLanguageV2(input: $input) {
    total
    list {
      date
      count
      data {
        foreign_language
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateAuthoriForeignLanguageV2QueryVariables,
  APITypes.AggregateAuthoriForeignLanguageV2Query
>;
export const aggregateDeviceStatusV2 = /* GraphQL */ `query AggregateDeviceStatusV2($input: AggregateInput!) {
  aggregateDeviceStatusV2(input: $input) {
    total
    list {
      date
      count
      data {
        valid
        invalid
        duplicated
        unidentified
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateDeviceStatusV2QueryVariables,
  APITypes.AggregateDeviceStatusV2Query
>;
export const aggregateAuthoriInputTypeV2 = /* GraphQL */ `query AggregateAuthoriInputTypeV2($input: AggregateInput!) {
  aggregateAuthoriInputTypeV2(input: $input) {
    total
    list {
      date
      count
      data {
        software_keyboard
        auto_complete
        copy_and_paste
        drag_and_drop
        key_typed
        unknown
        none
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateAuthoriInputTypeV2QueryVariables,
  APITypes.AggregateAuthoriInputTypeV2Query
>;
export const aggregateAuthoriIpCityLocationV2 = /* GraphQL */ `query AggregateAuthoriIpCityLocationV2(
  $input: AggregateAuthoriIpCityLocationInput!
) {
  aggregateAuthoriIpCityLocationV2(input: $input) {
    total
    list {
      count
      data {
        lat
        lon
        ip_city_a_name
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateAuthoriIpCityLocationV2QueryVariables,
  APITypes.AggregateAuthoriIpCityLocationV2Query
>;
export const aggregateByIpV2 = /* GraphQL */ `query AggregateByIpV2($input: AggregateByIpInput!) {
  aggregateByIpV2(input: $input) {
    total
    list {
      ip
      authori {
        count
      }
      user {
        count
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.AggregateByIpV2QueryVariables,
  APITypes.AggregateByIpV2Query
>;
export const getAuthoriCountV2 = /* GraphQL */ `query GetAuthoriCountV2($input: AuthoriCountInput!) {
  getAuthoriCountV2(input: $input) {
    count
  }
}
` as GeneratedQuery<
  APITypes.GetAuthoriCountV2QueryVariables,
  APITypes.GetAuthoriCountV2Query
>;
export const getReportAuthoriV2 = /* GraphQL */ `query GetReportAuthoriV2($input: ReportAuthoriInput!) {
  getReportAuthoriV2(input: $input) {
    total
    list {
      date
      count
      OK {
        count
      }
      NG {
        count
      }
      REVIEW {
        count
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.GetReportAuthoriV2QueryVariables,
  APITypes.GetReportAuthoriV2Query
>;
export const getAccount = /* GraphQL */ `query GetAccount($email: AWSEmail!) {
  getAccount(email: $email) {
    email
    companyId
    name
    role
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $email: AWSEmail
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAccounts(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      companyId
      name
      role
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const listAccountByCompanyIdAndCreatedAt = /* GraphQL */ `query ListAccountByCompanyIdAndCreatedAt(
  $companyId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountByCompanyIdAndCreatedAt(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      email
      companyId
      name
      role
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAccountByCompanyIdAndCreatedAtQueryVariables,
  APITypes.ListAccountByCompanyIdAndCreatedAtQuery
>;
export const getManagementAccount = /* GraphQL */ `query GetManagementAccount($email: AWSEmail!) {
  getManagementAccount(email: $email) {
    email
    name
    role
    allowedBy
    assumedCompanyId
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetManagementAccountQueryVariables,
  APITypes.GetManagementAccountQuery
>;
export const listManagementAccounts = /* GraphQL */ `query ListManagementAccounts(
  $email: AWSEmail
  $filter: ModelManagementAccountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listManagementAccounts(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      name
      role
      allowedBy
      assumedCompanyId
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListManagementAccountsQueryVariables,
  APITypes.ListManagementAccountsQuery
>;
export const listManagementAccountByAllowedByAndCreatedAt = /* GraphQL */ `query ListManagementAccountByAllowedByAndCreatedAt(
  $allowedBy: AccountType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelManagementAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listManagementAccountByAllowedByAndCreatedAt(
    allowedBy: $allowedBy
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      email
      name
      role
      allowedBy
      assumedCompanyId
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListManagementAccountByAllowedByAndCreatedAtQueryVariables,
  APITypes.ListManagementAccountByAllowedByAndCreatedAtQuery
>;
export const getAuthori = /* GraphQL */ `query GetAuthori($id: ID!) {
  getAuthori(id: $id) {
    id
    createdAt
    updatedAt
    expirationUnixTime
    authori_id
    omotion_user_id
    company_id
    send_token
    event_id
    event_name
    user_id_hashed
    user_id_encrypted
    result
    final_result
    reason
    feedback
    feedback_comment
    feedbacked_at
    feedback_type
    feedback_account_id
    feedback_account_name
    user_device_id
    event_url
    login_success
    cookie
    etag
    local_storage
    input_type
    bot_flag
    bot_type
    did_short
    did_middle
    source_ip
    tor_ip_flag
    foreign_ip_flag
    useragent
    wt_device_category_name
    wt_os_name
    wt_os_version
    wt_browser_name
    wt_browser_version
    browser_language
    referer
    timezone_offset
    access_at
    send_at
    request_at
    began_at
    ended_at
    login_time
    residence_time
    authori_time
    es_index_date
    es_company_index
    ip_version
    ip_country_code
    ip_country_a_name
    ip_country_j_name
    ip_pref_code
    ip_pref_a_name
    ip_pref_j_name
    ip_city_code
    ip_city_a_name
    ip_city_j_name
    ip_org_name
    ip_domain_name
    ip_line_j_name
    ip_proxy_flag
    ip_pref_cf_level
    ip_city_cf_level
    ip_line_cf_level
    ip_pref_location
    ip_city_location
    ip_read_time
    ip_created
    click_count
    keystroke_count
    mouse_count
    swipe_count
    remarks
    created_at
    updated_at
    labels
    memo
    connected_id
  }
}
` as GeneratedQuery<
  APITypes.GetAuthoriQueryVariables,
  APITypes.GetAuthoriQuery
>;
export const listAuthoris = /* GraphQL */ `query ListAuthoris(
  $filter: ModelAuthoriFilterInput
  $limit: Int
  $nextToken: String
) {
  listAuthoris(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      expirationUnixTime
      authori_id
      omotion_user_id
      company_id
      send_token
      event_id
      event_name
      user_id_hashed
      user_id_encrypted
      result
      final_result
      reason
      feedback
      feedback_comment
      feedbacked_at
      feedback_type
      feedback_account_id
      feedback_account_name
      user_device_id
      event_url
      login_success
      cookie
      etag
      local_storage
      input_type
      bot_flag
      bot_type
      did_short
      did_middle
      source_ip
      tor_ip_flag
      foreign_ip_flag
      useragent
      wt_device_category_name
      wt_os_name
      wt_os_version
      wt_browser_name
      wt_browser_version
      browser_language
      referer
      timezone_offset
      access_at
      send_at
      request_at
      began_at
      ended_at
      login_time
      residence_time
      authori_time
      es_index_date
      es_company_index
      ip_version
      ip_country_code
      ip_country_a_name
      ip_country_j_name
      ip_pref_code
      ip_pref_a_name
      ip_pref_j_name
      ip_city_code
      ip_city_a_name
      ip_city_j_name
      ip_org_name
      ip_domain_name
      ip_line_j_name
      ip_proxy_flag
      ip_pref_cf_level
      ip_city_cf_level
      ip_line_cf_level
      ip_pref_location
      ip_city_location
      ip_read_time
      ip_created
      click_count
      keystroke_count
      mouse_count
      swipe_count
      remarks
      created_at
      updated_at
      labels
      memo
      connected_id
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAuthorisQueryVariables,
  APITypes.ListAuthorisQuery
>;
export const listAuthoriByCompanyId = /* GraphQL */ `query ListAuthoriByCompanyId(
  $company_id: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAuthoriFilterInput
  $limit: Int
  $nextToken: String
) {
  listAuthoriByCompanyId(
    company_id: $company_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      expirationUnixTime
      authori_id
      omotion_user_id
      company_id
      send_token
      event_id
      event_name
      user_id_hashed
      user_id_encrypted
      result
      final_result
      reason
      feedback
      feedback_comment
      feedbacked_at
      feedback_type
      feedback_account_id
      feedback_account_name
      user_device_id
      event_url
      login_success
      cookie
      etag
      local_storage
      input_type
      bot_flag
      bot_type
      did_short
      did_middle
      source_ip
      tor_ip_flag
      foreign_ip_flag
      useragent
      wt_device_category_name
      wt_os_name
      wt_os_version
      wt_browser_name
      wt_browser_version
      browser_language
      referer
      timezone_offset
      access_at
      send_at
      request_at
      began_at
      ended_at
      login_time
      residence_time
      authori_time
      es_index_date
      es_company_index
      ip_version
      ip_country_code
      ip_country_a_name
      ip_country_j_name
      ip_pref_code
      ip_pref_a_name
      ip_pref_j_name
      ip_city_code
      ip_city_a_name
      ip_city_j_name
      ip_org_name
      ip_domain_name
      ip_line_j_name
      ip_proxy_flag
      ip_pref_cf_level
      ip_city_cf_level
      ip_line_cf_level
      ip_pref_location
      ip_city_location
      ip_read_time
      ip_created
      click_count
      keystroke_count
      mouse_count
      swipe_count
      remarks
      created_at
      updated_at
      labels
      memo
      connected_id
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAuthoriByCompanyIdQueryVariables,
  APITypes.ListAuthoriByCompanyIdQuery
>;
export const getAuthoriSummaryMonthly = /* GraphQL */ `query GetAuthoriSummaryMonthly($companyId: String!, $yearMonth: String!) {
  getAuthoriSummaryMonthly(companyId: $companyId, yearMonth: $yearMonth) {
    companyId
    yearMonth
    ok
    ng
    review
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetAuthoriSummaryMonthlyQueryVariables,
  APITypes.GetAuthoriSummaryMonthlyQuery
>;
export const listAuthoriSummaryMonthlies = /* GraphQL */ `query ListAuthoriSummaryMonthlies(
  $companyId: String
  $yearMonth: ModelStringKeyConditionInput
  $filter: ModelAuthoriSummaryMonthlyFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAuthoriSummaryMonthlies(
    companyId: $companyId
    yearMonth: $yearMonth
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      companyId
      yearMonth
      ok
      ng
      review
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListAuthoriSummaryMonthliesQueryVariables,
  APITypes.ListAuthoriSummaryMonthliesQuery
>;
export const getCompany = /* GraphQL */ `query GetCompany($companyId: String!) {
  getCompany(companyId: $companyId) {
    companyId
    companyName
    companyCode
    formalName
    hashSalt
    authoriExportLimit
    allowedBy
    status
    ipAccessControl {
      enable
      ipEntry {
        ip
        description
      }
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyQueryVariables,
  APITypes.GetCompanyQuery
>;
export const listCompanies = /* GraphQL */ `query ListCompanies(
  $companyId: String
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompanies(
    companyId: $companyId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      companyId
      companyName
      companyCode
      formalName
      hashSalt
      authoriExportLimit
      allowedBy
      status
      ipAccessControl {
        enable
        ipEntry {
          ip
          description
        }
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListCompaniesQueryVariables,
  APITypes.ListCompaniesQuery
>;
export const listCompanyByAllowedByAndCreatedAt = /* GraphQL */ `query ListCompanyByAllowedByAndCreatedAt(
  $allowedBy: AccountType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyByAllowedByAndCreatedAt(
    allowedBy: $allowedBy
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      companyId
      companyName
      companyCode
      formalName
      hashSalt
      authoriExportLimit
      allowedBy
      status
      ipAccessControl {
        enable
        ipEntry {
          ip
          description
        }
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyByAllowedByAndCreatedAtQueryVariables,
  APITypes.ListCompanyByAllowedByAndCreatedAtQuery
>;
export const getCompanyAddonV2 = /* GraphQL */ `query GetCompanyAddonV2($companyId: String!, $addonId: AddonId!) {
  getCompanyAddonV2(companyId: $companyId, addonId: $addonId) {
    companyId
    addonId
    name
    displayWeight
    companyEmail
    enabled
    allowedBy
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyAddonV2QueryVariables,
  APITypes.GetCompanyAddonV2Query
>;
export const listCompanyAddonV2s = /* GraphQL */ `query ListCompanyAddonV2s(
  $companyId: String
  $addonId: ModelStringKeyConditionInput
  $filter: ModelCompanyAddonV2FilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompanyAddonV2s(
    companyId: $companyId
    addonId: $addonId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      companyId
      addonId
      name
      displayWeight
      companyEmail
      enabled
      allowedBy
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyAddonV2sQueryVariables,
  APITypes.ListCompanyAddonV2sQuery
>;
export const getCompanyReportSetting = /* GraphQL */ `query GetCompanyReportSetting($companyId: ID!) {
  getCompanyReportSetting(companyId: $companyId) {
    companyId
    ipReportSetting {
      foreignIp
      proxyIp
      torIp
      country {
        items
        isInclude
      }
      domain {
        items
        isInclude
      }
      line {
        items
        isInclude
      }
      hourly {
        userThreshold
        authoriThreshold
      }
      daily {
        userThreshold
        authoriThreshold
      }
      weekly {
        userThreshold
        authoriThreshold
      }
    }
    allowedBy
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyReportSettingQueryVariables,
  APITypes.GetCompanyReportSettingQuery
>;
export const listCompanyReportSettings = /* GraphQL */ `query ListCompanyReportSettings(
  $companyId: ID
  $filter: ModelCompanyReportSettingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompanyReportSettings(
    companyId: $companyId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      companyId
      ipReportSetting {
        foreignIp
        proxyIp
        torIp
        country {
          items
          isInclude
        }
        domain {
          items
          isInclude
        }
        line {
          items
          isInclude
        }
        hourly {
          userThreshold
          authoriThreshold
        }
        daily {
          userThreshold
          authoriThreshold
        }
        weekly {
          userThreshold
          authoriThreshold
        }
      }
      allowedBy
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyReportSettingsQueryVariables,
  APITypes.ListCompanyReportSettingsQuery
>;
export const getExportRequest = /* GraphQL */ `query GetExportRequest($id: ID!) {
  getExportRequest(id: $id) {
    id
    companyId
    event
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    createdAt
    ttl
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetExportRequestQueryVariables,
  APITypes.GetExportRequestQuery
>;
export const listExportRequests = /* GraphQL */ `query ListExportRequests(
  $filter: ModelExportRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  listExportRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      companyId
      event
      accountName
      rangeFrom
      rangeTo
      conditions {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
      charset
      createdAt
      ttl
      aggregationUnit
      threshold {
        authoriStart
        authoriEnd
        userStart
        userEnd
      }
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListExportRequestsQueryVariables,
  APITypes.ListExportRequestsQuery
>;
export const getExportResult = /* GraphQL */ `query GetExportResult($companyId: String!, $exportRequestId: String!) {
  getExportResult(companyId: $companyId, exportRequestId: $exportRequestId) {
    companyId
    exportRequestId
    createdAt
    status
    error {
      errorType
      errorCode
    }
    s3Object {
      bucket
      key
      region
    }
    event
    accountName
    rangeFrom
    rangeTo
    conditions {
      result
      final_result
      tor_ip_flag
      login_success
      or {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
    }
    charset
    requestAt
    exportedAt
    expiredAt
    count
    ttl
    companyIdAndEvent
    aggregationUnit
    threshold {
      authoriStart
      authoriEnd
      userStart
      userEnd
    }
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetExportResultQueryVariables,
  APITypes.GetExportResultQuery
>;
export const listExportResults = /* GraphQL */ `query ListExportResults(
  $companyId: String
  $exportRequestId: ModelStringKeyConditionInput
  $filter: ModelExportResultFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listExportResults(
    companyId: $companyId
    exportRequestId: $exportRequestId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      companyId
      exportRequestId
      createdAt
      status
      error {
        errorType
        errorCode
      }
      s3Object {
        bucket
        key
        region
      }
      event
      accountName
      rangeFrom
      rangeTo
      conditions {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
      charset
      requestAt
      exportedAt
      expiredAt
      count
      ttl
      companyIdAndEvent
      aggregationUnit
      threshold {
        authoriStart
        authoriEnd
        userStart
        userEnd
      }
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListExportResultsQueryVariables,
  APITypes.ListExportResultsQuery
>;
export const listExportResultByCompanyIdAndCreatedAt = /* GraphQL */ `query ListExportResultByCompanyIdAndCreatedAt(
  $companyId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelExportResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listExportResultByCompanyIdAndCreatedAt(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      companyId
      exportRequestId
      createdAt
      status
      error {
        errorType
        errorCode
      }
      s3Object {
        bucket
        key
        region
      }
      event
      accountName
      rangeFrom
      rangeTo
      conditions {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
      charset
      requestAt
      exportedAt
      expiredAt
      count
      ttl
      companyIdAndEvent
      aggregationUnit
      threshold {
        authoriStart
        authoriEnd
        userStart
        userEnd
      }
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListExportResultByCompanyIdAndCreatedAtQueryVariables,
  APITypes.ListExportResultByCompanyIdAndCreatedAtQuery
>;
export const listExportResultByCompanyIdAndEventAndCreatedAt = /* GraphQL */ `query ListExportResultByCompanyIdAndEventAndCreatedAt(
  $companyIdAndEvent: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelExportResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listExportResultByCompanyIdAndEventAndCreatedAt(
    companyIdAndEvent: $companyIdAndEvent
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      companyId
      exportRequestId
      createdAt
      status
      error {
        errorType
        errorCode
      }
      s3Object {
        bucket
        key
        region
      }
      event
      accountName
      rangeFrom
      rangeTo
      conditions {
        result
        final_result
        tor_ip_flag
        login_success
        or {
          result
          final_result
          tor_ip_flag
          login_success
          or {
            result
            final_result
            tor_ip_flag
            login_success
          }
        }
      }
      charset
      requestAt
      exportedAt
      expiredAt
      count
      ttl
      companyIdAndEvent
      aggregationUnit
      threshold {
        authoriStart
        authoriEnd
        userStart
        userEnd
      }
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListExportResultByCompanyIdAndEventAndCreatedAtQueryVariables,
  APITypes.ListExportResultByCompanyIdAndEventAndCreatedAtQuery
>;
export const getInquiry = /* GraphQL */ `query GetInquiry($inquiryId: ID!) {
  getInquiry(inquiryId: $inquiryId) {
    inquiryId
    companyId
    companyName
    accountId
    accountName
    accountEmail
    title
    status
    category
    accessLevel
    referrerUrl
    allowedBy
    companyIdOrAccountId
    companyIdOrAccountIdAndStatus
    companyIdOrAccountIdAndCategory
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetInquiryQueryVariables,
  APITypes.GetInquiryQuery
>;
export const listInquiries = /* GraphQL */ `query ListInquiries(
  $inquiryId: ID
  $filter: ModelInquiryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInquiries(
    inquiryId: $inquiryId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      inquiryId
      companyId
      companyName
      accountId
      accountName
      accountEmail
      title
      status
      category
      accessLevel
      referrerUrl
      allowedBy
      companyIdOrAccountId
      companyIdOrAccountIdAndStatus
      companyIdOrAccountIdAndCategory
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInquiriesQueryVariables,
  APITypes.ListInquiriesQuery
>;
export const listInquiryByCompanyId = /* GraphQL */ `query ListInquiryByCompanyId(
  $companyId: ID!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInquiryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInquiryByCompanyId(
    companyId: $companyId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      inquiryId
      companyId
      companyName
      accountId
      accountName
      accountEmail
      title
      status
      category
      accessLevel
      referrerUrl
      allowedBy
      companyIdOrAccountId
      companyIdOrAccountIdAndStatus
      companyIdOrAccountIdAndCategory
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInquiryByCompanyIdQueryVariables,
  APITypes.ListInquiryByCompanyIdQuery
>;
export const listInquiryByStatus = /* GraphQL */ `query ListInquiryByStatus(
  $status: InquiryStatus!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInquiryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInquiryByStatus(
    status: $status
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      inquiryId
      companyId
      companyName
      accountId
      accountName
      accountEmail
      title
      status
      category
      accessLevel
      referrerUrl
      allowedBy
      companyIdOrAccountId
      companyIdOrAccountIdAndStatus
      companyIdOrAccountIdAndCategory
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInquiryByStatusQueryVariables,
  APITypes.ListInquiryByStatusQuery
>;
export const listInquiryByCategory = /* GraphQL */ `query ListInquiryByCategory(
  $category: InquiryCategory!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInquiryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInquiryByCategory(
    category: $category
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      inquiryId
      companyId
      companyName
      accountId
      accountName
      accountEmail
      title
      status
      category
      accessLevel
      referrerUrl
      allowedBy
      companyIdOrAccountId
      companyIdOrAccountIdAndStatus
      companyIdOrAccountIdAndCategory
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInquiryByCategoryQueryVariables,
  APITypes.ListInquiryByCategoryQuery
>;
export const listInquiryByAllowedBy = /* GraphQL */ `query ListInquiryByAllowedBy(
  $allowedBy: AccountType!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInquiryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInquiryByAllowedBy(
    allowedBy: $allowedBy
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      inquiryId
      companyId
      companyName
      accountId
      accountName
      accountEmail
      title
      status
      category
      accessLevel
      referrerUrl
      allowedBy
      companyIdOrAccountId
      companyIdOrAccountIdAndStatus
      companyIdOrAccountIdAndCategory
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInquiryByAllowedByQueryVariables,
  APITypes.ListInquiryByAllowedByQuery
>;
export const listInquiryBycompanyIdOrAccountId = /* GraphQL */ `query ListInquiryBycompanyIdOrAccountId(
  $companyIdOrAccountId: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInquiryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInquiryBycompanyIdOrAccountId(
    companyIdOrAccountId: $companyIdOrAccountId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      inquiryId
      companyId
      companyName
      accountId
      accountName
      accountEmail
      title
      status
      category
      accessLevel
      referrerUrl
      allowedBy
      companyIdOrAccountId
      companyIdOrAccountIdAndStatus
      companyIdOrAccountIdAndCategory
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInquiryBycompanyIdOrAccountIdQueryVariables,
  APITypes.ListInquiryBycompanyIdOrAccountIdQuery
>;
export const listInquiryBycompanyIdOrAccountIdAndStatus = /* GraphQL */ `query ListInquiryBycompanyIdOrAccountIdAndStatus(
  $companyIdOrAccountIdAndStatus: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInquiryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInquiryBycompanyIdOrAccountIdAndStatus(
    companyIdOrAccountIdAndStatus: $companyIdOrAccountIdAndStatus
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      inquiryId
      companyId
      companyName
      accountId
      accountName
      accountEmail
      title
      status
      category
      accessLevel
      referrerUrl
      allowedBy
      companyIdOrAccountId
      companyIdOrAccountIdAndStatus
      companyIdOrAccountIdAndCategory
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInquiryBycompanyIdOrAccountIdAndStatusQueryVariables,
  APITypes.ListInquiryBycompanyIdOrAccountIdAndStatusQuery
>;
export const listInquiryBycompanyIdOrAccountIdAndCategory = /* GraphQL */ `query ListInquiryBycompanyIdOrAccountIdAndCategory(
  $companyIdOrAccountIdAndCategory: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInquiryFilterInput
  $limit: Int
  $nextToken: String
) {
  listInquiryBycompanyIdOrAccountIdAndCategory(
    companyIdOrAccountIdAndCategory: $companyIdOrAccountIdAndCategory
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      inquiryId
      companyId
      companyName
      accountId
      accountName
      accountEmail
      title
      status
      category
      accessLevel
      referrerUrl
      allowedBy
      companyIdOrAccountId
      companyIdOrAccountIdAndStatus
      companyIdOrAccountIdAndCategory
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInquiryBycompanyIdOrAccountIdAndCategoryQueryVariables,
  APITypes.ListInquiryBycompanyIdOrAccountIdAndCategoryQuery
>;
export const getInquiryMessage = /* GraphQL */ `query GetInquiryMessage($inquiryMessageId: ID!) {
  getInquiryMessage(inquiryMessageId: $inquiryMessageId) {
    inquiryMessageId
    inquiryId
    companyId
    senderAccountType
    senderName
    body
    fileKeys
    cognitoUserIdentityId
    allowedBy
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetInquiryMessageQueryVariables,
  APITypes.GetInquiryMessageQuery
>;
export const listInquiryMessages = /* GraphQL */ `query ListInquiryMessages(
  $inquiryMessageId: ID
  $filter: ModelInquiryMessageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInquiryMessages(
    inquiryMessageId: $inquiryMessageId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      inquiryMessageId
      inquiryId
      companyId
      senderAccountType
      senderName
      body
      fileKeys
      cognitoUserIdentityId
      allowedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInquiryMessagesQueryVariables,
  APITypes.ListInquiryMessagesQuery
>;
export const listInquiryMessageByInquiryId = /* GraphQL */ `query ListInquiryMessageByInquiryId(
  $inquiryId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInquiryMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listInquiryMessageByInquiryId(
    inquiryId: $inquiryId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      inquiryMessageId
      inquiryId
      companyId
      senderAccountType
      senderName
      body
      fileKeys
      cognitoUserIdentityId
      allowedBy
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListInquiryMessageByInquiryIdQueryVariables,
  APITypes.ListInquiryMessageByInquiryIdQuery
>;
export const getReportScheduleAccount = /* GraphQL */ `query GetReportScheduleAccount($email: AWSEmail!) {
  getReportScheduleAccount(email: $email) {
    email
    companyId
    schedule {
      hourly
      daily
      weekly
    }
    ipReportSchedule {
      hourly
      daily
      weekly
    }
    ipReportForced
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetReportScheduleAccountQueryVariables,
  APITypes.GetReportScheduleAccountQuery
>;
export const listReportScheduleAccounts = /* GraphQL */ `query ListReportScheduleAccounts(
  $email: AWSEmail
  $filter: ModelReportScheduleAccountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReportScheduleAccounts(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      companyId
      schedule {
        hourly
        daily
        weekly
      }
      ipReportSchedule {
        hourly
        daily
        weekly
      }
      ipReportForced
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListReportScheduleAccountsQueryVariables,
  APITypes.ListReportScheduleAccountsQuery
>;
export const getSavedSearch = /* GraphQL */ `query GetSavedSearch($id: ID!) {
  getSavedSearch(id: $id) {
    id
    userId
    pagePathSearchId
    pagePath
    searchId
    queryString
    savedAt
    name
    createdAt
    updatedAt
    companyId
  }
}
` as GeneratedQuery<
  APITypes.GetSavedSearchQueryVariables,
  APITypes.GetSavedSearchQuery
>;
export const listSavedSearches = /* GraphQL */ `query ListSavedSearches(
  $filter: ModelSavedSearchFilterInput
  $limit: Int
  $nextToken: String
) {
  listSavedSearches(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      pagePathSearchId
      pagePath
      searchId
      queryString
      savedAt
      name
      createdAt
      updatedAt
      companyId
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSavedSearchesQueryVariables,
  APITypes.ListSavedSearchesQuery
>;
export const listByUserAndPagePathSearchId = /* GraphQL */ `query ListByUserAndPagePathSearchId(
  $userId: String!
  $pagePathSearchId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSavedSearchFilterInput
  $limit: Int
  $nextToken: String
) {
  listByUserAndPagePathSearchId(
    userId: $userId
    pagePathSearchId: $pagePathSearchId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      pagePathSearchId
      pagePath
      searchId
      queryString
      savedAt
      name
      createdAt
      updatedAt
      companyId
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListByUserAndPagePathSearchIdQueryVariables,
  APITypes.ListByUserAndPagePathSearchIdQuery
>;
export const listByUserAndSearchId = /* GraphQL */ `query ListByUserAndSearchId(
  $userId: String!
  $searchId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSavedSearchFilterInput
  $limit: Int
  $nextToken: String
) {
  listByUserAndSearchId(
    userId: $userId
    searchId: $searchId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      pagePathSearchId
      pagePath
      searchId
      queryString
      savedAt
      name
      createdAt
      updatedAt
      companyId
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListByUserAndSearchIdQueryVariables,
  APITypes.ListByUserAndSearchIdQuery
>;
