import { createRoot } from 'react-dom/client'
import { cofigureAmplify } from 'src/lib/amplify'
import Root from './containers/domains/app/Root'
import * as serviceWorker from './serviceWorkerRegistration'
import swConfig from './swConfig'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(<Root />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(swConfig)
cofigureAmplify()
