import React from 'react'

const SwitchCompany = React.lazy(
  () => import(/* webpackChunkName: "SwitchCompany" */ '../containers/pages/management/switchCompany')
)
const Company = React.lazy(() => import(/* webpackChunkName: "Company" */ '../containers/pages/management/company'))
const Addon = React.lazy(() => import(/* webpackChunkName: "Addon" */ '../containers/pages/management/addon'))
const ManagementInquiryList = React.lazy(
  () => import(/* webpackChunkName: "ManagementInquiryList" */ '../containers/pages/management/inquiry')
)
const ManagementInquiryDetail = React.lazy(
  () => import(/* webpackChunkName: "ManagementInquiryDetail" */ '../containers/pages/management/inquiryDetail')
)

export const ManagementAccountRoutes = [
  {
    path: '/management/switch_company',
    component: SwitchCompany,
    breadcrumbs: [{ name: '運営' }, { name: '加盟店切り替え' }]
  },
  {
    path: '/management/company',
    component: Company,
    breadcrumbs: [{ name: '運営' }, { name: '加盟店一覧' }]
  },
  {
    path: '/management/addon',
    component: Addon,
    breadcrumbs: [{ name: '運営' }, { name: 'アドオン一覧' }]
  },
  {
    path: '/management/inquiries',
    component: ManagementInquiryList,
    breadcrumbs: [{ name: '運営' }, { name: 'お問い合わせ一覧' }]
  },
  {
    path: '/management/inquiries/:id',
    component: ManagementInquiryDetail,
    breadcrumbs: [{ name: 'お問い合わせ一覧', path: '/management/inquiries' }, { name: 'お問い合わせ詳細' }]
  }
]
