import type { AuthoriReason, AuthoriResult, Order } from 'src/API'
import {
  AUTHORI_FEEDBACK_VALUE,
  AUTHORI_REASON_VALUE,
  AUTHORI_RESULT_VALUE,
  BROWSER_NAME_LIST_VALUE,
  COUNTRY_NAMES_VALUES,
  INCLUDE_OR_EXCLUDE,
  INPUT_TYPE_VALUE,
  IP_FLAG_OPTIONS_VALUE,
  LINE_J_NAMES_VALUES,
  LOGIN_SUCCESS_VALUE,
  OS_NAME_LIST_VALUE,
  WT_DEVICE_CATEGORY_NAME_VALUE
} from 'src/common/constants'
import { checkIpDomainNameInclude } from 'src/common/hooks/validateQueryString'
import type { FieldSelectOption, SearchType } from 'src/common/types'
import { generateDateInputWithSecond } from 'src/common/utils/Date'
import type { SelectedAuthoriState } from 'src/store/modules/authori'
import { LIMIT_AUTHORI_TOTAL_COUNT } from '../constants'
import type { SearchAuthoriParamsInput, SearchAuthoriParamsQuery } from '../types'

export function generateAuthoriQueryInput({
  values,
  page,
  size,
  searchType,
  order,
  sort = 'began_at'
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>
  page: number
  size: number
  searchType: SearchType
  order?: Order
  sort?: string
}): SearchAuthoriParamsInput {
  const {
    searchValue,
    resultList,
    finalResultList,
    feedbackList,
    beganFrom,
    beganTo,
    authoriId,
    userIdHashed,
    userDeviceId,
    reasonList,
    useragent,
    didShort,
    didMiddle,
    cookie,
    localStorage,
    etag,
    loginSuccessList,
    wtDeviceCategoryNameList,
    eventUrl,
    eventName,
    inputType,
    botFlag,
    sourceIp,
    ipCountryCodeList,
    ipCountryAName,
    wtOsNameList,
    wtOsVersion,
    wtBrowserNameList,
    wtBrowserVersion,
    browserLanguage,
    ipFlagList,
    labels,
    connectedId,
    ipLineJNameList,
    ipDomainNameList,
    ipDomainNameInclude,
    ipLineJNameInclude,
    ipCountryCodeInclude
  } = values

  const isDomainValueInclude =
    ipDomainNameInclude &&
    checkIpDomainNameInclude(ipDomainNameInclude as FieldSelectOption) &&
    ipDomainNameInclude.value === INCLUDE_OR_EXCLUDE[0].value

  const isDomainValueExclude =
    ipDomainNameInclude &&
    checkIpDomainNameInclude(ipDomainNameInclude as FieldSelectOption) &&
    ipDomainNameInclude.value === INCLUDE_OR_EXCLUDE[1].value
  const ipLineJNameListValueInclude =
    ipLineJNameInclude &&
    checkIpDomainNameInclude(ipLineJNameInclude as FieldSelectOption) &&
    ipLineJNameInclude.value === INCLUDE_OR_EXCLUDE[0].value

  const ipLineJNameListValueExclude =
    ipLineJNameInclude &&
    checkIpDomainNameInclude(ipLineJNameInclude as FieldSelectOption) &&
    ipLineJNameInclude.value === INCLUDE_OR_EXCLUDE[1].value
  const ipCountryCodeListValueInclude =
    ipCountryCodeInclude &&
    checkIpDomainNameInclude(ipCountryCodeInclude as FieldSelectOption) &&
    ipCountryCodeInclude.value === INCLUDE_OR_EXCLUDE[0].value
  const ipCountryCodeListValueExclude =
    ipCountryCodeInclude &&
    checkIpDomainNameInclude(ipCountryCodeInclude as FieldSelectOption) &&
    ipCountryCodeInclude.value === INCLUDE_OR_EXCLUDE[1].value

  const commonQuery = {
    from: page ? page * size - size : 0,
    size,
    order,
    sort,
    result_list: resultList?.length
      ? {
          result: resultList.map((e: FieldSelectOption) => e.value as AuthoriResult)
        }
      : null,
    final_result_list: finalResultList?.length
      ? {
          final_result: finalResultList.map((e: FieldSelectOption) => e.value as AuthoriResult)
        }
      : null,
    feedback_list: feedbackList?.length
      ? {
          feedback: feedbackList.map((e: FieldSelectOption) => e.value as AuthoriResult)
        }
      : null,
    login_success_list: loginSuccessList?.length
      ? {
          login_success: loginSuccessList.map((e: FieldSelectOption) => e.value)
        }
      : null,
    began_at: generateDateInputWithSecond({
      from: beganFrom,
      to: beganTo
    })
  }

  if (searchType === 'simple') {
    return {
      ...commonQuery,
      search_value: searchValue
    }
  }
  return {
    ...commonQuery,
    authori_id: authoriId,
    user_id_hashed: userIdHashed,
    user_device_id: userDeviceId,
    reason_list: reasonList?.length
      ? {
          reason: reasonList.map((e: FieldSelectOption) => e.value as AuthoriReason)
        }
      : null,
    useragent,
    did_short: didShort,
    did_middle: didMiddle,
    cookie,
    local_storage: localStorage,
    etag,
    wt_device_category_name_list: wtDeviceCategoryNameList && {
      wt_device_category_name: wtDeviceCategoryNameList.map((e: FieldSelectOption) => e.value)
    },
    event_url: eventUrl,
    event_name: eventName,
    input_type: inputType && inputType.value,
    bot_flag: botFlag || null,
    source_ip: sourceIp?.length > 0 ? sourceIp : null,
    ip_country_code_list:
      ipCountryCodeListValueInclude && ipCountryCodeList && ipCountryCodeList.length > 0
        ? {
            ip_country_code: ipCountryCodeList.map((e: FieldSelectOption) => e.value)
          }
        : null,
    must_not_ip_country_code_list:
      ipCountryCodeListValueExclude && ipCountryCodeList && ipCountryCodeList.length > 0
        ? {
            ip_country_code: ipCountryCodeList.map((e: FieldSelectOption) => e.value)
          }
        : null,
    ip_country_a_name: ipCountryAName,
    foreign_ip_flag: ipFlagList?.filter((e: FieldSelectOption) => e.value === 'foreign_ip_flag').length ? true : null,
    ip_proxy_flag: ipFlagList?.filter((e: FieldSelectOption) => e.value === 'ip_proxy_flag').length ? '1' : null,
    tor_ip_flag: ipFlagList?.filter((e: FieldSelectOption) => e.value === 'tor_ip_flag').length ? true : null,
    wt_os_name_list: wtOsNameList && {
      wt_os_name: wtOsNameList.map((e: FieldSelectOption) => e.value)
    },
    wt_os_version: wtOsVersion,
    wt_browser_name_list: wtBrowserNameList && {
      wt_browser_name: wtBrowserNameList.map((e: FieldSelectOption) => e.value)
    },
    ip_line_j_name_list:
      ipLineJNameListValueInclude && ipLineJNameList && ipLineJNameList.length > 0
        ? {
            ip_line_j_name: ipLineJNameList.map((e: FieldSelectOption) => e.value)
          }
        : null,
    must_not_ip_line_j_name_list:
      ipLineJNameListValueExclude && ipLineJNameList && ipLineJNameList.length > 0
        ? {
            ip_line_j_name: ipLineJNameList.map((e: FieldSelectOption) => e.value)
          }
        : null,
    ip_domain_name_list:
      isDomainValueInclude && ipDomainNameList && ipDomainNameList.length > 0
        ? {
            ip_domain_name: ipDomainNameList.map((e: FieldSelectOption) => e.value)
          }
        : null,
    must_not_ip_domain_name_list:
      isDomainValueExclude && ipDomainNameList && ipDomainNameList.length > 0
        ? {
            ip_domain_name: ipDomainNameList.map((e: FieldSelectOption) => e.value)
          }
        : null,
    wt_browser_version: wtBrowserVersion,
    browser_language: browserLanguage,
    labels: labels && labels.map((e: FieldSelectOption) => e.value).join(' '),
    connected_id: connectedId
  }
}

const authoriOptionsMap = new Map<string, { value: string; label: string }[]>([
  ['reasonList', AUTHORI_REASON_VALUE],
  ['loginSuccessList', LOGIN_SUCCESS_VALUE],
  ['resultList', AUTHORI_RESULT_VALUE],
  ['feedbackList', AUTHORI_FEEDBACK_VALUE],
  ['finalResultList', AUTHORI_RESULT_VALUE],
  ['wtDeviceCategoryNameList', WT_DEVICE_CATEGORY_NAME_VALUE],
  ['wtOsNameList', OS_NAME_LIST_VALUE],
  ['wtBrowserNameList', BROWSER_NAME_LIST_VALUE],
  ['ipFlagList', IP_FLAG_OPTIONS_VALUE],
  ['inputType', INPUT_TYPE_VALUE],
  ['ipLineJNameList', LINE_J_NAMES_VALUES],
  ['ipDomainNameInclude', INCLUDE_OR_EXCLUDE],
  ['ipLineJNameInclude', INCLUDE_OR_EXCLUDE],
  ['ipCountryCodeInclude', INCLUDE_OR_EXCLUDE],
  ['ipCountryCodeList', COUNTRY_NAMES_VALUES]
])

export const getAuthoriOptionLabel = (key: string, value: string | boolean | number): string | null => {
  if (authoriOptionsMap.has(key)) {
    const v = authoriOptionsMap.get(key)?.find((e) => e.value === value)
    return v ? v.label : ''
  }

  return `${value}`
}

export const genLabelsValue = (data: SelectedAuthoriState[]): { label: string; value: string }[] => {
  const dataToFlatLabels = data
    .filter((e) => e?.labels)
    .map((e) => e?.labels?.split(' '))
    .flat()
  const removedDuplicates = new Set(dataToFlatLabels)
  return Array.from(removedDuplicates).map((e) => ({ label: e, value: e })) as {
    label: string
    value: string
  }[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const authoriData = (d: SearchAuthoriParamsQuery): any =>
  'searchAuthoriBySimpleParamsV2' in d ? d.searchAuthoriBySimpleParamsV2 : d.searchAuthoriByAdvancedParamsV2

export const getPageParamLimit = (size: number): number => LIMIT_AUTHORI_TOTAL_COUNT / size

export const validSortValues = ['began_at']
