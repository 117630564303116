import { type AuthoriResult, Order } from 'src/API'
import { INCLUDE_OR_EXCLUDE } from 'src/common/constants'
import { DATE_AUTO_COMPLETION_MENU_DEFAULT } from 'src/common/utils/Date'
import type { SearchAuthoriValues } from '../types'

export const AUTHORI_DEFAULT_VALUE: SearchAuthoriValues = {
  // simple
  searchField: '',
  searchValue: '',
  resultList: [],
  finalResultList: [],
  feedbackList: [],
  beganFrom: DATE_AUTO_COMPLETION_MENU_DEFAULT.from(),
  beganTo: DATE_AUTO_COMPLETION_MENU_DEFAULT.to(),
  beganToDateUnitNum: undefined,
  beganFromDateUnitNum: undefined,
  // advanced
  authoriId: '',
  userIdHashed: '',
  userDeviceId: '',
  reasonList: [],
  useragent: '',
  didShort: '',
  didMiddle: '',
  cookie: '',
  localStorage: '',
  etag: '',
  loginSuccessList: [],
  wtDeviceCategoryNameList: null,
  eventUrl: '',
  eventName: '',
  inputType: null,
  botFlag: false,
  sourceIp: '',
  ipCountryCodeList: [],
  ipCountryCodeInclude: undefined,
  ipCountryAName: '',
  ipFlagList: [],
  wtOsNameList: null,
  wtOsVersion: '',
  wtBrowserNameList: null,
  wtBrowserVersion: '',
  browserLanguage: '',
  labels: [],
  connectedId: '',
  ipLineJNameList: [],
  ipLineJNameInclude: undefined,
  ipDomainNameList: [],
  ipDomainNameInclude: undefined
}

export const INITIAL_PARAMS: SearchAuthoriValues = {
  beganFrom: DATE_AUTO_COMPLETION_MENU_DEFAULT.from(),
  beganTo: DATE_AUTO_COMPLETION_MENU_DEFAULT.to(),
  finalResultList: [
    { label: 'NG', value: 'NG' as AuthoriResult },
    { label: 'REVIEW', value: 'REVIEW' as AuthoriResult }
  ],
  beganToDateUnitNum: '1',
  beganFromDateUnitNum: '1',
  ipDomainNameInclude: INCLUDE_OR_EXCLUDE[0], // '含める'
  ipLineJNameInclude: INCLUDE_OR_EXCLUDE[0], // '含める'
  ipCountryCodeInclude: INCLUDE_OR_EXCLUDE[0] // '含める'
}

export const PAGE_PARAMS = {
  page: 1,
  size: 20,
  limit: 100,
  maxCsvSize: 1000
}

export const PAGE_SIZES = [
  { value: 20, label: '20件表示' },
  { value: 50, label: '50件表示' },
  { value: 100, label: '100件表示' }
]

export const SORT_PARAMS = {
  sort: 'began_at',
  order: Order.desc
}

export const LIMIT_AUTHORI_TOTAL_COUNT = 10000
