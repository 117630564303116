import type { DayjsInput } from 'src/common/types'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { addDays } from './Date-manipulate'

dayjs.extend(utc)
dayjs.extend(timezone)

export const DATE_FORMAT = {
  default: 'YYYY-MM-DD HH:mm',
  year: 'YYYY',
  month: 'YYYY-MM',
  date: 'YYYY-MM-DD',
  hour: 'YYYY-MM-DD HH:00',
  timestamp: 'YYYY-MM-DD HH:mm:ss.SSS',
  dateTimeWithSeconds: 'YYYY-MM-DD HH:mm:ss',
  time: 'HH:mm:ss',
  hourOnly: 'HH',
  minuteOnly: 'mm',
  secondOnly: 'ss'
}

export const formatDate =
  (date: DayjsInput) =>
  (fmt?: string): string => {
    return dayjs(date).tz('Asia/Tokyo').format(fmt)
  }

export const defaultFormatDate = (date: DayjsInput): string => formatDate(date)(DATE_FORMAT.default)
export const formatDateWithSecond = (date: DayjsInput): string => formatDate(date)(DATE_FORMAT.dateTimeWithSeconds)
export const formatTimeStampDate = (date: string | Date): string => formatDate(date)(DATE_FORMAT.timestamp)
export const formatHour = (date: string | Date): string => formatDate(date)(DATE_FORMAT.hourOnly)
export const formatMinute = (date: string | Date): string => formatDate(date)(DATE_FORMAT.minuteOnly)
export const formatSecond = (date: string | Date): string => formatDate(date)(DATE_FORMAT.secondOnly)

export const formatTtl =
  (add: number) =>
  (date: DayjsInput): number =>
    Number(dayjs(addDays(add)(date)).unix())

export const ttlToNextDay = formatTtl(1)
