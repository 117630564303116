export default {
  onUpdate: (registration: ServiceWorkerRegistration): void => {
    registration.unregister().then(() => {
      // eslint-disable-next-line no-console
      console.info('@@@@@@@@@@@@window.location.reload()@@@@@@@@@')
      window.location.reload()
    })
  },
  onSuccess: (): void => {
    // eslint-disable-next-line no-console
    console.info('@@@@@@@@@@@@service worker on success state@@@@@@@@@')
  }
}
