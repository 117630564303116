import React, { type ReactNode } from 'react'
import ClassNamesExport from 'classnames'
import styled, { css, keyframes, theme } from 'src/common/styles'
import type { ComponentStyleProps } from 'src/common/types'
import type { SerializedStyles } from '@emotion/react'

type Props = {
  color?: string
  radius?: number
  outlined?: boolean
  isFadeOut?: boolean
  children: ReactNode
} & ComponentStyleProps

const fadeout = keyframes`
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
`

const ScPaper = styled.div<Props>`
  ${({ color = '#fff', outlined = false, radius = 0, isFadeOut = false }: Props): SerializedStyles => css`
    ${outlined && `border: 1px solid ${theme.base.borderColor};`}
    border-radius: ${radius}px;
    background-color: ${color};
    opacity: 1;
    animation: ${isFadeOut
      ? css`
          ${fadeout} 1s ease-out both
        `
      : ''};
  `}
`

export const Paper: React.FC<Props> = (props) => {
  const { children, className, ...rest } = props
  return (
    <ScPaper {...rest} className={ClassNamesExport(className)}>
      {children}
    </ScPaper>
  )
}
