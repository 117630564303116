import dayjs, { type OpUnitType, type ManipulateType } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import type { DateUnitTime, DayjsInput } from '../../types'

import { defaultFormatDate, formatDateWithSecond } from './Date-format'

dayjs.extend(isoWeek)

export type ISOUnitType = OpUnitType | 'isoWeek'

export const generateDateInput = ({
  from,
  to
}: Partial<{ from: string | null | Date; to: string | null | Date }>): {
  from: string | null
  to: string | null
} | null => {
  if (from || to) {
    return {
      from: from ? defaultFormatDate(from) : null,
      to: to ? defaultFormatDate(to) : null
    }
  }

  return null
}

export const generateDateInputWithSecond = ({
  from,
  to
}: Partial<{ from: string | null | Date; to: string | null | Date }>): {
  from: string | null
  to: string | null
} | null => {
  if (from || to) {
    return {
      from: from ? formatDateWithSecond(from) : null,
      to: to ? formatDateWithSecond(to) : null
    }
  }

  return null
}

export const generateDateByUnit =
  (date: DayjsInput) =>
  (amount: number, unit: DateUnitTime): Date => {
    if (!amount) return dayjs(date).toDate()
    const excludeMinute = amount < 0 ? 1 : -1
    const baseDate = dayjs(date).add(amount, unit)
    if (unit === 'minutes') {
      return baseDate.toDate()
    }

    if (unit === 'hours') {
      return baseDate.add(excludeMinute, 'seconds').toDate()
    }

    return excludeMinute > 0
      ? baseDate.add(excludeMinute, 'seconds').startOf('seconds').toDate()
      : baseDate.add(excludeMinute, 'seconds').endOf('seconds').toDate()
  }

// 過去XX(単位)
export const past = (amount: number, unit: ManipulateType): { from: () => Date; to: () => Date } => ({
  from: () => dayjs(new Date()).add(amount, unit).startOf('minutes').toDate(),
  to: () => dayjs(new Date()).add(-1, 'minutes').endOf('minutes').toDate()
})
// XX（単位）間
export const range = (amount: number, unit: ManipulateType): { from: () => Date; to: () => Date } => ({
  from: () =>
    dayjs(new Date())
      .add(amount, unit)
      // XX間〜は今日日付を含めるのでFrom日付＋１日する
      .add(1, 'day')
      .startOf('day')
      .toDate(),
  to: () => dayjs(new Date()).endOf('day').toDate()
})

export const termRange = (unit: ISOUnitType): { from: () => Date; to: () => Date } => ({
  from: () => dayjs(new Date()).startOf(unit).toDate(),
  to: () => dayjs(new Date()).endOf(unit).toDate()
})

// 昨日・先週・先月
const lastTermRange = (unit: ManipulateType): { from: () => Date; to: () => Date } => {
  const unitOfTimeStartOf = unit === 'week' ? 'isoWeek' : unit
  return {
    from: () => dayjs(new Date()).add(-1, unit).startOf(unitOfTimeStartOf).toDate(),
    to: () => dayjs(new Date()).add(-1, unit).endOf(unitOfTimeStartOf).toDate()
  }
}

export const DATE_AUTO_COMPLETION_MENU_DEFAULT = {
  value: 'last-1-month',
  label: '1ヶ月間',
  withInMonth: true, // TODO falseにすべきでは？
  ...range(-1, 'month')
} as const

export const DATE_AUTO_COMPLETION_MENU = [
  {
    value: 'last-15-minutes',
    label: '過去15分',
    withInMonth: true,
    ...past(-15, 'minutes')
  },
  {
    value: 'last-30-minutes',
    label: '過去30分',
    withInMonth: true,
    ...past(-30, 'minutes')
  },
  {
    value: 'last-1-hour',
    label: '過去1時間',
    withInMonth: true,
    ...past(-1, 'hour')
  },
  {
    value: 'last-3-hours',
    label: '過去3時間',
    withInMonth: true,
    ...past(-3, 'hour')
  },
  {
    value: 'last-12-hours',
    label: '過去12時間',
    withInMonth: true,
    ...past(-12, 'hour')
  },
  {
    value: 'last-24-hours',
    label: '過去24時間',
    withInMonth: true,
    ...past(-24, 'hour')
  },
  {
    value: 'last-2-days',
    label: '2日間',
    withInMonth: true,
    ...range(-2, 'days')
  },
  {
    value: 'last-7-days',
    label: '7日間',
    withInMonth: true,
    ...range(-7, 'days')
  },
  {
    value: 'last-10-days',
    label: '10日間',
    withInMonth: true,
    ...range(-10, 'days')
  },
  {
    value: 'last-20-days',
    label: '20日間',
    withInMonth: true,
    ...range(-20, 'days')
  },
  DATE_AUTO_COMPLETION_MENU_DEFAULT,
  {
    value: 'last-2-months',
    label: '2ヶ月間',
    withInMonth: false,
    ...range(-2, 'months')
  },
  {
    value: 'last-3-months',
    label: '3ヶ月間',
    withInMonth: false,
    ...range(-3, 'months')
  },
  {
    value: 'last-6-months',
    label: '半年間',
    withInMonth: false,
    ...range(-6, 'months')
  },
  {
    value: 'last-1-year',
    label: '1年間',
    withInMonth: false,
    ...range(-1, 'years')
  },
  {
    value: 'yesterday',
    label: '昨日',
    withInMonth: true,
    ...lastTermRange('days')
  },
  {
    value: 'last-week',
    label: '先週',
    withInMonth: true,
    ...lastTermRange('week')
  },
  {
    value: 'last-month',
    label: '先月',
    withInMonth: true,
    ...lastTermRange('months')
  },
  {
    value: 'today',
    label: '今日',
    withInMonth: true,
    ...termRange('days')
  },
  {
    value: 'this-week',
    label: '今週',
    withInMonth: true,
    ...termRange('isoWeek')
  },
  {
    value: 'this-month',
    label: '今月',
    withInMonth: true,
    ...termRange('months')
  },
  {
    value: 'this-year',
    label: '今年',
    withInMonth: false,
    ...termRange('years')
  }
] as const
