/**
 * CompanyとCompanyReportSettingを作成
 */
export const createCompanyAndCompanyReportSetting = /* GraphQL */ `
mutation CreateCompanyAndCompanyReportSetting(
  $companyInput: CreateCompanyInput!
  $companyReportSettingInput: CreateCompanyReportSettingInput!
) {
  createCompany(input: $companyInput) {
    companyId
    companyName
    companyCode
    formalName
    authoriExportLimit
    allowedBy
    status
    ipAccessControl {
      enable
      ipEntry {
        ip
        description
      }
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
  createCompanyReportSetting(input: $companyReportSettingInput) {
    companyId
    ipReportSetting {
      foreignIp
      proxyIp
      torIp
      country {
        items
        isInclude
      }
      domain {
        items
        isInclude
      }
      line {
        items
        isInclude
      }
      hourly {
        userThreshold
        authoriThreshold
      }
      daily {
        userThreshold
        authoriThreshold
      }
      weekly {
        userThreshold
        authoriThreshold
      }
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;


export const createInquiryAndInquiryMessage = /* GraphQL */ `
mutation CreateInquiryAndInquiryMessage(
$inquiryInput: CreateInquiryInput!
$inquiryMessageInput: CreateInquiryMessageInput!
) {
  createInquiry(input: $inquiryInput) {
    accessLevel
    accountEmail
    accountId
    accountName
    allowedBy
    category
    companyIdOrAccountId
    companyIdOrAccountIdAndCategory
    companyIdOrAccountIdAndStatus
    companyName
    inquiryId
    createdAt
    companyId
    referrerUrl
    status
    title
    updatedAt
  }
  createInquiryMessage(input: $inquiryMessageInput) {
    cognitoUserIdentityId
    body
    allowedBy
    createdAt
    fileKeys
    inquiryMessageId
    inquiryId
    senderAccountType
    senderName
    updatedAt
  }
}
`;
