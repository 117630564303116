import type { CognitoUser } from 'amazon-cognito-identity-js'
import React from 'react'
import type { ErrMessage } from 'src/common/helper'
import { getAuthoriIdFromCookie } from 'src/common/helper/cookieManager'
import { delay } from 'src/common/utils'
import { useToast } from 'src/components/ui-elements/Toast'
import { updateLoginSuccess } from 'src/omotion/omoti-server-api/api'
import { useAuthSignIn, useAuthenticateMutation } from 'src/services'
import { useShallowAuthenticateStore } from 'src/store/modules/authenticate'

type Params = {
  /** 初回ログイン時の処理 */
  onFirstLoggedIn: (data: CognitoUser) => void
  /** 2回目以降ログイン時の処理 */
  onLoggedIn: () => void
}

export const useLogin = ({
  onFirstLoggedIn,
  onLoggedIn
}: Params): {
  doLogin: (param: { username: string; password: string }) => void
  isPending: boolean
  errMessage?: ErrMessage
} => {
  const toast = useToast()
  const [{ mutate: signInMutate, isPending: isPendingSignIn }, signInError] = useAuthSignIn()
  const { mutate: authMutate, isPending: isPendingAuth, errMessage: authErr } = useAuthenticateMutation()
  const { setAuth } = useShallowAuthenticateStore()

  const doLogin = React.useCallback(
    (param: { username: string; password: string }) => {
      signInMutate(param, {
        onSuccess: (data) => {
          let loginSuccessParam: Omit<Parameters<typeof updateLoginSuccess>[number], 'authoriId'>

          // 初回ログイン = `NEW_PASSWORD_REQUIRED`チャレンジ状態
          if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
            loginSuccessParam = {
              connectedId: data.challengeParam?.userAttributes['custom:company_id'] as string,
              userId: data.challengeParam?.userAttributes.email as string
            }
            onFirstLoggedIn(data)
          }
          // 2回目以降ログインの場合、認可処理を実施
          else {
            authMutate(
              {},
              {
                onSuccess: () => {
                  toast({ text: 'ログインに成功しました', variant: 'success' })
                  onLoggedIn()
                  setAuth()
                }
              }
            )
            loginSuccessParam = {
              connectedId: data.signInUserSession?.getIdToken().payload['custom:company_id'],
              userId: data.signInUserSession?.getIdToken().payload.email
            }
          }

          // 順序保証されないので遅延しておく
          // TODO https://github.com/cacco-product/omoti-core/issues/105
          delay(5000, () => {
            const authoriId = getAuthoriIdFromCookie()
            authoriId &&
              updateLoginSuccess({
                authoriId,
                ...loginSuccessParam
              })
          })
        }
      })
    },
    [authMutate, onFirstLoggedIn, onLoggedIn, setAuth, signInMutate, toast]
  )

  return {
    doLogin,
    isPending: isPendingSignIn || isPendingAuth,
    errMessage: signInError || authErr
  }
}
