import React from 'react'

const Management = React.lazy(
  () => import(/* webpackChunkName: "Management" */ '../containers/pages/management/account')
)
export const ManagementAccountAdminRoutes = [
  {
    path: '/management/account',
    component: Management,
    breadcrumbs: [{ name: '運営' }, { name: '運営アカウント一覧' }]
  }
]
