import { Auth } from 'aws-amplify'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { useErrorHandler } from 'src/common/hooks'
import { createErrorMessage, type ErrMessage } from 'src/common/helper'
import { useToast } from 'src/components/ui-elements/Toast'
import { recordAnalyticsEvent } from 'src/lib/amplify/analytics/helper'

type ChangePasswordValues = {
  oldPassword: string
  newPassword: string
}

export function useChangePassword(): [UseMutationResult<{ isSuccess: boolean }>, ErrMessage | undefined] {
  const toast = useToast()
  const { errMessage, onSetError } = useErrorHandler()
  const result = useMutation({
    mutationFn: async (values: ChangePasswordValues) => {
      const user = await Auth.currentAuthenticatedUser()
      try {
        await Auth.changePassword(user, values.oldPassword, values.newPassword)
        toast({ text: 'パスワードを変更しました', variant: 'success' })
        return { isSuccess: true }
      } catch (errors) {
        // const str = e.message === 'Incorrect username or password.' ? 'IncorrectPassword' : e.code
        const errMsg = createErrorMessage(errors)
        onSetError(errMsg)
        toast({
          text: '失敗しました',
          code: errMsg.code,
          variant: 'error',
          errors
        })
        throw errors
      }
    },
    onSettled: (_, e) => recordAnalyticsEvent('passwordChange', { result: e ? 'failure' : 'success' })
  }) as UseMutationResult<{ isSuccess: boolean }>
  return [result, errMessage]
}
