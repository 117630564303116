import * as React from 'react'
import ClassNamesExport from 'classnames'
import styled, { type ITheme } from 'src/common/styles'
import type { ComponentStyleProps } from 'src/common/types'

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse'

type Props = {
  gutters?: boolean
  margin?: boolean
  query?: boolean
  vAlign?: 'top' | 'middle' | 'bottom' | 'stretch' | 'baseline'
  align?: 'left' | 'center' | 'right' | 'between' | 'around'
  direction?: FlexDirection
  queryCol2?: boolean
  flex?: number
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  children: React.ReactNode
} & ComponentStyleProps

type ColProps = {
  size?: number
  grow?: number
  children?: React.ReactNode
} & ComponentStyleProps

const getFlexVAlign = (vAlign: string | undefined): string => {
  if (vAlign === 'top') {
    return `flex-start`
  }
  if (vAlign === 'middle') {
    return `center`
  }
  if (vAlign === 'bottom') {
    return `flex-end`
  }
  if (vAlign === 'stretch') {
    return `stretch`
  }
  if (vAlign === 'baseline') {
    return `baseline`
  }
  return ''
}

const getFlexAlign = (align: string | undefined): string => {
  if (align === 'left') {
    return `flex-start`
  }
  if (align === 'center') {
    return `center`
  }
  if (align === 'right') {
    return `flex-end`
  }
  if (align === 'between') {
    return `space-between`
  }
  if (align === 'around') {
    return `space-around`
  }
  return ''
}

type GetFlexDirectionReturnType = FlexDirection | ''

const getFlexDirection = (direction?: FlexDirection): GetFlexDirectionReturnType => direction ?? ''

const getFlexSize = ({ size = 1, theme }: { size?: number; theme: ITheme }): string => {
  return `
    width: ${theme.flex.colWidth * size}%;
  `
}

const getFlexGuttersSize = ({ size, theme }: { size?: number; theme: ITheme }): string => {
  if (size) {
    return `
      .flex-gutters > & {
        width: ${theme.flex.colWidthGutters * size - theme.flex.gutters}%;
        margin-left: ${theme.flex.gutters}%;
          &:first-of-type {
            margin-left: 0;
          }
      }
    `
  }
  return `
      .flex-gutters > & {
        margin-left: ${theme.flex.gutters}%;
          &:first-of-type {
            margin-left: 0;
          }
      }
    `
}

const getFlexMargin = (theme: ITheme): string => {
  return `
    .flex-margin > & {
      margin-bottom: ${theme.base.size * 2}px;
    }
  `
}

const getFlexQuery = (theme: ITheme): string => {
  return `
    @media (max-width: ${theme.base.viewport.desktop}px) {
      .flex-query > & {
        width: 100% !important;
        display: block !important;
        margin: 0 0 ${theme.base.size * 3}px;
        padding: 0 !important;
        &.u-d-pc {
          display: none !important;
          margin: 0;
        }
      }
    }
  `
}

const getFlexQueryCol2 = (theme: ITheme): string => {
  return `
    @media (max-width: ${theme.base.viewport.desktop}px) {
      .flex-query-col2 > & {
        width: 50% !important;
      }
    }
  `
}

export const ScFlex = styled('div')<Props>`
  display: flex;
  flex-wrap: ${({ flexWrap }): string => flexWrap || 'wrap'};
  list-style: none;
  align-items: ${({ vAlign }): string => getFlexVAlign(vAlign)};
  justify-content: ${({ align }): string => getFlexAlign(align)};
  flex-direction: ${({ direction }): string => getFlexDirection(direction)};
  ${({ flex }): string | false =>
    flex !== undefined &&
    `
    flex: ${flex};
  `}
`

export const ScFlexCol = styled.div<ColProps>`
  box-sizing: border-box;
  flex-shrink: 0;
  ${({ grow }): string | false => grow !== undefined && `flex-grow: ${grow}`};
  ${({ size, theme }): string | false => size !== undefined && getFlexSize({ size, theme })};
  ${({ size, theme }): string => getFlexGuttersSize({ size, theme })};
  ${({ theme }): string => getFlexMargin(theme)};
  ${({ theme }): string => getFlexQuery(theme)};
  ${({ theme }): string => getFlexQueryCol2(theme)};
`

export const Flex: React.FC<Props> = (props) => {
  const { gutters, margin, query, children, queryCol2, className, ...rest } = props
  return (
    <ScFlex
      {...rest}
      className={ClassNamesExport(className, {
        'flex-gutters': gutters,
        'flex-margin': margin,
        'flex-query': query,
        'flex-query-col2': queryCol2
      })}
    >
      {children}
    </ScFlex>
  )
}

export const FlexCol: React.FC<ColProps> = (props) => {
  const { className, children, ...rest } = props
  return (
    <ScFlexCol {...rest} className={ClassNamesExport(className)}>
      {children}
    </ScFlexCol>
  )
}
