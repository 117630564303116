/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type LambdaResolverError = {
  __typename: "LambdaResolverError",
  status: number,
  errorCode: LambdaResolverErrorCode,
  message: string,
  name: string,
  stack?: string | null,
  cause?: string | null,
};

export enum LambdaResolverErrorCode {
  BAD_REQUEST = "BAD_REQUEST",
  DUPLICATED_ERROR = "DUPLICATED_ERROR",
  IP_ACCESS_BLOCKED = "IP_ACCESS_BLOCKED",
  NOT_FOUND = "NOT_FOUND",
  ACCESS_DENIED = "ACCESS_DENIED",
  INVALID_STATE_OF_INVITED_USER = "INVALID_STATE_OF_INVITED_USER",
  SERVER_ERROR = "SERVER_ERROR",
}


export type CreateAccountInput = {
  email: string,
  companyId: string,
  name: string,
  role: AccountRole,
  createdAt?: string | null,
  createdBy: string,
  updatedBy: string,
};

export enum AccountRole {
  ADMIN = "ADMIN",
  OPE = "OPE",
  VIEW = "VIEW",
}


export type ModelAccountConditionInput = {
  companyId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  role?: ModelAccountRoleInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelAccountRoleInput = {
  eq?: AccountRole | null,
  ne?: AccountRole | null,
};

export type Account = {
  __typename: "Account",
  email: string,
  companyId: string,
  name: string,
  role: AccountRole,
  createdAt: string,
  createdBy: string,
  updatedAt?: string | null,
  updatedBy: string,
};

export type UpdateAccountInput = {
  email: string,
  companyId?: string | null,
  name?: string | null,
  role?: AccountRole | null,
  createdAt?: string | null,
  createdBy?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
};

export type DeleteAccountInput = {
  email: string,
};

export type CreateManagementAccountInput = {
  email: string,
  name: string,
  role: AccountRole,
  allowedBy: AccountType,
  assumedCompanyId: string,
  createdAt?: string | null,
  createdBy: string,
  updatedBy: string,
};

export enum AccountType {
  MANAGEMENT = "MANAGEMENT",
  COMPANY = "COMPANY",
}


export type ModelManagementAccountConditionInput = {
  name?: ModelStringInput | null,
  role?: ModelAccountRoleInput | null,
  allowedBy?: ModelAccountTypeInput | null,
  assumedCompanyId?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelManagementAccountConditionInput | null > | null,
  or?: Array< ModelManagementAccountConditionInput | null > | null,
  not?: ModelManagementAccountConditionInput | null,
};

export type ModelAccountTypeInput = {
  eq?: AccountType | null,
  ne?: AccountType | null,
};

export type ManagementAccount = {
  __typename: "ManagementAccount",
  email: string,
  name: string,
  role: AccountRole,
  allowedBy: AccountType,
  assumedCompanyId: string,
  createdBy: string,
  updatedBy: string,
  createdAt: string,
  updatedAt?: string | null,
};

export type UpdateManagementAccountInput = {
  email: string,
  name?: string | null,
  role?: AccountRole | null,
  allowedBy?: AccountType | null,
  assumedCompanyId?: string | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteManagementAccountInput = {
  email: string,
};

export type CreateAuthoriInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  expirationUnixTime?: number | null,
  authori_id: string,
  omotion_user_id: string,
  company_id: string,
  send_token?: string | null,
  event_id?: string | null,
  event_name?: string | null,
  user_id_hashed?: string | null,
  user_id_encrypted?: string | null,
  result?: AuthoriResult | null,
  final_result?: AuthoriResult | null,
  reason?: AuthoriReason | null,
  feedback?: AuthoriResult | null,
  feedback_comment?: string | null,
  feedbacked_at?: string | null,
  feedback_type?: FeedbackType | null,
  feedback_account_id?: string | null,
  feedback_account_name?: string | null,
  user_device_id?: string | null,
  event_url?: string | null,
  login_success?: boolean | null,
  cookie?: string | null,
  etag?: string | null,
  local_storage?: string | null,
  input_type?: AuthoriInputType | null,
  bot_flag?: boolean | null,
  bot_type?: string | null,
  did_short?: string | null,
  did_middle?: string | null,
  source_ip?: string | null,
  tor_ip_flag?: boolean | null,
  foreign_ip_flag?: boolean | null,
  useragent?: string | null,
  wt_device_category_name?: string | null,
  wt_os_name?: string | null,
  wt_os_version?: string | null,
  wt_browser_name?: string | null,
  wt_browser_version?: string | null,
  browser_language?: string | null,
  referer?: string | null,
  timezone_offset?: number | null,
  access_at?: string | null,
  send_at?: string | null,
  request_at?: string | null,
  began_at?: string | null,
  ended_at?: string | null,
  login_time?: number | null,
  residence_time?: number | null,
  authori_time?: number | null,
  es_index_date?: string | null,
  es_company_index?: string | null,
  ip_version?: string | null,
  ip_country_code?: string | null,
  ip_country_a_name?: string | null,
  ip_country_j_name?: string | null,
  ip_pref_code?: string | null,
  ip_pref_a_name?: string | null,
  ip_pref_j_name?: string | null,
  ip_city_code?: string | null,
  ip_city_a_name?: string | null,
  ip_city_j_name?: string | null,
  ip_org_name?: string | null,
  ip_domain_name?: string | null,
  ip_line_j_name?: string | null,
  ip_proxy_flag?: string | null,
  ip_pref_cf_level?: number | null,
  ip_city_cf_level?: number | null,
  ip_line_cf_level?: number | null,
  ip_pref_location?: string | null,
  ip_city_location?: string | null,
  ip_read_time?: number | null,
  ip_created?: boolean | null,
  click_count?: number | null,
  keystroke_count?: number | null,
  mouse_count?: number | null,
  swipe_count?: number | null,
  remarks?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  labels?: string | null,
  memo?: string | null,
  connected_id?: string | null,
};

export enum AuthoriResult {
  OK = "OK",
  NG = "NG",
  REVIEW = "REVIEW",
  NONE = "NONE",
}


export enum AuthoriReason {
  USER_DEVICE_COOKIE = "USER_DEVICE_COOKIE",
  USER_DEVICE_ETAG = "USER_DEVICE_ETAG",
  USER_DEVICE_LOCAL_STORAGE = "USER_DEVICE_LOCAL_STORAGE",
  USER_DEVICE_DID_SHORT = "USER_DEVICE_DID_SHORT",
  USER_DEVICE_DID_MIDDLE_AND_IP = "USER_DEVICE_DID_MIDDLE_AND_IP",
  USER_DEVICE = "USER_DEVICE",
  NG_DEVICE = "NG_DEVICE",
  SAME_DEVICE = "SAME_DEVICE",
  EXCLUDED_IP = "EXCLUDED_IP",
  NEGATIVE_IP = "NEGATIVE_IP",
  TOR_IP_MATCH = "TOR_IP_MATCH",
  FOREIGN_IP_AND_LANGUAGE = "FOREIGN_IP_AND_LANGUAGE",
  FOREIGN_IP = "FOREIGN_IP",
  BOT = "BOT",
  FIRST_USER = "FIRST_USER",
  FIRST_USER_DEVICE = "FIRST_USER_DEVICE",
  FIRST_USER_DEVICE_COUNT_OVER = "FIRST_USER_DEVICE_COUNT_OVER",
}


export enum FeedbackType {
  API = "API",
  CONSOLE = "CONSOLE",
  NONE = "NONE",
}


export enum AuthoriInputType {
  SOFTWARE_KEYBOARD = "SOFTWARE_KEYBOARD",
  AUTO_COMPLETE = "AUTO_COMPLETE",
  COPY_AND_PASTE = "COPY_AND_PASTE",
  DRAG_AND_DROP = "DRAG_AND_DROP",
  KEY_TYPED = "KEY_TYPED",
  UNKNOWN = "UNKNOWN",
  NONE = "NONE",
}


export type ModelAuthoriConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expirationUnixTime?: ModelIntInput | null,
  authori_id?: ModelStringInput | null,
  omotion_user_id?: ModelStringInput | null,
  company_id?: ModelStringInput | null,
  send_token?: ModelStringInput | null,
  event_id?: ModelStringInput | null,
  event_name?: ModelStringInput | null,
  user_id_hashed?: ModelStringInput | null,
  user_id_encrypted?: ModelStringInput | null,
  result?: ModelAuthoriResultInput | null,
  final_result?: ModelAuthoriResultInput | null,
  reason?: ModelAuthoriReasonInput | null,
  feedback?: ModelAuthoriResultInput | null,
  feedback_comment?: ModelStringInput | null,
  feedbacked_at?: ModelStringInput | null,
  feedback_type?: ModelFeedbackTypeInput | null,
  feedback_account_id?: ModelStringInput | null,
  feedback_account_name?: ModelStringInput | null,
  user_device_id?: ModelStringInput | null,
  event_url?: ModelStringInput | null,
  login_success?: ModelBooleanInput | null,
  cookie?: ModelStringInput | null,
  etag?: ModelStringInput | null,
  local_storage?: ModelStringInput | null,
  input_type?: ModelAuthoriInputTypeInput | null,
  bot_flag?: ModelBooleanInput | null,
  bot_type?: ModelStringInput | null,
  did_short?: ModelStringInput | null,
  did_middle?: ModelStringInput | null,
  source_ip?: ModelStringInput | null,
  tor_ip_flag?: ModelBooleanInput | null,
  foreign_ip_flag?: ModelBooleanInput | null,
  useragent?: ModelStringInput | null,
  wt_device_category_name?: ModelStringInput | null,
  wt_os_name?: ModelStringInput | null,
  wt_os_version?: ModelStringInput | null,
  wt_browser_name?: ModelStringInput | null,
  wt_browser_version?: ModelStringInput | null,
  browser_language?: ModelStringInput | null,
  referer?: ModelStringInput | null,
  timezone_offset?: ModelIntInput | null,
  access_at?: ModelStringInput | null,
  send_at?: ModelStringInput | null,
  request_at?: ModelStringInput | null,
  began_at?: ModelStringInput | null,
  ended_at?: ModelStringInput | null,
  login_time?: ModelFloatInput | null,
  residence_time?: ModelIntInput | null,
  authori_time?: ModelIntInput | null,
  es_index_date?: ModelStringInput | null,
  es_company_index?: ModelStringInput | null,
  ip_version?: ModelStringInput | null,
  ip_country_code?: ModelStringInput | null,
  ip_country_a_name?: ModelStringInput | null,
  ip_country_j_name?: ModelStringInput | null,
  ip_pref_code?: ModelStringInput | null,
  ip_pref_a_name?: ModelStringInput | null,
  ip_pref_j_name?: ModelStringInput | null,
  ip_city_code?: ModelStringInput | null,
  ip_city_a_name?: ModelStringInput | null,
  ip_city_j_name?: ModelStringInput | null,
  ip_org_name?: ModelStringInput | null,
  ip_domain_name?: ModelStringInput | null,
  ip_line_j_name?: ModelStringInput | null,
  ip_proxy_flag?: ModelStringInput | null,
  ip_pref_cf_level?: ModelIntInput | null,
  ip_city_cf_level?: ModelIntInput | null,
  ip_line_cf_level?: ModelIntInput | null,
  ip_pref_location?: ModelStringInput | null,
  ip_city_location?: ModelStringInput | null,
  ip_read_time?: ModelIntInput | null,
  ip_created?: ModelBooleanInput | null,
  click_count?: ModelIntInput | null,
  keystroke_count?: ModelIntInput | null,
  mouse_count?: ModelIntInput | null,
  swipe_count?: ModelIntInput | null,
  remarks?: ModelStringInput | null,
  created_at?: ModelStringInput | null,
  updated_at?: ModelStringInput | null,
  labels?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  connected_id?: ModelStringInput | null,
  and?: Array< ModelAuthoriConditionInput | null > | null,
  or?: Array< ModelAuthoriConditionInput | null > | null,
  not?: ModelAuthoriConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelAuthoriResultInput = {
  eq?: AuthoriResult | null,
  ne?: AuthoriResult | null,
};

export type ModelAuthoriReasonInput = {
  eq?: AuthoriReason | null,
  ne?: AuthoriReason | null,
};

export type ModelFeedbackTypeInput = {
  eq?: FeedbackType | null,
  ne?: FeedbackType | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelAuthoriInputTypeInput = {
  eq?: AuthoriInputType | null,
  ne?: AuthoriInputType | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Authori = {
  __typename: "Authori",
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  expirationUnixTime?: number | null,
  authori_id: string,
  omotion_user_id: string,
  company_id: string,
  send_token?: string | null,
  event_id?: string | null,
  event_name?: string | null,
  user_id_hashed?: string | null,
  user_id_encrypted?: string | null,
  result?: AuthoriResult | null,
  final_result?: AuthoriResult | null,
  reason?: AuthoriReason | null,
  feedback?: AuthoriResult | null,
  feedback_comment?: string | null,
  feedbacked_at?: string | null,
  feedback_type?: FeedbackType | null,
  feedback_account_id?: string | null,
  feedback_account_name?: string | null,
  user_device_id?: string | null,
  event_url?: string | null,
  login_success?: boolean | null,
  cookie?: string | null,
  etag?: string | null,
  local_storage?: string | null,
  input_type?: AuthoriInputType | null,
  bot_flag?: boolean | null,
  bot_type?: string | null,
  did_short?: string | null,
  did_middle?: string | null,
  source_ip?: string | null,
  tor_ip_flag?: boolean | null,
  foreign_ip_flag?: boolean | null,
  useragent?: string | null,
  wt_device_category_name?: string | null,
  wt_os_name?: string | null,
  wt_os_version?: string | null,
  wt_browser_name?: string | null,
  wt_browser_version?: string | null,
  browser_language?: string | null,
  referer?: string | null,
  timezone_offset?: number | null,
  access_at?: string | null,
  send_at?: string | null,
  request_at?: string | null,
  began_at?: string | null,
  ended_at?: string | null,
  login_time?: number | null,
  residence_time?: number | null,
  authori_time?: number | null,
  es_index_date?: string | null,
  es_company_index?: string | null,
  ip_version?: string | null,
  ip_country_code?: string | null,
  ip_country_a_name?: string | null,
  ip_country_j_name?: string | null,
  ip_pref_code?: string | null,
  ip_pref_a_name?: string | null,
  ip_pref_j_name?: string | null,
  ip_city_code?: string | null,
  ip_city_a_name?: string | null,
  ip_city_j_name?: string | null,
  ip_org_name?: string | null,
  ip_domain_name?: string | null,
  ip_line_j_name?: string | null,
  ip_proxy_flag?: string | null,
  ip_pref_cf_level?: number | null,
  ip_city_cf_level?: number | null,
  ip_line_cf_level?: number | null,
  ip_pref_location?: string | null,
  ip_city_location?: string | null,
  ip_read_time?: number | null,
  ip_created?: boolean | null,
  click_count?: number | null,
  keystroke_count?: number | null,
  mouse_count?: number | null,
  swipe_count?: number | null,
  remarks?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  labels?: string | null,
  memo?: string | null,
  connected_id?: string | null,
};

export type UpdateAuthoriInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  expirationUnixTime?: number | null,
  authori_id?: string | null,
  omotion_user_id?: string | null,
  company_id?: string | null,
  send_token?: string | null,
  event_id?: string | null,
  event_name?: string | null,
  user_id_hashed?: string | null,
  user_id_encrypted?: string | null,
  result?: AuthoriResult | null,
  final_result?: AuthoriResult | null,
  reason?: AuthoriReason | null,
  feedback?: AuthoriResult | null,
  feedback_comment?: string | null,
  feedbacked_at?: string | null,
  feedback_type?: FeedbackType | null,
  feedback_account_id?: string | null,
  feedback_account_name?: string | null,
  user_device_id?: string | null,
  event_url?: string | null,
  login_success?: boolean | null,
  cookie?: string | null,
  etag?: string | null,
  local_storage?: string | null,
  input_type?: AuthoriInputType | null,
  bot_flag?: boolean | null,
  bot_type?: string | null,
  did_short?: string | null,
  did_middle?: string | null,
  source_ip?: string | null,
  tor_ip_flag?: boolean | null,
  foreign_ip_flag?: boolean | null,
  useragent?: string | null,
  wt_device_category_name?: string | null,
  wt_os_name?: string | null,
  wt_os_version?: string | null,
  wt_browser_name?: string | null,
  wt_browser_version?: string | null,
  browser_language?: string | null,
  referer?: string | null,
  timezone_offset?: number | null,
  access_at?: string | null,
  send_at?: string | null,
  request_at?: string | null,
  began_at?: string | null,
  ended_at?: string | null,
  login_time?: number | null,
  residence_time?: number | null,
  authori_time?: number | null,
  es_index_date?: string | null,
  es_company_index?: string | null,
  ip_version?: string | null,
  ip_country_code?: string | null,
  ip_country_a_name?: string | null,
  ip_country_j_name?: string | null,
  ip_pref_code?: string | null,
  ip_pref_a_name?: string | null,
  ip_pref_j_name?: string | null,
  ip_city_code?: string | null,
  ip_city_a_name?: string | null,
  ip_city_j_name?: string | null,
  ip_org_name?: string | null,
  ip_domain_name?: string | null,
  ip_line_j_name?: string | null,
  ip_proxy_flag?: string | null,
  ip_pref_cf_level?: number | null,
  ip_city_cf_level?: number | null,
  ip_line_cf_level?: number | null,
  ip_pref_location?: string | null,
  ip_city_location?: string | null,
  ip_read_time?: number | null,
  ip_created?: boolean | null,
  click_count?: number | null,
  keystroke_count?: number | null,
  mouse_count?: number | null,
  swipe_count?: number | null,
  remarks?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  labels?: string | null,
  memo?: string | null,
  connected_id?: string | null,
};

export type DeleteAuthoriInput = {
  id: string,
};

export type CreateAuthoriSummaryMonthlyInput = {
  companyId: string,
  yearMonth: string,
  ok: number,
  ng: number,
  review: number,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelAuthoriSummaryMonthlyConditionInput = {
  ok?: ModelIntInput | null,
  ng?: ModelIntInput | null,
  review?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAuthoriSummaryMonthlyConditionInput | null > | null,
  or?: Array< ModelAuthoriSummaryMonthlyConditionInput | null > | null,
  not?: ModelAuthoriSummaryMonthlyConditionInput | null,
};

export type AuthoriSummaryMonthly = {
  __typename: "AuthoriSummaryMonthly",
  companyId: string,
  yearMonth: string,
  ok: number,
  ng: number,
  review: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAuthoriSummaryMonthlyInput = {
  companyId: string,
  yearMonth: string,
  ok?: number | null,
  ng?: number | null,
  review?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteAuthoriSummaryMonthlyInput = {
  companyId: string,
  yearMonth: string,
};

export type CreateCompanyInput = {
  companyId: string,
  companyName: string,
  companyCode: string,
  formalName: string,
  hashSalt?: string | null,
  authoriExportLimit?: number | null,
  allowedBy: AccountType,
  status: CompanyStatus,
  ipAccessControl?: IpAccessControlInput | null,
  createdBy: string,
  updatedBy: string,
  createdAt?: string | null,
};

export enum CompanyStatus {
  CREATED = "CREATED",
  INSTALL = "INSTALL",
  READY = "READY",
  RUNNING = "RUNNING",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
}


export type IpAccessControlInput = {
  enable: boolean,
  ipEntry: Array< IpEntryInput >,
};

export type IpEntryInput = {
  ip: string,
  description: string,
};

export type ModelCompanyConditionInput = {
  companyName?: ModelStringInput | null,
  companyCode?: ModelStringInput | null,
  formalName?: ModelStringInput | null,
  hashSalt?: ModelStringInput | null,
  authoriExportLimit?: ModelIntInput | null,
  allowedBy?: ModelAccountTypeInput | null,
  status?: ModelCompanyStatusInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCompanyConditionInput | null > | null,
  or?: Array< ModelCompanyConditionInput | null > | null,
  not?: ModelCompanyConditionInput | null,
};

export type ModelCompanyStatusInput = {
  eq?: CompanyStatus | null,
  ne?: CompanyStatus | null,
};

export type Company = {
  __typename: "Company",
  companyId: string,
  companyName: string,
  companyCode: string,
  formalName: string,
  hashSalt?: string | null,
  authoriExportLimit?: number | null,
  allowedBy: AccountType,
  status: CompanyStatus,
  ipAccessControl?: IpAccessControl | null,
  createdBy: string,
  updatedBy: string,
  createdAt: string,
  updatedAt: string,
};

export type IpAccessControl = {
  __typename: "IpAccessControl",
  enable: boolean,
  ipEntry:  Array<IpEntry >,
};

export type IpEntry = {
  __typename: "IpEntry",
  ip: string,
  description: string,
};

export type UpdateCompanyInput = {
  companyId: string,
  companyName?: string | null,
  companyCode?: string | null,
  formalName?: string | null,
  hashSalt?: string | null,
  authoriExportLimit?: number | null,
  allowedBy?: AccountType | null,
  status?: CompanyStatus | null,
  ipAccessControl?: IpAccessControlInput | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  createdAt?: string | null,
};

export type DeleteCompanyInput = {
  companyId: string,
};

export type CreateCompanyAddonV2Input = {
  companyId: string,
  addonId: AddonId,
  name: string,
  displayWeight?: number | null,
  companyEmail?: string | null,
  enabled: boolean,
  allowedBy: AccountType,
  createdBy: string,
  updatedBy: string,
};

export enum AddonId {
  daily_data_export = "daily_data_export",
  daily_report = "daily_report",
}


export type ModelCompanyAddonV2ConditionInput = {
  name?: ModelStringInput | null,
  displayWeight?: ModelIntInput | null,
  companyEmail?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  allowedBy?: ModelAccountTypeInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCompanyAddonV2ConditionInput | null > | null,
  or?: Array< ModelCompanyAddonV2ConditionInput | null > | null,
  not?: ModelCompanyAddonV2ConditionInput | null,
};

export type CompanyAddonV2 = {
  __typename: "CompanyAddonV2",
  companyId: string,
  addonId: AddonId,
  name: string,
  displayWeight?: number | null,
  companyEmail?: string | null,
  enabled: boolean,
  allowedBy: AccountType,
  createdBy: string,
  updatedBy: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCompanyAddonV2Input = {
  companyId: string,
  addonId: AddonId,
  name?: string | null,
  displayWeight?: number | null,
  companyEmail?: string | null,
  enabled?: boolean | null,
  allowedBy?: AccountType | null,
  createdBy?: string | null,
  updatedBy?: string | null,
};

export type DeleteCompanyAddonV2Input = {
  companyId: string,
  addonId: AddonId,
};

export type CreateCompanyReportSettingInput = {
  companyId: string,
  ipReportSetting?: IpReportSettingInput | null,
  allowedBy: AccountType,
  createdBy: string,
  updatedBy: string,
};

export type IpReportSettingInput = {
  foreignIp: boolean,
  proxyIp: boolean,
  torIp: boolean,
  country?: IpReportSearchConditionInput | null,
  domain?: IpReportSearchConditionInput | null,
  line?: IpReportSearchConditionInput | null,
  hourly: IpReportThresholdInput,
  daily: IpReportThresholdInput,
  weekly: IpReportThresholdInput,
};

export type IpReportSearchConditionInput = {
  items: Array< string >,
  isInclude: boolean,
};

export type IpReportThresholdInput = {
  userThreshold: number,
  authoriThreshold: number,
};

export type ModelCompanyReportSettingConditionInput = {
  allowedBy?: ModelAccountTypeInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCompanyReportSettingConditionInput | null > | null,
  or?: Array< ModelCompanyReportSettingConditionInput | null > | null,
  not?: ModelCompanyReportSettingConditionInput | null,
};

export type CompanyReportSetting = {
  __typename: "CompanyReportSetting",
  companyId: string,
  ipReportSetting?: IpReportSetting | null,
  allowedBy: AccountType,
  createdBy: string,
  updatedBy: string,
  createdAt: string,
  updatedAt: string,
};

export type IpReportSetting = {
  __typename: "IpReportSetting",
  foreignIp: boolean,
  proxyIp: boolean,
  torIp: boolean,
  country?: IpReportSearchCondition | null,
  domain?: IpReportSearchCondition | null,
  line?: IpReportSearchCondition | null,
  hourly: IpReportThreshold,
  daily: IpReportThreshold,
  weekly: IpReportThreshold,
};

export type IpReportSearchCondition = {
  __typename: "IpReportSearchCondition",
  items: Array< string >,
  isInclude: boolean,
};

export type IpReportThreshold = {
  __typename: "IpReportThreshold",
  userThreshold: number,
  authoriThreshold: number,
};

export type UpdateCompanyReportSettingInput = {
  companyId: string,
  ipReportSetting?: IpReportSettingInput | null,
  allowedBy?: AccountType | null,
  createdBy?: string | null,
  updatedBy?: string | null,
};

export type DeleteCompanyReportSettingInput = {
  companyId: string,
};

export type CreateExportRequestInput = {
  id?: string | null,
  companyId: string,
  event: ExportEvent,
  accountName: string,
  rangeFrom: string,
  rangeTo: string,
  conditions?: ConditionInput | null,
  charset: Charset,
  createdAt?: string | null,
  ttl?: number | null,
  aggregationUnit?: TimeUnit | null,
  threshold?: ExportThresholdInput | null,
};

export enum ExportEvent {
  AuthoriExport = "AuthoriExport", // 審査エクスポート
  AuthoriExportByManagement = "AuthoriExportByManagement",
  AuthoriExportForMatsui = "AuthoriExportForMatsui", // 日次データ出力（アドオン）
  AuthoriExportForMatsuiByManagement = "AuthoriExportForMatsuiByManagement",
  IpAggregationExport = "IpAggregationExport", // IP集計
  IpAggregationExportByManagement = "IpAggregationExportByManagement",
  AuditLogExport = "AuditLogExport", // 監査ログ
  AuditLogExportByManagement = "AuditLogExportByManagement",
}


export type ConditionInput = {
  result?: Array< AuthoriResult | null > | null,
  final_result?: Array< AuthoriResult | null > | null,
  tor_ip_flag?: boolean | null,
  login_success?: Array< boolean | null > | null,
  or?: Array< ConditionInput | null > | null,
};

export enum Charset {
  Shift_JIS = "Shift_JIS",
  UTF_8 = "UTF_8",
  UTF_16 = "UTF_16",
}


// IP集計エクスポート条件: 集計単位
export enum TimeUnit {
  minutes = "minutes",
  hours = "hours",
  days = "days",
  months = "months",
}


export type ExportThresholdInput = {
  authoriStart: string,
  authoriEnd: string,
  userStart: string,
  userEnd: string,
};

export type ModelExportRequestConditionInput = {
  companyId?: ModelStringInput | null,
  event?: ModelExportEventInput | null,
  accountName?: ModelStringInput | null,
  rangeFrom?: ModelStringInput | null,
  rangeTo?: ModelStringInput | null,
  charset?: ModelCharsetInput | null,
  createdAt?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  aggregationUnit?: ModelTimeUnitInput | null,
  and?: Array< ModelExportRequestConditionInput | null > | null,
  or?: Array< ModelExportRequestConditionInput | null > | null,
  not?: ModelExportRequestConditionInput | null,
};

export type ModelExportEventInput = {
  eq?: ExportEvent | null,
  ne?: ExportEvent | null,
};

export type ModelCharsetInput = {
  eq?: Charset | null,
  ne?: Charset | null,
};

export type ModelTimeUnitInput = {
  eq?: TimeUnit | null,
  ne?: TimeUnit | null,
};

export type ExportRequest = {
  __typename: "ExportRequest",
  id: string,
  companyId: string,
  event: ExportEvent,
  accountName: string,
  rangeFrom: string,
  rangeTo: string,
  conditions?: Condition | null,
  charset: Charset,
  createdAt: string,
  ttl?: number | null,
  aggregationUnit?: TimeUnit | null,
  threshold?: ExportThreshold | null,
  updatedAt: string,
};

export type Condition = {
  __typename: "Condition",
  result?: Array< AuthoriResult | null > | null,
  final_result?: Array< AuthoriResult | null > | null,
  tor_ip_flag?: boolean | null,
  login_success?: Array< boolean | null > | null,
  or?:  Array<Condition | null > | null,
};

export type ExportThreshold = {
  __typename: "ExportThreshold",
  authoriStart: string,
  authoriEnd: string,
  userStart: string,
  userEnd: string,
};

export type UpdateExportRequestInput = {
  id: string,
  companyId?: string | null,
  event?: ExportEvent | null,
  accountName?: string | null,
  rangeFrom?: string | null,
  rangeTo?: string | null,
  conditions?: ConditionInput | null,
  charset?: Charset | null,
  createdAt?: string | null,
  ttl?: number | null,
  aggregationUnit?: TimeUnit | null,
  threshold?: ExportThresholdInput | null,
};

export type DeleteExportRequestInput = {
  id: string,
};

export type CreateExportResultInput = {
  companyId: string,
  exportRequestId: string,
  createdAt?: string | null,
  status: ExportStatus,
  error?: ExportErrorInput | null,
  s3Object?: S3ObjectInput | null,
  event: ExportEvent,
  accountName: string,
  rangeFrom: string,
  rangeTo: string,
  conditions?: ConditionInput | null,
  charset: Charset,
  requestAt: string,
  exportedAt: string,
  expiredAt: string,
  count?: number | null,
  ttl: number,
  companyIdAndEvent: string,
  aggregationUnit?: TimeUnit | null,
  threshold?: ExportThresholdInput | null,
};

export enum ExportStatus {
  REQUEST = "REQUEST",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}


export type ExportErrorInput = {
  errorType: ExportErrorType,
  errorCode: number,
};

export enum ExportErrorType {
  NOT_FOUND = "NOT_FOUND",
  RANGE_FORWARD_TO_NEXT_MONTH = "RANGE_FORWARD_TO_NEXT_MONTH",
  RANGE_ERROR = "RANGE_ERROR",
  LIMIT_ERROR = "LIMIT_ERROR",
  EXPORT_LIMIT_ERROR = "EXPORT_LIMIT_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
}


export type S3ObjectInput = {
  bucket: string,
  key: string,
  region: string,
};

export type ModelExportResultConditionInput = {
  createdAt?: ModelStringInput | null,
  status?: ModelExportStatusInput | null,
  event?: ModelExportEventInput | null,
  accountName?: ModelStringInput | null,
  rangeFrom?: ModelStringInput | null,
  rangeTo?: ModelStringInput | null,
  charset?: ModelCharsetInput | null,
  requestAt?: ModelStringInput | null,
  exportedAt?: ModelStringInput | null,
  expiredAt?: ModelStringInput | null,
  count?: ModelIntInput | null,
  ttl?: ModelIntInput | null,
  companyIdAndEvent?: ModelStringInput | null,
  aggregationUnit?: ModelTimeUnitInput | null,
  and?: Array< ModelExportResultConditionInput | null > | null,
  or?: Array< ModelExportResultConditionInput | null > | null,
  not?: ModelExportResultConditionInput | null,
};

export type ModelExportStatusInput = {
  eq?: ExportStatus | null,
  ne?: ExportStatus | null,
};

export type ExportResult = {
  __typename: "ExportResult",
  companyId: string,
  exportRequestId: string,
  createdAt: string,
  status: ExportStatus,
  error?: ExportError | null,
  s3Object?: S3Object | null,
  event: ExportEvent,
  accountName: string,
  rangeFrom: string,
  rangeTo: string,
  conditions?: Condition | null,
  charset: Charset,
  requestAt: string,
  exportedAt: string,
  expiredAt: string,
  count?: number | null,
  ttl: number,
  companyIdAndEvent: string,
  aggregationUnit?: TimeUnit | null,
  threshold?: ExportThreshold | null,
  updatedAt: string,
};

export type ExportError = {
  __typename: "ExportError",
  errorType: ExportErrorType,
  errorCode: number,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  key: string,
  region: string,
};

export type UpdateExportResultInput = {
  companyId: string,
  exportRequestId: string,
  createdAt?: string | null,
  status?: ExportStatus | null,
  error?: ExportErrorInput | null,
  s3Object?: S3ObjectInput | null,
  event?: ExportEvent | null,
  accountName?: string | null,
  rangeFrom?: string | null,
  rangeTo?: string | null,
  conditions?: ConditionInput | null,
  charset?: Charset | null,
  requestAt?: string | null,
  exportedAt?: string | null,
  expiredAt?: string | null,
  count?: number | null,
  ttl?: number | null,
  companyIdAndEvent?: string | null,
  aggregationUnit?: TimeUnit | null,
  threshold?: ExportThresholdInput | null,
};

export type DeleteExportResultInput = {
  companyId: string,
  exportRequestId: string,
};

export type CreateInquiryInput = {
  inquiryId: string,
  companyId: string,
  companyName: string,
  accountId: string,
  accountName: string,
  accountEmail: string,
  title: string,
  status: InquiryStatus,
  category: InquiryCategory,
  accessLevel: AccessLevel,
  referrerUrl?: string | null,
  allowedBy: AccountType,
  companyIdOrAccountId: string,
  companyIdOrAccountIdAndStatus: string,
  companyIdOrAccountIdAndCategory: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum InquiryStatus {
  MANAGEMENT_PENDING = "MANAGEMENT_PENDING",
  COMPANY_PENDING = "COMPANY_PENDING",
  DONE = "DONE",
}


export enum InquiryCategory {
  GENERAL_QUESTION = "GENERAL_QUESTION",
  ONBOARDING_QUESTION = "ONBOARDING_QUESTION",
  FEATURE_REQUEST = "FEATURE_REQUEST",
  REVIEW_RULE_CHANGE_REQUEST = "REVIEW_RULE_CHANGE_REQUEST",
  BUG_REPORT = "BUG_REPORT",
  OTHERS = "OTHERS",
}


export enum AccessLevel {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}


export type ModelInquiryConditionInput = {
  companyId?: ModelIDInput | null,
  companyName?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  accountName?: ModelStringInput | null,
  accountEmail?: ModelStringInput | null,
  title?: ModelStringInput | null,
  status?: ModelInquiryStatusInput | null,
  category?: ModelInquiryCategoryInput | null,
  accessLevel?: ModelAccessLevelInput | null,
  referrerUrl?: ModelStringInput | null,
  allowedBy?: ModelAccountTypeInput | null,
  companyIdOrAccountId?: ModelStringInput | null,
  companyIdOrAccountIdAndStatus?: ModelStringInput | null,
  companyIdOrAccountIdAndCategory?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInquiryConditionInput | null > | null,
  or?: Array< ModelInquiryConditionInput | null > | null,
  not?: ModelInquiryConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelInquiryStatusInput = {
  eq?: InquiryStatus | null,
  ne?: InquiryStatus | null,
};

export type ModelInquiryCategoryInput = {
  eq?: InquiryCategory | null,
  ne?: InquiryCategory | null,
};

export type ModelAccessLevelInput = {
  eq?: AccessLevel | null,
  ne?: AccessLevel | null,
};

export type Inquiry = {
  __typename: "Inquiry",
  inquiryId: string,
  companyId: string,
  companyName: string,
  accountId: string,
  accountName: string,
  accountEmail: string,
  title: string,
  status: InquiryStatus,
  category: InquiryCategory,
  accessLevel: AccessLevel,
  referrerUrl?: string | null,
  allowedBy: AccountType,
  companyIdOrAccountId: string,
  companyIdOrAccountIdAndStatus: string,
  companyIdOrAccountIdAndCategory: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateInquiryInput = {
  inquiryId: string,
  companyId?: string | null,
  companyName?: string | null,
  accountId?: string | null,
  accountName?: string | null,
  accountEmail?: string | null,
  title?: string | null,
  status?: InquiryStatus | null,
  category?: InquiryCategory | null,
  accessLevel?: AccessLevel | null,
  referrerUrl?: string | null,
  allowedBy?: AccountType | null,
  companyIdOrAccountId?: string | null,
  companyIdOrAccountIdAndStatus?: string | null,
  companyIdOrAccountIdAndCategory?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteInquiryInput = {
  inquiryId: string,
};

export type CreateInquiryMessageInput = {
  inquiryMessageId: string,
  inquiryId: string,
  companyId: string,
  senderAccountType: AccountType,
  senderName: string,
  body: string,
  fileKeys: Array< string >,
  cognitoUserIdentityId: string,
  allowedBy: AccountType,
  createdAt?: string | null,
};

export type ModelInquiryMessageConditionInput = {
  inquiryId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  senderAccountType?: ModelAccountTypeInput | null,
  senderName?: ModelStringInput | null,
  body?: ModelStringInput | null,
  fileKeys?: ModelStringInput | null,
  cognitoUserIdentityId?: ModelStringInput | null,
  allowedBy?: ModelAccountTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelInquiryMessageConditionInput | null > | null,
  or?: Array< ModelInquiryMessageConditionInput | null > | null,
  not?: ModelInquiryMessageConditionInput | null,
};

export type InquiryMessage = {
  __typename: "InquiryMessage",
  inquiryMessageId: string,
  inquiryId: string,
  companyId: string,
  senderAccountType: AccountType,
  senderName: string,
  body: string,
  fileKeys: Array< string >,
  cognitoUserIdentityId: string,
  allowedBy: AccountType,
  createdAt: string,
  updatedAt: string,
};

export type UpdateInquiryMessageInput = {
  inquiryMessageId: string,
  inquiryId?: string | null,
  companyId?: string | null,
  senderAccountType?: AccountType | null,
  senderName?: string | null,
  body?: string | null,
  fileKeys?: Array< string > | null,
  cognitoUserIdentityId?: string | null,
  allowedBy?: AccountType | null,
  createdAt?: string | null,
};

export type DeleteInquiryMessageInput = {
  inquiryMessageId: string,
};

export type CreateReportScheduleAccountInput = {
  email: string,
  companyId: string,
  schedule?: ScheduleInput | null,
  ipReportSchedule?: ScheduleInput | null,
  ipReportForced?: boolean | null,
};

export type ScheduleInput = {
  hourly?: boolean | null,
  daily?: boolean | null,
  weekly?: boolean | null,
};

export type ModelReportScheduleAccountConditionInput = {
  companyId?: ModelStringInput | null,
  ipReportForced?: ModelBooleanInput | null,
  and?: Array< ModelReportScheduleAccountConditionInput | null > | null,
  or?: Array< ModelReportScheduleAccountConditionInput | null > | null,
  not?: ModelReportScheduleAccountConditionInput | null,
};

export type ReportScheduleAccount = {
  __typename: "ReportScheduleAccount",
  email: string,
  companyId: string,
  schedule?: Schedule | null,
  ipReportSchedule?: Schedule | null,
  ipReportForced?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type Schedule = {
  __typename: "Schedule",
  hourly?: boolean | null,
  daily?: boolean | null,
  weekly?: boolean | null,
};

export type UpdateReportScheduleAccountInput = {
  email: string,
  companyId?: string | null,
  schedule?: ScheduleInput | null,
  ipReportSchedule?: ScheduleInput | null,
  ipReportForced?: boolean | null,
};

export type DeleteReportScheduleAccountInput = {
  email: string,
};

export type CreateSavedSearchInput = {
  id?: string | null,
  userId: string,
  pagePathSearchId: string,
  pagePath: string,
  searchId: string,
  queryString?: string | null,
  savedAt?: string | null,
  name: string,
};

export type ModelSavedSearchConditionInput = {
  userId?: ModelStringInput | null,
  pagePathSearchId?: ModelStringInput | null,
  pagePath?: ModelStringInput | null,
  searchId?: ModelStringInput | null,
  queryString?: ModelStringInput | null,
  savedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelSavedSearchConditionInput | null > | null,
  or?: Array< ModelSavedSearchConditionInput | null > | null,
  not?: ModelSavedSearchConditionInput | null,
};

export type SavedSearch = {
  __typename: "SavedSearch",
  id: string,
  userId: string,
  pagePathSearchId: string,
  pagePath: string,
  searchId: string,
  queryString?: string | null,
  savedAt?: string | null,
  name: string,
  createdAt: string,
  updatedAt: string,
  companyId?: string | null,
};

export type UpdateSavedSearchInput = {
  id: string,
  userId?: string | null,
  pagePathSearchId?: string | null,
  pagePath?: string | null,
  searchId?: string | null,
  queryString?: string | null,
  savedAt?: string | null,
  name?: string | null,
};

export type DeleteSavedSearchInput = {
  id: string,
};

export type InviteAccountResponse = {
  __typename: "InviteAccountResponse",
  success?: boolean | null,
};

export type LogInput = {
  level: LogLevel,
  message?: string | null,
  email?: string | null,
  path: string,
  timestamp: string,
};

export enum LogLevel {
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}


export type LogMutationResponse = {
  __typename: "LogMutationResponse",
  success?: boolean | null,
};

export type AuthenticateResponse = {
  __typename: "AuthenticateResponse",
  status: number,
};

export type DeleteCompanyInfraResponse = {
  __typename: "DeleteCompanyInfraResponse",
  success: boolean,
};

export type SearchAuthoriBySimpleParamsInput = {
  from: number,
  size: number,
  search_value?: string | null,
  result_list?: ResultList | null,
  final_result_list?: FinalResultList | null,
  feedback_list?: FeedbackList | null,
  login_success_list?: LoginSuccessList | null,
  began_at?: DateTimeRange | null,
  order?: Order | null,
  sort?: string | null,
};

export type ResultList = {
  result: Array< AuthoriResult | null >,
};

export type FinalResultList = {
  final_result: Array< AuthoriResult | null >,
};

export type FeedbackList = {
  feedback: Array< AuthoriResult | null >,
};

export type LoginSuccessList = {
  login_success: Array< boolean | null >,
};

export type DateTimeRange = {
  from?: string | null,
  to?: string | null,
};

export enum Order {
  asc = "asc",
  desc = "desc",
}


export type AuthoriResponse = {
  __typename: "AuthoriResponse",
  total?: number | null,
  authori_list?:  Array<Authori | null > | null,
  authori_file?: S3Object | null,
};

export type SearchAuthoriByAdvancedParamsInput = {
  from: number,
  size: number,
  authori_id?: string | null,
  user_id_hashed?: string | null,
  user_device_id?: string | null,
  event_name?: string | null,
  result_list?: ResultList | null,
  final_result_list?: FinalResultList | null,
  feedback_list?: FeedbackList | null,
  reason_list?: ReasonList | null,
  useragent?: string | null,
  did_short?: string | null,
  did_middle?: string | null,
  cookie?: string | null,
  local_storage?: string | null,
  etag?: string | null,
  login_success_list?: LoginSuccessList | null,
  wt_device_category_name_list?: WtDeviceCategoryNameList | null,
  wt_os_name_list?: WtOsNameList | null,
  wt_os_version?: string | null,
  wt_browser_name_list?: WtBrowserNameList | null,
  wt_browser_version?: string | null,
  browser_language?: string | null,
  event_url?: string | null,
  foreign_ip_flag?: boolean | null,
  source_ip?: string | null,
  ip_country_code_list?: IpCountryCodeList | null,
  must_not_ip_country_code_list?: IpCountryCodeList | null,
  ip_country_a_name?: string | null,
  ip_proxy_flag?: string | null,
  tor_ip_flag?: boolean | null,
  input_type?: AuthoriInputType | null,
  bot_flag?: boolean | null,
  began_at?: DateTimeRange | null,
  labels?: string | null,
  memo?: string | null,
  connected_id?: string | null,
  ip_domain_name_list?: IpDomainNameList | null,
  must_not_ip_domain_name_list?: IpDomainNameList | null,
  ip_line_j_name_list?: IpLineJNameList | null,
  must_not_ip_line_j_name_list?: IpLineJNameList | null,
  order?: Order | null,
  sort?: string | null,
};

export type ReasonList = {
  reason: Array< AuthoriReason | null >,
};

export type WtDeviceCategoryNameList = {
  wt_device_category_name: Array< string | null >,
};

export type WtOsNameList = {
  wt_os_name: Array< string | null >,
};

export type WtBrowserNameList = {
  wt_browser_name: Array< string | null >,
};

export type IpCountryCodeList = {
  ip_country_code: Array< string | null >,
};

export type IpDomainNameList = {
  ip_domain_name: Array< string | null >,
};

export type IpLineJNameList = {
  ip_line_j_name: Array< string | null >,
};

export type AuthoriFeedback = {
  __typename: "AuthoriFeedback",
  authoriId: string,
  feedbackedAt: string,
  companyId?: string | null,
  omotionUserId?: string | null,
  feedback?: AuthoriResult | null,
  type?: FeedbackType | null,
  comment?: string | null,
  accountId?: string | null,
  accountName?: string | null,
  createdAt?: string | null,
};

export type SearchUserDeviceInput = {
  from: number,
  size: number,
  user_id_hashed?: string | null,
  user_device_id?: string | null,
  did_short?: string | null,
  did_middle?: string | null,
  cookie?: string | null,
  local_storage?: string | null,
  etag?: string | null,
  status_list?: UserDeviceStatusList | null,
  wt_device_category_name_list?: WtDeviceCategoryNameList | null,
  wt_browser_name_list?: WtBrowserNameList | null,
  wt_os_name_list?: WtOsNameList | null,
  updated_at?: DateTimeRange | null,
  created_at?: DateTimeRange | null,
  current_ip?: string | null,
  order?: Order | null,
};

export type UserDeviceStatusList = {
  status: Array< UserDeviceStatus | null >,
};

export enum UserDeviceStatus {
  VALID = "VALID",
  INVALID = "INVALID",
  DUPLICATED = "DUPLICATED",
  UNIDENTIFIED = "UNIDENTIFIED",
  WAITING = "WAITING",
}


export type UserDeviceResponse = {
  __typename: "UserDeviceResponse",
  total: number,
  user_device_list:  Array<UserDevice | null >,
};

export type UserDevice = {
  __typename: "UserDevice",
  user_device_id?: string | null,
  es_user_device_id: string,
  es_company_index?: string | null,
  omotion_user_id?: string | null,
  user_id_hashed?: string | null,
  company_id?: string | null,
  status?: UserDeviceStatus | null,
  cookie?: string | null,
  etag?: string | null,
  local_storage?: string | null,
  did_short?: string | null,
  did_middle?: string | null,
  wt_device_category_name?: string | null,
  wt_os_name?: string | null,
  wt_browser_name?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  current_ip?: string | null,
};

export type CalcUsersOfDeviceInput = {
  size?: number | null,
  user_device_status_list?: UserDeviceStatusList | null,
  wt_device_category_name_list?: WtDeviceCategoryNameList | null,
  wt_browser_name_list?: WtBrowserNameList | null,
  wt_os_name_list?: WtOsNameList | null,
  updated_at?: DateTimeRange | null,
  created_at?: DateTimeRange | null,
  threshold: Threshold,
  order?: Order | null,
};

export type Threshold = {
  start?: number | null,
  end?: number | null,
};

export type CalcUsersOfDeviceResponse = {
  __typename: "CalcUsersOfDeviceResponse",
  total: number,
  list?:  Array<UsersOfDevice | null > | null,
};

export type UsersOfDevice = {
  __typename: "UsersOfDevice",
  user_device_id?: string | null,
  count?: number | null,
};

export type CalcDevicesOfUserInput = {
  size?: number | null,
  user_device_status_list?: UserDeviceStatusList | null,
  wt_browser_name_list?: WtBrowserNameList | null,
  wt_os_name_list?: WtOsNameList | null,
  updated_at?: DateTimeRange | null,
  created_at?: DateTimeRange | null,
  threshold: Threshold,
  order?: Order | null,
};

export type CalcDevicesOfUserResponse = {
  __typename: "CalcDevicesOfUserResponse",
  total: number,
  list?:  Array<DevicesOfUser | null > | null,
};

export type DevicesOfUser = {
  __typename: "DevicesOfUser",
  user_id_hashed?: string | null,
  count?: number | null,
};

export type AggregateInput = {
  began_at?: DateTimeRange | null,
  interval: string,
  event_name?: string | null,
  wt_device_category_name_list?: WtDeviceCategoryNameList | null,
  size?: number | null,
};

export type AggregateAuthoriResultResponse = {
  __typename: "AggregateAuthoriResultResponse",
  total: number,
  list:  Array<AggregateAuthoriResult | null >,
};

export type AggregateAuthoriResult = {
  __typename: "AggregateAuthoriResult",
  date?: string | null,
  count?: number | null,
  data?: AggregateAuthoriResultData | null,
};

export type AggregateAuthoriResultData = {
  __typename: "AggregateAuthoriResultData",
  ok?: number | null,
  ng?: number | null,
  review?: number | null,
};

export type AggregateAuthoriReasonReviewResponse = {
  __typename: "AggregateAuthoriReasonReviewResponse",
  total: number,
  list:  Array<AggregateAuthoriReasonReview | null >,
};

export type AggregateAuthoriReasonReview = {
  __typename: "AggregateAuthoriReasonReview",
  date?: string | null,
  count?: number | null,
  data?: AggregateAuthoriReasonReviewData | null,
};

export type AggregateAuthoriReasonReviewData = {
  __typename: "AggregateAuthoriReasonReviewData",
  foreign_ip?: number | null,
  tor_ip?: number | null,
  user_device?: number | null,
  same_device?: number | null,
  ng_device?: number | null,
  bot_review?: number | null,
  foreign_ip_and_language_review?: number | null,
  first_user_device_count_over?: number | null,
};

export type AggregateAuthoriReasonNgResponse = {
  __typename: "AggregateAuthoriReasonNgResponse",
  total: number,
  list:  Array<AggregateAuthoriReasonNg | null >,
};

export type AggregateAuthoriReasonNg = {
  __typename: "AggregateAuthoriReasonNg",
  date?: string | null,
  count?: number | null,
  data?: AggregateAuthoriReasonNgData | null,
};

export type AggregateAuthoriReasonNgData = {
  __typename: "AggregateAuthoriReasonNgData",
  bot_ng?: number | null,
  user_device?: number | null,
  ng_device?: number | null,
  foreign_ip_ng?: number | null,
  tor_ip_ng?: number | null,
  foreign_ip_and_language_ng?: number | null,
};

export type AggregateAuthoriTorIpResponse = {
  __typename: "AggregateAuthoriTorIpResponse",
  total: number,
  list:  Array<AggregateAuthoriTorIp | null >,
};

export type AggregateAuthoriTorIp = {
  __typename: "AggregateAuthoriTorIp",
  date?: string | null,
  count?: number | null,
  data?: AggregateAuthoriTorIpData | null,
};

export type AggregateAuthoriTorIpData = {
  __typename: "AggregateAuthoriTorIpData",
  tor_ip?: number | null,
};

export type AggregateAuthoriBotResponse = {
  __typename: "AggregateAuthoriBotResponse",
  total: number,
  list:  Array<AggregateAuthoriBot | null >,
};

export type AggregateAuthoriBot = {
  __typename: "AggregateAuthoriBot",
  date?: string | null,
  count?: number | null,
  data?: AggregateAuthoriBotData | null,
};

export type AggregateAuthoriBotData = {
  __typename: "AggregateAuthoriBotData",
  bot_flag?: number | null,
};

export type AggregateAuthoriForeignAccessResponse = {
  __typename: "AggregateAuthoriForeignAccessResponse",
  total: number,
  list:  Array<AggregateAuthoriForeignAccess | null >,
};

export type AggregateAuthoriForeignAccess = {
  __typename: "AggregateAuthoriForeignAccess",
  count?: number | null,
  country?: string | null,
};

export type AggregateAuthoriForeignIpResponse = {
  __typename: "AggregateAuthoriForeignIpResponse",
  total: number,
  list:  Array<AggregateAuthoriForeignIp | null >,
};

export type AggregateAuthoriForeignIp = {
  __typename: "AggregateAuthoriForeignIp",
  date?: string | null,
  count?: number | null,
  data?: AggregateAuthoriForeignIpData | null,
};

export type AggregateAuthoriForeignIpData = {
  __typename: "AggregateAuthoriForeignIpData",
  foreign_ip?: number | null,
};

export type AggregateAuthoriForeignLanguageResponse = {
  __typename: "AggregateAuthoriForeignLanguageResponse",
  total: number,
  list:  Array<AggregateAuthoriForeignLanguage | null >,
};

export type AggregateAuthoriForeignLanguage = {
  __typename: "AggregateAuthoriForeignLanguage",
  date?: string | null,
  count?: number | null,
  data?: AggregateAuthoriForeignLanguageData | null,
};

export type AggregateAuthoriForeignLanguageData = {
  __typename: "AggregateAuthoriForeignLanguageData",
  foreign_language?: number | null,
};

export type AggregateDeviceStatusResponse = {
  __typename: "AggregateDeviceStatusResponse",
  total: number,
  list:  Array<AggregateDeviceStatus | null >,
};

export type AggregateDeviceStatus = {
  __typename: "AggregateDeviceStatus",
  date?: string | null,
  count?: number | null,
  data?: AggregateDeviceStatusData | null,
};

export type AggregateDeviceStatusData = {
  __typename: "AggregateDeviceStatusData",
  valid?: number | null,
  invalid?: number | null,
  duplicated?: number | null,
  unidentified?: number | null,
};

export type AggregateAuthoriInputTypeResponse = {
  __typename: "AggregateAuthoriInputTypeResponse",
  total: number,
  list:  Array<AggregateAuthoriInputType | null >,
};

export type AggregateAuthoriInputType = {
  __typename: "AggregateAuthoriInputType",
  date?: string | null,
  count?: number | null,
  data?: AggregateAuthoriInputTypeData | null,
};

export type AggregateAuthoriInputTypeData = {
  __typename: "AggregateAuthoriInputTypeData",
  software_keyboard?: number | null,
  auto_complete?: number | null,
  copy_and_paste?: number | null,
  drag_and_drop?: number | null,
  key_typed?: number | null,
  unknown?: number | null,
  none?: number | null,
};

export type AggregateAuthoriIpCityLocationInput = {
  began_at?: DateTimeRange | null,
  interval: string,
  event_name?: string | null,
  wt_device_category_name_list?: WtDeviceCategoryNameList | null,
  ip_city_cf_level?: number | null,
  result?: string | null,
  is_only_japan?: boolean | null,
  is_only_overseas?: boolean | null,
};

export type AggregateAuthoriIpCityLocationResponse = {
  __typename: "AggregateAuthoriIpCityLocationResponse",
  total: number,
  list:  Array<AggregateAuthoriIpCityLocation | null >,
};

export type AggregateAuthoriIpCityLocation = {
  __typename: "AggregateAuthoriIpCityLocation",
  count?: number | null,
  data?: AggregateAuthoriIpCityLocationData | null,
};

export type AggregateAuthoriIpCityLocationData = {
  __typename: "AggregateAuthoriIpCityLocationData",
  lat?: number | null,
  lon?: number | null,
  ip_city_a_name?: string | null,
};

export type AggregateByIpInput = {
  size: number,
  from: number,
  began_at?: DateTimeRange | null,
  authori_threshold?: Threshold | null,
  user_threshold?: Threshold | null,
  foreign_ip_flag?: boolean | null,
  ip_proxy_flag?: string | null,
  tor_ip_flag?: boolean | null,
  ip_domain_name_list?: IpDomainNameList | null,
  must_not_ip_domain_name_list?: IpDomainNameList | null,
  ip_line_j_name_list?: IpLineJNameList | null,
  must_not_ip_line_j_name_list?: IpLineJNameList | null,
  ip_country_code_list?: IpCountryCodeList | null,
  must_not_ip_country_code_list?: IpCountryCodeList | null,
};

export type AggregateByIpResponse = {
  __typename: "AggregateByIpResponse",
  total: number,
  list:  Array<AggregateByIp | null >,
};

export type AggregateByIp = {
  __typename: "AggregateByIp",
  ip?: string | null,
  authori?: AggregateCount | null,
  user?: AggregateCount | null,
};

export type AggregateCount = {
  __typename: "AggregateCount",
  count?: number | null,
};

export type AuthoriCountInput = {
  began_at: DateTimeRange,
  result: AuthoriResult,
  key: string,
};

export type AuthoriCountResult = {
  __typename: "AuthoriCountResult",
  count?: number | null,
};

export type ReportAuthoriInput = {
  began_at: DateTimeRange,
  interval: ReportInterval,
};

export enum ReportInterval {
  hour = "hour",
  day = "day",
  month = "month",
  week = "week",
}


export type ReportAuthoriResponse = {
  __typename: "ReportAuthoriResponse",
  total: number,
  list:  Array<ReportResult >,
};

export type ReportResult = {
  __typename: "ReportResult",
  date: string,
  count: number,
  OK: ReportResultCount,
  NG: ReportResultCount,
  REVIEW: ReportResultCount,
};

export type ReportResultCount = {
  __typename: "ReportResultCount",
  count: number,
};

export type ModelAccountFilterInput = {
  email?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  role?: ModelAccountRoleInput | null,
  createdAt?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelManagementAccountFilterInput = {
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  role?: ModelAccountRoleInput | null,
  allowedBy?: ModelAccountTypeInput | null,
  assumedCompanyId?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelManagementAccountFilterInput | null > | null,
  or?: Array< ModelManagementAccountFilterInput | null > | null,
  not?: ModelManagementAccountFilterInput | null,
};

export type ModelManagementAccountConnection = {
  __typename: "ModelManagementAccountConnection",
  items:  Array<ManagementAccount | null >,
  nextToken?: string | null,
};

export type ModelAuthoriFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  expirationUnixTime?: ModelIntInput | null,
  authori_id?: ModelStringInput | null,
  omotion_user_id?: ModelStringInput | null,
  company_id?: ModelStringInput | null,
  send_token?: ModelStringInput | null,
  event_id?: ModelStringInput | null,
  event_name?: ModelStringInput | null,
  user_id_hashed?: ModelStringInput | null,
  user_id_encrypted?: ModelStringInput | null,
  result?: ModelAuthoriResultInput | null,
  final_result?: ModelAuthoriResultInput | null,
  reason?: ModelAuthoriReasonInput | null,
  feedback?: ModelAuthoriResultInput | null,
  feedback_comment?: ModelStringInput | null,
  feedbacked_at?: ModelStringInput | null,
  feedback_type?: ModelFeedbackTypeInput | null,
  feedback_account_id?: ModelStringInput | null,
  feedback_account_name?: ModelStringInput | null,
  user_device_id?: ModelStringInput | null,
  event_url?: ModelStringInput | null,
  login_success?: ModelBooleanInput | null,
  cookie?: ModelStringInput | null,
  etag?: ModelStringInput | null,
  local_storage?: ModelStringInput | null,
  input_type?: ModelAuthoriInputTypeInput | null,
  bot_flag?: ModelBooleanInput | null,
  bot_type?: ModelStringInput | null,
  did_short?: ModelStringInput | null,
  did_middle?: ModelStringInput | null,
  source_ip?: ModelStringInput | null,
  tor_ip_flag?: ModelBooleanInput | null,
  foreign_ip_flag?: ModelBooleanInput | null,
  useragent?: ModelStringInput | null,
  wt_device_category_name?: ModelStringInput | null,
  wt_os_name?: ModelStringInput | null,
  wt_os_version?: ModelStringInput | null,
  wt_browser_name?: ModelStringInput | null,
  wt_browser_version?: ModelStringInput | null,
  browser_language?: ModelStringInput | null,
  referer?: ModelStringInput | null,
  timezone_offset?: ModelIntInput | null,
  access_at?: ModelStringInput | null,
  send_at?: ModelStringInput | null,
  request_at?: ModelStringInput | null,
  began_at?: ModelStringInput | null,
  ended_at?: ModelStringInput | null,
  login_time?: ModelFloatInput | null,
  residence_time?: ModelIntInput | null,
  authori_time?: ModelIntInput | null,
  es_index_date?: ModelStringInput | null,
  es_company_index?: ModelStringInput | null,
  ip_version?: ModelStringInput | null,
  ip_country_code?: ModelStringInput | null,
  ip_country_a_name?: ModelStringInput | null,
  ip_country_j_name?: ModelStringInput | null,
  ip_pref_code?: ModelStringInput | null,
  ip_pref_a_name?: ModelStringInput | null,
  ip_pref_j_name?: ModelStringInput | null,
  ip_city_code?: ModelStringInput | null,
  ip_city_a_name?: ModelStringInput | null,
  ip_city_j_name?: ModelStringInput | null,
  ip_org_name?: ModelStringInput | null,
  ip_domain_name?: ModelStringInput | null,
  ip_line_j_name?: ModelStringInput | null,
  ip_proxy_flag?: ModelStringInput | null,
  ip_pref_cf_level?: ModelIntInput | null,
  ip_city_cf_level?: ModelIntInput | null,
  ip_line_cf_level?: ModelIntInput | null,
  ip_pref_location?: ModelStringInput | null,
  ip_city_location?: ModelStringInput | null,
  ip_read_time?: ModelIntInput | null,
  ip_created?: ModelBooleanInput | null,
  click_count?: ModelIntInput | null,
  keystroke_count?: ModelIntInput | null,
  mouse_count?: ModelIntInput | null,
  swipe_count?: ModelIntInput | null,
  remarks?: ModelStringInput | null,
  created_at?: ModelStringInput | null,
  updated_at?: ModelStringInput | null,
  labels?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  connected_id?: ModelStringInput | null,
  and?: Array< ModelAuthoriFilterInput | null > | null,
  or?: Array< ModelAuthoriFilterInput | null > | null,
  not?: ModelAuthoriFilterInput | null,
};

export type ModelAuthoriConnection = {
  __typename: "ModelAuthoriConnection",
  items:  Array<Authori | null >,
  nextToken?: string | null,
};

export type ModelAuthoriSummaryMonthlyFilterInput = {
  companyId?: ModelStringInput | null,
  yearMonth?: ModelStringInput | null,
  ok?: ModelIntInput | null,
  ng?: ModelIntInput | null,
  review?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAuthoriSummaryMonthlyFilterInput | null > | null,
  or?: Array< ModelAuthoriSummaryMonthlyFilterInput | null > | null,
  not?: ModelAuthoriSummaryMonthlyFilterInput | null,
};

export type ModelAuthoriSummaryMonthlyConnection = {
  __typename: "ModelAuthoriSummaryMonthlyConnection",
  items:  Array<AuthoriSummaryMonthly | null >,
  nextToken?: string | null,
};

export type ModelCompanyFilterInput = {
  companyId?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  companyCode?: ModelStringInput | null,
  formalName?: ModelStringInput | null,
  hashSalt?: ModelStringInput | null,
  authoriExportLimit?: ModelIntInput | null,
  allowedBy?: ModelAccountTypeInput | null,
  status?: ModelCompanyStatusInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection",
  items:  Array<Company | null >,
  nextToken?: string | null,
};

export type ModelCompanyAddonV2FilterInput = {
  companyId?: ModelStringInput | null,
  addonId?: ModelAddonIdInput | null,
  name?: ModelStringInput | null,
  displayWeight?: ModelIntInput | null,
  companyEmail?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  allowedBy?: ModelAccountTypeInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCompanyAddonV2FilterInput | null > | null,
  or?: Array< ModelCompanyAddonV2FilterInput | null > | null,
  not?: ModelCompanyAddonV2FilterInput | null,
};

export type ModelAddonIdInput = {
  eq?: AddonId | null,
  ne?: AddonId | null,
};

export type ModelCompanyAddonV2Connection = {
  __typename: "ModelCompanyAddonV2Connection",
  items:  Array<CompanyAddonV2 | null >,
  nextToken?: string | null,
};

export type ModelCompanyReportSettingFilterInput = {
  companyId?: ModelIDInput | null,
  allowedBy?: ModelAccountTypeInput | null,
  createdBy?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  and?: Array< ModelCompanyReportSettingFilterInput | null > | null,
  or?: Array< ModelCompanyReportSettingFilterInput | null > | null,
  not?: ModelCompanyReportSettingFilterInput | null,
};

export type ModelCompanyReportSettingConnection = {
  __typename: "ModelCompanyReportSettingConnection",
  items:  Array<CompanyReportSetting | null >,
  nextToken?: string | null,
};

export type ModelExportRequestFilterInput = {
  id?: ModelIDInput | null,
  companyId?: ModelStringInput | null,
  event?: ModelExportEventInput | null,
  accountName?: ModelStringInput | null,
  rangeFrom?: ModelStringInput | null,
  rangeTo?: ModelStringInput | null,
  charset?: ModelCharsetInput | null,
  createdAt?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  aggregationUnit?: ModelTimeUnitInput | null,
  and?: Array< ModelExportRequestFilterInput | null > | null,
  or?: Array< ModelExportRequestFilterInput | null > | null,
  not?: ModelExportRequestFilterInput | null,
};

export type ModelExportRequestConnection = {
  __typename: "ModelExportRequestConnection",
  items:  Array<ExportRequest | null >,
  nextToken?: string | null,
};

export type ModelExportResultFilterInput = {
  companyId?: ModelStringInput | null,
  exportRequestId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  status?: ModelExportStatusInput | null,
  event?: ModelExportEventInput | null,
  accountName?: ModelStringInput | null,
  rangeFrom?: ModelStringInput | null,
  rangeTo?: ModelStringInput | null,
  charset?: ModelCharsetInput | null,
  requestAt?: ModelStringInput | null,
  exportedAt?: ModelStringInput | null,
  expiredAt?: ModelStringInput | null,
  count?: ModelIntInput | null,
  ttl?: ModelIntInput | null,
  companyIdAndEvent?: ModelStringInput | null,
  aggregationUnit?: ModelTimeUnitInput | null,
  and?: Array< ModelExportResultFilterInput | null > | null,
  or?: Array< ModelExportResultFilterInput | null > | null,
  not?: ModelExportResultFilterInput | null,
};

export type ModelExportResultConnection = {
  __typename: "ModelExportResultConnection",
  items:  Array<ExportResult | null >,
  nextToken?: string | null,
};

export type ModelInquiryFilterInput = {
  inquiryId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  companyName?: ModelStringInput | null,
  accountId?: ModelIDInput | null,
  accountName?: ModelStringInput | null,
  accountEmail?: ModelStringInput | null,
  title?: ModelStringInput | null,
  status?: ModelInquiryStatusInput | null,
  category?: ModelInquiryCategoryInput | null,
  accessLevel?: ModelAccessLevelInput | null,
  referrerUrl?: ModelStringInput | null,
  allowedBy?: ModelAccountTypeInput | null,
  companyIdOrAccountId?: ModelStringInput | null,
  companyIdOrAccountIdAndStatus?: ModelStringInput | null,
  companyIdOrAccountIdAndCategory?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInquiryFilterInput | null > | null,
  or?: Array< ModelInquiryFilterInput | null > | null,
  not?: ModelInquiryFilterInput | null,
};

export type ModelInquiryConnection = {
  __typename: "ModelInquiryConnection",
  items:  Array<Inquiry | null >,
  nextToken?: string | null,
};

export type ModelInquiryMessageFilterInput = {
  inquiryMessageId?: ModelIDInput | null,
  inquiryId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  senderAccountType?: ModelAccountTypeInput | null,
  senderName?: ModelStringInput | null,
  body?: ModelStringInput | null,
  fileKeys?: ModelStringInput | null,
  cognitoUserIdentityId?: ModelStringInput | null,
  allowedBy?: ModelAccountTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelInquiryMessageFilterInput | null > | null,
  or?: Array< ModelInquiryMessageFilterInput | null > | null,
  not?: ModelInquiryMessageFilterInput | null,
};

export type ModelInquiryMessageConnection = {
  __typename: "ModelInquiryMessageConnection",
  items:  Array<InquiryMessage | null >,
  nextToken?: string | null,
};

export type ModelReportScheduleAccountFilterInput = {
  email?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  ipReportForced?: ModelBooleanInput | null,
  and?: Array< ModelReportScheduleAccountFilterInput | null > | null,
  or?: Array< ModelReportScheduleAccountFilterInput | null > | null,
  not?: ModelReportScheduleAccountFilterInput | null,
};

export type ModelReportScheduleAccountConnection = {
  __typename: "ModelReportScheduleAccountConnection",
  items:  Array<ReportScheduleAccount | null >,
  nextToken?: string | null,
};

export type ModelSavedSearchFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  pagePathSearchId?: ModelStringInput | null,
  pagePath?: ModelStringInput | null,
  searchId?: ModelStringInput | null,
  queryString?: ModelStringInput | null,
  savedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelSavedSearchFilterInput | null > | null,
  or?: Array< ModelSavedSearchFilterInput | null > | null,
  not?: ModelSavedSearchFilterInput | null,
};

export type ModelSavedSearchConnection = {
  __typename: "ModelSavedSearchConnection",
  items:  Array<SavedSearch | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionAuthoriFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  expirationUnixTime?: ModelSubscriptionIntInput | null,
  authori_id?: ModelSubscriptionStringInput | null,
  omotion_user_id?: ModelSubscriptionStringInput | null,
  company_id?: ModelSubscriptionStringInput | null,
  send_token?: ModelSubscriptionStringInput | null,
  event_id?: ModelSubscriptionStringInput | null,
  event_name?: ModelSubscriptionStringInput | null,
  user_id_hashed?: ModelSubscriptionStringInput | null,
  user_id_encrypted?: ModelSubscriptionStringInput | null,
  result?: ModelSubscriptionStringInput | null,
  final_result?: ModelSubscriptionStringInput | null,
  reason?: ModelSubscriptionStringInput | null,
  feedback?: ModelSubscriptionStringInput | null,
  feedback_comment?: ModelSubscriptionStringInput | null,
  feedbacked_at?: ModelSubscriptionStringInput | null,
  feedback_type?: ModelSubscriptionStringInput | null,
  feedback_account_id?: ModelSubscriptionStringInput | null,
  feedback_account_name?: ModelSubscriptionStringInput | null,
  user_device_id?: ModelSubscriptionStringInput | null,
  event_url?: ModelSubscriptionStringInput | null,
  login_success?: ModelSubscriptionBooleanInput | null,
  cookie?: ModelSubscriptionStringInput | null,
  etag?: ModelSubscriptionStringInput | null,
  local_storage?: ModelSubscriptionStringInput | null,
  input_type?: ModelSubscriptionStringInput | null,
  bot_flag?: ModelSubscriptionBooleanInput | null,
  bot_type?: ModelSubscriptionStringInput | null,
  did_short?: ModelSubscriptionStringInput | null,
  did_middle?: ModelSubscriptionStringInput | null,
  source_ip?: ModelSubscriptionStringInput | null,
  tor_ip_flag?: ModelSubscriptionBooleanInput | null,
  foreign_ip_flag?: ModelSubscriptionBooleanInput | null,
  useragent?: ModelSubscriptionStringInput | null,
  wt_device_category_name?: ModelSubscriptionStringInput | null,
  wt_os_name?: ModelSubscriptionStringInput | null,
  wt_os_version?: ModelSubscriptionStringInput | null,
  wt_browser_name?: ModelSubscriptionStringInput | null,
  wt_browser_version?: ModelSubscriptionStringInput | null,
  browser_language?: ModelSubscriptionStringInput | null,
  referer?: ModelSubscriptionStringInput | null,
  timezone_offset?: ModelSubscriptionIntInput | null,
  access_at?: ModelSubscriptionStringInput | null,
  send_at?: ModelSubscriptionStringInput | null,
  request_at?: ModelSubscriptionStringInput | null,
  began_at?: ModelSubscriptionStringInput | null,
  ended_at?: ModelSubscriptionStringInput | null,
  login_time?: ModelSubscriptionFloatInput | null,
  residence_time?: ModelSubscriptionIntInput | null,
  authori_time?: ModelSubscriptionIntInput | null,
  es_index_date?: ModelSubscriptionStringInput | null,
  es_company_index?: ModelSubscriptionStringInput | null,
  ip_version?: ModelSubscriptionStringInput | null,
  ip_country_code?: ModelSubscriptionStringInput | null,
  ip_country_a_name?: ModelSubscriptionStringInput | null,
  ip_country_j_name?: ModelSubscriptionStringInput | null,
  ip_pref_code?: ModelSubscriptionStringInput | null,
  ip_pref_a_name?: ModelSubscriptionStringInput | null,
  ip_pref_j_name?: ModelSubscriptionStringInput | null,
  ip_city_code?: ModelSubscriptionStringInput | null,
  ip_city_a_name?: ModelSubscriptionStringInput | null,
  ip_city_j_name?: ModelSubscriptionStringInput | null,
  ip_org_name?: ModelSubscriptionStringInput | null,
  ip_domain_name?: ModelSubscriptionStringInput | null,
  ip_line_j_name?: ModelSubscriptionStringInput | null,
  ip_proxy_flag?: ModelSubscriptionStringInput | null,
  ip_pref_cf_level?: ModelSubscriptionIntInput | null,
  ip_city_cf_level?: ModelSubscriptionIntInput | null,
  ip_line_cf_level?: ModelSubscriptionIntInput | null,
  ip_pref_location?: ModelSubscriptionStringInput | null,
  ip_city_location?: ModelSubscriptionStringInput | null,
  ip_read_time?: ModelSubscriptionIntInput | null,
  ip_created?: ModelSubscriptionBooleanInput | null,
  click_count?: ModelSubscriptionIntInput | null,
  keystroke_count?: ModelSubscriptionIntInput | null,
  mouse_count?: ModelSubscriptionIntInput | null,
  swipe_count?: ModelSubscriptionIntInput | null,
  remarks?: ModelSubscriptionStringInput | null,
  created_at?: ModelSubscriptionStringInput | null,
  updated_at?: ModelSubscriptionStringInput | null,
  labels?: ModelSubscriptionStringInput | null,
  memo?: ModelSubscriptionStringInput | null,
  connected_id?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAuthoriFilterInput | null > | null,
  or?: Array< ModelSubscriptionAuthoriFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionAuthoriSummaryMonthlyFilterInput = {
  yearMonth?: ModelSubscriptionStringInput | null,
  ok?: ModelSubscriptionIntInput | null,
  ng?: ModelSubscriptionIntInput | null,
  review?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAuthoriSummaryMonthlyFilterInput | null > | null,
  or?: Array< ModelSubscriptionAuthoriSummaryMonthlyFilterInput | null > | null,
};

export type ModelSubscriptionExportRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  event?: ModelSubscriptionStringInput | null,
  accountName?: ModelSubscriptionStringInput | null,
  rangeFrom?: ModelSubscriptionStringInput | null,
  rangeTo?: ModelSubscriptionStringInput | null,
  charset?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  aggregationUnit?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionExportRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionExportRequestFilterInput | null > | null,
};

export type ModelSubscriptionExportResultFilterInput = {
  exportRequestId?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  event?: ModelSubscriptionStringInput | null,
  accountName?: ModelSubscriptionStringInput | null,
  rangeFrom?: ModelSubscriptionStringInput | null,
  rangeTo?: ModelSubscriptionStringInput | null,
  charset?: ModelSubscriptionStringInput | null,
  requestAt?: ModelSubscriptionStringInput | null,
  exportedAt?: ModelSubscriptionStringInput | null,
  expiredAt?: ModelSubscriptionStringInput | null,
  count?: ModelSubscriptionIntInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  companyIdAndEvent?: ModelSubscriptionStringInput | null,
  aggregationUnit?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionExportResultFilterInput | null > | null,
  or?: Array< ModelSubscriptionExportResultFilterInput | null > | null,
};

export type ModelSubscriptionInquiryFilterInput = {
  inquiryId?: ModelSubscriptionIDInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  accountId?: ModelSubscriptionIDInput | null,
  accountName?: ModelSubscriptionStringInput | null,
  accountEmail?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  accessLevel?: ModelSubscriptionStringInput | null,
  referrerUrl?: ModelSubscriptionStringInput | null,
  companyIdOrAccountId?: ModelSubscriptionStringInput | null,
  companyIdOrAccountIdAndStatus?: ModelSubscriptionStringInput | null,
  companyIdOrAccountIdAndCategory?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInquiryFilterInput | null > | null,
  or?: Array< ModelSubscriptionInquiryFilterInput | null > | null,
};

export type ModelSubscriptionInquiryMessageFilterInput = {
  inquiryMessageId?: ModelSubscriptionIDInput | null,
  inquiryId?: ModelSubscriptionIDInput | null,
  senderAccountType?: ModelSubscriptionStringInput | null,
  senderName?: ModelSubscriptionStringInput | null,
  body?: ModelSubscriptionStringInput | null,
  fileKeys?: ModelSubscriptionStringInput | null,
  cognitoUserIdentityId?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInquiryMessageFilterInput | null > | null,
  or?: Array< ModelSubscriptionInquiryMessageFilterInput | null > | null,
};

export type ModelSubscriptionSavedSearchFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionStringInput | null,
  pagePathSearchId?: ModelSubscriptionStringInput | null,
  pagePath?: ModelSubscriptionStringInput | null,
  searchId?: ModelSubscriptionStringInput | null,
  queryString?: ModelSubscriptionStringInput | null,
  savedAt?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSavedSearchFilterInput | null > | null,
  or?: Array< ModelSubscriptionSavedSearchFilterInput | null > | null,
};

export type DummyMutationVariables = {
};

export type DummyMutation = {
  dummy:  {
    __typename: "LambdaResolverError",
    status: number,
    errorCode: LambdaResolverErrorCode,
    message: string,
    name: string,
    stack?: string | null,
    cause?: string | null,
  },
};

export type CreateAccountByIamMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountByIamMutation = {
  createAccountByIam?:  {
    __typename: "Account",
    email: string,
    companyId: string,
    name: string,
    role: AccountRole,
    createdAt: string,
    createdBy: string,
    updatedAt?: string | null,
    updatedBy: string,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    email: string,
    companyId: string,
    name: string,
    role: AccountRole,
    createdAt: string,
    createdBy: string,
    updatedAt?: string | null,
    updatedBy: string,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    email: string,
    companyId: string,
    name: string,
    role: AccountRole,
    createdAt: string,
    createdBy: string,
    updatedAt?: string | null,
    updatedBy: string,
  } | null,
};

export type CreateManagementAccountByIamMutationVariables = {
  input: CreateManagementAccountInput,
  condition?: ModelManagementAccountConditionInput | null,
};

export type CreateManagementAccountByIamMutation = {
  createManagementAccountByIam?:  {
    __typename: "ManagementAccount",
    email: string,
    name: string,
    role: AccountRole,
    allowedBy: AccountType,
    assumedCompanyId: string,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type UpdateManagementAccountMutationVariables = {
  input: UpdateManagementAccountInput,
  condition?: ModelManagementAccountConditionInput | null,
};

export type UpdateManagementAccountMutation = {
  updateManagementAccount?:  {
    __typename: "ManagementAccount",
    email: string,
    name: string,
    role: AccountRole,
    allowedBy: AccountType,
    assumedCompanyId: string,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type DeleteManagementAccountMutationVariables = {
  input: DeleteManagementAccountInput,
  condition?: ModelManagementAccountConditionInput | null,
};

export type DeleteManagementAccountMutation = {
  deleteManagementAccount?:  {
    __typename: "ManagementAccount",
    email: string,
    name: string,
    role: AccountRole,
    allowedBy: AccountType,
    assumedCompanyId: string,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type CreateAuthoriMutationVariables = {
  input: CreateAuthoriInput,
  condition?: ModelAuthoriConditionInput | null,
};

export type CreateAuthoriMutation = {
  createAuthori?:  {
    __typename: "Authori",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    authori_id: string,
    omotion_user_id: string,
    company_id: string,
    send_token?: string | null,
    event_id?: string | null,
    event_name?: string | null,
    user_id_hashed?: string | null,
    user_id_encrypted?: string | null,
    result?: AuthoriResult | null,
    final_result?: AuthoriResult | null,
    reason?: AuthoriReason | null,
    feedback?: AuthoriResult | null,
    feedback_comment?: string | null,
    feedbacked_at?: string | null,
    feedback_type?: FeedbackType | null,
    feedback_account_id?: string | null,
    feedback_account_name?: string | null,
    user_device_id?: string | null,
    event_url?: string | null,
    login_success?: boolean | null,
    cookie?: string | null,
    etag?: string | null,
    local_storage?: string | null,
    input_type?: AuthoriInputType | null,
    bot_flag?: boolean | null,
    bot_type?: string | null,
    did_short?: string | null,
    did_middle?: string | null,
    source_ip?: string | null,
    tor_ip_flag?: boolean | null,
    foreign_ip_flag?: boolean | null,
    useragent?: string | null,
    wt_device_category_name?: string | null,
    wt_os_name?: string | null,
    wt_os_version?: string | null,
    wt_browser_name?: string | null,
    wt_browser_version?: string | null,
    browser_language?: string | null,
    referer?: string | null,
    timezone_offset?: number | null,
    access_at?: string | null,
    send_at?: string | null,
    request_at?: string | null,
    began_at?: string | null,
    ended_at?: string | null,
    login_time?: number | null,
    residence_time?: number | null,
    authori_time?: number | null,
    es_index_date?: string | null,
    es_company_index?: string | null,
    ip_version?: string | null,
    ip_country_code?: string | null,
    ip_country_a_name?: string | null,
    ip_country_j_name?: string | null,
    ip_pref_code?: string | null,
    ip_pref_a_name?: string | null,
    ip_pref_j_name?: string | null,
    ip_city_code?: string | null,
    ip_city_a_name?: string | null,
    ip_city_j_name?: string | null,
    ip_org_name?: string | null,
    ip_domain_name?: string | null,
    ip_line_j_name?: string | null,
    ip_proxy_flag?: string | null,
    ip_pref_cf_level?: number | null,
    ip_city_cf_level?: number | null,
    ip_line_cf_level?: number | null,
    ip_pref_location?: string | null,
    ip_city_location?: string | null,
    ip_read_time?: number | null,
    ip_created?: boolean | null,
    click_count?: number | null,
    keystroke_count?: number | null,
    mouse_count?: number | null,
    swipe_count?: number | null,
    remarks?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    labels?: string | null,
    memo?: string | null,
    connected_id?: string | null,
  } | null,
};

export type UpdateAuthoriMutationVariables = {
  input: UpdateAuthoriInput,
  condition?: ModelAuthoriConditionInput | null,
};

export type UpdateAuthoriMutation = {
  updateAuthori?:  {
    __typename: "Authori",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    authori_id: string,
    omotion_user_id: string,
    company_id: string,
    send_token?: string | null,
    event_id?: string | null,
    event_name?: string | null,
    user_id_hashed?: string | null,
    user_id_encrypted?: string | null,
    result?: AuthoriResult | null,
    final_result?: AuthoriResult | null,
    reason?: AuthoriReason | null,
    feedback?: AuthoriResult | null,
    feedback_comment?: string | null,
    feedbacked_at?: string | null,
    feedback_type?: FeedbackType | null,
    feedback_account_id?: string | null,
    feedback_account_name?: string | null,
    user_device_id?: string | null,
    event_url?: string | null,
    login_success?: boolean | null,
    cookie?: string | null,
    etag?: string | null,
    local_storage?: string | null,
    input_type?: AuthoriInputType | null,
    bot_flag?: boolean | null,
    bot_type?: string | null,
    did_short?: string | null,
    did_middle?: string | null,
    source_ip?: string | null,
    tor_ip_flag?: boolean | null,
    foreign_ip_flag?: boolean | null,
    useragent?: string | null,
    wt_device_category_name?: string | null,
    wt_os_name?: string | null,
    wt_os_version?: string | null,
    wt_browser_name?: string | null,
    wt_browser_version?: string | null,
    browser_language?: string | null,
    referer?: string | null,
    timezone_offset?: number | null,
    access_at?: string | null,
    send_at?: string | null,
    request_at?: string | null,
    began_at?: string | null,
    ended_at?: string | null,
    login_time?: number | null,
    residence_time?: number | null,
    authori_time?: number | null,
    es_index_date?: string | null,
    es_company_index?: string | null,
    ip_version?: string | null,
    ip_country_code?: string | null,
    ip_country_a_name?: string | null,
    ip_country_j_name?: string | null,
    ip_pref_code?: string | null,
    ip_pref_a_name?: string | null,
    ip_pref_j_name?: string | null,
    ip_city_code?: string | null,
    ip_city_a_name?: string | null,
    ip_city_j_name?: string | null,
    ip_org_name?: string | null,
    ip_domain_name?: string | null,
    ip_line_j_name?: string | null,
    ip_proxy_flag?: string | null,
    ip_pref_cf_level?: number | null,
    ip_city_cf_level?: number | null,
    ip_line_cf_level?: number | null,
    ip_pref_location?: string | null,
    ip_city_location?: string | null,
    ip_read_time?: number | null,
    ip_created?: boolean | null,
    click_count?: number | null,
    keystroke_count?: number | null,
    mouse_count?: number | null,
    swipe_count?: number | null,
    remarks?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    labels?: string | null,
    memo?: string | null,
    connected_id?: string | null,
  } | null,
};

export type DeleteAuthoriMutationVariables = {
  input: DeleteAuthoriInput,
  condition?: ModelAuthoriConditionInput | null,
};

export type DeleteAuthoriMutation = {
  deleteAuthori?:  {
    __typename: "Authori",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    authori_id: string,
    omotion_user_id: string,
    company_id: string,
    send_token?: string | null,
    event_id?: string | null,
    event_name?: string | null,
    user_id_hashed?: string | null,
    user_id_encrypted?: string | null,
    result?: AuthoriResult | null,
    final_result?: AuthoriResult | null,
    reason?: AuthoriReason | null,
    feedback?: AuthoriResult | null,
    feedback_comment?: string | null,
    feedbacked_at?: string | null,
    feedback_type?: FeedbackType | null,
    feedback_account_id?: string | null,
    feedback_account_name?: string | null,
    user_device_id?: string | null,
    event_url?: string | null,
    login_success?: boolean | null,
    cookie?: string | null,
    etag?: string | null,
    local_storage?: string | null,
    input_type?: AuthoriInputType | null,
    bot_flag?: boolean | null,
    bot_type?: string | null,
    did_short?: string | null,
    did_middle?: string | null,
    source_ip?: string | null,
    tor_ip_flag?: boolean | null,
    foreign_ip_flag?: boolean | null,
    useragent?: string | null,
    wt_device_category_name?: string | null,
    wt_os_name?: string | null,
    wt_os_version?: string | null,
    wt_browser_name?: string | null,
    wt_browser_version?: string | null,
    browser_language?: string | null,
    referer?: string | null,
    timezone_offset?: number | null,
    access_at?: string | null,
    send_at?: string | null,
    request_at?: string | null,
    began_at?: string | null,
    ended_at?: string | null,
    login_time?: number | null,
    residence_time?: number | null,
    authori_time?: number | null,
    es_index_date?: string | null,
    es_company_index?: string | null,
    ip_version?: string | null,
    ip_country_code?: string | null,
    ip_country_a_name?: string | null,
    ip_country_j_name?: string | null,
    ip_pref_code?: string | null,
    ip_pref_a_name?: string | null,
    ip_pref_j_name?: string | null,
    ip_city_code?: string | null,
    ip_city_a_name?: string | null,
    ip_city_j_name?: string | null,
    ip_org_name?: string | null,
    ip_domain_name?: string | null,
    ip_line_j_name?: string | null,
    ip_proxy_flag?: string | null,
    ip_pref_cf_level?: number | null,
    ip_city_cf_level?: number | null,
    ip_line_cf_level?: number | null,
    ip_pref_location?: string | null,
    ip_city_location?: string | null,
    ip_read_time?: number | null,
    ip_created?: boolean | null,
    click_count?: number | null,
    keystroke_count?: number | null,
    mouse_count?: number | null,
    swipe_count?: number | null,
    remarks?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    labels?: string | null,
    memo?: string | null,
    connected_id?: string | null,
  } | null,
};

export type CreateAuthoriSummaryMonthlyMutationVariables = {
  input: CreateAuthoriSummaryMonthlyInput,
  condition?: ModelAuthoriSummaryMonthlyConditionInput | null,
};

export type CreateAuthoriSummaryMonthlyMutation = {
  createAuthoriSummaryMonthly?:  {
    __typename: "AuthoriSummaryMonthly",
    companyId: string,
    yearMonth: string,
    ok: number,
    ng: number,
    review: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAuthoriSummaryMonthlyMutationVariables = {
  input: UpdateAuthoriSummaryMonthlyInput,
  condition?: ModelAuthoriSummaryMonthlyConditionInput | null,
};

export type UpdateAuthoriSummaryMonthlyMutation = {
  updateAuthoriSummaryMonthly?:  {
    __typename: "AuthoriSummaryMonthly",
    companyId: string,
    yearMonth: string,
    ok: number,
    ng: number,
    review: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAuthoriSummaryMonthlyMutationVariables = {
  input: DeleteAuthoriSummaryMonthlyInput,
  condition?: ModelAuthoriSummaryMonthlyConditionInput | null,
};

export type DeleteAuthoriSummaryMonthlyMutation = {
  deleteAuthoriSummaryMonthly?:  {
    __typename: "AuthoriSummaryMonthly",
    companyId: string,
    yearMonth: string,
    ok: number,
    ng: number,
    review: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type CreateCompanyMutation = {
  createCompany?:  {
    __typename: "Company",
    companyId: string,
    companyName: string,
    companyCode: string,
    formalName: string,
    hashSalt?: string | null,
    authoriExportLimit?: number | null,
    allowedBy: AccountType,
    status: CompanyStatus,
    ipAccessControl?:  {
      __typename: "IpAccessControl",
      enable: boolean,
      ipEntry:  Array< {
        __typename: "IpEntry",
        ip: string,
        description: string,
      } >,
    } | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type UpdateCompanyMutation = {
  updateCompany?:  {
    __typename: "Company",
    companyId: string,
    companyName: string,
    companyCode: string,
    formalName: string,
    hashSalt?: string | null,
    authoriExportLimit?: number | null,
    allowedBy: AccountType,
    status: CompanyStatus,
    ipAccessControl?:  {
      __typename: "IpAccessControl",
      enable: boolean,
      ipEntry:  Array< {
        __typename: "IpEntry",
        ip: string,
        description: string,
      } >,
    } | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type DeleteCompanyMutation = {
  deleteCompany?:  {
    __typename: "Company",
    companyId: string,
    companyName: string,
    companyCode: string,
    formalName: string,
    hashSalt?: string | null,
    authoriExportLimit?: number | null,
    allowedBy: AccountType,
    status: CompanyStatus,
    ipAccessControl?:  {
      __typename: "IpAccessControl",
      enable: boolean,
      ipEntry:  Array< {
        __typename: "IpEntry",
        ip: string,
        description: string,
      } >,
    } | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCompanyAddonV2MutationVariables = {
  input: CreateCompanyAddonV2Input,
  condition?: ModelCompanyAddonV2ConditionInput | null,
};

export type CreateCompanyAddonV2Mutation = {
  createCompanyAddonV2?:  {
    __typename: "CompanyAddonV2",
    companyId: string,
    addonId: AddonId,
    name: string,
    displayWeight?: number | null,
    companyEmail?: string | null,
    enabled: boolean,
    allowedBy: AccountType,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompanyAddonV2MutationVariables = {
  input: UpdateCompanyAddonV2Input,
  condition?: ModelCompanyAddonV2ConditionInput | null,
};

export type UpdateCompanyAddonV2Mutation = {
  updateCompanyAddonV2?:  {
    __typename: "CompanyAddonV2",
    companyId: string,
    addonId: AddonId,
    name: string,
    displayWeight?: number | null,
    companyEmail?: string | null,
    enabled: boolean,
    allowedBy: AccountType,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompanyAddonV2MutationVariables = {
  input: DeleteCompanyAddonV2Input,
  condition?: ModelCompanyAddonV2ConditionInput | null,
};

export type DeleteCompanyAddonV2Mutation = {
  deleteCompanyAddonV2?:  {
    __typename: "CompanyAddonV2",
    companyId: string,
    addonId: AddonId,
    name: string,
    displayWeight?: number | null,
    companyEmail?: string | null,
    enabled: boolean,
    allowedBy: AccountType,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCompanyReportSettingMutationVariables = {
  input: CreateCompanyReportSettingInput,
  condition?: ModelCompanyReportSettingConditionInput | null,
};

export type CreateCompanyReportSettingMutation = {
  createCompanyReportSetting?:  {
    __typename: "CompanyReportSetting",
    companyId: string,
    ipReportSetting?:  {
      __typename: "IpReportSetting",
      foreignIp: boolean,
      proxyIp: boolean,
      torIp: boolean,
      country?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      domain?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      line?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      hourly:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
      daily:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
      weekly:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
    } | null,
    allowedBy: AccountType,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompanyReportSettingMutationVariables = {
  input: UpdateCompanyReportSettingInput,
  condition?: ModelCompanyReportSettingConditionInput | null,
};

export type UpdateCompanyReportSettingMutation = {
  updateCompanyReportSetting?:  {
    __typename: "CompanyReportSetting",
    companyId: string,
    ipReportSetting?:  {
      __typename: "IpReportSetting",
      foreignIp: boolean,
      proxyIp: boolean,
      torIp: boolean,
      country?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      domain?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      line?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      hourly:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
      daily:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
      weekly:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
    } | null,
    allowedBy: AccountType,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompanyReportSettingMutationVariables = {
  input: DeleteCompanyReportSettingInput,
  condition?: ModelCompanyReportSettingConditionInput | null,
};

export type DeleteCompanyReportSettingMutation = {
  deleteCompanyReportSetting?:  {
    __typename: "CompanyReportSetting",
    companyId: string,
    ipReportSetting?:  {
      __typename: "IpReportSetting",
      foreignIp: boolean,
      proxyIp: boolean,
      torIp: boolean,
      country?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      domain?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      line?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      hourly:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
      daily:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
      weekly:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
    } | null,
    allowedBy: AccountType,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateExportRequestMutationVariables = {
  input: CreateExportRequestInput,
  condition?: ModelExportRequestConditionInput | null,
};

export type CreateExportRequestMutation = {
  createExportRequest?:  {
    __typename: "ExportRequest",
    id: string,
    companyId: string,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    createdAt: string,
    ttl?: number | null,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateExportRequestMutationVariables = {
  input: UpdateExportRequestInput,
  condition?: ModelExportRequestConditionInput | null,
};

export type UpdateExportRequestMutation = {
  updateExportRequest?:  {
    __typename: "ExportRequest",
    id: string,
    companyId: string,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    createdAt: string,
    ttl?: number | null,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteExportRequestMutationVariables = {
  input: DeleteExportRequestInput,
  condition?: ModelExportRequestConditionInput | null,
};

export type DeleteExportRequestMutation = {
  deleteExportRequest?:  {
    __typename: "ExportRequest",
    id: string,
    companyId: string,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    createdAt: string,
    ttl?: number | null,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateExportResultMutationVariables = {
  input: CreateExportResultInput,
  condition?: ModelExportResultConditionInput | null,
};

export type CreateExportResultMutation = {
  createExportResult?:  {
    __typename: "ExportResult",
    companyId: string,
    exportRequestId: string,
    createdAt: string,
    status: ExportStatus,
    error?:  {
      __typename: "ExportError",
      errorType: ExportErrorType,
      errorCode: number,
    } | null,
    s3Object?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    requestAt: string,
    exportedAt: string,
    expiredAt: string,
    count?: number | null,
    ttl: number,
    companyIdAndEvent: string,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateExportResultMutationVariables = {
  input: UpdateExportResultInput,
  condition?: ModelExportResultConditionInput | null,
};

export type UpdateExportResultMutation = {
  updateExportResult?:  {
    __typename: "ExportResult",
    companyId: string,
    exportRequestId: string,
    createdAt: string,
    status: ExportStatus,
    error?:  {
      __typename: "ExportError",
      errorType: ExportErrorType,
      errorCode: number,
    } | null,
    s3Object?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    requestAt: string,
    exportedAt: string,
    expiredAt: string,
    count?: number | null,
    ttl: number,
    companyIdAndEvent: string,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteExportResultMutationVariables = {
  input: DeleteExportResultInput,
  condition?: ModelExportResultConditionInput | null,
};

export type DeleteExportResultMutation = {
  deleteExportResult?:  {
    __typename: "ExportResult",
    companyId: string,
    exportRequestId: string,
    createdAt: string,
    status: ExportStatus,
    error?:  {
      __typename: "ExportError",
      errorType: ExportErrorType,
      errorCode: number,
    } | null,
    s3Object?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    requestAt: string,
    exportedAt: string,
    expiredAt: string,
    count?: number | null,
    ttl: number,
    companyIdAndEvent: string,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateInquiryMutationVariables = {
  input: CreateInquiryInput,
  condition?: ModelInquiryConditionInput | null,
};

export type CreateInquiryMutation = {
  createInquiry?:  {
    __typename: "Inquiry",
    inquiryId: string,
    companyId: string,
    companyName: string,
    accountId: string,
    accountName: string,
    accountEmail: string,
    title: string,
    status: InquiryStatus,
    category: InquiryCategory,
    accessLevel: AccessLevel,
    referrerUrl?: string | null,
    allowedBy: AccountType,
    companyIdOrAccountId: string,
    companyIdOrAccountIdAndStatus: string,
    companyIdOrAccountIdAndCategory: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateInquiryMutationVariables = {
  input: UpdateInquiryInput,
  condition?: ModelInquiryConditionInput | null,
};

export type UpdateInquiryMutation = {
  updateInquiry?:  {
    __typename: "Inquiry",
    inquiryId: string,
    companyId: string,
    companyName: string,
    accountId: string,
    accountName: string,
    accountEmail: string,
    title: string,
    status: InquiryStatus,
    category: InquiryCategory,
    accessLevel: AccessLevel,
    referrerUrl?: string | null,
    allowedBy: AccountType,
    companyIdOrAccountId: string,
    companyIdOrAccountIdAndStatus: string,
    companyIdOrAccountIdAndCategory: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteInquiryMutationVariables = {
  input: DeleteInquiryInput,
  condition?: ModelInquiryConditionInput | null,
};

export type DeleteInquiryMutation = {
  deleteInquiry?:  {
    __typename: "Inquiry",
    inquiryId: string,
    companyId: string,
    companyName: string,
    accountId: string,
    accountName: string,
    accountEmail: string,
    title: string,
    status: InquiryStatus,
    category: InquiryCategory,
    accessLevel: AccessLevel,
    referrerUrl?: string | null,
    allowedBy: AccountType,
    companyIdOrAccountId: string,
    companyIdOrAccountIdAndStatus: string,
    companyIdOrAccountIdAndCategory: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInquiryMessageMutationVariables = {
  input: CreateInquiryMessageInput,
  condition?: ModelInquiryMessageConditionInput | null,
};

export type CreateInquiryMessageMutation = {
  createInquiryMessage?:  {
    __typename: "InquiryMessage",
    inquiryMessageId: string,
    inquiryId: string,
    companyId: string,
    senderAccountType: AccountType,
    senderName: string,
    body: string,
    fileKeys: Array< string >,
    cognitoUserIdentityId: string,
    allowedBy: AccountType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateInquiryMessageMutationVariables = {
  input: UpdateInquiryMessageInput,
  condition?: ModelInquiryMessageConditionInput | null,
};

export type UpdateInquiryMessageMutation = {
  updateInquiryMessage?:  {
    __typename: "InquiryMessage",
    inquiryMessageId: string,
    inquiryId: string,
    companyId: string,
    senderAccountType: AccountType,
    senderName: string,
    body: string,
    fileKeys: Array< string >,
    cognitoUserIdentityId: string,
    allowedBy: AccountType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteInquiryMessageMutationVariables = {
  input: DeleteInquiryMessageInput,
  condition?: ModelInquiryMessageConditionInput | null,
};

export type DeleteInquiryMessageMutation = {
  deleteInquiryMessage?:  {
    __typename: "InquiryMessage",
    inquiryMessageId: string,
    inquiryId: string,
    companyId: string,
    senderAccountType: AccountType,
    senderName: string,
    body: string,
    fileKeys: Array< string >,
    cognitoUserIdentityId: string,
    allowedBy: AccountType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReportScheduleAccountMutationVariables = {
  input: CreateReportScheduleAccountInput,
  condition?: ModelReportScheduleAccountConditionInput | null,
};

export type CreateReportScheduleAccountMutation = {
  createReportScheduleAccount?:  {
    __typename: "ReportScheduleAccount",
    email: string,
    companyId: string,
    schedule?:  {
      __typename: "Schedule",
      hourly?: boolean | null,
      daily?: boolean | null,
      weekly?: boolean | null,
    } | null,
    ipReportSchedule?:  {
      __typename: "Schedule",
      hourly?: boolean | null,
      daily?: boolean | null,
      weekly?: boolean | null,
    } | null,
    ipReportForced?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReportScheduleAccountMutationVariables = {
  input: UpdateReportScheduleAccountInput,
  condition?: ModelReportScheduleAccountConditionInput | null,
};

export type UpdateReportScheduleAccountMutation = {
  updateReportScheduleAccount?:  {
    __typename: "ReportScheduleAccount",
    email: string,
    companyId: string,
    schedule?:  {
      __typename: "Schedule",
      hourly?: boolean | null,
      daily?: boolean | null,
      weekly?: boolean | null,
    } | null,
    ipReportSchedule?:  {
      __typename: "Schedule",
      hourly?: boolean | null,
      daily?: boolean | null,
      weekly?: boolean | null,
    } | null,
    ipReportForced?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReportScheduleAccountMutationVariables = {
  input: DeleteReportScheduleAccountInput,
  condition?: ModelReportScheduleAccountConditionInput | null,
};

export type DeleteReportScheduleAccountMutation = {
  deleteReportScheduleAccount?:  {
    __typename: "ReportScheduleAccount",
    email: string,
    companyId: string,
    schedule?:  {
      __typename: "Schedule",
      hourly?: boolean | null,
      daily?: boolean | null,
      weekly?: boolean | null,
    } | null,
    ipReportSchedule?:  {
      __typename: "Schedule",
      hourly?: boolean | null,
      daily?: boolean | null,
      weekly?: boolean | null,
    } | null,
    ipReportForced?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSavedSearchMutationVariables = {
  input: CreateSavedSearchInput,
  condition?: ModelSavedSearchConditionInput | null,
};

export type CreateSavedSearchMutation = {
  createSavedSearch?:  {
    __typename: "SavedSearch",
    id: string,
    userId: string,
    pagePathSearchId: string,
    pagePath: string,
    searchId: string,
    queryString?: string | null,
    savedAt?: string | null,
    name: string,
    createdAt: string,
    updatedAt: string,
    companyId?: string | null,
  } | null,
};

export type UpdateSavedSearchMutationVariables = {
  input: UpdateSavedSearchInput,
  condition?: ModelSavedSearchConditionInput | null,
};

export type UpdateSavedSearchMutation = {
  updateSavedSearch?:  {
    __typename: "SavedSearch",
    id: string,
    userId: string,
    pagePathSearchId: string,
    pagePath: string,
    searchId: string,
    queryString?: string | null,
    savedAt?: string | null,
    name: string,
    createdAt: string,
    updatedAt: string,
    companyId?: string | null,
  } | null,
};

export type DeleteSavedSearchMutationVariables = {
  input: DeleteSavedSearchInput,
  condition?: ModelSavedSearchConditionInput | null,
};

export type DeleteSavedSearchMutation = {
  deleteSavedSearch?:  {
    __typename: "SavedSearch",
    id: string,
    userId: string,
    pagePathSearchId: string,
    pagePath: string,
    searchId: string,
    queryString?: string | null,
    savedAt?: string | null,
    name: string,
    createdAt: string,
    updatedAt: string,
    companyId?: string | null,
  } | null,
};

export type InviteAccountMutationVariables = {
  email: string,
};

export type InviteAccountMutation = {
  inviteAccount?:  {
    __typename: "InviteAccountResponse",
    success?: boolean | null,
  } | null,
};

export type CreateCompanyAccountMutationVariables = {
  input: CreateAccountInput,
};

export type CreateCompanyAccountMutation = {
  createCompanyAccount?:  {
    __typename: "Account",
    email: string,
    companyId: string,
    name: string,
    role: AccountRole,
    createdAt: string,
    createdBy: string,
    updatedAt?: string | null,
    updatedBy: string,
  } | null,
};

export type CreateManagementAccountMutationVariables = {
  input: CreateManagementAccountInput,
};

export type CreateManagementAccountMutation = {
  createManagementAccount?:  {
    __typename: "ManagementAccount",
    email: string,
    name: string,
    role: AccountRole,
    allowedBy: AccountType,
    assumedCompanyId: string,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type LogMutationVariables = {
  input?: LogInput | null,
};

export type LogMutation = {
  log?:  {
    __typename: "LogMutationResponse",
    success?: boolean | null,
  } | null,
};

export type AuthenticateMutationVariables = {
};

export type AuthenticateMutation = {
  authenticate:  {
    __typename: "AuthenticateResponse",
    status: number,
  },
};

export type DeleteCompanyInfraMutationVariables = {
  companyId: string,
};

export type DeleteCompanyInfraMutation = {
  deleteCompanyInfra:  {
    __typename: "DeleteCompanyInfraResponse",
    success: boolean,
  },
};

export type SearchAuthoriBySimpleParamsV2QueryVariables = {
  input: SearchAuthoriBySimpleParamsInput,
  fileDownload?: boolean | null,
};

export type SearchAuthoriBySimpleParamsV2Query = {
  searchAuthoriBySimpleParamsV2:  {
    __typename: "AuthoriResponse",
    total?: number | null,
    authori_list?:  Array< {
      __typename: "Authori",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      authori_id: string,
      omotion_user_id: string,
      company_id: string,
      send_token?: string | null,
      event_id?: string | null,
      event_name?: string | null,
      user_id_hashed?: string | null,
      user_id_encrypted?: string | null,
      result?: AuthoriResult | null,
      final_result?: AuthoriResult | null,
      reason?: AuthoriReason | null,
      feedback?: AuthoriResult | null,
      feedback_comment?: string | null,
      feedbacked_at?: string | null,
      feedback_type?: FeedbackType | null,
      feedback_account_id?: string | null,
      feedback_account_name?: string | null,
      user_device_id?: string | null,
      event_url?: string | null,
      login_success?: boolean | null,
      cookie?: string | null,
      etag?: string | null,
      local_storage?: string | null,
      input_type?: AuthoriInputType | null,
      bot_flag?: boolean | null,
      bot_type?: string | null,
      did_short?: string | null,
      did_middle?: string | null,
      source_ip?: string | null,
      tor_ip_flag?: boolean | null,
      foreign_ip_flag?: boolean | null,
      useragent?: string | null,
      wt_device_category_name?: string | null,
      wt_os_name?: string | null,
      wt_os_version?: string | null,
      wt_browser_name?: string | null,
      wt_browser_version?: string | null,
      browser_language?: string | null,
      referer?: string | null,
      timezone_offset?: number | null,
      access_at?: string | null,
      send_at?: string | null,
      request_at?: string | null,
      began_at?: string | null,
      ended_at?: string | null,
      login_time?: number | null,
      residence_time?: number | null,
      authori_time?: number | null,
      es_index_date?: string | null,
      es_company_index?: string | null,
      ip_version?: string | null,
      ip_country_code?: string | null,
      ip_country_a_name?: string | null,
      ip_country_j_name?: string | null,
      ip_pref_code?: string | null,
      ip_pref_a_name?: string | null,
      ip_pref_j_name?: string | null,
      ip_city_code?: string | null,
      ip_city_a_name?: string | null,
      ip_city_j_name?: string | null,
      ip_org_name?: string | null,
      ip_domain_name?: string | null,
      ip_line_j_name?: string | null,
      ip_proxy_flag?: string | null,
      ip_pref_cf_level?: number | null,
      ip_city_cf_level?: number | null,
      ip_line_cf_level?: number | null,
      ip_pref_location?: string | null,
      ip_city_location?: string | null,
      ip_read_time?: number | null,
      ip_created?: boolean | null,
      click_count?: number | null,
      keystroke_count?: number | null,
      mouse_count?: number | null,
      swipe_count?: number | null,
      remarks?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      labels?: string | null,
      memo?: string | null,
      connected_id?: string | null,
    } | null > | null,
    authori_file?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
  },
};

export type SearchAuthoriByAdvancedParamsV2QueryVariables = {
  input: SearchAuthoriByAdvancedParamsInput,
  fileDownload?: boolean | null,
};

export type SearchAuthoriByAdvancedParamsV2Query = {
  searchAuthoriByAdvancedParamsV2:  {
    __typename: "AuthoriResponse",
    total?: number | null,
    authori_list?:  Array< {
      __typename: "Authori",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      authori_id: string,
      omotion_user_id: string,
      company_id: string,
      send_token?: string | null,
      event_id?: string | null,
      event_name?: string | null,
      user_id_hashed?: string | null,
      user_id_encrypted?: string | null,
      result?: AuthoriResult | null,
      final_result?: AuthoriResult | null,
      reason?: AuthoriReason | null,
      feedback?: AuthoriResult | null,
      feedback_comment?: string | null,
      feedbacked_at?: string | null,
      feedback_type?: FeedbackType | null,
      feedback_account_id?: string | null,
      feedback_account_name?: string | null,
      user_device_id?: string | null,
      event_url?: string | null,
      login_success?: boolean | null,
      cookie?: string | null,
      etag?: string | null,
      local_storage?: string | null,
      input_type?: AuthoriInputType | null,
      bot_flag?: boolean | null,
      bot_type?: string | null,
      did_short?: string | null,
      did_middle?: string | null,
      source_ip?: string | null,
      tor_ip_flag?: boolean | null,
      foreign_ip_flag?: boolean | null,
      useragent?: string | null,
      wt_device_category_name?: string | null,
      wt_os_name?: string | null,
      wt_os_version?: string | null,
      wt_browser_name?: string | null,
      wt_browser_version?: string | null,
      browser_language?: string | null,
      referer?: string | null,
      timezone_offset?: number | null,
      access_at?: string | null,
      send_at?: string | null,
      request_at?: string | null,
      began_at?: string | null,
      ended_at?: string | null,
      login_time?: number | null,
      residence_time?: number | null,
      authori_time?: number | null,
      es_index_date?: string | null,
      es_company_index?: string | null,
      ip_version?: string | null,
      ip_country_code?: string | null,
      ip_country_a_name?: string | null,
      ip_country_j_name?: string | null,
      ip_pref_code?: string | null,
      ip_pref_a_name?: string | null,
      ip_pref_j_name?: string | null,
      ip_city_code?: string | null,
      ip_city_a_name?: string | null,
      ip_city_j_name?: string | null,
      ip_org_name?: string | null,
      ip_domain_name?: string | null,
      ip_line_j_name?: string | null,
      ip_proxy_flag?: string | null,
      ip_pref_cf_level?: number | null,
      ip_city_cf_level?: number | null,
      ip_line_cf_level?: number | null,
      ip_pref_location?: string | null,
      ip_city_location?: string | null,
      ip_read_time?: number | null,
      ip_created?: boolean | null,
      click_count?: number | null,
      keystroke_count?: number | null,
      mouse_count?: number | null,
      swipe_count?: number | null,
      remarks?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      labels?: string | null,
      memo?: string | null,
      connected_id?: string | null,
    } | null > | null,
    authori_file?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
  },
};

export type FindAuthoriV2QueryVariables = {
  authoriId: string,
};

export type FindAuthoriV2Query = {
  findAuthoriV2?:  {
    __typename: "Authori",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    authori_id: string,
    omotion_user_id: string,
    company_id: string,
    send_token?: string | null,
    event_id?: string | null,
    event_name?: string | null,
    user_id_hashed?: string | null,
    user_id_encrypted?: string | null,
    result?: AuthoriResult | null,
    final_result?: AuthoriResult | null,
    reason?: AuthoriReason | null,
    feedback?: AuthoriResult | null,
    feedback_comment?: string | null,
    feedbacked_at?: string | null,
    feedback_type?: FeedbackType | null,
    feedback_account_id?: string | null,
    feedback_account_name?: string | null,
    user_device_id?: string | null,
    event_url?: string | null,
    login_success?: boolean | null,
    cookie?: string | null,
    etag?: string | null,
    local_storage?: string | null,
    input_type?: AuthoriInputType | null,
    bot_flag?: boolean | null,
    bot_type?: string | null,
    did_short?: string | null,
    did_middle?: string | null,
    source_ip?: string | null,
    tor_ip_flag?: boolean | null,
    foreign_ip_flag?: boolean | null,
    useragent?: string | null,
    wt_device_category_name?: string | null,
    wt_os_name?: string | null,
    wt_os_version?: string | null,
    wt_browser_name?: string | null,
    wt_browser_version?: string | null,
    browser_language?: string | null,
    referer?: string | null,
    timezone_offset?: number | null,
    access_at?: string | null,
    send_at?: string | null,
    request_at?: string | null,
    began_at?: string | null,
    ended_at?: string | null,
    login_time?: number | null,
    residence_time?: number | null,
    authori_time?: number | null,
    es_index_date?: string | null,
    es_company_index?: string | null,
    ip_version?: string | null,
    ip_country_code?: string | null,
    ip_country_a_name?: string | null,
    ip_country_j_name?: string | null,
    ip_pref_code?: string | null,
    ip_pref_a_name?: string | null,
    ip_pref_j_name?: string | null,
    ip_city_code?: string | null,
    ip_city_a_name?: string | null,
    ip_city_j_name?: string | null,
    ip_org_name?: string | null,
    ip_domain_name?: string | null,
    ip_line_j_name?: string | null,
    ip_proxy_flag?: string | null,
    ip_pref_cf_level?: number | null,
    ip_city_cf_level?: number | null,
    ip_line_cf_level?: number | null,
    ip_pref_location?: string | null,
    ip_city_location?: string | null,
    ip_read_time?: number | null,
    ip_created?: boolean | null,
    click_count?: number | null,
    keystroke_count?: number | null,
    mouse_count?: number | null,
    swipe_count?: number | null,
    remarks?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    labels?: string | null,
    memo?: string | null,
    connected_id?: string | null,
  } | null,
};

export type ListAuthoriFeedbackQueryVariables = {
  authoriId: string,
};

export type ListAuthoriFeedbackQuery = {
  listAuthoriFeedback?:  Array< {
    __typename: "AuthoriFeedback",
    authoriId: string,
    feedbackedAt: string,
    companyId?: string | null,
    omotionUserId?: string | null,
    feedback?: AuthoriResult | null,
    type?: FeedbackType | null,
    comment?: string | null,
    accountId?: string | null,
    accountName?: string | null,
    createdAt?: string | null,
  } | null > | null,
};

export type SearchUserDeviceV2QueryVariables = {
  input: SearchUserDeviceInput,
};

export type SearchUserDeviceV2Query = {
  searchUserDeviceV2:  {
    __typename: "UserDeviceResponse",
    total: number,
    user_device_list:  Array< {
      __typename: "UserDevice",
      user_device_id?: string | null,
      es_user_device_id: string,
      es_company_index?: string | null,
      omotion_user_id?: string | null,
      user_id_hashed?: string | null,
      company_id?: string | null,
      status?: UserDeviceStatus | null,
      cookie?: string | null,
      etag?: string | null,
      local_storage?: string | null,
      did_short?: string | null,
      did_middle?: string | null,
      wt_device_category_name?: string | null,
      wt_os_name?: string | null,
      wt_browser_name?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      current_ip?: string | null,
    } | null >,
  },
};

export type CalcUsersOfDeviceV2QueryVariables = {
  input: CalcUsersOfDeviceInput,
};

export type CalcUsersOfDeviceV2Query = {
  calcUsersOfDeviceV2:  {
    __typename: "CalcUsersOfDeviceResponse",
    total: number,
    list?:  Array< {
      __typename: "UsersOfDevice",
      user_device_id?: string | null,
      count?: number | null,
    } | null > | null,
  },
};

export type CalcDevicesOfUserV2QueryVariables = {
  input: CalcDevicesOfUserInput,
};

export type CalcDevicesOfUserV2Query = {
  calcDevicesOfUserV2:  {
    __typename: "CalcDevicesOfUserResponse",
    total: number,
    list?:  Array< {
      __typename: "DevicesOfUser",
      user_id_hashed?: string | null,
      count?: number | null,
    } | null > | null,
  },
};

export type GetUserDeviceV2QueryVariables = {
  esUserDeviceId: string,
};

export type GetUserDeviceV2Query = {
  getUserDeviceV2?:  {
    __typename: "UserDevice",
    user_device_id?: string | null,
    es_user_device_id: string,
    es_company_index?: string | null,
    omotion_user_id?: string | null,
    user_id_hashed?: string | null,
    company_id?: string | null,
    status?: UserDeviceStatus | null,
    cookie?: string | null,
    etag?: string | null,
    local_storage?: string | null,
    did_short?: string | null,
    did_middle?: string | null,
    wt_device_category_name?: string | null,
    wt_os_name?: string | null,
    wt_browser_name?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    current_ip?: string | null,
  } | null,
};

export type AggregateAuthoriResultV2QueryVariables = {
  input: AggregateInput,
};

export type AggregateAuthoriResultV2Query = {
  aggregateAuthoriResultV2:  {
    __typename: "AggregateAuthoriResultResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateAuthoriResult",
      date?: string | null,
      count?: number | null,
      data?:  {
        __typename: "AggregateAuthoriResultData",
        ok?: number | null,
        ng?: number | null,
        review?: number | null,
      } | null,
    } | null >,
  },
};

export type AggregateAuthoriReasonReviewV2QueryVariables = {
  input: AggregateInput,
};

export type AggregateAuthoriReasonReviewV2Query = {
  aggregateAuthoriReasonReviewV2:  {
    __typename: "AggregateAuthoriReasonReviewResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateAuthoriReasonReview",
      date?: string | null,
      count?: number | null,
      data?:  {
        __typename: "AggregateAuthoriReasonReviewData",
        foreign_ip?: number | null,
        tor_ip?: number | null,
        user_device?: number | null,
        same_device?: number | null,
        ng_device?: number | null,
        bot_review?: number | null,
        foreign_ip_and_language_review?: number | null,
        first_user_device_count_over?: number | null,
      } | null,
    } | null >,
  },
};

export type AggregateAuthoriReasonNgV2QueryVariables = {
  input: AggregateInput,
};

export type AggregateAuthoriReasonNgV2Query = {
  aggregateAuthoriReasonNgV2:  {
    __typename: "AggregateAuthoriReasonNgResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateAuthoriReasonNg",
      date?: string | null,
      count?: number | null,
      data?:  {
        __typename: "AggregateAuthoriReasonNgData",
        bot_ng?: number | null,
        user_device?: number | null,
        ng_device?: number | null,
        foreign_ip_ng?: number | null,
        tor_ip_ng?: number | null,
        foreign_ip_and_language_ng?: number | null,
      } | null,
    } | null >,
  },
};

export type AggregateAuthoriTorIpV2QueryVariables = {
  input: AggregateInput,
};

export type AggregateAuthoriTorIpV2Query = {
  aggregateAuthoriTorIpV2:  {
    __typename: "AggregateAuthoriTorIpResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateAuthoriTorIp",
      date?: string | null,
      count?: number | null,
      data?:  {
        __typename: "AggregateAuthoriTorIpData",
        tor_ip?: number | null,
      } | null,
    } | null >,
  },
};

export type AggregateAuthoriBotV2QueryVariables = {
  input: AggregateInput,
};

export type AggregateAuthoriBotV2Query = {
  aggregateAuthoriBotV2:  {
    __typename: "AggregateAuthoriBotResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateAuthoriBot",
      date?: string | null,
      count?: number | null,
      data?:  {
        __typename: "AggregateAuthoriBotData",
        bot_flag?: number | null,
      } | null,
    } | null >,
  },
};

export type AggregateAuthoriForeignAccessV2QueryVariables = {
  input: AggregateInput,
};

export type AggregateAuthoriForeignAccessV2Query = {
  aggregateAuthoriForeignAccessV2:  {
    __typename: "AggregateAuthoriForeignAccessResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateAuthoriForeignAccess",
      count?: number | null,
      country?: string | null,
    } | null >,
  },
};

export type AggregateAuthoriForeignIpV2QueryVariables = {
  input: AggregateInput,
};

export type AggregateAuthoriForeignIpV2Query = {
  aggregateAuthoriForeignIpV2:  {
    __typename: "AggregateAuthoriForeignIpResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateAuthoriForeignIp",
      date?: string | null,
      count?: number | null,
      data?:  {
        __typename: "AggregateAuthoriForeignIpData",
        foreign_ip?: number | null,
      } | null,
    } | null >,
  },
};

export type AggregateAuthoriForeignLanguageV2QueryVariables = {
  input: AggregateInput,
};

export type AggregateAuthoriForeignLanguageV2Query = {
  aggregateAuthoriForeignLanguageV2:  {
    __typename: "AggregateAuthoriForeignLanguageResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateAuthoriForeignLanguage",
      date?: string | null,
      count?: number | null,
      data?:  {
        __typename: "AggregateAuthoriForeignLanguageData",
        foreign_language?: number | null,
      } | null,
    } | null >,
  },
};

export type AggregateDeviceStatusV2QueryVariables = {
  input: AggregateInput,
};

export type AggregateDeviceStatusV2Query = {
  aggregateDeviceStatusV2:  {
    __typename: "AggregateDeviceStatusResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateDeviceStatus",
      date?: string | null,
      count?: number | null,
      data?:  {
        __typename: "AggregateDeviceStatusData",
        valid?: number | null,
        invalid?: number | null,
        duplicated?: number | null,
        unidentified?: number | null,
      } | null,
    } | null >,
  },
};

export type AggregateAuthoriInputTypeV2QueryVariables = {
  input: AggregateInput,
};

export type AggregateAuthoriInputTypeV2Query = {
  aggregateAuthoriInputTypeV2:  {
    __typename: "AggregateAuthoriInputTypeResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateAuthoriInputType",
      date?: string | null,
      count?: number | null,
      data?:  {
        __typename: "AggregateAuthoriInputTypeData",
        software_keyboard?: number | null,
        auto_complete?: number | null,
        copy_and_paste?: number | null,
        drag_and_drop?: number | null,
        key_typed?: number | null,
        unknown?: number | null,
        none?: number | null,
      } | null,
    } | null >,
  },
};

export type AggregateAuthoriIpCityLocationV2QueryVariables = {
  input: AggregateAuthoriIpCityLocationInput,
};

export type AggregateAuthoriIpCityLocationV2Query = {
  aggregateAuthoriIpCityLocationV2:  {
    __typename: "AggregateAuthoriIpCityLocationResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateAuthoriIpCityLocation",
      count?: number | null,
      data?:  {
        __typename: "AggregateAuthoriIpCityLocationData",
        lat?: number | null,
        lon?: number | null,
        ip_city_a_name?: string | null,
      } | null,
    } | null >,
  },
};

export type AggregateByIpV2QueryVariables = {
  input: AggregateByIpInput,
};

export type AggregateByIpV2Query = {
  aggregateByIpV2:  {
    __typename: "AggregateByIpResponse",
    total: number,
    list:  Array< {
      __typename: "AggregateByIp",
      ip?: string | null,
      authori?:  {
        __typename: "AggregateCount",
        count?: number | null,
      } | null,
      user?:  {
        __typename: "AggregateCount",
        count?: number | null,
      } | null,
    } | null >,
  },
};

export type GetAuthoriCountV2QueryVariables = {
  input: AuthoriCountInput,
};

export type GetAuthoriCountV2Query = {
  getAuthoriCountV2?:  {
    __typename: "AuthoriCountResult",
    count?: number | null,
  } | null,
};

export type GetReportAuthoriV2QueryVariables = {
  input: ReportAuthoriInput,
};

export type GetReportAuthoriV2Query = {
  getReportAuthoriV2:  {
    __typename: "ReportAuthoriResponse",
    total: number,
    list:  Array< {
      __typename: "ReportResult",
      date: string,
      count: number,
      OK:  {
        __typename: "ReportResultCount",
        count: number,
      },
      NG:  {
        __typename: "ReportResultCount",
        count: number,
      },
      REVIEW:  {
        __typename: "ReportResultCount",
        count: number,
      },
    } >,
  },
};

export type GetAccountQueryVariables = {
  email: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    email: string,
    companyId: string,
    name: string,
    role: AccountRole,
    createdAt: string,
    createdBy: string,
    updatedAt?: string | null,
    updatedBy: string,
  } | null,
};

export type ListAccountsQueryVariables = {
  email?: string | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      email: string,
      companyId: string,
      name: string,
      role: AccountRole,
      createdAt: string,
      createdBy: string,
      updatedAt?: string | null,
      updatedBy: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAccountByCompanyIdAndCreatedAtQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountByCompanyIdAndCreatedAtQuery = {
  listAccountByCompanyIdAndCreatedAt?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      email: string,
      companyId: string,
      name: string,
      role: AccountRole,
      createdAt: string,
      createdBy: string,
      updatedAt?: string | null,
      updatedBy: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetManagementAccountQueryVariables = {
  email: string,
};

export type GetManagementAccountQuery = {
  getManagementAccount?:  {
    __typename: "ManagementAccount",
    email: string,
    name: string,
    role: AccountRole,
    allowedBy: AccountType,
    assumedCompanyId: string,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type ListManagementAccountsQueryVariables = {
  email?: string | null,
  filter?: ModelManagementAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListManagementAccountsQuery = {
  listManagementAccounts?:  {
    __typename: "ModelManagementAccountConnection",
    items:  Array< {
      __typename: "ManagementAccount",
      email: string,
      name: string,
      role: AccountRole,
      allowedBy: AccountType,
      assumedCompanyId: string,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListManagementAccountByAllowedByAndCreatedAtQueryVariables = {
  allowedBy: AccountType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelManagementAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListManagementAccountByAllowedByAndCreatedAtQuery = {
  listManagementAccountByAllowedByAndCreatedAt?:  {
    __typename: "ModelManagementAccountConnection",
    items:  Array< {
      __typename: "ManagementAccount",
      email: string,
      name: string,
      role: AccountRole,
      allowedBy: AccountType,
      assumedCompanyId: string,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAuthoriQueryVariables = {
  id: string,
};

export type GetAuthoriQuery = {
  getAuthori?:  {
    __typename: "Authori",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    authori_id: string,
    omotion_user_id: string,
    company_id: string,
    send_token?: string | null,
    event_id?: string | null,
    event_name?: string | null,
    user_id_hashed?: string | null,
    user_id_encrypted?: string | null,
    result?: AuthoriResult | null,
    final_result?: AuthoriResult | null,
    reason?: AuthoriReason | null,
    feedback?: AuthoriResult | null,
    feedback_comment?: string | null,
    feedbacked_at?: string | null,
    feedback_type?: FeedbackType | null,
    feedback_account_id?: string | null,
    feedback_account_name?: string | null,
    user_device_id?: string | null,
    event_url?: string | null,
    login_success?: boolean | null,
    cookie?: string | null,
    etag?: string | null,
    local_storage?: string | null,
    input_type?: AuthoriInputType | null,
    bot_flag?: boolean | null,
    bot_type?: string | null,
    did_short?: string | null,
    did_middle?: string | null,
    source_ip?: string | null,
    tor_ip_flag?: boolean | null,
    foreign_ip_flag?: boolean | null,
    useragent?: string | null,
    wt_device_category_name?: string | null,
    wt_os_name?: string | null,
    wt_os_version?: string | null,
    wt_browser_name?: string | null,
    wt_browser_version?: string | null,
    browser_language?: string | null,
    referer?: string | null,
    timezone_offset?: number | null,
    access_at?: string | null,
    send_at?: string | null,
    request_at?: string | null,
    began_at?: string | null,
    ended_at?: string | null,
    login_time?: number | null,
    residence_time?: number | null,
    authori_time?: number | null,
    es_index_date?: string | null,
    es_company_index?: string | null,
    ip_version?: string | null,
    ip_country_code?: string | null,
    ip_country_a_name?: string | null,
    ip_country_j_name?: string | null,
    ip_pref_code?: string | null,
    ip_pref_a_name?: string | null,
    ip_pref_j_name?: string | null,
    ip_city_code?: string | null,
    ip_city_a_name?: string | null,
    ip_city_j_name?: string | null,
    ip_org_name?: string | null,
    ip_domain_name?: string | null,
    ip_line_j_name?: string | null,
    ip_proxy_flag?: string | null,
    ip_pref_cf_level?: number | null,
    ip_city_cf_level?: number | null,
    ip_line_cf_level?: number | null,
    ip_pref_location?: string | null,
    ip_city_location?: string | null,
    ip_read_time?: number | null,
    ip_created?: boolean | null,
    click_count?: number | null,
    keystroke_count?: number | null,
    mouse_count?: number | null,
    swipe_count?: number | null,
    remarks?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    labels?: string | null,
    memo?: string | null,
    connected_id?: string | null,
  } | null,
};

export type ListAuthorisQueryVariables = {
  filter?: ModelAuthoriFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuthorisQuery = {
  listAuthoris?:  {
    __typename: "ModelAuthoriConnection",
    items:  Array< {
      __typename: "Authori",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      authori_id: string,
      omotion_user_id: string,
      company_id: string,
      send_token?: string | null,
      event_id?: string | null,
      event_name?: string | null,
      user_id_hashed?: string | null,
      user_id_encrypted?: string | null,
      result?: AuthoriResult | null,
      final_result?: AuthoriResult | null,
      reason?: AuthoriReason | null,
      feedback?: AuthoriResult | null,
      feedback_comment?: string | null,
      feedbacked_at?: string | null,
      feedback_type?: FeedbackType | null,
      feedback_account_id?: string | null,
      feedback_account_name?: string | null,
      user_device_id?: string | null,
      event_url?: string | null,
      login_success?: boolean | null,
      cookie?: string | null,
      etag?: string | null,
      local_storage?: string | null,
      input_type?: AuthoriInputType | null,
      bot_flag?: boolean | null,
      bot_type?: string | null,
      did_short?: string | null,
      did_middle?: string | null,
      source_ip?: string | null,
      tor_ip_flag?: boolean | null,
      foreign_ip_flag?: boolean | null,
      useragent?: string | null,
      wt_device_category_name?: string | null,
      wt_os_name?: string | null,
      wt_os_version?: string | null,
      wt_browser_name?: string | null,
      wt_browser_version?: string | null,
      browser_language?: string | null,
      referer?: string | null,
      timezone_offset?: number | null,
      access_at?: string | null,
      send_at?: string | null,
      request_at?: string | null,
      began_at?: string | null,
      ended_at?: string | null,
      login_time?: number | null,
      residence_time?: number | null,
      authori_time?: number | null,
      es_index_date?: string | null,
      es_company_index?: string | null,
      ip_version?: string | null,
      ip_country_code?: string | null,
      ip_country_a_name?: string | null,
      ip_country_j_name?: string | null,
      ip_pref_code?: string | null,
      ip_pref_a_name?: string | null,
      ip_pref_j_name?: string | null,
      ip_city_code?: string | null,
      ip_city_a_name?: string | null,
      ip_city_j_name?: string | null,
      ip_org_name?: string | null,
      ip_domain_name?: string | null,
      ip_line_j_name?: string | null,
      ip_proxy_flag?: string | null,
      ip_pref_cf_level?: number | null,
      ip_city_cf_level?: number | null,
      ip_line_cf_level?: number | null,
      ip_pref_location?: string | null,
      ip_city_location?: string | null,
      ip_read_time?: number | null,
      ip_created?: boolean | null,
      click_count?: number | null,
      keystroke_count?: number | null,
      mouse_count?: number | null,
      swipe_count?: number | null,
      remarks?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      labels?: string | null,
      memo?: string | null,
      connected_id?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAuthoriByCompanyIdQueryVariables = {
  company_id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAuthoriFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuthoriByCompanyIdQuery = {
  listAuthoriByCompanyId?:  {
    __typename: "ModelAuthoriConnection",
    items:  Array< {
      __typename: "Authori",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      expirationUnixTime?: number | null,
      authori_id: string,
      omotion_user_id: string,
      company_id: string,
      send_token?: string | null,
      event_id?: string | null,
      event_name?: string | null,
      user_id_hashed?: string | null,
      user_id_encrypted?: string | null,
      result?: AuthoriResult | null,
      final_result?: AuthoriResult | null,
      reason?: AuthoriReason | null,
      feedback?: AuthoriResult | null,
      feedback_comment?: string | null,
      feedbacked_at?: string | null,
      feedback_type?: FeedbackType | null,
      feedback_account_id?: string | null,
      feedback_account_name?: string | null,
      user_device_id?: string | null,
      event_url?: string | null,
      login_success?: boolean | null,
      cookie?: string | null,
      etag?: string | null,
      local_storage?: string | null,
      input_type?: AuthoriInputType | null,
      bot_flag?: boolean | null,
      bot_type?: string | null,
      did_short?: string | null,
      did_middle?: string | null,
      source_ip?: string | null,
      tor_ip_flag?: boolean | null,
      foreign_ip_flag?: boolean | null,
      useragent?: string | null,
      wt_device_category_name?: string | null,
      wt_os_name?: string | null,
      wt_os_version?: string | null,
      wt_browser_name?: string | null,
      wt_browser_version?: string | null,
      browser_language?: string | null,
      referer?: string | null,
      timezone_offset?: number | null,
      access_at?: string | null,
      send_at?: string | null,
      request_at?: string | null,
      began_at?: string | null,
      ended_at?: string | null,
      login_time?: number | null,
      residence_time?: number | null,
      authori_time?: number | null,
      es_index_date?: string | null,
      es_company_index?: string | null,
      ip_version?: string | null,
      ip_country_code?: string | null,
      ip_country_a_name?: string | null,
      ip_country_j_name?: string | null,
      ip_pref_code?: string | null,
      ip_pref_a_name?: string | null,
      ip_pref_j_name?: string | null,
      ip_city_code?: string | null,
      ip_city_a_name?: string | null,
      ip_city_j_name?: string | null,
      ip_org_name?: string | null,
      ip_domain_name?: string | null,
      ip_line_j_name?: string | null,
      ip_proxy_flag?: string | null,
      ip_pref_cf_level?: number | null,
      ip_city_cf_level?: number | null,
      ip_line_cf_level?: number | null,
      ip_pref_location?: string | null,
      ip_city_location?: string | null,
      ip_read_time?: number | null,
      ip_created?: boolean | null,
      click_count?: number | null,
      keystroke_count?: number | null,
      mouse_count?: number | null,
      swipe_count?: number | null,
      remarks?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      labels?: string | null,
      memo?: string | null,
      connected_id?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAuthoriSummaryMonthlyQueryVariables = {
  companyId: string,
  yearMonth: string,
};

export type GetAuthoriSummaryMonthlyQuery = {
  getAuthoriSummaryMonthly?:  {
    __typename: "AuthoriSummaryMonthly",
    companyId: string,
    yearMonth: string,
    ok: number,
    ng: number,
    review: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAuthoriSummaryMonthliesQueryVariables = {
  companyId?: string | null,
  yearMonth?: ModelStringKeyConditionInput | null,
  filter?: ModelAuthoriSummaryMonthlyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAuthoriSummaryMonthliesQuery = {
  listAuthoriSummaryMonthlies?:  {
    __typename: "ModelAuthoriSummaryMonthlyConnection",
    items:  Array< {
      __typename: "AuthoriSummaryMonthly",
      companyId: string,
      yearMonth: string,
      ok: number,
      ng: number,
      review: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  companyId: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    companyId: string,
    companyName: string,
    companyCode: string,
    formalName: string,
    hashSalt?: string | null,
    authoriExportLimit?: number | null,
    allowedBy: AccountType,
    status: CompanyStatus,
    ipAccessControl?:  {
      __typename: "IpAccessControl",
      enable: boolean,
      ipEntry:  Array< {
        __typename: "IpEntry",
        ip: string,
        description: string,
      } >,
    } | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompaniesQueryVariables = {
  companyId?: string | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompaniesQuery = {
  listCompanies?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      companyId: string,
      companyName: string,
      companyCode: string,
      formalName: string,
      hashSalt?: string | null,
      authoriExportLimit?: number | null,
      allowedBy: AccountType,
      status: CompanyStatus,
      ipAccessControl?:  {
        __typename: "IpAccessControl",
        enable: boolean,
        ipEntry:  Array< {
          __typename: "IpEntry",
          ip: string,
          description: string,
        } >,
      } | null,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCompanyByAllowedByAndCreatedAtQueryVariables = {
  allowedBy: AccountType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompanyByAllowedByAndCreatedAtQuery = {
  listCompanyByAllowedByAndCreatedAt?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      companyId: string,
      companyName: string,
      companyCode: string,
      formalName: string,
      hashSalt?: string | null,
      authoriExportLimit?: number | null,
      allowedBy: AccountType,
      status: CompanyStatus,
      ipAccessControl?:  {
        __typename: "IpAccessControl",
        enable: boolean,
        ipEntry:  Array< {
          __typename: "IpEntry",
          ip: string,
          description: string,
        } >,
      } | null,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyAddonV2QueryVariables = {
  companyId: string,
  addonId: AddonId,
};

export type GetCompanyAddonV2Query = {
  getCompanyAddonV2?:  {
    __typename: "CompanyAddonV2",
    companyId: string,
    addonId: AddonId,
    name: string,
    displayWeight?: number | null,
    companyEmail?: string | null,
    enabled: boolean,
    allowedBy: AccountType,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompanyAddonV2sQueryVariables = {
  companyId?: string | null,
  addonId?: ModelStringKeyConditionInput | null,
  filter?: ModelCompanyAddonV2FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompanyAddonV2sQuery = {
  listCompanyAddonV2s?:  {
    __typename: "ModelCompanyAddonV2Connection",
    items:  Array< {
      __typename: "CompanyAddonV2",
      companyId: string,
      addonId: AddonId,
      name: string,
      displayWeight?: number | null,
      companyEmail?: string | null,
      enabled: boolean,
      allowedBy: AccountType,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyReportSettingQueryVariables = {
  companyId: string,
};

export type GetCompanyReportSettingQuery = {
  getCompanyReportSetting?:  {
    __typename: "CompanyReportSetting",
    companyId: string,
    ipReportSetting?:  {
      __typename: "IpReportSetting",
      foreignIp: boolean,
      proxyIp: boolean,
      torIp: boolean,
      country?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      domain?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      line?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      hourly:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
      daily:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
      weekly:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
    } | null,
    allowedBy: AccountType,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompanyReportSettingsQueryVariables = {
  companyId?: string | null,
  filter?: ModelCompanyReportSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompanyReportSettingsQuery = {
  listCompanyReportSettings?:  {
    __typename: "ModelCompanyReportSettingConnection",
    items:  Array< {
      __typename: "CompanyReportSetting",
      companyId: string,
      ipReportSetting?:  {
        __typename: "IpReportSetting",
        foreignIp: boolean,
        proxyIp: boolean,
        torIp: boolean,
        country?:  {
          __typename: "IpReportSearchCondition",
          items: Array< string >,
          isInclude: boolean,
        } | null,
        domain?:  {
          __typename: "IpReportSearchCondition",
          items: Array< string >,
          isInclude: boolean,
        } | null,
        line?:  {
          __typename: "IpReportSearchCondition",
          items: Array< string >,
          isInclude: boolean,
        } | null,
        hourly:  {
          __typename: "IpReportThreshold",
          userThreshold: number,
          authoriThreshold: number,
        },
        daily:  {
          __typename: "IpReportThreshold",
          userThreshold: number,
          authoriThreshold: number,
        },
        weekly:  {
          __typename: "IpReportThreshold",
          userThreshold: number,
          authoriThreshold: number,
        },
      } | null,
      allowedBy: AccountType,
      createdBy: string,
      updatedBy: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExportRequestQueryVariables = {
  id: string,
};

export type GetExportRequestQuery = {
  getExportRequest?:  {
    __typename: "ExportRequest",
    id: string,
    companyId: string,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    createdAt: string,
    ttl?: number | null,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListExportRequestsQueryVariables = {
  filter?: ModelExportRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExportRequestsQuery = {
  listExportRequests?:  {
    __typename: "ModelExportRequestConnection",
    items:  Array< {
      __typename: "ExportRequest",
      id: string,
      companyId: string,
      event: ExportEvent,
      accountName: string,
      rangeFrom: string,
      rangeTo: string,
      conditions?:  {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null,
      charset: Charset,
      createdAt: string,
      ttl?: number | null,
      aggregationUnit?: TimeUnit | null,
      threshold?:  {
        __typename: "ExportThreshold",
        authoriStart: string,
        authoriEnd: string,
        userStart: string,
        userEnd: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExportResultQueryVariables = {
  companyId: string,
  exportRequestId: string,
};

export type GetExportResultQuery = {
  getExportResult?:  {
    __typename: "ExportResult",
    companyId: string,
    exportRequestId: string,
    createdAt: string,
    status: ExportStatus,
    error?:  {
      __typename: "ExportError",
      errorType: ExportErrorType,
      errorCode: number,
    } | null,
    s3Object?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    requestAt: string,
    exportedAt: string,
    expiredAt: string,
    count?: number | null,
    ttl: number,
    companyIdAndEvent: string,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListExportResultsQueryVariables = {
  companyId?: string | null,
  exportRequestId?: ModelStringKeyConditionInput | null,
  filter?: ModelExportResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListExportResultsQuery = {
  listExportResults?:  {
    __typename: "ModelExportResultConnection",
    items:  Array< {
      __typename: "ExportResult",
      companyId: string,
      exportRequestId: string,
      createdAt: string,
      status: ExportStatus,
      error?:  {
        __typename: "ExportError",
        errorType: ExportErrorType,
        errorCode: number,
      } | null,
      s3Object?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null,
      event: ExportEvent,
      accountName: string,
      rangeFrom: string,
      rangeTo: string,
      conditions?:  {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null,
      charset: Charset,
      requestAt: string,
      exportedAt: string,
      expiredAt: string,
      count?: number | null,
      ttl: number,
      companyIdAndEvent: string,
      aggregationUnit?: TimeUnit | null,
      threshold?:  {
        __typename: "ExportThreshold",
        authoriStart: string,
        authoriEnd: string,
        userStart: string,
        userEnd: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListExportResultByCompanyIdAndCreatedAtQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExportResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExportResultByCompanyIdAndCreatedAtQuery = {
  listExportResultByCompanyIdAndCreatedAt?:  {
    __typename: "ModelExportResultConnection",
    items:  Array< {
      __typename: "ExportResult",
      companyId: string,
      exportRequestId: string,
      createdAt: string,
      status: ExportStatus,
      error?:  {
        __typename: "ExportError",
        errorType: ExportErrorType,
        errorCode: number,
      } | null,
      s3Object?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null,
      event: ExportEvent,
      accountName: string,
      rangeFrom: string,
      rangeTo: string,
      conditions?:  {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null,
      charset: Charset,
      requestAt: string,
      exportedAt: string,
      expiredAt: string,
      count?: number | null,
      ttl: number,
      companyIdAndEvent: string,
      aggregationUnit?: TimeUnit | null,
      threshold?:  {
        __typename: "ExportThreshold",
        authoriStart: string,
        authoriEnd: string,
        userStart: string,
        userEnd: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListExportResultByCompanyIdAndEventAndCreatedAtQueryVariables = {
  companyIdAndEvent: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExportResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExportResultByCompanyIdAndEventAndCreatedAtQuery = {
  listExportResultByCompanyIdAndEventAndCreatedAt?:  {
    __typename: "ModelExportResultConnection",
    items:  Array< {
      __typename: "ExportResult",
      companyId: string,
      exportRequestId: string,
      createdAt: string,
      status: ExportStatus,
      error?:  {
        __typename: "ExportError",
        errorType: ExportErrorType,
        errorCode: number,
      } | null,
      s3Object?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null,
      event: ExportEvent,
      accountName: string,
      rangeFrom: string,
      rangeTo: string,
      conditions?:  {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null,
      charset: Charset,
      requestAt: string,
      exportedAt: string,
      expiredAt: string,
      count?: number | null,
      ttl: number,
      companyIdAndEvent: string,
      aggregationUnit?: TimeUnit | null,
      threshold?:  {
        __typename: "ExportThreshold",
        authoriStart: string,
        authoriEnd: string,
        userStart: string,
        userEnd: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInquiryQueryVariables = {
  inquiryId: string,
};

export type GetInquiryQuery = {
  getInquiry?:  {
    __typename: "Inquiry",
    inquiryId: string,
    companyId: string,
    companyName: string,
    accountId: string,
    accountName: string,
    accountEmail: string,
    title: string,
    status: InquiryStatus,
    category: InquiryCategory,
    accessLevel: AccessLevel,
    referrerUrl?: string | null,
    allowedBy: AccountType,
    companyIdOrAccountId: string,
    companyIdOrAccountIdAndStatus: string,
    companyIdOrAccountIdAndCategory: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListInquiriesQueryVariables = {
  inquiryId?: string | null,
  filter?: ModelInquiryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInquiriesQuery = {
  listInquiries?:  {
    __typename: "ModelInquiryConnection",
    items:  Array< {
      __typename: "Inquiry",
      inquiryId: string,
      companyId: string,
      companyName: string,
      accountId: string,
      accountName: string,
      accountEmail: string,
      title: string,
      status: InquiryStatus,
      category: InquiryCategory,
      accessLevel: AccessLevel,
      referrerUrl?: string | null,
      allowedBy: AccountType,
      companyIdOrAccountId: string,
      companyIdOrAccountIdAndStatus: string,
      companyIdOrAccountIdAndCategory: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInquiryByCompanyIdQueryVariables = {
  companyId: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInquiryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInquiryByCompanyIdQuery = {
  listInquiryByCompanyId?:  {
    __typename: "ModelInquiryConnection",
    items:  Array< {
      __typename: "Inquiry",
      inquiryId: string,
      companyId: string,
      companyName: string,
      accountId: string,
      accountName: string,
      accountEmail: string,
      title: string,
      status: InquiryStatus,
      category: InquiryCategory,
      accessLevel: AccessLevel,
      referrerUrl?: string | null,
      allowedBy: AccountType,
      companyIdOrAccountId: string,
      companyIdOrAccountIdAndStatus: string,
      companyIdOrAccountIdAndCategory: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInquiryByStatusQueryVariables = {
  status: InquiryStatus,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInquiryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInquiryByStatusQuery = {
  listInquiryByStatus?:  {
    __typename: "ModelInquiryConnection",
    items:  Array< {
      __typename: "Inquiry",
      inquiryId: string,
      companyId: string,
      companyName: string,
      accountId: string,
      accountName: string,
      accountEmail: string,
      title: string,
      status: InquiryStatus,
      category: InquiryCategory,
      accessLevel: AccessLevel,
      referrerUrl?: string | null,
      allowedBy: AccountType,
      companyIdOrAccountId: string,
      companyIdOrAccountIdAndStatus: string,
      companyIdOrAccountIdAndCategory: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInquiryByCategoryQueryVariables = {
  category: InquiryCategory,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInquiryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInquiryByCategoryQuery = {
  listInquiryByCategory?:  {
    __typename: "ModelInquiryConnection",
    items:  Array< {
      __typename: "Inquiry",
      inquiryId: string,
      companyId: string,
      companyName: string,
      accountId: string,
      accountName: string,
      accountEmail: string,
      title: string,
      status: InquiryStatus,
      category: InquiryCategory,
      accessLevel: AccessLevel,
      referrerUrl?: string | null,
      allowedBy: AccountType,
      companyIdOrAccountId: string,
      companyIdOrAccountIdAndStatus: string,
      companyIdOrAccountIdAndCategory: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInquiryByAllowedByQueryVariables = {
  allowedBy: AccountType,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInquiryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInquiryByAllowedByQuery = {
  listInquiryByAllowedBy?:  {
    __typename: "ModelInquiryConnection",
    items:  Array< {
      __typename: "Inquiry",
      inquiryId: string,
      companyId: string,
      companyName: string,
      accountId: string,
      accountName: string,
      accountEmail: string,
      title: string,
      status: InquiryStatus,
      category: InquiryCategory,
      accessLevel: AccessLevel,
      referrerUrl?: string | null,
      allowedBy: AccountType,
      companyIdOrAccountId: string,
      companyIdOrAccountIdAndStatus: string,
      companyIdOrAccountIdAndCategory: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInquiryBycompanyIdOrAccountIdQueryVariables = {
  companyIdOrAccountId: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInquiryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInquiryBycompanyIdOrAccountIdQuery = {
  listInquiryBycompanyIdOrAccountId?:  {
    __typename: "ModelInquiryConnection",
    items:  Array< {
      __typename: "Inquiry",
      inquiryId: string,
      companyId: string,
      companyName: string,
      accountId: string,
      accountName: string,
      accountEmail: string,
      title: string,
      status: InquiryStatus,
      category: InquiryCategory,
      accessLevel: AccessLevel,
      referrerUrl?: string | null,
      allowedBy: AccountType,
      companyIdOrAccountId: string,
      companyIdOrAccountIdAndStatus: string,
      companyIdOrAccountIdAndCategory: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInquiryBycompanyIdOrAccountIdAndStatusQueryVariables = {
  companyIdOrAccountIdAndStatus: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInquiryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInquiryBycompanyIdOrAccountIdAndStatusQuery = {
  listInquiryBycompanyIdOrAccountIdAndStatus?:  {
    __typename: "ModelInquiryConnection",
    items:  Array< {
      __typename: "Inquiry",
      inquiryId: string,
      companyId: string,
      companyName: string,
      accountId: string,
      accountName: string,
      accountEmail: string,
      title: string,
      status: InquiryStatus,
      category: InquiryCategory,
      accessLevel: AccessLevel,
      referrerUrl?: string | null,
      allowedBy: AccountType,
      companyIdOrAccountId: string,
      companyIdOrAccountIdAndStatus: string,
      companyIdOrAccountIdAndCategory: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInquiryBycompanyIdOrAccountIdAndCategoryQueryVariables = {
  companyIdOrAccountIdAndCategory: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInquiryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInquiryBycompanyIdOrAccountIdAndCategoryQuery = {
  listInquiryBycompanyIdOrAccountIdAndCategory?:  {
    __typename: "ModelInquiryConnection",
    items:  Array< {
      __typename: "Inquiry",
      inquiryId: string,
      companyId: string,
      companyName: string,
      accountId: string,
      accountName: string,
      accountEmail: string,
      title: string,
      status: InquiryStatus,
      category: InquiryCategory,
      accessLevel: AccessLevel,
      referrerUrl?: string | null,
      allowedBy: AccountType,
      companyIdOrAccountId: string,
      companyIdOrAccountIdAndStatus: string,
      companyIdOrAccountIdAndCategory: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInquiryMessageQueryVariables = {
  inquiryMessageId: string,
};

export type GetInquiryMessageQuery = {
  getInquiryMessage?:  {
    __typename: "InquiryMessage",
    inquiryMessageId: string,
    inquiryId: string,
    companyId: string,
    senderAccountType: AccountType,
    senderName: string,
    body: string,
    fileKeys: Array< string >,
    cognitoUserIdentityId: string,
    allowedBy: AccountType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListInquiryMessagesQueryVariables = {
  inquiryMessageId?: string | null,
  filter?: ModelInquiryMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInquiryMessagesQuery = {
  listInquiryMessages?:  {
    __typename: "ModelInquiryMessageConnection",
    items:  Array< {
      __typename: "InquiryMessage",
      inquiryMessageId: string,
      inquiryId: string,
      companyId: string,
      senderAccountType: AccountType,
      senderName: string,
      body: string,
      fileKeys: Array< string >,
      cognitoUserIdentityId: string,
      allowedBy: AccountType,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListInquiryMessageByInquiryIdQueryVariables = {
  inquiryId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInquiryMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInquiryMessageByInquiryIdQuery = {
  listInquiryMessageByInquiryId?:  {
    __typename: "ModelInquiryMessageConnection",
    items:  Array< {
      __typename: "InquiryMessage",
      inquiryMessageId: string,
      inquiryId: string,
      companyId: string,
      senderAccountType: AccountType,
      senderName: string,
      body: string,
      fileKeys: Array< string >,
      cognitoUserIdentityId: string,
      allowedBy: AccountType,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportScheduleAccountQueryVariables = {
  email: string,
};

export type GetReportScheduleAccountQuery = {
  getReportScheduleAccount?:  {
    __typename: "ReportScheduleAccount",
    email: string,
    companyId: string,
    schedule?:  {
      __typename: "Schedule",
      hourly?: boolean | null,
      daily?: boolean | null,
      weekly?: boolean | null,
    } | null,
    ipReportSchedule?:  {
      __typename: "Schedule",
      hourly?: boolean | null,
      daily?: boolean | null,
      weekly?: boolean | null,
    } | null,
    ipReportForced?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReportScheduleAccountsQueryVariables = {
  email?: string | null,
  filter?: ModelReportScheduleAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReportScheduleAccountsQuery = {
  listReportScheduleAccounts?:  {
    __typename: "ModelReportScheduleAccountConnection",
    items:  Array< {
      __typename: "ReportScheduleAccount",
      email: string,
      companyId: string,
      schedule?:  {
        __typename: "Schedule",
        hourly?: boolean | null,
        daily?: boolean | null,
        weekly?: boolean | null,
      } | null,
      ipReportSchedule?:  {
        __typename: "Schedule",
        hourly?: boolean | null,
        daily?: boolean | null,
        weekly?: boolean | null,
      } | null,
      ipReportForced?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSavedSearchQueryVariables = {
  id: string,
};

export type GetSavedSearchQuery = {
  getSavedSearch?:  {
    __typename: "SavedSearch",
    id: string,
    userId: string,
    pagePathSearchId: string,
    pagePath: string,
    searchId: string,
    queryString?: string | null,
    savedAt?: string | null,
    name: string,
    createdAt: string,
    updatedAt: string,
    companyId?: string | null,
  } | null,
};

export type ListSavedSearchesQueryVariables = {
  filter?: ModelSavedSearchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSavedSearchesQuery = {
  listSavedSearches?:  {
    __typename: "ModelSavedSearchConnection",
    items:  Array< {
      __typename: "SavedSearch",
      id: string,
      userId: string,
      pagePathSearchId: string,
      pagePath: string,
      searchId: string,
      queryString?: string | null,
      savedAt?: string | null,
      name: string,
      createdAt: string,
      updatedAt: string,
      companyId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListByUserAndPagePathSearchIdQueryVariables = {
  userId: string,
  pagePathSearchId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSavedSearchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListByUserAndPagePathSearchIdQuery = {
  listByUserAndPagePathSearchId?:  {
    __typename: "ModelSavedSearchConnection",
    items:  Array< {
      __typename: "SavedSearch",
      id: string,
      userId: string,
      pagePathSearchId: string,
      pagePath: string,
      searchId: string,
      queryString?: string | null,
      savedAt?: string | null,
      name: string,
      createdAt: string,
      updatedAt: string,
      companyId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListByUserAndSearchIdQueryVariables = {
  userId: string,
  searchId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSavedSearchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListByUserAndSearchIdQuery = {
  listByUserAndSearchId?:  {
    __typename: "ModelSavedSearchConnection",
    items:  Array< {
      __typename: "SavedSearch",
      id: string,
      userId: string,
      pagePathSearchId: string,
      pagePath: string,
      searchId: string,
      queryString?: string | null,
      savedAt?: string | null,
      name: string,
      createdAt: string,
      updatedAt: string,
      companyId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnUpdateExportResultByCompanyIdAndExportRequestIdSubscriptionVariables = {
  companyId: string,
  exportRequestId: string,
};

export type OnUpdateExportResultByCompanyIdAndExportRequestIdSubscription = {
  onUpdateExportResultByCompanyIdAndExportRequestId?:  {
    __typename: "ExportResult",
    companyId: string,
    exportRequestId: string,
    createdAt: string,
    status: ExportStatus,
    error?:  {
      __typename: "ExportError",
      errorType: ExportErrorType,
      errorCode: number,
    } | null,
    s3Object?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    requestAt: string,
    exportedAt: string,
    expiredAt: string,
    count?: number | null,
    ttl: number,
    companyIdAndEvent: string,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAuthoriSubscriptionVariables = {
  filter?: ModelSubscriptionAuthoriFilterInput | null,
};

export type OnCreateAuthoriSubscription = {
  onCreateAuthori?:  {
    __typename: "Authori",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    authori_id: string,
    omotion_user_id: string,
    company_id: string,
    send_token?: string | null,
    event_id?: string | null,
    event_name?: string | null,
    user_id_hashed?: string | null,
    user_id_encrypted?: string | null,
    result?: AuthoriResult | null,
    final_result?: AuthoriResult | null,
    reason?: AuthoriReason | null,
    feedback?: AuthoriResult | null,
    feedback_comment?: string | null,
    feedbacked_at?: string | null,
    feedback_type?: FeedbackType | null,
    feedback_account_id?: string | null,
    feedback_account_name?: string | null,
    user_device_id?: string | null,
    event_url?: string | null,
    login_success?: boolean | null,
    cookie?: string | null,
    etag?: string | null,
    local_storage?: string | null,
    input_type?: AuthoriInputType | null,
    bot_flag?: boolean | null,
    bot_type?: string | null,
    did_short?: string | null,
    did_middle?: string | null,
    source_ip?: string | null,
    tor_ip_flag?: boolean | null,
    foreign_ip_flag?: boolean | null,
    useragent?: string | null,
    wt_device_category_name?: string | null,
    wt_os_name?: string | null,
    wt_os_version?: string | null,
    wt_browser_name?: string | null,
    wt_browser_version?: string | null,
    browser_language?: string | null,
    referer?: string | null,
    timezone_offset?: number | null,
    access_at?: string | null,
    send_at?: string | null,
    request_at?: string | null,
    began_at?: string | null,
    ended_at?: string | null,
    login_time?: number | null,
    residence_time?: number | null,
    authori_time?: number | null,
    es_index_date?: string | null,
    es_company_index?: string | null,
    ip_version?: string | null,
    ip_country_code?: string | null,
    ip_country_a_name?: string | null,
    ip_country_j_name?: string | null,
    ip_pref_code?: string | null,
    ip_pref_a_name?: string | null,
    ip_pref_j_name?: string | null,
    ip_city_code?: string | null,
    ip_city_a_name?: string | null,
    ip_city_j_name?: string | null,
    ip_org_name?: string | null,
    ip_domain_name?: string | null,
    ip_line_j_name?: string | null,
    ip_proxy_flag?: string | null,
    ip_pref_cf_level?: number | null,
    ip_city_cf_level?: number | null,
    ip_line_cf_level?: number | null,
    ip_pref_location?: string | null,
    ip_city_location?: string | null,
    ip_read_time?: number | null,
    ip_created?: boolean | null,
    click_count?: number | null,
    keystroke_count?: number | null,
    mouse_count?: number | null,
    swipe_count?: number | null,
    remarks?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    labels?: string | null,
    memo?: string | null,
    connected_id?: string | null,
  } | null,
};

export type OnUpdateAuthoriSubscriptionVariables = {
  filter?: ModelSubscriptionAuthoriFilterInput | null,
};

export type OnUpdateAuthoriSubscription = {
  onUpdateAuthori?:  {
    __typename: "Authori",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    authori_id: string,
    omotion_user_id: string,
    company_id: string,
    send_token?: string | null,
    event_id?: string | null,
    event_name?: string | null,
    user_id_hashed?: string | null,
    user_id_encrypted?: string | null,
    result?: AuthoriResult | null,
    final_result?: AuthoriResult | null,
    reason?: AuthoriReason | null,
    feedback?: AuthoriResult | null,
    feedback_comment?: string | null,
    feedbacked_at?: string | null,
    feedback_type?: FeedbackType | null,
    feedback_account_id?: string | null,
    feedback_account_name?: string | null,
    user_device_id?: string | null,
    event_url?: string | null,
    login_success?: boolean | null,
    cookie?: string | null,
    etag?: string | null,
    local_storage?: string | null,
    input_type?: AuthoriInputType | null,
    bot_flag?: boolean | null,
    bot_type?: string | null,
    did_short?: string | null,
    did_middle?: string | null,
    source_ip?: string | null,
    tor_ip_flag?: boolean | null,
    foreign_ip_flag?: boolean | null,
    useragent?: string | null,
    wt_device_category_name?: string | null,
    wt_os_name?: string | null,
    wt_os_version?: string | null,
    wt_browser_name?: string | null,
    wt_browser_version?: string | null,
    browser_language?: string | null,
    referer?: string | null,
    timezone_offset?: number | null,
    access_at?: string | null,
    send_at?: string | null,
    request_at?: string | null,
    began_at?: string | null,
    ended_at?: string | null,
    login_time?: number | null,
    residence_time?: number | null,
    authori_time?: number | null,
    es_index_date?: string | null,
    es_company_index?: string | null,
    ip_version?: string | null,
    ip_country_code?: string | null,
    ip_country_a_name?: string | null,
    ip_country_j_name?: string | null,
    ip_pref_code?: string | null,
    ip_pref_a_name?: string | null,
    ip_pref_j_name?: string | null,
    ip_city_code?: string | null,
    ip_city_a_name?: string | null,
    ip_city_j_name?: string | null,
    ip_org_name?: string | null,
    ip_domain_name?: string | null,
    ip_line_j_name?: string | null,
    ip_proxy_flag?: string | null,
    ip_pref_cf_level?: number | null,
    ip_city_cf_level?: number | null,
    ip_line_cf_level?: number | null,
    ip_pref_location?: string | null,
    ip_city_location?: string | null,
    ip_read_time?: number | null,
    ip_created?: boolean | null,
    click_count?: number | null,
    keystroke_count?: number | null,
    mouse_count?: number | null,
    swipe_count?: number | null,
    remarks?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    labels?: string | null,
    memo?: string | null,
    connected_id?: string | null,
  } | null,
};

export type OnDeleteAuthoriSubscriptionVariables = {
  filter?: ModelSubscriptionAuthoriFilterInput | null,
};

export type OnDeleteAuthoriSubscription = {
  onDeleteAuthori?:  {
    __typename: "Authori",
    id: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    expirationUnixTime?: number | null,
    authori_id: string,
    omotion_user_id: string,
    company_id: string,
    send_token?: string | null,
    event_id?: string | null,
    event_name?: string | null,
    user_id_hashed?: string | null,
    user_id_encrypted?: string | null,
    result?: AuthoriResult | null,
    final_result?: AuthoriResult | null,
    reason?: AuthoriReason | null,
    feedback?: AuthoriResult | null,
    feedback_comment?: string | null,
    feedbacked_at?: string | null,
    feedback_type?: FeedbackType | null,
    feedback_account_id?: string | null,
    feedback_account_name?: string | null,
    user_device_id?: string | null,
    event_url?: string | null,
    login_success?: boolean | null,
    cookie?: string | null,
    etag?: string | null,
    local_storage?: string | null,
    input_type?: AuthoriInputType | null,
    bot_flag?: boolean | null,
    bot_type?: string | null,
    did_short?: string | null,
    did_middle?: string | null,
    source_ip?: string | null,
    tor_ip_flag?: boolean | null,
    foreign_ip_flag?: boolean | null,
    useragent?: string | null,
    wt_device_category_name?: string | null,
    wt_os_name?: string | null,
    wt_os_version?: string | null,
    wt_browser_name?: string | null,
    wt_browser_version?: string | null,
    browser_language?: string | null,
    referer?: string | null,
    timezone_offset?: number | null,
    access_at?: string | null,
    send_at?: string | null,
    request_at?: string | null,
    began_at?: string | null,
    ended_at?: string | null,
    login_time?: number | null,
    residence_time?: number | null,
    authori_time?: number | null,
    es_index_date?: string | null,
    es_company_index?: string | null,
    ip_version?: string | null,
    ip_country_code?: string | null,
    ip_country_a_name?: string | null,
    ip_country_j_name?: string | null,
    ip_pref_code?: string | null,
    ip_pref_a_name?: string | null,
    ip_pref_j_name?: string | null,
    ip_city_code?: string | null,
    ip_city_a_name?: string | null,
    ip_city_j_name?: string | null,
    ip_org_name?: string | null,
    ip_domain_name?: string | null,
    ip_line_j_name?: string | null,
    ip_proxy_flag?: string | null,
    ip_pref_cf_level?: number | null,
    ip_city_cf_level?: number | null,
    ip_line_cf_level?: number | null,
    ip_pref_location?: string | null,
    ip_city_location?: string | null,
    ip_read_time?: number | null,
    ip_created?: boolean | null,
    click_count?: number | null,
    keystroke_count?: number | null,
    mouse_count?: number | null,
    swipe_count?: number | null,
    remarks?: string | null,
    created_at?: string | null,
    updated_at?: string | null,
    labels?: string | null,
    memo?: string | null,
    connected_id?: string | null,
  } | null,
};

export type OnCreateAuthoriSummaryMonthlySubscriptionVariables = {
  filter?: ModelSubscriptionAuthoriSummaryMonthlyFilterInput | null,
  companyId?: string | null,
};

export type OnCreateAuthoriSummaryMonthlySubscription = {
  onCreateAuthoriSummaryMonthly?:  {
    __typename: "AuthoriSummaryMonthly",
    companyId: string,
    yearMonth: string,
    ok: number,
    ng: number,
    review: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAuthoriSummaryMonthlySubscriptionVariables = {
  filter?: ModelSubscriptionAuthoriSummaryMonthlyFilterInput | null,
  companyId?: string | null,
};

export type OnUpdateAuthoriSummaryMonthlySubscription = {
  onUpdateAuthoriSummaryMonthly?:  {
    __typename: "AuthoriSummaryMonthly",
    companyId: string,
    yearMonth: string,
    ok: number,
    ng: number,
    review: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAuthoriSummaryMonthlySubscriptionVariables = {
  filter?: ModelSubscriptionAuthoriSummaryMonthlyFilterInput | null,
  companyId?: string | null,
};

export type OnDeleteAuthoriSummaryMonthlySubscription = {
  onDeleteAuthoriSummaryMonthly?:  {
    __typename: "AuthoriSummaryMonthly",
    companyId: string,
    yearMonth: string,
    ok: number,
    ng: number,
    review: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateExportRequestSubscriptionVariables = {
  filter?: ModelSubscriptionExportRequestFilterInput | null,
  companyId?: string | null,
};

export type OnCreateExportRequestSubscription = {
  onCreateExportRequest?:  {
    __typename: "ExportRequest",
    id: string,
    companyId: string,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    createdAt: string,
    ttl?: number | null,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateExportRequestSubscriptionVariables = {
  filter?: ModelSubscriptionExportRequestFilterInput | null,
  companyId?: string | null,
};

export type OnUpdateExportRequestSubscription = {
  onUpdateExportRequest?:  {
    __typename: "ExportRequest",
    id: string,
    companyId: string,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    createdAt: string,
    ttl?: number | null,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteExportRequestSubscriptionVariables = {
  filter?: ModelSubscriptionExportRequestFilterInput | null,
  companyId?: string | null,
};

export type OnDeleteExportRequestSubscription = {
  onDeleteExportRequest?:  {
    __typename: "ExportRequest",
    id: string,
    companyId: string,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    createdAt: string,
    ttl?: number | null,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateExportResultByCompanyIdSubscriptionVariables = {
  filter?: ModelSubscriptionExportResultFilterInput | null,
  companyId?: string | null,
};

export type OnUpdateExportResultByCompanyIdSubscription = {
  onUpdateExportResultByCompanyId?:  {
    __typename: "ExportResult",
    companyId: string,
    exportRequestId: string,
    createdAt: string,
    status: ExportStatus,
    error?:  {
      __typename: "ExportError",
      errorType: ExportErrorType,
      errorCode: number,
    } | null,
    s3Object?:  {
      __typename: "S3Object",
      bucket: string,
      key: string,
      region: string,
    } | null,
    event: ExportEvent,
    accountName: string,
    rangeFrom: string,
    rangeTo: string,
    conditions?:  {
      __typename: "Condition",
      result?: Array< AuthoriResult | null > | null,
      final_result?: Array< AuthoriResult | null > | null,
      tor_ip_flag?: boolean | null,
      login_success?: Array< boolean | null > | null,
      or?:  Array< {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null,
    charset: Charset,
    requestAt: string,
    exportedAt: string,
    expiredAt: string,
    count?: number | null,
    ttl: number,
    companyIdAndEvent: string,
    aggregationUnit?: TimeUnit | null,
    threshold?:  {
      __typename: "ExportThreshold",
      authoriStart: string,
      authoriEnd: string,
      userStart: string,
      userEnd: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateInquirySubscriptionVariables = {
  filter?: ModelSubscriptionInquiryFilterInput | null,
  allowedBy?: string | null,
  companyId?: string | null,
};

export type OnCreateInquirySubscription = {
  onCreateInquiry?:  {
    __typename: "Inquiry",
    inquiryId: string,
    companyId: string,
    companyName: string,
    accountId: string,
    accountName: string,
    accountEmail: string,
    title: string,
    status: InquiryStatus,
    category: InquiryCategory,
    accessLevel: AccessLevel,
    referrerUrl?: string | null,
    allowedBy: AccountType,
    companyIdOrAccountId: string,
    companyIdOrAccountIdAndStatus: string,
    companyIdOrAccountIdAndCategory: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInquirySubscriptionVariables = {
  filter?: ModelSubscriptionInquiryFilterInput | null,
  allowedBy?: string | null,
  companyId?: string | null,
};

export type OnUpdateInquirySubscription = {
  onUpdateInquiry?:  {
    __typename: "Inquiry",
    inquiryId: string,
    companyId: string,
    companyName: string,
    accountId: string,
    accountName: string,
    accountEmail: string,
    title: string,
    status: InquiryStatus,
    category: InquiryCategory,
    accessLevel: AccessLevel,
    referrerUrl?: string | null,
    allowedBy: AccountType,
    companyIdOrAccountId: string,
    companyIdOrAccountIdAndStatus: string,
    companyIdOrAccountIdAndCategory: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteInquirySubscriptionVariables = {
  filter?: ModelSubscriptionInquiryFilterInput | null,
  allowedBy?: string | null,
  companyId?: string | null,
};

export type OnDeleteInquirySubscription = {
  onDeleteInquiry?:  {
    __typename: "Inquiry",
    inquiryId: string,
    companyId: string,
    companyName: string,
    accountId: string,
    accountName: string,
    accountEmail: string,
    title: string,
    status: InquiryStatus,
    category: InquiryCategory,
    accessLevel: AccessLevel,
    referrerUrl?: string | null,
    allowedBy: AccountType,
    companyIdOrAccountId: string,
    companyIdOrAccountIdAndStatus: string,
    companyIdOrAccountIdAndCategory: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateInquiryMessageSubscriptionVariables = {
  filter?: ModelSubscriptionInquiryMessageFilterInput | null,
  allowedBy?: string | null,
  companyId?: string | null,
};

export type OnCreateInquiryMessageSubscription = {
  onCreateInquiryMessage?:  {
    __typename: "InquiryMessage",
    inquiryMessageId: string,
    inquiryId: string,
    companyId: string,
    senderAccountType: AccountType,
    senderName: string,
    body: string,
    fileKeys: Array< string >,
    cognitoUserIdentityId: string,
    allowedBy: AccountType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInquiryMessageSubscriptionVariables = {
  filter?: ModelSubscriptionInquiryMessageFilterInput | null,
  allowedBy?: string | null,
  companyId?: string | null,
};

export type OnUpdateInquiryMessageSubscription = {
  onUpdateInquiryMessage?:  {
    __typename: "InquiryMessage",
    inquiryMessageId: string,
    inquiryId: string,
    companyId: string,
    senderAccountType: AccountType,
    senderName: string,
    body: string,
    fileKeys: Array< string >,
    cognitoUserIdentityId: string,
    allowedBy: AccountType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteInquiryMessageSubscriptionVariables = {
  filter?: ModelSubscriptionInquiryMessageFilterInput | null,
  allowedBy?: string | null,
  companyId?: string | null,
};

export type OnDeleteInquiryMessageSubscription = {
  onDeleteInquiryMessage?:  {
    __typename: "InquiryMessage",
    inquiryMessageId: string,
    inquiryId: string,
    companyId: string,
    senderAccountType: AccountType,
    senderName: string,
    body: string,
    fileKeys: Array< string >,
    cognitoUserIdentityId: string,
    allowedBy: AccountType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSavedSearchSubscriptionVariables = {
  filter?: ModelSubscriptionSavedSearchFilterInput | null,
  companyId?: string | null,
};

export type OnCreateSavedSearchSubscription = {
  onCreateSavedSearch?:  {
    __typename: "SavedSearch",
    id: string,
    userId: string,
    pagePathSearchId: string,
    pagePath: string,
    searchId: string,
    queryString?: string | null,
    savedAt?: string | null,
    name: string,
    createdAt: string,
    updatedAt: string,
    companyId?: string | null,
  } | null,
};

export type OnUpdateSavedSearchSubscriptionVariables = {
  filter?: ModelSubscriptionSavedSearchFilterInput | null,
  companyId?: string | null,
};

export type OnUpdateSavedSearchSubscription = {
  onUpdateSavedSearch?:  {
    __typename: "SavedSearch",
    id: string,
    userId: string,
    pagePathSearchId: string,
    pagePath: string,
    searchId: string,
    queryString?: string | null,
    savedAt?: string | null,
    name: string,
    createdAt: string,
    updatedAt: string,
    companyId?: string | null,
  } | null,
};

export type OnDeleteSavedSearchSubscriptionVariables = {
  filter?: ModelSubscriptionSavedSearchFilterInput | null,
  companyId?: string | null,
};

export type OnDeleteSavedSearchSubscription = {
  onDeleteSavedSearch?:  {
    __typename: "SavedSearch",
    id: string,
    userId: string,
    pagePathSearchId: string,
    pagePath: string,
    searchId: string,
    queryString?: string | null,
    savedAt?: string | null,
    name: string,
    createdAt: string,
    updatedAt: string,
    companyId?: string | null,
  } | null,
};

export type CreateCompanyAndCompanyReportSettingMutationVariables = {
  companyInput: CreateCompanyInput,
  companyReportSettingInput: CreateCompanyReportSettingInput,
};

export type CreateCompanyAndCompanyReportSettingMutation = {
  createCompany?:  {
    __typename: "Company",
    companyId: string,
    companyName: string,
    companyCode: string,
    formalName: string,
    authoriExportLimit?: number | null,
    allowedBy: AccountType,
    status: CompanyStatus,
    ipAccessControl?:  {
      __typename: "IpAccessControl",
      enable: boolean,
      ipEntry:  Array< {
        __typename: "IpEntry",
        ip: string,
        description: string,
      } >,
    } | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
  createCompanyReportSetting?:  {
    __typename: "CompanyReportSetting",
    companyId: string,
    ipReportSetting?:  {
      __typename: "IpReportSetting",
      foreignIp: boolean,
      proxyIp: boolean,
      torIp: boolean,
      country?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      domain?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      line?:  {
        __typename: "IpReportSearchCondition",
        items: Array< string >,
        isInclude: boolean,
      } | null,
      hourly:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
      daily:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
      weekly:  {
        __typename: "IpReportThreshold",
        userThreshold: number,
        authoriThreshold: number,
      },
    } | null,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInquiryAndInquiryMessageMutationVariables = {
  inquiryInput: CreateInquiryInput,
  inquiryMessageInput: CreateInquiryMessageInput,
};

export type CreateInquiryAndInquiryMessageMutation = {
  createInquiry?:  {
    __typename: "Inquiry",
    accessLevel: AccessLevel,
    accountEmail: string,
    accountId: string,
    accountName: string,
    allowedBy: AccountType,
    category: InquiryCategory,
    companyIdOrAccountId: string,
    companyIdOrAccountIdAndCategory: string,
    companyIdOrAccountIdAndStatus: string,
    companyName: string,
    inquiryId: string,
    createdAt: string,
    companyId: string,
    referrerUrl?: string | null,
    status: InquiryStatus,
    title: string,
    updatedAt: string,
  } | null,
  createInquiryMessage?:  {
    __typename: "InquiryMessage",
    cognitoUserIdentityId: string,
    body: string,
    allowedBy: AccountType,
    createdAt: string,
    fileKeys: Array< string >,
    inquiryMessageId: string,
    inquiryId: string,
    senderAccountType: AccountType,
    senderName: string,
    updatedAt: string,
  } | null,
};

export type ListBothExportResultsByCompanyIdAndEventAndCreatedAtQueryVariables = {
  companyIdAndEventByManagement: string,
  companyIdAndEvent: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExportResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBothExportResultsByCompanyIdAndEventAndCreatedAtQuery = {
  management?:  {
    __typename: "ModelExportResultConnection",
    items:  Array< {
      __typename: "ExportResult",
      companyId: string,
      exportRequestId: string,
      createdAt: string,
      status: ExportStatus,
      error?:  {
        __typename: "ExportError",
        errorType: ExportErrorType,
        errorCode: number,
      } | null,
      s3Object?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null,
      event: ExportEvent,
      accountName: string,
      rangeFrom: string,
      rangeTo: string,
      conditions?:  {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null,
      charset: Charset,
      requestAt: string,
      exportedAt: string,
      expiredAt: string,
      count?: number | null,
      ttl: number,
      companyIdAndEvent: string,
      aggregationUnit?: TimeUnit | null,
      threshold?:  {
        __typename: "ExportThreshold",
        authoriStart: string,
        authoriEnd: string,
        userStart: string,
        userEnd: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
  company?:  {
    __typename: "ModelExportResultConnection",
    items:  Array< {
      __typename: "ExportResult",
      companyId: string,
      exportRequestId: string,
      createdAt: string,
      status: ExportStatus,
      error?:  {
        __typename: "ExportError",
        errorType: ExportErrorType,
        errorCode: number,
      } | null,
      s3Object?:  {
        __typename: "S3Object",
        bucket: string,
        key: string,
        region: string,
      } | null,
      event: ExportEvent,
      accountName: string,
      rangeFrom: string,
      rangeTo: string,
      conditions?:  {
        __typename: "Condition",
        result?: Array< AuthoriResult | null > | null,
        final_result?: Array< AuthoriResult | null > | null,
        tor_ip_flag?: boolean | null,
        login_success?: Array< boolean | null > | null,
        or?:  Array< {
          __typename: "Condition",
          result?: Array< AuthoriResult | null > | null,
          final_result?: Array< AuthoriResult | null > | null,
          tor_ip_flag?: boolean | null,
          login_success?: Array< boolean | null > | null,
          or?:  Array< {
            __typename: "Condition",
            result?: Array< AuthoriResult | null > | null,
            final_result?: Array< AuthoriResult | null > | null,
            tor_ip_flag?: boolean | null,
            login_success?: Array< boolean | null > | null,
          } | null > | null,
        } | null > | null,
      } | null,
      charset: Charset,
      requestAt: string,
      exportedAt: string,
      expiredAt: string,
      count?: number | null,
      ttl: number,
      companyIdAndEvent: string,
      aggregationUnit?: TimeUnit | null,
      threshold?:  {
        __typename: "ExportThreshold",
        authoriStart: string,
        authoriEnd: string,
        userStart: string,
        userEnd: string,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};
