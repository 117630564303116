import { endOfDate } from 'src/common/utils/Date'
import Cookies from 'js-cookie'
import { OMOTI_SERVER_API } from 'src/omotion/omoti-server-api/constant'
import { AUTHORI_SEARCH_PATHS } from '../constants'
import type { AuthoriSearchPath } from '../types'

const COOKIE_NAMES = {
  searchCondition: 'searchCondition',
  lastAuthoriSearchedUrl: 'lastAuthoriSearchedUrl'
}

export const setSearchConditionToCookie = (searchCondition: string, type: AuthoriSearchPath['type']): void => {
  if (searchCondition.includes('?')) {
    const result = searchCondition.substring(searchCondition.indexOf('?') + 1)
    Cookies.set(`${COOKIE_NAMES.searchCondition}-${type}`, result, { expires: endOfDate(new Date()).toDate() })
  }
}

export const getSearchConditionFromCookie = (type: AuthoriSearchPath['type']): string | undefined => {
  return Cookies.get(`${COOKIE_NAMES.searchCondition}-${type}`)
}

export const setLastAuthoriSearchedUrlToCookie = (type: AuthoriSearchPath['type']): void => {
  const urlType = AUTHORI_SEARCH_PATHS.find((e) => e.type === type)?.path as string
  Cookies.set(COOKIE_NAMES.lastAuthoriSearchedUrl, urlType, { expires: endOfDate(new Date()).toDate() })
}

export const getLastAuthoriSearchedUrlFromCookie = (): string | undefined => {
  return Cookies.get(COOKIE_NAMES.lastAuthoriSearchedUrl)
}

export const getAuthoriIdFromCookie = (): string | undefined => {
  return Cookies.get(OMOTI_SERVER_API.KEY)
}

export const removeCookies = (): void => {
  Cookies.remove(`${COOKIE_NAMES.searchCondition}-advanced`)
  Cookies.remove(`${COOKIE_NAMES.searchCondition}-simple`)
  Cookies.remove(COOKIE_NAMES.lastAuthoriSearchedUrl)
}
