import React, { type ReactNode } from 'react'
import { useServiceStatus } from 'src/common/hooks'

export const CompanyStatusChecker: React.FC<{
  serviceType: 'COMPANYINSTALL' | 'ON_SERVICE'
  children: ReactNode
}> = ({ serviceType, children }) => {
  const { isCompanyInstall, isOnService } = useServiceStatus()

  if (
    // 加盟店導入
    (serviceType === 'COMPANYINSTALL' && isCompanyInstall) ||
    // オンサービス
    (serviceType === 'ON_SERVICE' && isOnService)
  ) {
    return <>{children}</>
  }
  return null
}
